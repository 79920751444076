import React from 'react';
import { Grid } from '@mui/material';
import uniq from 'lodash/uniq';
import Dropdown from '../../components/inputs/Dropdown';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import VEHICLES from '../../data/VEHICLES';
import { transformArchetypeToFossilVehicle } from '../../utils/conversions';

export default function VehicleSelect({ vehicleSet, onChange, isFossilVehicle, disabled }) {
  const { formatMessage } = useIntl();
  const { apiElectricVehicles } = useInputs();
  const { vehicle, fossilVehicle } = vehicleSet;
  const currentVehicle = isFossilVehicle ? fossilVehicle : vehicle;
  const { type, subtype } = currentVehicle || { type: '', subtype: '' };

  const vehicleList = isFossilVehicle
    ? VEHICLES.map(transformArchetypeToFossilVehicle)
    : apiElectricVehicles;
  const typeOptions = uniq(vehicleList.map(({ type }) => type))
    .map((type) => ({
      value: type,
      children: type,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const handleChangeType = (e) => {
    const newVehicle = vehicleList.find((v) => v.type === e.target.value);
    onChange({
      [isFossilVehicle ? 'fossilVehicle' : 'vehicle']: newVehicle,
      [isFossilVehicle ? 'fossilVehicleId' : 'vehicle_id']: newVehicle.id,
    });
  };

  const subtypeOptions = uniq(vehicleList.filter((v) => v.type === type).map((v) => v.subtype))
    .map((subtype) => ({ value: subtype, children: subtype }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const handleChangeSubtype = (e) => {
    const newVehicle = vehicleList.find((v) => v.type === type && v.subtype === e.target.value);
    onChange({
      [isFossilVehicle ? 'fossilVehicle' : 'vehicle']: newVehicle,
      [isFossilVehicle ? 'fossilVehicleId' : 'vehicle_id']: newVehicle.id,
    });
  };

  return (
    <>
      <Grid item xs={6}>
        <Dropdown
          onChange={handleChangeType}
          options={typeOptions}
          value={type}
          label={formatMessage({ id: 'editVehicleSets.info.type' })}
          labelTooltip={formatMessage({ id: 'editVehicleSets.info.typeTooltip' })}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          onChange={handleChangeSubtype}
          options={subtypeOptions}
          value={subtype}
          label={formatMessage({ id: 'editVehicleSets.info.subtype' })}
          labelTooltip={
            type.toLowerCase() === 'pick-up truck'
              ? formatMessage({ id: 'editVehicleSets.info.subtypePickupTooltip' })
              : formatMessage({ id: 'editVehicleSets.info.subtypeTooltip' })
          }
          disabled={disabled}
        />
      </Grid>
    </>
  );
}
