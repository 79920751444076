// Source: https://docs.google.com/spreadsheets/d/1o8ydfXcQ6B6Mtk_EagQECyZ2LXQICQFbT2WAtkXboXI/edit#gid=1078164887

/** @type Array<ApiCharger> */
const CHARGERS = [
  {
    type: 'Level 2 - 7.2kW',
    msrp: 800,
    msrpIsEstimated: true,
    rebate: 2500,
    ports: 1,
    chargeRate: 7.2,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.2KW.png',
  },
  {
    type: 'Level 2 - 7.7kW',
    msrp: 1000,
    msrpIsEstimated: true,
    rebate: 2500,
    ports: 1,
    chargeRate: 7.7,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png',
  },
  {
    type: 'Level 2 - 9.6kW',
    msrp: 1200,
    msrpIsEstimated: true,
    rebate: 2500,
    ports: 1,
    chargeRate: 9.6,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-01.png',
  },
  {
    type: 'Level 2 - 11.5kW',
    msrp: 1500,
    msrpIsEstimated: true,
    rebate: 2500,
    ports: 1,
    chargeRate: 11.5,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 11.5KW-01.png',
  },
  {
    type: 'Level 2 - 16.8kW',
    msrp: 2000,
    msrpIsEstimated: true,
    rebate: 2500,
    ports: 1,
    chargeRate: 16.8,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 16.8KW-01.png',
  },
  {
    type: 'Level 2 - 19.2kW',
    msrp: 2600,
    msrpIsEstimated: true,
    rebate: 2500,
    ports: 1,
    chargeRate: 19.2,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 19.2KW-01.png',
  },
  {
    type: 'DCFC - 25kW',
    msrp: 24800,
    msrpIsEstimated: true,
    rebate: 18000,
    ports: 1,
    chargeRate: 25,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 25 KW Single port charger-01.png',
  },
  {
    type: 'DCFC - 50kW',
    msrp: 27800,
    msrpIsEstimated: true,
    rebate: 18000,
    ports: 1,
    chargeRate: 50,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 50 KW Single port charger-01.png',
  },
  {
    type: 'DCFC - 75kW',
    msrp: 27800,
    msrpIsEstimated: true,
    rebate: 18000,
    ports: 1,
    chargeRate: 75,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 75 KW Single port charger-01.png',
  },
  {
    type: 'DCFC - 100kW',
    msrp: 59000,
    msrpIsEstimated: true,
    rebate: 30000,
    ports: 1,
    chargeRate: 100,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 100 KW dual port charger-01.png',
  },
  {
    type: 'DCFC - 175kW',
    msrp: 104000,
    msrpIsEstimated: true,
    rebate: 55000,
    ports: 1,
    chargeRate: 175,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DC 175kW Singal Port--01.png',
  },
  {
    type: 'DCFC - 300kW',
    msrp: 305000,
    msrpIsEstimated: true,
    rebate: 70000,
    ports: 1,
    chargeRate: 300,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 6 port 300kW-01.png',
  },
];

export default CHARGERS;
