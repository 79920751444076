import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import implementSteps from '../../components/ImplementSteps/ImplementSteps';
import StyledCollapse from '../StyledCollapse/StyledCollapse';
import Section from '../../components/Section/Section';

function StepSection({ steps, titleId, offset = 0 }) {
  const { formatMessage } = useIntl();

  return (
    <Section title={formatMessage({ id: titleId })}>
      {steps.map((step, index) => (
        <StyledCollapse
          title={`${formatMessage({ id: 'panels.implement.step' }).toUpperCase()} ${
            index + offset + 1
          }: ${formatMessage({ id: step.title }).toUpperCase()}`}
          styleName="primary"
          leftIcon={true}
          key={step.title}
        >
          <Box
            sx={{
              textAlign: 'left',
              color: 'common.secondaryGrey',
              backgroundColor: '#FBFBFC',
              lineHeight: '22px',
              px: '52px',
              py: '24px',
            }}
          >
            {typeof step.content === 'string'
              ? formatMessage({ id: `${step.content}` })
              : step.content}
          </Box>
        </StyledCollapse>
      ))}
    </Section>
  );
}

export default function StepList() {
  return (
    <>
      <StepSection titleId={'panels.implement.design'} steps={implementSteps.slice(0, 7)} />
      <StepSection
        titleId={'panels.implement.permitting'}
        steps={implementSteps.slice(7, 8)}
        offset={7}
      />
      <StepSection
        titleId={'panels.implement.procurement'}
        steps={implementSteps.slice(8)}
        offset={8}
      />
    </>
  );
}
