import React from 'react';
import PanelIntro from '../PanelIntro/PanelIntro';
import { Box } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import VehicleSetList from './VehicleSetList';
import SubHeader from '../../components/subHeader/SubHeader';
import ButtonInput from '../../components/inputs/ButtonInput';
import { useIntl } from 'react-intl';

export default function Vehicles() {
  const { formatMessage } = useIntl();
  const { sites, isAllSites, selectedSite } = useInputs();

  return (
    <Box>
      <PanelIntro title="panels.vehicles.title" summary="panels.vehicles.summary" />
      <Box textAlign="right" mt={1}>
        <ButtonInput variant="link" href="/vehicle-catalog" newTab>
          {formatMessage({ id: 'vehicleCatalogLink' })}
        </ButtonInput>
      </Box>
      {isAllSites ? (
        <Box>
          {sites.map((site) => {
            return (
              <Box key={site.id}>
                <SubHeader>{site.name}</SubHeader>
                <VehicleSetList vehicleSets={site.vehicleSets} site={site} />
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box>
          <VehicleSetList vehicleSets={selectedSite.vehicleSets} site={selectedSite} />
        </Box>
      )}
    </Box>
  );
}
