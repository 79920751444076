import React from 'react';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

export default function PanelIntro(props) {
  const { formatMessage } = useIntl();
  const { title, summary } = props;

  return (
    <Box>
      <Box sx={{ textAlign: 'left', marginLeft: '5px' }}>
        <Typography
          component="h1"
          sx={{
            fontSize: '25px',
            borderBottom: (theme) => `8px solid ${theme.palette.common.brandBlue}`,
            width: 'fit-content',
            margin: '40px 0 20px 0',
            color: 'common.DTEPrimary',
          }}
        >
          {formatMessage({ id: title })}
        </Typography>
        {summary && (
          <Box sx={{ fontSize: '16px', color: 'common.secondaryGrey' }}>
            {formatMessage({ id: summary })}
          </Box>
        )}
      </Box>
    </Box>
  );
}
