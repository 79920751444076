import { getHoursOfYear, isHourInUse } from '../utils/time';
import CHARGERS from '../data/CHARGERS';
import times from 'lodash/times';
import mean from 'lodash/mean';
import VehicleSet from './VehicleSet';

/**
 *
 * @param {VehicleSet} vehicleSet
 * @returns number
 */
const calcKwhPerDay = (vehicleSet) => {
  const {
    batteryCapacityInKwh,
    rangeInMiles,
    electricRange,
    rangeInHours,
    category,
    idlingFactor = 0, // if this is constant, it should be added to vehicles via the computed property or on fetch or whatever
  } = VehicleSet.getElectricVehicle(vehicleSet);

  const { milesPerWorkday, hoursPerWorkday, vehicleCount } = vehicleSet;

  const kwhPerMile = batteryCapacityInKwh / (electricRange || rangeInMiles);
  const kwhPerHour = batteryCapacityInKwh / (rangeInHours || 1);
  const idling = 1 - idlingFactor;
  const kwhPerDay =
    category === 'On-Road' ? milesPerWorkday * kwhPerMile : hoursPerWorkday * kwhPerHour;

  return vehicleCount * kwhPerDay * idling;
};

/**
 *
 * @param {VehicleSet} vehicleSet
 * @returns
 */
const calcPlugSchedule = ({ chargingWindows }) =>
  times(24).map((hour) => {
    const firstInUseChargingWindow = chargingWindows.filter((cw) => isHourInUse(cw, hour))[0];

    const charger = CHARGERS.find((c) => c.type === firstInUseChargingWindow?.type);

    return {
      chargingApproach: firstInUseChargingWindow?.chargingApproach || null,
      portKw: charger?.chargeRate || null,
    };
  });

const calcChargingHour = ({
  vehicleSet,
  startKwh,
  hourStart,
  plugSchedule,
  evenPowerDrawInKwhPerHour,
}) => {
  const { batteryCapacityInKwh } = VehicleSet.getElectricVehicle(vehicleSet);
  const { vehicleCount } = vehicleSet;
  const { chargingApproach, portKw } = plugSchedule[hourStart] || {};

  const totalPortKw = portKw * vehicleCount;

  const availablePowerInKw =
    chargingApproach === 'Max' ? totalPortKw : Math.min(totalPortKw, evenPowerDrawInKwhPerHour); // even if approach is "even", the available power shouldn't surpass the charger's kW availability

  const unchargedBatteryCapacity = vehicleCount * batteryCapacityInKwh - startKwh;
  const actualPowerDrawInKwh = chargingApproach
    ? Math.min(availablePowerInKw, unchargedBatteryCapacity)
    : 0;

  return {
    startKwh,
    finishKwh: startKwh + actualPowerDrawInKwh,
    dischargedKwh: 0,
    chargedKwh: actualPowerDrawInKwh,
  };
};

const BatterySchedule = {
  /**
   *
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns
   */
  annualBatterySchedule(vehicleSet, year) {
    const { batteryCapacityInKwh, idlingFactor } = VehicleSet.getElectricVehicle(vehicleSet);
    const { vehicleCount, workdays, workmonths } = vehicleSet;
    const hoursOfYear = getHoursOfYear(year);
    const plugSchedule = calcPlugSchedule(vehicleSet);
    const kWhPerDay = calcKwhPerDay(vehicleSet);

    const hoursOfCharging = plugSchedule.filter((plug) => plug.portKw).length;
    const hoursAwayFromChargers = 24 - hoursOfCharging;

    const dischargedKwhPerHour = (kWhPerDay * (idlingFactor || 1)) / hoursAwayFromChargers;

    const evenPowerDrawInKwhPerHour = kWhPerDay / hoursOfCharging;

    const batterySchedule = [];

    hoursOfYear.forEach((date, currIdx) => {
      const { finishKwh } = batterySchedule[currIdx - 1] || {
        finishKwh: batteryCapacityInKwh * vehicleCount,
      };
      const { hourStart } = date;

      const isWorkday = workdays.includes(date.dayOfWeek) && workmonths.includes(date.month);
      const isDriving = isWorkday && plugSchedule[date.hourStart].portKw === null;

      let hourEntry;
      if (isDriving) {
        hourEntry = {
          startKwh: finishKwh,
          finishKwh: finishKwh - dischargedKwhPerHour,
          dischargedKwh: dischargedKwhPerHour,
          chargedKwh: 0,
        };
      } else {
        // charging
        hourEntry = calcChargingHour({
          vehicleSet,
          evenPowerDrawInKwhPerHour,
          startKwh: finishKwh,
          hourStart,
          plugSchedule,
        });
      }
      batterySchedule[currIdx] = { ...hourEntry, date };
    });
    return batterySchedule;
  },

  /**
   *
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns Array<number>
   */
  averageWorkdayBatterySchedule(vehicleSet, year) {
    const { batteryCapacityInKwh } = VehicleSet.getElectricVehicle(vehicleSet);
    const { workdays, workmonths, vehicleCount } = vehicleSet;
    const totalBatteryCapacity = batteryCapacityInKwh * vehicleCount;

    const schedule = this.annualBatterySchedule(vehicleSet, year);

    // only use battery schedule for valid workdays
    const workdaysSchedule = schedule.filter(
      ({ date: { month, dayOfWeek } }) =>
        workmonths.includes(month) && workdays.includes(dayOfWeek),
    );

    return times(24, (i) => {
      // filter by hour of the day and take the average
      const hourlyStartKwhs = workdaysSchedule
        .filter(({ date: { hourStart } }) => hourStart === i)
        .map(({ startKwh }) => startKwh);

      return Math.round(Math.max(mean(hourlyStartKwhs) / totalBatteryCapacity, 0) * 100);
    });
  },
};

export default BatterySchedule;
