import React from 'react';
import { Grid, Box, Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import CheckboxButtonRow from '../../components/inputs/CheckboxButtonRow';
import { DAYS_ABBREVIATED, MONTHS_ABBREVIATED } from '../../data/timePeriods';
import TextInput from '../../components/inputs/TextInput';
import { stringToInt } from '../../utils/formatters';

export default function VehicleUsage({ vehicleSet, onChangeVehicleSet }) {
  const { formatMessage } = useIntl();
  const { milesPerWorkday, vehicleCount, workdays, workmonths } = vehicleSet;

  const handleChange = (fieldName) => (value) => {
    onChangeVehicleSet({ [fieldName]: stringToInt(value) });
  };

  return (
    <Grid container rowSpacing={4} columnSpacing={30}>
      <Grid item xs={6}>
        <TextInput
          mask="9999999"
          label={formatMessage({ id: 'editVehicleSets.usage.miles' }).toUpperCase()}
          defaultValue={milesPerWorkday}
          placeholder=""
          onChange={(value) => onChangeVehicleSet({ milesPerWorkday: stringToInt(value) })}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mask="9999999"
          label={formatMessage({ id: 'editVehicleSets.usage.count' }).toUpperCase()}
          defaultValue={vehicleCount}
          placeholder=""
          onChange={handleChange('vehicleCount')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt="40px" mb="40px">
          <Divider color="#E4E4E4" width="100%" />
        </Box>
      </Grid>
      <Grid item xs={12} maxWidth="550px">
        <CheckboxButtonRow
          label="editVehicleSets.usage.days"
          data={DAYS_ABBREVIATED}
          selectionArray={workdays}
          onChange={handleChange('workdays')}
        />
      </Grid>
      <Grid item xs={12} maxWidth="700px">
        <CheckboxButtonRow
          label="editVehicleSets.usage.months"
          data={MONTHS_ABBREVIATED}
          selectionArray={workmonths}
          onChange={(value) => onChangeVehicleSet({ workmonths: value })}
        />
      </Grid>
    </Grid>
  );
}
