import { createTheme as createThemeV4 } from '@material-ui/core';
import { createTheme } from '@mui/material';
import OpenSansRegular from '../assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../assets/fonts/OpenSans-Bold.ttf';
import OpenSansExtraBold from '../assets/fonts/OpenSans-ExtraBold.ttf';
import OpenSansSemiBold from '../assets/fonts/OpenSans-SemiBold.ttf';
import DTElogo from '../assets/images/DTE_logo.png';
import FacebookLogo from '../assets/images/icons/social/Facebook.svg';
import LinkedInLogo from '../assets/images/icons/social/LinkedIn.svg';
import TwitterLogo from '../assets/images/icons/social/Twitter.svg';
import YouTubeLogo from '../assets/images/icons/social/YouTube.svg';

// Follows from Material UI docs:
// https://material-ui.com/customization/typography/#self-hosted-fonts
const openSansRegular = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Open Sans'),
      url(${OpenSansRegular}) format('truetype')
    `,
};

const openSansSemiBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
      local('Open Sans'),
      url(${OpenSansSemiBold}) format('truetype')
    `,
};

const openSansBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
      local('Open Sans'),
      url(${OpenSansBold}) format('truetype')
    `,
};

const openSansExtraBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
      local('Open Sans'),
      url(${OpenSansExtraBold}) format('truetype')
    `,
};

const LIGHT_GREY = '#DFE0EB';
const LIGHT_GREY_C4 = '#C4C4C4';
const BORDER_GREY = '#D7D7D7';
const SITE_GREY = '#00000080';
const GREY_F4 = '#F4F4F4';
const GREY_200 = '#F6F6F6';
const GREY_300 = '#D9D9D9';
const GREY_400 = '#B1B3B3';
const GREY_500 = '#73767B';
const GREY_777 = '#777777';
const EXTRA_DARK_BLUE = '#0C1B40';
const DARK_BLUE = '#1E3575';
const LAVENDER = '#C1A0DA';
const ACCENT_BLUE = '#0072CE';
const DIVIDER_GREY = '#E4E4E4';
const SECONDARY_GREY = '#505357';
const DTE_PRIMARY = '#2B2B2B';
const BRAND_BLUE = '#1E3575';
const DARK_GREY = '#485465';
const LIGHT_AQUA = '#59BEC9';
const SUCCESS = '#F6F9F6';
const ERROR = '#FEFAFB';
const ERROR_BORDER = '#E31937';
const CONTENT_POSITIVE = '#1D661A';
const WARNING = '#F5F9FD';
const DARK_AQUA = '#006269';
const LIGHT_GREEN = '#D2D755';
const DARK_PURPLE = '#523178';

const themeConfig = {
  header: {
    logoPath: DTElogo,
  },
  footer: {
    logoPath: DTElogo,
    socialIconPaths: {
      facebook: FacebookLogo,
      linkedIn: LinkedInLogo,
      twitter: TwitterLogo,
      youTube: YouTubeLogo,
    },
  },
  palette: {
    primary: {
      main: DARK_BLUE,
    },
    secondary: {
      main: ACCENT_BLUE,
    },
    grey: {
      grey200: GREY_200,
      grey300: GREY_300,
      grey400: GREY_400,
      grey500: GREY_500,
      grey700: GREY_777,
    },
    common: {
      grey777: GREY_777,
      lightGrey: LIGHT_GREY,
      lightGreyC4: LIGHT_GREY_C4,
      siteGrey: SITE_GREY,
      dividerGrey: DIVIDER_GREY,
      greyF4: GREY_F4,
      secondaryGrey: SECONDARY_GREY,
      darkGrey: DARK_GREY,
      grey200: GREY_200,
      grey300: GREY_300,
      grey400: GREY_400,
      grey500: GREY_500,
      darkBlue: EXTRA_DARK_BLUE,
      borderGrey: BORDER_GREY,
      lavender: LAVENDER,
      accentBlue: ACCENT_BLUE,
      DTEPrimary: DTE_PRIMARY,
      brandBlue: BRAND_BLUE,
      lightAqua: LIGHT_AQUA,
      success: SUCCESS,
      successBorder: CONTENT_POSITIVE,
      warning: WARNING,
      warningBorder: ACCENT_BLUE,
      error: ERROR,
      errorBorder: ERROR_BORDER,
      darkAqua: DARK_AQUA,
      lightGreen: LIGHT_GREEN,
      darkPurple: DARK_PURPLE,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [openSansBold, openSansExtraBold, openSansRegular, openSansSemiBold],
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
      },
      h2: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
      },
      h5: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
      },
      h6: {
        fontWeight: '600',
      },
      body1: {
        fontFamily: 'Open Sans, sans-serif !important',
      },
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    primary: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      color: ACCENT_BLUE,
    },
    secondary: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
    },
  },
  link: {
    fontWeight: '600',
  },
};

export const themeV4 = createThemeV4(themeConfig);
export const theme = createTheme(themeConfig);

export default themeV4;
