import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import VehicleCatalogue from '../../components/Onboarding/VehicleCatalogue/VehicleCatalogue';
import Modal from '../../components/modal/Modal';

export default function SelectVehicleDialog({
  isOpen,
  vehicleSet,
  onClose,
  onDelete,
  onSave,
  canRemove,
  isFossilVehicle,
}) {
  const { formatMessage } = useIntl();
  const [selectedVehicle, setSelectedVehicle] = useState(vehicleSet.vehicle);

  const handleSelectVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const handleSave = () => {
    if (isFossilVehicle) {
      onSave({
        ...vehicleSet,
        fossilVehicle: selectedVehicle,
        fossilVehicleId: selectedVehicle.id,
      });
    } else {
      onSave({
        ...vehicleSet,
        vehicle: selectedVehicle,
        vehicle_id: selectedVehicle.id,
      });
    }
  };

  return (
    <Modal
      title={formatMessage({ id: 'replacementVehicle.title' })}
      isOpen={isOpen}
      deleteButtonText={formatMessage({ id: 'editVehicleSets.cancel' })}
      onClose={onClose}
      onDelete={canRemove ? onDelete : null}
      onConfirm={handleSave}
    >
      <VehicleCatalogue
        vehicleSet={vehicleSet}
        handleSelect={handleSelectVehicle}
        selectedVehicle={selectedVehicle}
        isFossilVehicle={isFossilVehicle}
      />
    </Modal>
  );
}
