import React from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import times from 'lodash/times';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core';
import StyledBarGraph from '../Charts/StyledBarGraph/StyledBarGraph';
import { formatHours } from '../../utils/formatters';
import Site from '../../calculations/Site';

const labels = times(24);

export const EquipmentLoadChart = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { selectedSite, startYear } = useInputs();

  // Use final year, because all vehicles will be electrified by then
  const siteLoadProfile = Site.annualLoadProfile(selectedSite, startYear + 9);

  const chargerUseData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.connect.allChargers' }),
        // the max load for each hour over the course of the year
        data: labels.map((hour) => siteLoadProfile.filterBy({ hourStarts: [hour] }).max()),
        backgroundColor: theme.palette.common.lightAqua,
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };

  const xTicks = {
    min: 0,
    callback: function (value) {
      if (value % 2 === 0) {
        return formatHours(value);
      }
    },
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
  };

  const yTicks = {
    min: 0,
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
  };

  const gridOptions = {
    color: function (context) {
      if (context.tick.value === 200) {
        return '#1E3575';
      } else {
        return '#D9D9D9';
      }
    },
    lineWidth: function (context) {
      if (context.tick.value === 200) {
        return 3;
      } else {
        return 1;
      }
    },
  };

  return (
    <StyledBarGraph
      data={chargerUseData}
      yTitle={formatMessage({ id: 'panels.connect.siteUse' })}
      yTicks={yTicks}
      xTicks={xTicks}
      showHorizontalGrid={true}
      showLegend={true}
      stacked={true}
      large={true}
      gridOptions={gridOptions}
      tooltipTitleFormatter={(data) => formatHours(data[0].dataIndex)}
      tooltipLabelFormatter={(data) => `${data.dataset.label}: ${Math.round(data.parsed.y)}kW`}
    />
  );
};
