import { React } from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import Totals from '../../calculations/Totals';
import { Box } from '@mui/material';
import PanelIntro from '../PanelIntro/PanelIntro';
import times from 'lodash/times';
import min from 'lodash/min';
import TotalFuelSavingsChart from './components/TotalFuelSavingsChart';
import AnnualFuelSavingsChart from './components/AnnualFuelSavingsChart';
import FuelAndElectricPricesCompared from './components/FuelAndElectricPricesCompared';
import AnnualFuelCostsTable from './components/AnnualFuelCostsTable';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';

export default function Fuel() {
  const { formatMessage } = useIntl();

  const { dieselPrice, gasolinePrice, startYear, sites, selectedSite, isAllSites } = useInputs();

  // first year that a vehicle is electrified for given site (or all sites)
  const firstReplacementYear = isAllSites
    ? min(sites.flatMap((site) => site.vehicleSets).map(({ replacementYear }) => replacementYear))
    : min(selectedSite.vehicleSets.map(({ replacementYear }) => replacementYear));

  const selectedSites = isAllSites ? sites : [selectedSite];
  const yearRange = times(10, (i) => startYear + i);

  const tenYearData = yearRange.reduce(
    (acc, year) => ({
      ...acc,
      [year]:
        year < firstReplacementYear
          ? {}
          : Totals.getAnnualFleetFuelCosts(selectedSites, year, {
              dieselPrice,
              gasolinePrice,
            }),
    }),
    {},
  );

  const tenYearGasolineFleetCost = yearRange.reduce(
    (acc, year) => (acc += tenYearData[year].gasolineCost || 0),
    0,
  );
  const tenYearDieselFleetCost = yearRange.reduce(
    (acc, year) => (acc += tenYearData[year].dieselCost || 0),
    0,
  );
  const tenYearElectricFleetCost = yearRange.reduce(
    (acc, year) =>
      (acc += tenYearData[year].bevElectricCost + tenYearData[year].phevElectricCost || 0),
    0,
  );
  const tenYearPhevGasCost = yearRange.reduce(
    (acc, year) => (acc += tenYearData[year].phevGasCost || 0),
    0,
  );

  const detailedCostTable = {
    headings: [
      formatMessage({ id: 'panels.fuel.detailedCostTable.year' }),
      formatMessage({ id: 'panels.fuel.detailedCostTable.diesel' }),
      formatMessage({ id: 'panels.fuel.detailedCostTable.gas' }),
      formatMessage({ id: 'panels.fuel.detailedCostTable.ev' }),
      tenYearPhevGasCost > 0 && formatMessage({ id: 'panels.fuel.detailedCostTable.phevGas' }),
    ],
    rows: yearRange.map((year) => [
      year,
      formatAsDollars(tenYearData[year].dieselCost),
      formatAsDollars(tenYearData[year].gasolineCost),
      formatAsDollars(
        tenYearData[year].bevElectricCost + tenYearData[year].phevElectricCost || undefined,
      ),
      tenYearPhevGasCost > 0 && formatAsDollars(tenYearData[year].phevGasCost),
    ]),
  };

  return (
    <Box>
      <PanelIntro title="panels.fuel.title" summary="panels.fuel.summary" />
      <TotalFuelSavingsChart
        tenYearDieselCost={tenYearDieselFleetCost}
        tenYearGasolineCost={tenYearGasolineFleetCost}
        tenYearElectricCost={tenYearElectricFleetCost}
        tenYearPhevGasCost={tenYearPhevGasCost}
      />
      <AnnualFuelSavingsChart
        labels={yearRange}
        data={yearRange.reduce((acc, year, idx) => {
          const previousYearValue = acc[idx - 1] || 0;
          return [
            ...acc,
            previousYearValue + tenYearData[year].fuelCost - tenYearData[year].electricFleetTotal,
          ];
        }, [])}
      />
      <FuelAndElectricPricesCompared
        chartLabels={yearRange}
        chartData={{
          gasoline: yearRange.map((year) => tenYearData[year].gasolineCost),
          diesel: yearRange.map((year) => tenYearData[year].dieselCost),
          electric: yearRange.map(
            (year) => tenYearData[year].bevElectricCost + tenYearData[year].phevElectricCost,
          ),
          phevGas: yearRange.map((year) => tenYearData[year].phevGasCost),
        }}
        tableData={detailedCostTable}
      />
      <AnnualFuelCostsTable defaultYear={firstReplacementYear} />
    </Box>
  );
}
