// Modal.js

import React, { useState } from 'react';
import './Modal.scss';

const Modal = ({
  title,
  onClose,
  handle,
  isEditing = false,
  item = {},
  setIsEditing,
  setSelectedItem,
}) => {
  const initialValues = isEditing
    ? [
        { name: 'Value', value: item.Value || '' },
        { name: 'Grantor', value: item.Grantor || '' },
        { name: 'Incentive', value: item.Incentive || '' },
        { name: 'Description', value: item.Description || '' },
        { name: 'Notes', value: item.Notes || '' },
      ]
    : [
        { name: 'Value', value: '' },
        { name: 'Grantor', value: '' },
        { name: 'Incentive', value: '' },
        { name: 'Description', value: '' },
        { name: 'Notes', value: '' },
      ];

  const [inputs, setInputs] = useState(initialValues);

  const handleChange = (e, index) => {
    let value = e.target.value;
    if (index === 0 && !e.target.validity.valid) {
      return;
    }
    const newInputs = [...inputs];
    newInputs[index] = { ...newInputs[index], value: value };
    setInputs(newInputs);
  };

  const handleSave = () => {
    if (inputs.every((input) => input.value.trim() !== '')) {
      const inputObject = inputs.reduce((accumulator, input) => {
        accumulator[input.name] = input.value;
        return accumulator;
      }, {});

      handle(inputObject);
      onClose();
    } else {
      alert('Please fill in all inputs.');
    }
  };

  const handleCancel = () => {
    setSelectedItem({});
    setIsEditing(false);
    onClose();
  };

  return (
    <div className={'modalOverlay'}>
      <div className={'modalContainer'}>
        <div className="top-container">
          <div className={'title'}>{title}</div>
          <span className={'closeButton'} onClick={handleCancel}>
            &times;
          </span>
        </div>
        <div className={'inputContainer'}>
          {inputs.map((input, index) => (
            <div key={index} className="input-wrapper">
              <label htmlFor={`input-${index}`}>
                {input.name === 'Value' ? 'Incentive Value / Vehicle ($)' : input.name}
              </label>
              <input
                id={`input-${index}`}
                type={input.name === 'Value' ? 'number' : 'text'}
                value={input.value}
                onChange={(e) => handleChange(e, index)}
                pattern="[0-9]*"
              />
            </div>
          ))}
        </div>
        <div className={'buttonContainer'}>
          <button className="btn-cancel" onClick={handleCancel}>
            Cancel
          </button>
          <button className="btn-confirm" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
