import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import TopInputs from '../../components/topInputs/TopInputs';
import { useAnalytics } from '../../utils/useAnalytics';

export default function TabContent(props) {
  const { GaTracker } = useAnalytics();
  const { selectedTab, setInput } = useInputs();
  const [currentTab, setCurrentTab] = useState(selectedTab || 0);

  useEffect(() => {
    if (selectedTab !== currentTab) {
      // useInputs has a lot of connections therefore slow to return the selectedTab change
      // Instead we track the currentTab locally so the UI updates the tab faster
      // We update the useInputs selectedTab value here after the UI get the change
      setInput({ selectedTab: currentTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    if (selectedTab !== currentTab) {
      // The tab can be changed outside of this component, specifically the [Edit Inputs] button
      // Pickup the external selectedTab change and update the internal currentTab
      setCurrentTab(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    GaTracker.trackEvent({
      category: 'Navigation',
      action: 'Tab change',
      value: selectedTab,
    });
  }, [selectedTab]);

  const { tabList } = props;

  return (
    <Box>
      <Box role="navigation" className="container" sx={{ marginBottom: '40px' }}>
        <TopInputs />
        <Tabs
          sx={{
            backgroundColor: 'transparent',
            minHeight: 'auto',
            justifyContent: 'space-between',
            '& .MuiTabs-indicator': {
              backgroundColor: 'common.brandBlue',
            },
            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-between',
            },
          }}
          value={currentTab}
          onChange={(_, newTab) => setCurrentTab(newTab)}
        >
          {tabList.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                value={index}
                key={index}
                disableRipple
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  color: 'common.grey500',
                  padding: '2px 6px',
                  minHeight: 'auto',
                  borderBottom: '2px solid transparent',
                  '&:focus': {
                    opacity: 1,
                    borderColor: 'common.lightAqua',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'transparent',
                    color: 'common.brandBlue',
                    fontWeight: 600,
                  },
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box role="main" className="container">
        {tabList[currentTab].content}
      </Box>
    </Box>
  );
}
