import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Inputs } from '@bellawatt/use-inputs';
import getDefaultsWithApi from './use-inputs/getDefaults';
import computed from './use-inputs/computed';
import App from './App/App';
import ReactIntlProvider from './utils/ReactIntlProvider';
import { CssBaseline, ThemeProvider as ThemeProviderV4 } from '@material-ui/core';
import { ThemeProvider } from '@mui/material';
import { theme, themeV4 } from '../src/config/theme.js';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import IncentivesContext from './utils/IncentivesContext';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line import/no-extraneous-dependencies, global-require
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

ChartJS.register(...registerables, ChartDataLabels);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProviderV4 theme={themeV4}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Inputs
          computed={computed}
          defaults={getDefaultsWithApi()}
          ignore={['apiElectricVehicles', 'apiFossilVehicles', 'apiChargers', 'apiIncentives']}
        >
          <IncentivesContext>
            <ReactIntlProvider>
              <App />
            </ReactIntlProvider>
          </IncentivesContext>
        </Inputs>
      </ThemeProvider>
    </ThemeProviderV4>
  </BrowserRouter>,
  document.getElementById('root'),
);
