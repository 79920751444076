import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { NoVehicleIcon } from '../../assets/icons/icons';
import times from 'lodash/times';
import ButtonInput from '../../components/inputs/ButtonInput';

export default function EmptyContent({ handleEdit, isElectric }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const handleReplacementVehicleEdit = () => {
    handleEdit(true);
  };

  return (
    <Box>
      <Box
        backgroundColor={theme.palette.common.greyF4}
        display="flex"
        justifyContent="center"
        fontSize="16px"
        fontWeight="600"
      >
        <Box padding="10px 15px" flexShrink={0}>
          {formatMessage({ id: 'onboardingSteps.recommend.noVehicle' })}
        </Box>
      </Box>
      <Box paddingTop="17px" paddingBottom="12px">
        <NoVehicleIcon />
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        flexDirection="column"
        paddingLeft="17px"
        paddingRight="17px"
      >
        {times(5, (i) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '6px' }} key={i}>
              <Box
                sx={{
                  backgroundColor: theme.palette.common.grey300,
                  height: '16px',
                  width: '87px',
                }}
              />
              <Box
                sx={{
                  backgroundColor: theme.palette.common.grey300,
                  height: '16px',
                  width: '215px',
                  ml: '16px',
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Box sx={{ paddingTop: '26px', display: 'flex', justifyContent: 'center' }}>
        <ButtonInput onClick={handleReplacementVehicleEdit} variant="link">
          {!isElectric
            ? formatMessage({ id: 'edit' })
            : formatMessage({ id: 'panels.vehicles.changeReplacement' })}
        </ButtonInput>
      </Box>
    </Box>
  );
}
