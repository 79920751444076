import React from 'react';
import { Box } from '@mui/material';
import { makeStyles, useTheme } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const stackedTotals = {
  id: 'stackedTotals',

  beforeUpdate: (chart) => {
    let totals = {};
    let utmost = 0;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        utmost = datasetIndex;
        dataset.data.forEach((value, index) => {
          totals[index] = (totals[index] || 0) + value;
        });
      }
    });

    chart.$stackedTotals = {
      totals: totals,
      utmost: utmost,
    };
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.common.borderGrey}`,
    borderRadius: '4px',
    height: '240px',
    marginTop: '20px',
    padding: '10px',
    width: '100%',
  },
  large: {
    height: '400px',
  },
}));

export default function StyledBarGraph(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    yTitle,
    showHorizontalGrid,
    showVerticalGrid,
    data,
    graphTitle,
    showLegend,
    legendOptions,
    showDataLabels,
    largestLabelValue,
    dataLabelOptions,
    indexAxis,
    stacked,
    xStacked,
    yStacked,
    large,
    gridOptions,
    xTicks = {},
    yTicks = {},
    tooltipLabelFormatter,
    tooltipTitleFormatter,
    tooltipEnabled = true,
  } = props;

  const addExtraPadding = (value, basePadding = 0) => {
    const characters = value.toString().length;
    return basePadding + characters * 15;
  };

  const options = {
    layout: {
      padding: {
        right:
          showDataLabels && largestLabelValue && indexAxis === 'y'
            ? addExtraPadding(Math.round(largestLabelValue), 5)
            : 0,
      },
    },
    responsive: true,
    indexAxis,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
      legend: {
        onClick: null,
        display: showLegend,
        align: 'end',
        position: 'bottom',
        labels: {
          font: {
            size: 16,
          },
        },
        ...legendOptions,
      },
      datalabels: {
        display: function (context) {
          return !!showDataLabels && context.dataset.data[context.dataIndex] > 0;
        },
        color: theme.palette.common.black,
        font: {
          size: 16,
        },
        align: 'end',
        anchor: 'end',
        ...dataLabelOptions,
      },
      tooltip: {
        enabled: tooltipEnabled,
        callbacks: {
          label:
            tooltipLabelFormatter ||
            function (data) {
              return `${data.dataset.label}: ${data.formattedValue}`;
            },
          title:
            tooltipTitleFormatter ||
            function (data) {
              return data[0].dataIndex;
            },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: showVerticalGrid ?? false,
          drawBorder: true,
          drawTicks: true,
        },
        ticks: {
          color: theme.palette.common.secondaryGrey,
          padding: 10,
          ...xTicks,
          font: {
            size: 16,
            ...xTicks.font,
          },
        },
        stacked: xStacked || stacked,
      },
      y: {
        grid: {
          color: gridOptions ? gridOptions.color : '#D9D9D9',
          lineWidth: gridOptions ? gridOptions.lineWidth : 1,
          display: showHorizontalGrid ?? false,
          drawBorder: false,
          drawTicks: true,
        },
        title: {
          display: true,
          text: yTitle,
          color: theme.palette.common.secondaryGrey,
          font: {
            size: 16,
          },
        },
        ticks: {
          color: theme.palette.common.secondaryGrey,
          padding: 20,
          ...yTicks,
          font: {
            size: 16,
            ...yTicks.font,
          },
        },
        stacked: yStacked || stacked,
      },
    },
  };

  return (
    <Box className={classes.container + large ? ' large' : ''}>
      <Box textAlign="center" sx={{ height: large ? '380px' : '220px' }}>
        <Box>{graphTitle}</Box>
        <Bar
          aria-label={graphTitle || 'graph'}
          options={options}
          data={data}
          plugins={[ChartDataLabels, stackedTotals]}
        />
      </Box>
    </Box>
  );
}
