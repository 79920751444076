import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import Dropdown from '../inputs/Dropdown';
import { useInputs } from '@bellawatt/use-inputs';
import cloneDeep from 'lodash/cloneDeep';
import { PlusIcon } from '../../assets/icons/icons';
import { DEFAULT_SITE } from '../../use-inputs/getDefaults';
import ButtonInput from '../inputs/ButtonInput';
import { omitComputedData } from '../../utils/computedData';
import ResetInputs from '../ResetInputs/ResetInputs';

export default function TopInputs() {
  const { formatMessage } = useIntl();

  const { sites, selectedSite, setInput } = useInputs();

  const AddNewSiteOption = () => {
    return (
      <Box display="flex">
        <Box mr="12px">
          <PlusIcon />
        </Box>
        <Box>{formatMessage({ id: 'header.topInputs.addNewSite' })}</Box>
      </Box>
    );
  };

  const options = [
    ...sites.map((site) => ({
      value: site.id,
      children: site.name,
    })),
    { value: 'all', children: formatMessage({ id: 'header.topInputs.allSites' }) },
    { value: 'add', children: <AddNewSiteOption /> },
  ];

  const addNewSite = () => {
    const newSites = cloneDeep(sites);
    let newSite = cloneDeep(DEFAULT_SITE);
    newSite.id = sites.length;
    newSite.name = `My Site #${sites.length + 1}`;
    newSites.push(newSite);
    setInput({ sites: omitComputedData(newSites) });
  };

  const handleDropdownSelect = (e) => {
    if (e.target.value === 'add') {
      addNewSite();
    } else {
      setInput({
        selectedSite: e.target.value === 'all' ? 'all' : parseInt(e.target.value),
      });
    }
  };

  const goBackToAssessment = () => {
    //Take user to the assessment tab to edit inputs
    setInput({ selectedTab: 1 });
  };

  return (
    <Box
      className={`container`}
      sx={{ margin: '85px 0 50px 0', display: 'flex', justifyContent: 'space-between' }}
    >
      <Box>
        <Dropdown
          value={selectedSite.id}
          options={options}
          label={formatMessage({ id: 'header.topInputs.viewingInformation' })}
          labelInline
          onChange={(e) => handleDropdownSelect(e)}
        />
      </Box>

      <Box display={'flex'}>
        <Box marginRight={'24px'}>
          <ResetInputs />
        </Box>
        <ButtonInput variant="outlined" onClick={goBackToAssessment}>
          {formatMessage({ id: 'header.topInputs.editInputs' })}
        </ButtonInput>
      </Box>
    </Box>
  );
}
