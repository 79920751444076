import React from 'react';
import { Typography, Link, Icon, Box, makeStyles, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ButtonInput from '../inputs/ButtonInput';
import Copyright from '../copyright/Copyright';
import { useInputs } from '@bellawatt/use-inputs';
import { useDepletedBatterySites } from '../../utils/useDepletedBatterySites';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAnalytics } from '../../utils/useAnalytics';

const useStyles = makeStyles((theme) => ({
  footerBox: {
    padding: `${theme.spacing(4)}px 0`,
    backgroundColor: theme.palette.primary.main,
  },
  footerText: {
    color: '#fff',
  },
  logoBox: {
    marginBottom: theme.spacing(4),
  },
  socialIconsBox: {
    marginBottom: theme.spacing(3),
  },
  linksBox: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
  },
  textLink: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: '600',
    position: 'relative',
    marginRight: '20px',
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-child):after': {
        color: 'grey',
        position: 'absolute',
        top: 0,
        right: '-10px',
        content: '"|"',
      },
    },
  },
  copyrightText: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
  },
  hr: {
    border: '1px solid #d9d9d9',
  },
  bottomButtonContainer: {
    padding: '40px 20px',
    justifyContent: 'center',
    display: 'flex',
  },
  bottomButtons: {
    width: '100%',
    maxWidth: '1135px',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  btn: {
    color: theme.palette.common.accentBlue,
    borderColor: theme.palette.common.accentBlue,
    textTransform: 'none',
    borderRadius: '0',
    minWidth: '320px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const SocialIcon = ({ href, imgSrc, imgClass, altText, onClick }) => (
  <a href={href} target="_blank" rel="noreferrer" aria-label={altText} onClick={onClick}>
    <Icon>
      <img className={imgClass} src={imgSrc} alt={altText} />
    </Icon>
  </a>
);

export default function Footer({ hideRebates }) {
  const { GaTracker } = useAnalytics();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { isOnboarded } = useInputs();
  const { depletedBatterySites } = useDepletedBatterySites();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const path = window.location.pathname;
  const showPrint =
    isOnboarded && isLargeScreen && path === '/fleets' && depletedBatterySites.length === 0;

  const handleLinkClick = (url, name, blank = false) => {
    GaTracker.trackEvent({
      category: 'DTE Fleets Link',
      action: 'Clicked DTE Footer Link',
      label: `Clicked on ${name}`,
    });

    if (blank) {
      return window.open(url, '_blank');
    }

    return window.open(url);
  };

  return (
    <Box role="contentinfo">
      {!hideRebates && (
        <>
          <hr className={classes.hr} />
          <Box className={`${classes.bottomButtonContainer} container`}>
            <Grid container justifyContent="space-between" className={classes.bottomButtons}>
              <ButtonInput
                variant="outlined"
                href="#"
                onClick={() =>
                  handleLinkClick(
                    formatMessage({ id: 'footer.footerButtons.chargerRebates.url' }),
                    formatMessage({ id: 'footer.footerButtons.chargerRebates.text' }),
                    true,
                  )
                }
                className={classes.btn}
              >
                {formatMessage({ id: 'footer.footerButtons.chargerRebates.text' })}
              </ButtonInput>
              <ButtonInput
                variant="outlined"
                href="#"
                onClick={() =>
                  handleLinkClick(
                    formatMessage({ id: 'footer.footerButtons.advisoryServices.url' }),
                    formatMessage({ id: 'footer.footerButtons.advisoryServices.text' }),
                    true,
                  )
                }
                className={classes.btn}
              >
                {formatMessage({ id: 'footer.footerButtons.advisoryServices.text' })}
              </ButtonInput>
              {showPrint && (
                <ButtonInput
                  variant="outlined"
                  href="#"
                  onClick={() =>
                    handleLinkClick(
                      formatMessage({ id: 'footer.footerButtons.printResults.url' }),
                      formatMessage({ id: 'footer.footerButtons.printResults.text' }),
                      true,
                    )
                  }
                  className={classes.btn}
                >
                  {formatMessage({ id: 'footer.footerButtons.printResults.text' })}
                </ButtonInput>
              )}
            </Grid>
          </Box>
        </>
      )}
      <Box className={classes.footerBox}>
        <Box className={classes.logoBox}>
          <img src={theme.footer.logoPath} alt="DTE" />
        </Box>
        <Box className={classes.socialIconsBox}>
          <SocialIcon
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'footer.footerButtons.twitter.url' }),
                'Twitter',
                true,
              )
            }
            imgSrc={theme.footer.socialIconPaths.twitter}
            imgClass={classes.imageIcon}
            altText="Twitter"
          />
          <SocialIcon
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'footer.footerButtons.facebook.url' }),
                'Facebook',
                true,
              )
            }
            imgSrc={theme.footer.socialIconPaths.facebook}
            imgClass={classes.imageIcon}
            altText="Facebook"
          />
          <SocialIcon
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'footer.footerButtons.linkedin.url' }),
                'LinkedIn',
                true,
              )
            }
            imgSrc={theme.footer.socialIconPaths.linkedIn}
            imgClass={classes.imageIcon}
            altText="LinkedIn"
          />
          <SocialIcon
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'footer.footerButtons.youtube.url' }),
                'YouTube',
                true,
              )
            }
            imgSrc={theme.footer.socialIconPaths.youTube}
            imgClass={classes.imageIcon}
            altText="YouTube"
          />
        </Box>
        <Box className={classes.linksBox}>
          <Link
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'footer.termsAndConditions.url' }),
                formatMessage({ id: 'footer.termsAndConditions.text' }),
                true,
              )
            }
            className={classes.textLink}
          >
            {formatMessage({ id: 'footer.termsAndConditions.text' })}
          </Link>
          <Link
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'footer.privacyPolicy.url' }),
                formatMessage({ id: 'footer.privacyPolicy.text' }),
                true,
              )
            }
            className={classes.textLink}
          >
            {formatMessage({ id: 'footer.privacyPolicy.text' })}
          </Link>
          <Link
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'footer.contactUs.url' }),
                formatMessage({ id: 'footer.contactUs.text' }),
                true,
              )
            }
            className={classes.textLink}
          >
            {formatMessage({ id: 'footer.contactUs.text' })}
          </Link>
        </Box>
        <Box className={classes.copyrightBox}>
          <Typography className={`${classes.footerText} ${classes.copyrightText}`}>
            <Copyright />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
