import sum from 'lodash/sum';
import times from 'lodash/times';
import { LoadProfile } from '@bellawatt/electric-rate-engine';
import VehicleSet from './VehicleSet';
import { getNumberOfHoursInYear } from '../utils/time';

const Site = {
  /**
   * @param {Site} site
   * @param {number} startYear
   * @param {boolean} isFossilFleet
   * @param {CostOptions} costOptions
   * @returns
   */
  totalCosts(site, startYear, isFossilFleet, { dieselPrice, gasolinePrice }, isTco = false) {
    const byYear = [];
    const byCategory = {};

    for (let i = 0; i < 10; i++) {
      // for 10 years, compute all costs for every vehicle set
      const annualCosts = site.vehicleSets.reduce((acc, vehicleSet) => {
        // category costs for a single vehicle set
        const vehicleSetCosts = VehicleSet.totalAnnualCosts(
          vehicleSet,
          startYear + i,
          startYear,
          isFossilFleet,
          {
            dieselPrice,
            gasolinePrice,
          },
          isTco,
        );
        // accumulate category costs
        Object.keys(vehicleSetCosts).forEach((key) => {
          acc[key] = vehicleSetCosts[key] + (acc[key] ? acc[key] : 0);
        });

        return acc;
      }, {});

      // add yearly total (all vehicle sets) to running list of years
      const total = sum(Object.values(annualCosts));
      byYear.push(i === 0 ? total : total + byYear[i - 1]);

      // add category costs (all vehicle sets) to running total
      Object.keys(annualCosts).forEach((key) => {
        byCategory[key] = annualCosts[key] + (byCategory[key] ? byCategory[key] : 0);
      });
    }

    const incentiveAmount = !isFossilFleet
      ? site.vehicleSets.reduce((acc, vehicleSet) => {
          const incentiveVS = vehicleSet.customIncentives.reduce((accc, obj) => {
            const value = parseFloat(obj.Value * vehicleSet.vehicleCount);
            return accc + value;
          }, 0);
          return acc + incentiveVS;
        }, 0)
      : 0;

    const ret = {
      byCategory: {
        ...byCategory,
        msrp: byCategory.msrp - incentiveAmount,
      },
      byYear,
    };

    return ret;
  },

  /**
   * @param {Site} site
   * @param {number} year
   * @returns
   */
  annualLoadProfile(site, year) {
    const { vehicleSets } = site;

    const loadProfileOfZeros = new LoadProfile(
      times(getNumberOfHoursInYear(year), () => 0),
      { year },
    );

    return vehicleSets.reduce((acc, vehicleSet) => {
      const loadProfile = VehicleSet.annualLoadProfile(vehicleSet, year);
      return acc.aggregate(loadProfile);
    }, loadProfileOfZeros);
  },

  /**
   * @param {Site} site
   * @param {number} year
   * @returns number
   */
  maxDemand(site, year) {
    const aggregateLoadProfile = this.annualLoadProfile(site, year);
    return aggregateLoadProfile.max();
  },

  /**
   * @param {Site} site
   * @returns number
   */
  getTotalVehicles(site) {
    return sum(site.vehicleSets.map((set) => set.vehicleCount));
  },

  /**
   * @param {Site} site
   * @returns number
   */
  getAverageMPG(site) {
    return (
      sum(site.vehicleSets.map((set) => set.fossilVehicle.milesPerGallon * set.vehicleCount)) /
      this.getTotalVehicles(site)
    );
  },

  /**
   * @param {Site} site
   * @returns number
   */
  getAverageMPGElectric(site) {
    return sum(
      site.vehicleSets.map((set) => {
        const vehicle = VehicleSet.getElectricVehicle(set);
        return (vehicle.milesPerGallon * set.vehicleCount) / this.getTotalVehicles(site);
      }),
    );
  },

  /**
   * @param {Site} site
   * @param {number} year
   * @returns number
   */
  getAnnualKwhUsage(site, year) {
    return sum(
      site.vehicleSets.map((set) => {
        if (year && year < set.replacementYear) {
          return 0;
        }
        return VehicleSet.annualKwhUsage(set);
      }),
    );
  },

  /**
   * @param {Site} site
   * @param {number} year
   * @returns number
   */
  getAnnualTotalMiles(site, year) {
    return sum(
      site.vehicleSets.map((set) => {
        if (year && year < set.replacementYear) {
          return 0;
        }
        return VehicleSet.annualMiles(set);
      }),
    );
  },

  /**
   * @param {Site} site
   * @returns number
   */
  getTotalMiles(site) {
    return sum(site.vehicleSets.map((set) => VehicleSet.annualMiles(set)));
  },

  /**
   * @param {Site} site
   * @param {number} year
   * @param {FuelPrices} fuelPrices
   * @returns
   */
  getTotalAnnualFuelCosts(site, year, fuelPrice) {
    return sum(
      site.vehicleSets.map((set) => VehicleSet.annualFossilFuelCosts(set, year, fuelPrice)),
    );
  },

  /**
   * @param {Site} site
   * @param {number} year
   * @param {FuelPrices} fuelPrices
   * @returns object
   */
  getTotalAnnualElectricFleetCosts(site, year, fuelPrice) {
    return site.vehicleSets.reduce(
      (acc, set) => {
        const { electric, fossil } = VehicleSet.annualPhevFuelCosts(set, year, fuelPrice);
        return {
          total: acc.total + electric + fossil,
          electric: acc.electric + electric,
          fossil: acc.fossil + fossil,
        };
      },
      { total: 0, electric: 0, fossil: 0 },
    );
  },
};

export default Site;
