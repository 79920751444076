import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import VehicleCard from '../vehicleCard/VehicleCard';

export default function VehicleList({ vehicles }) {
  const { formatMessage } = useIntl();

  if (vehicles.length === 0) {
    return (
      <Box fontWeight="600" fontSize="14px">
        {formatMessage({ id: 'replacementVehicle.noMatches' })}
      </Box>
    );
  }
  return (
    <Box display="grid" gap="16px 20px" gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))">
      {vehicles.map((vehicle) => (
        <VehicleCard vehicle={vehicle} key={vehicle.id} />
      ))}
    </Box>
  );
}
