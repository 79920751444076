import times from 'lodash/times';
import { isHourInUse } from '../utils/time';

// calculations that require an array of vehicle sets, but don't necessarily apply to only one site
const VehicleSets = {
  /**
   * @param {Array<VehicleSet>} vehicleSets
   * @param {number|string} siteInd
   * @returns
   */
  groupedChargers(vehicleSets, siteInd = null) {
    const chargingSets = {};

    vehicleSets.forEach(({ chargingWindows, ...vehicle }, vsi) => {
      chargingWindows.forEach((chargingWindow, cwi) => {
        // uses 'type' for generic and 'id' for API chargers
        const index = chargingWindow.isGeneric ? chargingWindow.type : chargingWindow.id;

        const vehicleChargingUsage = {
          ...vehicle,
          usage: times(24, (hour) =>
            isHourInUse(chargingWindow, hour) ? vehicle.vehicleCount : 0,
          ),
        };
        chargingSets[index] = {
          siteInd: siteInd,
          vehicleSetInd: vsi,
          chargingWindowInd: cwi,
          ...chargingWindow,
          ...chargingWindow.charger,
          vehicles: chargingSets[index]
            ? [...chargingSets[index].vehicles, vehicleChargingUsage]
            : [vehicleChargingUsage],
        };
      });
    });

    Object.keys(chargingSets).forEach((key) => {
      const summedHours = chargingSets[key].vehicles.reduce(
        (sum, { usage }) => sum.map((val, i) => val + usage[i]),
        times(24, () => 0),
      );

      chargingSets[key].minimumRequired = Math.ceil(
        Math.max(...summedHours) / chargingSets[key].ports,
      );
    });

    return Object.values(chargingSets);
  },
};

export default VehicleSets;
