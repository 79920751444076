import { React } from 'react';
import { Box, Toolbar, Typography, AppBar, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import GreyBox from './greyBox/GreyBox';
import { VisitIcon } from '../../assets/icons/icons';
import FleetSummary from '../fleetSummary/FleetSummary';
import { useInputs } from '@bellawatt/use-inputs';
import { Route } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoCard from '../../Panels/InfoCard/InfoCard';
import { useAnalytics } from '../../utils/useAnalytics';

export default function Header() {
  const { GaTracker } = useAnalytics();
  const { formatMessage } = useIntl();
  const { isOnboarded } = useInputs();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const handleLinkClick = (url, name, blank = false) => {
    GaTracker.trackEvent({
      category: 'DTE Fleets Link',
      action: 'Clicked DTE Header Link',
      label: `Clicked on ${name}`,
    });

    if (blank) {
      return window.open(url, '_blank');
    }

    return window.open(url);
  };

  return (
    <Box>
      <AppBar position="relative">
        <Toolbar
          className="container"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              borderBottom: '2px solid white',
              padding: '16px 0',
              paddingLeft: '0',
              paddingRight: '0',
            },
          })}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            {isLargeScreen && (
              <Grid item sm={4} style={{ textAlign: 'start' }}>
                <Typography>{formatMessage({ id: 'header.slogan' })}</Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <img src={theme.header.logoPath} alt="DTE" />
            </Grid>
            {!isLargeScreen && (
              <Grid item xs={12} style={{ textAlign: 'center', fontSize: '16px' }}>
                {formatMessage({ id: 'header.slogan' })}
              </Grid>
            )}
            {isLargeScreen && (
              <Grid item sm={4}>
                <a
                  style={{ textDecoration: 'none' }}
                  href="#"
                  onClick={() =>
                    handleLinkClick(
                      formatMessage({ id: 'header.visitLink' }),
                      formatMessage({ id: 'header.visit' }),
                      true,
                    )
                  }
                >
                  <Box display="flex" justifyContent="end" color="white">
                    <Box pr="10px" pt="3px">
                      <VisitIcon />
                    </Box>
                    <Typography>{formatMessage({ id: 'header.visit' })}</Typography>
                  </Box>
                </a>
              </Grid>
            )}
          </Grid>
        </Toolbar>
        {isLargeScreen && <GreyBox handleLinkClick={handleLinkClick} />}
        {isOnboarded && <Route path="/fleets" component={FleetSummary} />}
      </AppBar>
      {!isLargeScreen && (
        <div style={{ padding: '16px' }}>
          <InfoCard variant="info" message={formatMessage({ id: 'header.mobileWarning' })} />
        </div>
      )}
    </Box>
  );
}
