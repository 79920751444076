import React, { useState } from 'react';
import { Grid, Box, Checkbox, FormControlLabel } from '@mui/material';
import TextInput from '../inputs/TextInput';
import CheckboxButtonRow from '../inputs/CheckboxButtonRow';
import { DAYS_ABBREVIATED, MONTHS_ABBREVIATED } from '../../data/timePeriods';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { stringToInt } from '../../utils/formatters';

export default function VehicleUsageInputs({ vehicleSet, onChangeVehicleSet }) {
  const { formatMessage } = useIntl();

  const { milesPerWorkday, vehicleCount, workdays, workmonths } = vehicleSet;
  const [showDays, setShowDays] = useState(!isEqual(workdays, [1, 2, 3, 4, 5]));
  const [showMonths, setShowMonths] = useState(workmonths.length !== 12);

  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={5}
      justifyContent="center"
      maxWidth="900px"
      m="0 auto"
    >
      <Grid item xs={6}>
        <TextInput
          mask="9999999"
          label={formatMessage({ id: 'editVehicleSets.usage.miles' }).toUpperCase()}
          labelTooltip={formatMessage({ id: 'editVehicleSets.averageDailyMiles' })}
          defaultValue={milesPerWorkday}
          placeholder=""
          onChange={(value) => {
            onChangeVehicleSet({ milesPerWorkday: value });
          }}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mask="9999999"
          label={formatMessage({ id: 'editVehicleSets.usage.count' }).toUpperCase()}
          labelTooltip={formatMessage({ id: 'editVehicleSets.vehicleCount' })}
          defaultValue={vehicleCount}
          placeholder=""
          onChange={(value) => onChangeVehicleSet({ vehicleCount: stringToInt(value) })}
          required
        />
      </Grid>
      <Grid item xs={12} textAlign="left">
        <Grid item xs={5} textAlign="left" maxWidth="300px">
          <Box mt="48px">
            {showDays ? (
              <CheckboxButtonRow
                label="editVehicleSets.usage.days"
                data={DAYS_ABBREVIATED}
                selectionArray={workdays}
                onChange={(value) => onChangeVehicleSet({ workdays: value })}
              />
            ) : (
              <>
                <FormControlLabel
                  control={<Checkbox checked={isEqual(workdays, [1, 2, 3, 4, 5])} />}
                  label={formatMessage({ id: 'onboardingSteps.usage.weekdays' })}
                />
                <Box display="flex">
                  <Box sx={{ fontSize: '16px', color: '#505357', pr: '5px' }}>
                    {formatMessage({ id: 'onboardingSteps.usage.customDays' })}
                  </Box>
                  <Box
                    sx={{ fontSize: '16px', color: '#0072CE', textDecoration: 'underline' }}
                    onClick={() => setShowDays(true)}
                  >
                    {formatMessage({ id: 'onboardingSteps.usage.selectDays' })}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={8} textAlign="left" maxWidth="440px">
          <Box mt="48px">
            {showMonths ? (
              <CheckboxButtonRow
                label="editVehicleSets.usage.months"
                data={MONTHS_ABBREVIATED}
                selectionArray={workmonths}
                onChange={(value) => onChangeVehicleSet({ workmonths: value })}
              />
            ) : (
              <>
                <FormControlLabel
                  control={<Checkbox checked={true} />}
                  label={formatMessage({ id: 'onboardingSteps.usage.allMonths' })}
                />
                <Box display="flex">
                  <Box sx={{ fontSize: '16px', color: '#505357', pr: '5px' }}>
                    {formatMessage({ id: 'onboardingSteps.usage.customMonths' })}
                  </Box>
                  <Box
                    sx={{ fontSize: '16px', color: '#0072CE', textDecoration: 'underline' }}
                    onClick={() => setShowMonths(true)}
                  >
                    {formatMessage({ id: 'onboardingSteps.usage.selectMonths' })}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
