import VehicleSet from './VehicleSet';
import VehicleSets from './VehicleSets';
import sum from 'lodash/sum';
import times from 'lodash/times';
import Site from './Site';

const Totals = {
  /**
   * @param {Array<Site>} sites
   * @param {number} startYear
   * @returns number
   */
  emissionsSavings(sites, startYear) {
    return sum(
      times(10, (i) =>
        sum(
          sites.map(({ vehicleSets }) =>
            sum(vehicleSets.map((set) => VehicleSet.annualEmissionsSaved(set, startYear + i))),
          ),
        ),
      ),
    );
  },

  /**
   * @param {Array<Site>} sites
   * @param {number} year
   * @param {FuelPrices} fuelPrices
   * @returns
   */
  getAnnualFleetFuelCosts(sites, year, fuelPrices) {
    let gasolineCost = 0;
    let dieselCost = 0;
    let bevElectricCost = 0;
    let phevElectricCost = 0;
    let phevGasCost = 0;

    sites.forEach((site) => {
      site.vehicleSets.forEach((vehicleSet) => {
        let fuel = vehicleSet?.fossilVehicle?.fuel || vehicleSet?.fossilFuelType;

        if (fuel === 'gasoline') {
          gasolineCost += VehicleSet.annualFossilFuelCosts(vehicleSet, year, fuelPrices, false);
        } else if (fuel === 'diesel') {
          dieselCost += VehicleSet.annualFossilFuelCosts(vehicleSet, year, fuelPrices, false);
        } else {
          console.warn('Invalid fuel type', fuel);
        }
        if (vehicleSet.vehicle?.fuel === 'PHEV') {
          const annualCosts = VehicleSet.annualPhevFuelCosts(vehicleSet, year, fuelPrices, false);
          phevElectricCost += annualCosts.electric;
          phevGasCost += annualCosts.fossil;
        } else {
          bevElectricCost += VehicleSet.annualElectricityCosts(vehicleSet, year, false);
        }
      });
    });

    return {
      gasolineCost,
      dieselCost,
      bevElectricCost,
      phevElectricCost,
      phevGasCost,
      fossilFleetTotal: gasolineCost + dieselCost,
      electricFleetTotal: bevElectricCost + phevElectricCost + phevGasCost,
      fuelCost: gasolineCost + dieselCost,
    };
  },

  /**
   * @param {Array<Site>} sites
   * @param {number} startYear
   * @param {boolean} isFossilFleet
   * @param {CostOptions} costOptions
   * @returns
   */
  totalCosts(sites, startYear, isFossilFleet, costOptions, isTco = false) {
    const { gasolinePrice, dieselPrice } = costOptions;
    const byCategory = {};
    let byYear = [];

    sites.forEach((site) => {
      const siteCosts = Site.totalCosts(
        site,
        startYear,
        isFossilFleet,
        {
          gasolinePrice,
          dieselPrice,
        },
        isTco,
      );

      // accumulate yearly costs
      byYear = siteCosts.byYear.map((cost, i) => cost + (byYear[i] || 0));

      // accumulate category costs
      Object.keys(siteCosts.byCategory).forEach((key) => {
        byCategory[key] = siteCosts.byCategory[key] + (byCategory[key] ? byCategory[key] : 0);
      });
    });

    const ret = {
      byCategory: {
        ...byCategory,
        msrp: byCategory.msrp,
      },
      byYear,
    };
    return ret;
  },

  /**
   * @param {Array<Site>} sites
   * @param {number} year
   * @returns number
   */
  maxDemand(sites, year) {
    const siteMaxes = sites.map((site) => Site.maxDemand(site, year));
    // max demand of the site with the highest demand
    return Math.max(...siteMaxes);
  },

  /**
   * @param {Array<Site>} sites
   * @returns
   */
  getAllGroupedChargers(sites) {
    return sites.map((site) => VehicleSets.groupedChargers(site.vehicleSets, site.id));
  },
};

export default Totals;
