import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import { DAYS_ABBREVIATED, MONTHS_ABBREVIATED } from '../../../data/timePeriods';

export function Workdays({ days }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Grid container direction="column" columnSpacing={0} height="210px">
      {DAYS_ABBREVIATED.sort((a, b) => a.position - b.position).map(
        ({ itemIndex, translationKey }) => (
          <Grid key={itemIndex} item xs={3}>
            <Box
              color={
                days.includes(itemIndex)
                  ? theme.palette.common.brandBlue
                  : theme.palette.common.secondaryGrey
              }
              fontWeight={days.includes(itemIndex) ? 'bold' : 'normal'}
            >
              {formatMessage({ id: translationKey }).toUpperCase()}
            </Box>
          </Grid>
        ),
      )}
    </Grid>
  );
}

export function WorkMonths({ months }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={2} height="300px">
      {MONTHS_ABBREVIATED.sort((a, b) => a.position - b.position).map(
        ({ itemIndex, translationKey }) => (
          <Grid key={itemIndex} item xs={1}>
            <Box
              color={
                months.includes(itemIndex)
                  ? theme.palette.common.brandBlue
                  : theme.palette.common.secondaryGrey
              }
              fontWeight={months.includes(itemIndex) ? 'bold' : 'normal'}
            >
              {formatMessage({ id: translationKey }).toUpperCase()}
            </Box>
          </Grid>
        ),
      )}
    </Grid>
  );
}
