import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Divider } from '@mui/material';
import StyledCollapse from '../StyledCollapse/StyledCollapse';
import ReplacementSummary from '../../components/Onboarding/RecommendedVehicle/ReplacementSummary';
import UsageOutput from './UsageOutput/UsageOutput';
import InfoCard from '../InfoCard/InfoCard';
import VehicleSet from '../../calculations/VehicleSet';

export default function VehicleSetList({ vehicleSets, site }) {
  const { formatMessage } = useIntl();

  return (
    <Box>
      {vehicleSets.map((vehicleSet, index) => {
        const ev = VehicleSet.getElectricVehicle(vehicleSet);
        const insufficientRange = vehicleSet.milesPerWorkday > ev.rangeInMiles;
        const insufficientCharging =
          ev.fuel === 'BEV' &&
          0 > VehicleSet.minimumStateOfCharge(vehicleSet, vehicleSet.replacementYear);
        let defaultOpen = false;
        if (index === 0) {
          defaultOpen = true;
        }

        return (
          <StyledCollapse
            key={index}
            title={vehicleSet.name}
            styleName="primary"
            leftIcon={true}
            defaultOpen={defaultOpen}
          >
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={11}>
                <ReplacementSummary vehicleSet={vehicleSet} site={site} vehicleInd={index} />
              </Grid>
              {insufficientRange && (
                <Grid item xs={11} marginTop="24px">
                  <InfoCard
                    variant={'warning'}
                    message={
                      <>
                        <b>{formatMessage({ id: 'chargingWindows.warning' })}: </b>
                        {formatMessage({ id: 'chargingWindows.warningInsufficientRange' })}
                      </>
                    }
                  />
                </Grid>
              )}
              <Grid item xs={11}>
                <Box mt="40px" mb="40px">
                  <Divider color="#E4E4E4" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <UsageOutput vehicleSet={vehicleSet} />
              </Grid>
              {insufficientCharging && (
                <Grid item xs={11} margin="16px 0">
                  <InfoCard
                    variant={'error'}
                    message={
                      <>
                        <b>{formatMessage({ id: 'chargingWindows.warning' })}: </b>
                        {formatMessage({ id: 'chargingWindows.warningInsufficientCharge' })}
                      </>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </StyledCollapse>
        );
      })}
    </Box>
  );
}
