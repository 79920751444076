import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import ChargerCard from '../chargerCard/ChargerCard';

export default function ChargerList({ chargers }) {
  const { formatMessage } = useIntl();

  if (chargers.length === 0) {
    return (
      <Box fontWeight="600" fontSize="14px">
        {formatMessage({ id: 'replacementVehicle.noMatches' })}
      </Box>
    );
  }
  return (
    <Box display="grid" gap="16px 20px" gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))">
      {chargers.map((charger) => (
        <ChargerCard charger={charger} key={JSON.stringify(charger)} />
      ))}
    </Box>
  );
}
