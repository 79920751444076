import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useIntl } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import ChargingWindows from './ChargingWindows';
import VehicleUsage from './VehicleUsage';
import ModalButtonRow from '../ModalButtonRow/ModalButtonRow';
import { CloseIcon } from '../../assets/icons/icons';
import { findArchetypeById } from '../../data/VEHICLES';
import ButtonInput from '../../components/inputs/ButtonInput';
import VehicleInfoInputs from '../../components/Onboarding/VehicleInfoInputs';
import { transformArchetypeToFossilVehicle } from '../../utils/conversions';

const SectionTitle = ({ title }) => {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          textAlign: 'left',
          color: '#2B2B2B',
          fontSize: '18px',
          fontWeight: '600',
          pb: '20px',
        }}
      >
        {title}
      </Box>
    </Grid>
  );
};

export default function EditVehicleSetDialog({
  isOpen,
  vehicleSet,
  siteIndex,
  onClose,
  onDelete,
  onSave,
  canRemove,
}) {
  const { formatMessage } = useIntl();

  /** @type {[VehicleSet, (vehicleSet: VehicleSet) => void]} */
  const [workingVehicleSet, setWorkingVehicleSet] = useState(cloneDeep(vehicleSet));

  useEffect(() => {
    setWorkingVehicleSet(cloneDeep(vehicleSet));
  }, [vehicleSet]);

  /** @type {[number, (siteIndex: number) => void]} */
  const [workingSite, setWorkingSite] = useState(siteIndex);

  const handleChangeVehicleSet = (newData) => {
    // if (newData['vehicle_id'] !== undefined) {
    if (newData.fossilVehicleId && !newData.fossilVehicle) {
      const fossilVehicle = transformArchetypeToFossilVehicle(
        findArchetypeById(newData['fossilVehicleId']),
      );
      setWorkingVehicleSet({
        ...workingVehicleSet,
        ...newData,
        fossilVehicleId: fossilVehicle.id,
      });
    } else {
      setWorkingVehicleSet({
        ...workingVehicleSet,
        ...newData,
      });
    }
  };

  const handleSiteChange = (value) => {
    setWorkingSite(value);
  };

  const handleSave = () => {
    onSave(workingVehicleSet, workingSite);
  };

  return (
    <Dialog open={isOpen} maxWidth="lg" disableEnforceFocus>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '28px' }}>
          {formatMessage({ id: 'editVehicleSets.title' })}
          <ButtonInput variant="link" onClick={onClose}>
            <span aria-label={formatMessage({ id: 'editVehicleSets.cancel' })} />
            <CloseIcon />
          </ButtonInput>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <SectionTitle title={formatMessage({ id: 'editVehicleSets.info.title' })} />
          <VehicleInfoInputs
            vehicleSet={workingVehicleSet}
            onChangeVehicleSet={handleChangeVehicleSet}
            onChangeSite={handleSiteChange}
          />
          <Box mt="40px" mb="40px">
            <Divider color="#E4E4E4" width="100%" />
          </Box>
          <Box>
            <VehicleUsage
              vehicleSet={workingVehicleSet}
              onChangeVehicleSet={handleChangeVehicleSet}
            />
          </Box>
          <Box>
            <Box mt="40px" mb="40px">
              <Divider color="#E4E4E4" width="100%" />
            </Box>
          </Box>
          <Box>
            <SectionTitle title={formatMessage({ id: 'editVehicleSets.times.title' })} />
            <Box mb="40px">{formatMessage({ id: 'chargingWindows.description' })}</Box>
            <ChargingWindows
              vehicleSet={workingVehicleSet}
              onChangeVehicleSet={handleChangeVehicleSet}
            />
          </Box>
          <Box>
            <ModalButtonRow
              onClose={onClose}
              onDelete={onDelete}
              showDelete={canRemove}
              onSaveAndClose={handleSave}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
