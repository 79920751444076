import React from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import { useIntl } from 'react-intl';
import { Box, Link } from '@mui/material';
import StyledCollapse from '../StyledCollapse/StyledCollapse';
import InfoCard from '../InfoCard/InfoCard';
import sum from 'lodash/sum';
import Site from '../../calculations/Site';
import Section from '../../components/Section/Section';

const MenuHeader = (site) => {
  const { formatMessage } = useIntl();

  const numVehicles = sum(site.vehicleSets.map(({ vehicleCount }) => vehicleCount));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <Box sx={{ mr: '50px' }}>
        <b>{site.name}</b>
      </Box>
      <Box sx={{ mr: '50px' }}>
        <b>{formatMessage({ id: 'zipcode' })}</b> {site.address.zip}
      </Box>
      <Box>
        <b>{formatMessage({ id: 'panels.connect.numberOfVehicles' })}</b> {numVehicles}
      </Box>
    </Box>
  );
};

const SiteCollapse = ({ site, startYear }) => {
  const { formatMessage } = useIntl();

  // Use final year, because all vehicles will be electrified by then
  const exceedsLimit = Site.maxDemand(site, startYear + 9) > 200;

  const warningMessage = formatMessage(
    { id: 'panels.connect.warningLimit' },
    {
      siteExceeds: <b>{formatMessage({ id: 'panels.connect.siteExceeds' })}</b>,
      installer: (
        <Link
          href="https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/business/electric/electric-vehicles/pev-biz-installers"
          underline="always"
          color="secondary"
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({ id: 'panels.charge.installer' })}
        </Link>
      ),
      advisory: (
        <Link
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=_tVhjkl3dk6I7m2Hma6BQ72yt26BtDJJnBKKSVUFMepUQzlVRDdGTDdUTDI2UzczRzY1UjAxQjZNTyQlQCN0PWcu"
          underline="always"
          color="secondary"
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({ id: 'panels.charge.advisory' })}
        </Link>
      ),
    },
  );

  return (
    <StyledCollapse title={MenuHeader(site)} styleName="primary" leftIcon={true} pencilIcon={true}>
      <Box p="12px" backgroundColor="#FBFBFC">
        <InfoCard
          variant={exceedsLimit ? 'warning' : 'success'}
          message={
            exceedsLimit
              ? warningMessage
              : formatMessage(
                  { id: 'panels.connect.siteBelow' },
                  {
                    installer: (
                      <Link
                        href="https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/business/electric/electric-vehicles/pev-biz-installers"
                        underline="always"
                        color="secondary"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {formatMessage({ id: 'panels.charge.installer' })}
                      </Link>
                    ),
                  },
                )
          }
        />
      </Box>
    </StyledCollapse>
  );
};

export default function ConnectAllSites() {
  const { formatMessage } = useIntl();

  const { sites, startYear } = useInputs();

  return (
    <Section
      title={formatMessage({ id: 'panels.connect.gridConnections' })}
      description={formatMessage({ id: 'panels.connect.connectionSummary' })}
    >
      {sites.map((site) => (
        <SiteCollapse key={site.name} site={site} startYear={startYear} />
      ))}
    </Section>
  );
}
