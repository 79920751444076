import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import without from 'lodash/without';
import CheckboxButtonInput from './CheckboxButtonInput';

export default function CheckboxButtonRow({ onChange, selectionArray = [], label, data }) {
  const { formatMessage } = useIntl();

  const toggleButton = (itemIndex) => {
    if (selectionArray.includes(itemIndex)) {
      onChange(without(selectionArray, itemIndex));
    } else {
      onChange([...selectionArray, itemIndex]);
    }
  };

  return (
    <Box>
      <Box
        align="left"
        fontSize="0.875rem"
        sx={{ fontWeight: 'bold', mb: '10px', color: '#505357' }}
      >
        {formatMessage({ id: label }).toUpperCase()}
      </Box>
      <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        {data
          .sort((a, b) => a.position - b.position)
          .map(({ itemIndex, translationKey }) => (
            <Box key={itemIndex} sx={{ flexGrow: '0' }}>
              <CheckboxButtonInput
                checked={selectionArray.includes(itemIndex)}
                onClick={() => toggleButton(itemIndex)}
              >
                {formatMessage({ id: translationKey })}
              </CheckboxButtonInput>
            </Box>
          ))}
      </Box>
    </Box>
  );
}
