import { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';

export function useAnalytics() {
  const trackers = useMemo(
    () => [
      {
        trackingId: process.env.REACT_APP_GA_CLIENT_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CLIENT_TRACKING_OPTIONS_NAME,
        },
      },
      {
        trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_OPTIONS_NAME,
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (trackers.length) {
      ReactGA.initialize(trackers);
    }
  }, [trackers]);

  const GaTracker = {
    trackEvent: (event = {}) => {
      // These fields are required by Google Analytics for events
      if (!event.category || !event.action) {
        return;
      }

      ReactGA.event(event);
    },

    pageview: (location) => {
      if (!location) return;

      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    },
  };

  return {
    GaTracker,
  };
}
