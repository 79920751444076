import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ButtonInput from '../../inputs/ButtonInput';

export default function ReplacementVehicleCard({
  imageUrl,
  make,
  model,
  electricRange,
  range,
  msrp,
  msrpIsEstimated,
  icon,
  handleSelect,
  active,
  isFossilVehicle,
}) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
        padding: '15px',
        overflow: 'hidden',
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item sx={{ fontSize: '22px', fontWeight: 'bold' }}>
          {make}
        </Grid>
        <Grid item>{icon}</Grid>
      </Grid>

      <Box>{model}</Box>

      <Box sx={{ textAlign: 'center', minHeight: '100px' }}>
        <img src={imageUrl} alt={make} style={{ maxWidth: '75%' }} />
      </Box>

      <Box sx={{ fontSize: '14px' }}>
        {electricRange != range && (
          <>
            <Grid container justifyContent="space-between">
              <Grid item>{formatMessage({ id: 'replacementVehicle.electricRange' })}</Grid>
              <Grid item sx={{ fontWeight: 'bold' }}>
                {`${electricRange} ${formatMessage({ id: 'replacementVehicle.miles' })}`}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>{formatMessage({ id: 'replacementVehicle.totalRange' })}</Grid>
              <Grid item sx={{ fontWeight: 'bold' }}>
                {`${range} ${formatMessage({ id: 'replacementVehicle.miles' })}`}
              </Grid>
            </Grid>
            <hr />
          </>
        )}

        {electricRange == range && (
          <>
            <Grid container justifyContent="space-between">
              <Grid item>{formatMessage({ id: 'replacementVehicle.range' })}</Grid>
              <Grid item sx={{ fontWeight: 'bold' }}>
                {`${range} ${formatMessage({ id: 'replacementVehicle.miles' })}`}
              </Grid>
            </Grid>
            <hr />
          </>
        )}

        <Grid container justifyContent="space-between">
          <Grid item>
            {msrpIsEstimated
              ? formatMessage({ id: 'replacementVehicle.estimatedMsrp' })
              : formatMessage({ id: 'replacementVehicle.msrp' })}
          </Grid>
          <Grid item sx={{ fontWeight: 'bold', fontSize: '22px' }}>
            {msrp}
          </Grid>
        </Grid>
        <hr />
      </Box>

      {active ? (
        <Box
          sx={{ textAlign: 'center' }}
          color={theme.palette.common.white}
          backgroundColor={theme.palette.common.accentBlue}
          margin="-10px -15px -15px -15px"
          padding="23px 15px"
        >
          {formatMessage({
            id: isFossilVehicle
              ? 'replacementVehicle.selectedFossil'
              : 'replacementVehicle.selectedAsReplacement',
          })}
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <ButtonInput variant="link" onClick={handleSelect}>
            {formatMessage({
              id: isFossilVehicle
                ? 'replacementVehicle.selectFossil'
                : 'replacementVehicle.selectAsReplacement',
            })}
          </ButtonInput>
        </Box>
      )}
    </Box>
  );
}
