import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { formatAsDollars } from '../../../utils/formatters';
import { generateTicks } from '../../../utils/functions';
import { useIntl } from 'react-intl';
import StyledBarGraph from '../../Charts/StyledBarGraph/StyledBarGraph';
import Section from '../../../components/Section/Section';

export default function TotalFuelSavingsChart({
  tenYearGasolineCost,
  tenYearDieselCost,
  tenYearElectricCost,
  tenYearPhevGasCost,
}) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const tfcLabels = [
    [
      formatMessage({ id: 'panels.fuel.fuelSavingsLabels.currentFleet' }),
      formatMessage({ id: 'panels.fuel.fuelSavingsLabels.estimatedCosts' }),
    ],
    [
      formatMessage({ id: 'panels.fuel.fuelSavingsLabels.electrifiedFleet' }),
      formatMessage({ id: 'panels.fuel.fuelSavingsLabels.yourSelection' }),
    ],
  ];

  const tenYearSavings =
    tenYearGasolineCost + tenYearDieselCost - tenYearElectricCost - tenYearPhevGasCost;
  const tfcData = {
    labels: tfcLabels,
    datasets: [
      {
        label: 'Electric',
        data: [0, tenYearElectricCost],
        backgroundColor: theme.palette.common.lightAqua,
      },
      tenYearGasolineCost && {
        label: 'Gasoline',
        data: [tenYearGasolineCost, tenYearPhevGasCost],
        backgroundColor: theme.palette.common.grey500,
      },
      tenYearDieselCost && {
        label: 'Diesel',
        data: [tenYearDieselCost],
        backgroundColor: theme.palette.common.grey300,
      },
    ].filter((x) => !!x),
  };

  const totalFuelSavingsTicks = generateTicks({
    datasets: tfcData.datasets,
    formatThousands: true,
  });

  return (
    <Section
      title={formatMessage({ id: 'panels.fuel.totalFuelSavings' })}
      description={formatMessage(
        {
          id:
            tenYearSavings > 0 ? 'panels.fuel.totalFuelCostLess' : 'panels.fuel.totalFuelCostMore',
        },
        { totalCost: `${formatAsDollars(Math.abs(tenYearSavings))}` },
      )}
    >
      <StyledBarGraph
        data={tfcData}
        xTicks={totalFuelSavingsTicks}
        showLegend={true}
        indexAxis={'y'}
        stacked={true}
        tooltipTitleFormatter={() => {}}
        tooltipLabelFormatter={(data) =>
          `${data.dataset.label}: ${formatAsDollars(data.formattedValue)}`
        }
        showVerticalGrid
        showHorizontalGrid
      />
      <Typography align="left" sx={{ fontSize: '16px', fontStyle: 'italic', mt: '20px' }}>
        {formatMessage({ id: 'panels.fuel.fuelCostAssumptions' })}
      </Typography>
    </Section>
  );
}
