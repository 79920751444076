import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Box } from '@mui/material';
import Dropdown from '../../components/inputs/Dropdown';
import TextInput from '../../components/inputs/TextInput';
import { chargingTimes } from '../../data/chargingWindows';
import cloneDeep from 'lodash/cloneDeep';
import {
  DEFAULT_CHARGING_WINDOW,
  SECONDARY_DEFAULT_CHARGING_WINDOW,
} from '../../use-inputs/getDefaults';
import { TrashIcon } from '../../assets/icons/icons';
import CHARGERS from '../../data/CHARGERS';
import VehicleSet from '../../calculations/VehicleSet';
import InfoCard from '../InfoCard/InfoCard';
import ButtonInput from '../../components/inputs/ButtonInput';

const MAX_CHARGING_WINDOWS = 4;

const ChargingWindow = ({
  charger,
  start,
  finish,
  onChange,
  onRemove,
  canDelete,
  type,
  isGeneric,
  chargingApproach,
}) => {
  const { formatMessage } = useIntl();
  const chargerType = type;

  const handleChange = (e, key) => {
    onChange({
      start,
      finish,
      type,
      chargingApproach,
      isGeneric,
      [key]: e.target.value,
    });
  };

  const NEW_CHARGERS = [
    {
      type: 'Level 2 - 7.2kW',
    },
    {
      type: 'Level 2 - 7.7kW',
    },
    {
      type: 'Level 2 - 9.6kW',
    },
    {
      type: 'Level 2 - 11.5kW',
    },
    {
      type: 'Level 2 - 16.8kW',
    },
    {
      type: 'Level 2 - 19.2kW',
    },
    {
      type: 'DCFC - 25kW',
    },
    {
      type: 'DCFC - 50kW',
    },
    {
      type: 'DCFC - 75kW',
    },
    {
      type: 'DCFC - 100kW',
    },
    {
      type: 'DCFC - 175kW',
    },
    {
      type: 'DCFC - 300kW',
    },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'stretch',
          gap: '24px',
          width: '35%',
          minWidth: '254px',
        }}
      >
        <Dropdown
          onChange={(e) => handleChange(e, 'start')}
          options={chargingTimes.map(({ id, translationKey }) => ({
            value: id,
            children: formatMessage({ id: translationKey }),
          }))}
          value={start}
          label={formatMessage({ id: 'editVehicleSets.times.start' }).toUpperCase()}
        />
        <Dropdown
          onChange={(e) => handleChange(e, 'finish')}
          options={chargingTimes.map(({ id, translationKey }) => ({
            value: id,
            children: formatMessage({ id: translationKey }),
          }))}
          value={finish}
          label={formatMessage({ id: 'editVehicleSets.times.end' }).toUpperCase()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '35%', minWidth: '295px' }}>
        {isGeneric ? (
          <Dropdown
            onChange={(e) => handleChange(e, 'type')}
            options={NEW_CHARGERS.map(({ type }) => ({
              value: type,
              children: type,
            }))}
            value={chargerType}
            label={formatMessage({ id: 'editVehicleSets.times.type' }).toUpperCase()}
            labelTooltip={formatMessage({ id: 'chargingWindows.tooltip' })}
          />
        ) : (
          <Box>
            <TextInput
              placeHolder={`${charger.chargeRate}kW ${charger.make} (${charger.chargerType})`}
              label={formatMessage({ id: 'editVehicleSets.times.type' }).toUpperCase()}
              labelTooltip={formatMessage({ id: 'chargingWindows.tooltip' })}
              disabled={true}
            />
            <Box sx={{ fontSize: '12px', marginLeft: '10px' }}>
              {formatMessage({ id: 'editVehicleSets.chargerSelectedMessage' })}
            </Box>
          </Box>
        )}
        {canDelete && (
          <Box mt="20px" ml="16px">
            <ButtonInput variant="link" alert onClick={onRemove}>
              <TrashIcon />
            </ButtonInput>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default function ChargingWindows({ vehicleSet, onChangeVehicleSet }) {
  const { formatMessage } = useIntl();
  const { chargingWindows } = vehicleSet;

  const handleChange = (idx, data) => {
    const newChargingWindows = cloneDeep(chargingWindows);
    newChargingWindows[idx] = data;
    onChangeVehicleSet({ chargingWindows: newChargingWindows });
  };

  const handleAddChargingWindow = () =>
    onChangeVehicleSet({
      chargingWindows: [
        ...vehicleSet.chargingWindows,
        chargingWindows.length === 0 ? DEFAULT_CHARGING_WINDOW : SECONDARY_DEFAULT_CHARGING_WINDOW,
      ],
    });

  const handleRemoveChargingWindow = (idx) => {
    onChangeVehicleSet({
      chargingWindows: vehicleSet.chargingWindows.filter((_, i) => i !== idx),
    });
  };

  const depletedBattery = VehicleSet.minimumStateOfCharge(vehicleSet) < 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {chargingWindows &&
          chargingWindows.map((chargingWindow, idx) => (
            <ChargingWindow
              key={idx}
              onChange={(data) => handleChange(idx, data)}
              onRemove={() => handleRemoveChargingWindow(idx)}
              canDelete={chargingWindows.length > 1}
              {...chargingWindow}
            />
          ))}
      </Grid>
      <Grid item xs={12}>
        {chargingWindows && chargingWindows.length < MAX_CHARGING_WINDOWS && (
          <ButtonInput variant="link" onClick={handleAddChargingWindow}>
            + {formatMessage({ id: 'editVehicleSets.times.add' })}
          </ButtonInput>
        )}
      </Grid>
      {depletedBattery && (
        <Grid item xs={10}>
          <InfoCard
            message={formatMessage(
              { id: 'editVehicleSets.times.windowError' },
              {
                warning: <b>{formatMessage({ id: 'editVehicleSets.times.warning' })}</b>,
              },
            )}
            variant="error"
          />
        </Grid>
      )}
    </Grid>
  );
}
