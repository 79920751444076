import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import { makeStyles, useTheme } from '@material-ui/core';
import { GasIcon, PencilIcon } from '../../assets/icons/icons';
import { useIntl } from 'react-intl';
import { formatAsCents } from '../../utils/formatters';
import EditAssumptionsDialog from '../EditAssumptionsDialog/EditAssumptionsDialog';
import ButtonInput from '../../components/inputs/ButtonInput';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    fontSize: '16px',
  },
  label: {
    color: theme.palette.common.secondaryGrey,
    textAlign: 'left',
    lineHeight: '22px',
    marginTop: '39px',
  },
  divider: {
    height: '1px',
    backgroundColor: theme.palette.common.grey300,
    maxWidth: '960px',
    marginLeft: '20px',
    marginTop: '43px',
    marginBottom: '38px',
  },
  bolt: {
    marginLeft: '40px',
  },
}));

export default function AssumptionCard() {
  const { gasolinePrice, dieselPrice } = useInputs();
  const classes = useStyles();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [openEdit, setOpenEdit] = useState(false);

  return (
    <Grid
      sx={{
        border: `1px solid ${theme.palette.common.borderGrey}`,
        borderRadius: '4px',
      }}
    >
      <Box pr="40px" pl="43px" pb="50px" className={`${classes.flexCol}`}>
        <Box id="header" pt="38px" justifyContent="end" className={classes.flexRow}>
          <ButtonInput onClick={() => setOpenEdit(true)} variant="link">
            <PencilIcon />
            {formatMessage({ id: 'panels.assessment.editAssumption' })}
          </ButtonInput>
        </Box>
        <Box className={classes.flexRow}>
          <Box width="103px" height="99px">
            <GasIcon />
          </Box>
          <Box ml="28px" className={classes.flexCol}>
            <span className={classes.label}>
              {formatMessage({ id: 'panels.assessment.gasPrice' }).toUpperCase()}
            </span>
            <Box fontWeight="600">{formatAsCents(gasolinePrice)}</Box>
          </Box>
          <Box ml="156px" className={classes.flexCol}>
            <span className={classes.label}>
              {formatMessage({ id: 'panels.assessment.dieselPrice' }).toUpperCase()}
            </span>
            <Box fontWeight="600">{formatAsCents(dieselPrice)}</Box>
          </Box>
        </Box>
      </Box>
      <EditAssumptionsDialog isOpen={openEdit} onClose={() => setOpenEdit(false)} />
    </Grid>
  );
}
