import React from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import { useIntl } from 'react-intl';
import { Box, Link } from '@mui/material';
import InfoCard from '../InfoCard/InfoCard';
import { EquipmentLoadChart } from './ConnectCharts';
import Site from '../../calculations/Site';
import Section from '../../components/Section/Section';

export default function ConnectIndividualSite() {
  const { formatMessage } = useIntl();

  const { selectedSite, startYear } = useInputs();

  // Use final year, because all vehicles will be electrified by then
  const exceedsLimit = Site.maxDemand(selectedSite, startYear + 9) > 200;

  const warningMessage = formatMessage(
    { id: 'panels.connect.warningLimit' },
    {
      siteExceeds: <b>{formatMessage({ id: 'panels.connect.siteExceeds' })}</b>,
      installer: (
        <Link
          href="https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/business/electric/electric-vehicles/pev-biz-installers"
          underline="always"
          color="secondary"
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({ id: 'panels.charge.installer' })}
        </Link>
      ),
      advisory: (
        <Link
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=_tVhjkl3dk6I7m2Hma6BQ72yt26BtDJJnBKKSVUFMepUQzlVRDdGTDdUTDI2UzczRzY1UjAxQjZNTyQlQCN0PWcu"
          underline="always"
          color="secondary"
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({ id: 'panels.charge.advisory' })}
        </Link>
      ),
    },
  );

  return (
    <>
      <Box pt="43px">
        {exceedsLimit && <InfoCard variant={'warning'} message={warningMessage} />}
      </Box>
      <Section
        title={formatMessage({ id: 'panels.connect.chargingLoads' })}
        description={formatMessage({ id: 'panels.connect.chargeLoadSummary' })}
      >
        <EquipmentLoadChart />
      </Section>
    </>
  );
}
