import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';

export default function TimelineChart({ selectedSite }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const labels = [
    formatMessage({ id: 'panels.implement.design' }),
    formatMessage({ id: 'panels.implement.permitting' }),
    formatMessage({ id: 'panels.implement.procurement' }),
    formatMessage({ id: 'panels.implement.utility' }),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.implement.design' }),
        data: [12, null, null, null],
        backgroundColor: theme.palette.common.brandBlue,
        barThickness: 47,
      },
      {
        label: formatMessage({ id: 'panels.implement.design' }),
        data: [null, 12, null, null],
        backgroundColor: 'transparent',
        barThickness: 47,
        datalabels: {
          display: false,
        },
      },
      {
        label: formatMessage({ id: 'panels.implement.design' }),
        data: [null, 6, null, null],
        backgroundColor: theme.palette.common.brandBlue,
        barThickness: 47,
      },
      {
        label: formatMessage({ id: 'panels.implement.procurement' }),
        data: [null, null, 18, null],
        backgroundColor: 'transparent',
        barThickness: 47,
        datalabels: {
          display: false,
        },
      },
      {
        label: formatMessage({ id: 'panels.implement.procurement' }),
        data: [null, null, 8, null],
        backgroundColor: theme.palette.common.brandBlue,
        barThickness: 47,
      },
      {
        label: formatMessage({ id: 'panels.implement.utility' }),
        data: [null, null, null, 12],
        backgroundColor: 'transparent',
        barThickness: 47,
        datalabels: {
          display: false,
        },
      },
      {
        label: formatMessage({ id: 'panels.implement.utility' }),
        data: [null, null, null, 12],
        backgroundColor: '#6F5091',
        barThickness: 47,
      },
    ],
  };

  //TODO: set this dynamically when the data values are set.
  const myLabels = ['6 - 12 weeks', '4 - 6 weeks', '6 - 12 weeks', '12 - 16 weeks'];

  const options = {
    responsive: true,
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== null;
        },
        labels: {
          title: {
            align: 'top',
            offset: 20,
            color: '#2B2B2B',
            font: {
              size: 16,
              weight: 600,
            },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
          timeline: {
            color: 'white',
            font: {
              size: 16,
              weight: 400,
            },
            formatter: function (value, context) {
              return myLabels[context.dataIndex];
            },
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
        stacked: true,
      },
      y: {
        fontSize: '18px',
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
        ticks: {
          display: false,
        },
        stacked: true,
      },
    },
  };

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.common.borderGrey}`,
        borderRadius: '4px',
        height: '430px',
        padding: '40px 55px 0 55px',
        borderLeft: (theme) => `8px solid ${theme.palette.common.brandBlue}`,
      }}
    >
      <Box
        sx={{
          height: 'calc(100% - 65px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
        }}
      >
        <Box sx={{ textAlign: 'left', fontWeight: '600', fontSize: '18px', mb: '12px' }}>
          {formatMessage({ id: 'panels.implement.milestones' })}
        </Box>
        <Bar options={options} data={data}></Bar>
      </Box>
    </Box>
  );
}
