import React from 'react';
import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { PlusIcon } from '../../assets/icons/icons';
import { useInputs } from '@bellawatt/use-inputs';
import cloneDeep from 'lodash/cloneDeep';
import { DEFAULT_VEHICLE_SET } from '../../use-inputs/getDefaults';
import ButtonInput from '../../components/inputs/ButtonInput';
import { omitComputedData } from '../../utils/computedData';

export default function AddNewVehicleButton({ siteId }) {
  const { sites, setInput } = useInputs();
  const { formatMessage } = useIntl();

  const addDefaultSet = () => {
    const siteIndex = sites.findIndex((s) => s.id === siteId);
    const newSites = cloneDeep(sites);
    newSites[siteIndex].vehicleSets.push({
      ...DEFAULT_VEHICLE_SET,
      name: `Vehicle Set #${newSites[siteIndex].vehicleSets.length + 1}`,
    });
    setInput({ sites: omitComputedData(newSites) });
  };

  return (
    <Box mt="10px" display="flex" justifyContent="flex-end">
      <ButtonInput variant="link" onClick={addDefaultSet}>
        <PlusIcon />
        {formatMessage({ id: 'panels.assessment.addNewSet' })}
      </ButtonInput>
    </Box>
  );
}
