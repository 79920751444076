import React, { useState } from 'react';
import PanelIntro from '../PanelIntro/PanelIntro';
import { useIntl } from 'react-intl';
import { Box, Link } from '@mui/material';
import Dropdown from '../../components/inputs/Dropdown';
import InfoCard from '../InfoCard/InfoCard';
import { useInputs } from '@bellawatt/use-inputs';
import { DailyUseChart, BatteryChargeChart, ChargingTable } from './ChargeCharts';
import ChargerList from './ChargerList';
import Totals from '../../calculations/Totals';
import sum from 'lodash/sum';
import { formatAsDollars } from '../../utils/formatters';
import Section from '../../components/Section/Section';
import ChargerDialog from '../../components/ChargerDialog/ChargerDialog';
import cloneDeep from 'lodash/cloneDeep';
import { getChargerRebates, getMaxRebate } from '../../calculations/Rebate';
import { omitComputedData } from '../../utils/computedData';
import ButtonInput from '../../components/inputs/ButtonInput';

export default function Charge() {
  const { formatMessage } = useIntl();
  const { sites, selectedSite, isAllSites, setInput } = useInputs();
  const [selectedDropSite, setSelectedDropSite] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(0);
  const [selectedCharger, setSelectedCharger] = useState();
  const [openSelectCharger, setOpenSelectCharger] = useState(false);

  const [siteInd, setSiteInd] = useState(0);
  const [vehicleSetInd, setVehicleSetInd] = useState(0);
  const [chargingWindowInd, setChargingWindowInx] = useState(0);

  const handleOpenChargerDialog = (charger) => {
    setSiteInd(charger.siteInd);
    setVehicleSetInd(charger.vehicleSetInd);
    setSelectedCharger(charger);
    setChargingWindowInx(charger.chargingWindowInd);
    setOpenSelectCharger(true);
  };

  const handleChangeCharger = (charger) => {
    setOpenSelectCharger(false);
    const newSites = cloneDeep(sites);
    const vehicleSet = cloneDeep(newSites[siteInd].vehicleSets[vehicleSetInd]);
    const newSets = cloneDeep(newSites[siteInd].vehicleSets);
    let newChargingWindow = cloneDeep(vehicleSet.chargingWindows[chargingWindowInd]);
    newChargingWindow = {
      ...newChargingWindow,
      id: charger.id,
      isGeneric: false,
    };

    newSets[vehicleSetInd].chargingWindows[chargingWindowInd] = newChargingWindow;
    newSites[siteInd].vehicleSets = newSets;

    setInput({ sites: omitComputedData(newSites) });
  };

  /** @type Array<Site> */
  const workingSites = isAllSites ? sites : [selectedSite];

  const groupedChargers = Totals.getAllGroupedChargers(workingSites).flat();

  // const chargerRebates = getChargerRebates(workingSites);

  const chargerRebatesPerSite = workingSites.map((site) => getChargerRebates([site]));

  // const { totalChargerRebate } = getMaxRebate(chargerRebates);

  const totalChargerRebate = sum(
    chargerRebatesPerSite.map((rebate) => getMaxRebate(rebate).totalChargerRebate),
  );

  const totalChargerMsrp = sum(
    groupedChargers.map(
      ({ minimumRequired, charger }) => charger && minimumRequired * charger.msrp,
    ),
  );

  const rebatesMessage = formatMessage(
    { id: 'panels.charge.rebatesWarning' },
    {
      installer: (
        <Link
          href="https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/business/electric/electric-vehicles/pev-biz-installers"
          underline="always"
          color="secondary"
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({ id: 'panels.charge.installer' })}
        </Link>
      ),
      advisory: (
        <Link
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=_tVhjkl3dk6I7m2Hma6BQ72yt26BtDJJnBKKSVUFMepUQzlVRDdGTDdUTDI2UzczRzY1UjAxQjZNTyQlQCN0PWcu"
          underline="always"
          color="secondary"
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({ id: 'panels.charge.advisory' })}
        </Link>
      ),
    },
  );

  return (
    <Box>
      <PanelIntro title="panels.charge.title" summary="panels.charge.summary" />
      <Box textAlign="right" mt={1}>
        <ButtonInput variant="link" href="/charger-catalog" newTab>
          {formatMessage({ id: 'chargerCatalogLink' })}
        </ButtonInput>
      </Box>
      <Section
        title={formatMessage({ id: 'panels.charge.chargingTitle' })}
        description={formatMessage({ id: 'panels.charge.chargingSummary' })}
      >
        <ChargerList chargingWindows={groupedChargers} onPickCharger={handleOpenChargerDialog} />
      </Section>
      <ChargerDialog
        isOpen={openSelectCharger}
        onClose={() => setOpenSelectCharger(false)}
        onSave={handleChangeCharger}
        currentCharger={selectedCharger}
      />
      <Box
        sx={{
          border: `1px solid #485465`,
          boxSizing: 'border-box',
          borderRadius: '4px',
          p: '21px',
          maxWidth: '100%',
          mb: '38px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          <Box>{formatMessage({ id: 'panels.charge.totalEstimatedCharger' })}</Box>
          <Box>{formatAsDollars(totalChargerMsrp)}</Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>
          <Box>{formatMessage({ id: 'panels.charge.totalEstimatedRebates' })}</Box>
          <Box>{formatAsDollars(totalChargerRebate)}</Box>
        </Box>
      </Box>
      <InfoCard variant="warning" message={rebatesMessage} />

      {isAllSites && (
        <Section title={formatMessage({ id: 'panels.charge.chargingAssignments' })}>
          <ChargingTable />
        </Section>
      )}

      {!isAllSites && (
        <Box>
          <Box fontWeight="bold" textAlign="left" fontSize="18px" mt="32px">
            {formatMessage({ id: 'panels.charge.chargeAnalysis' })}
          </Box>
          <Box textAlign="left" width="440px" mt="32px">
            <Dropdown
              onChange={(ev) => setSelectedVehicle(ev.target.value)}
              options={selectedSite.vehicleSets.map((set, idx) => ({
                value: idx,
                children: set.name,
              }))}
              value={selectedVehicle}
              label={formatMessage({ id: 'panels.charge.selectVehicle' }).toUpperCase()}
            />
          </Box>
          <Section
            title={formatMessage({ id: 'panels.charge.typicalWorkday' })}
            description={formatMessage({ id: 'panels.charge.typicalSummary' })}
          >
            <BatteryChargeChart selectedVehicle={selectedVehicle} />
          </Section>
        </Box>
      )}

      {isAllSites && (
        <Box textAlign="left" width="440px" mt="32px">
          <Dropdown
            onChange={(ev) => setSelectedDropSite(ev.target.value)}
            options={sites.map(({ id, name }) => ({
              value: id,
              children: name,
            }))}
            value={selectedDropSite}
            label={formatMessage({ id: 'panels.charge.selectSite' }).toUpperCase()}
          />
        </Box>
      )}
      <Section
        title={formatMessage({ id: 'panels.charge.dailyUsage' })}
        description={formatMessage({ id: 'panels.charge.dailySummary' })}
      >
        <DailyUseChart
          selectedSite={isAllSites ? sites.find(({ id }) => id === selectedDropSite) : selectedSite}
        />
      </Section>
    </Box>
  );
}
