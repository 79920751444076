import React from 'react';
import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/inputs/ButtonInput';
import { useInputs } from '@bellawatt/use-inputs';
import { steps } from '../../data/onboardingSteps';
import { useHistory } from 'react-router-dom';

export default function OnboardingButtonRow({ handleSave }) {
  const { formatMessage } = useIntl();
  const { onboardingStep, setInput } = useInputs();
  const history = useHistory();

  const handleGoForward = () => {
    const current = steps.findIndex((step) => step === onboardingStep);
    if (current !== steps.length - 1) {
      setInput({ onboardingStep: steps[current + 1] });
    } else {
      //save global site/vehicle/charging and go to outputs
      handleSave();
    }
  };

  const handleGoBack = () => {
    const current = steps.findIndex((step) => step === onboardingStep);
    if (current !== 0) {
      setInput({ onboardingStep: steps[current - 1] });
    } else {
      //go back to the homepage
      history.replace('/');
    }
  };

  return (
    <Box
      mt="130px"
      sx={{ borderTop: '1px solid #E4E4E4' }}
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      height="115px"
    >
      <ButtonInput variant="outlined" onClick={handleGoBack}>
        {formatMessage({ id: 'onboardingSteps.back' })}
      </ButtonInput>
      <ButtonInput variant="contained" onClick={handleGoForward}>
        {formatMessage({ id: `onboardingSteps.${onboardingStep}.next` })}
      </ButtonInput>
    </Box>
  );
}
