import React, { useEffect } from 'react';
import './App.css';
import ContentPane from '../ContentPane/ContentPane';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Loading from '../components/Loading/Loading';
import OnboardingLayout from '../components/Onboarding/OnboardingLayout';
import { useInputs } from '@bellawatt/use-inputs';
import { Switch, Route, useLocation } from 'react-router-dom';
import Homepage from '../components/Homepage/Homepage';
import ContentPrint from '../ContentPrint/ContentPrint';
import DisplayProvider from '../DisplayProvider/DisplayProvider';
import ErrorBoundary from '../components/errorBoundary/ErrorBoundary';
import VehiclePage from '../VehiclePage/VehiclePage';
import ChargerPage from '../ChargerPage/ChargerPage';
import VehicleDescriptionsPage from '../VehicleDescriptionsPage/VehicleDescriptionsPage';
import { useAnalytics } from '../utils/useAnalytics';

function App() {
  const { loading: isInitialLoad, isResetting, isOnboarded } = useInputs();
  const location = useLocation();
  const { GaTracker } = useAnalytics();

  useEffect(() => {
    GaTracker.pageview(location);
  }, [location]);

  return (
    <div className="App">
      <ErrorBoundary>
        <DisplayProvider>
          {isInitialLoad || isResetting ? (
            <Loading />
          ) : (
            <Switch>
              <Route path="/print" component={ContentPrint} />
              <Route path="/">
                <Header />
                <Route exact path="/" component={Homepage} />
                <Route path="/fleets" component={isOnboarded ? ContentPane : OnboardingLayout} />
                <Route path="/vehicle-catalog" component={VehiclePage} />
                <Route path="/charger-catalog" component={ChargerPage} />
                <Route path="/vehicle-descriptions" component={VehicleDescriptionsPage} />
                <Footer />
              </Route>
            </Switch>
          )}
        </DisplayProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
