import React from 'react';
import { Box } from '@mui/material';
import { makeStyles, useTheme } from '@material-ui/core';
import { Line } from 'react-chartjs-2';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '360px',
    padding: '10px',
    width: '100%',
  },
}));

export default function StyledLineGraph(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    yTitle,
    data,
    graphTitle,
    showLegend,
    showHorizontalGrid,
    showVerticalGrid,
    xTicks = {},
    yTicks = {},
    plugins,
  } = props;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
      },
      legend: {
        onClick: null,
        display: showLegend,
        align: 'end',
        labels: {
          font: {
            size: 16,
          },
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.yLabel;
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: showVerticalGrid ?? false,
          drawBorder: true,
          drawTicks: true,
        },
        ticks: {
          color: theme.palette.common.secondaryGrey,
          padding: 10,
          ...xTicks,
          font: {
            size: 16,
            ...xTicks.font,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: showHorizontalGrid ?? false,
          drawBorder: false,
          drawTicks: true,
        },
        title: {
          display: true,
          text: yTitle,
          color: theme.palette.common.secondaryGrey,
          font: {
            size: 16,
          },
        },
        ticks: {
          color: theme.palette.common.secondaryGrey,
          padding: 20,
          ...yTicks,
          font: {
            size: 16,
            ...yTicks.font,
          },
        },
      },
    },
  };

  return (
    <Box className={classes.container}>
      <Box textAlign="center" sx={{ height: '340px' }}>
        <Box>{graphTitle}</Box>
        <Line aria-label={graphTitle || 'graph'} options={options} plugins={plugins} data={data} />
      </Box>
    </Box>
  );
}
