import React from 'react';
import { Grid, Box } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import SiteInputs from './SiteInputs';
import VehicleInfoInputs from './VehicleInfoInputs';
import VehicleUsageInputs from './VehicleUsageInputs';
import RecommendedVehicle from './RecommendedVehicle/RecommendedVehicle';
import OnboardingButtonRow from './OnboardingButtonRow';
import VEHICLES, { findArchetypeById } from '../../data/VEHICLES';
import { omitComputedData } from '../../utils/computedData';
import { transformArchetypeToFossilVehicle } from '../../utils/conversions';

export default function OnboardingContent() {
  const { onboardingStep, setInput, sites } = useInputs();

  /** @type Site */
  const workingSite = sites[0];
  const workingVehicleSet = workingSite.vehicleSets[0];

  const setSite = (site) => {
    setInput({ sites: omitComputedData([site]) });
  };

  const setVehicleSet = (newVehicleSet) => {
    setSite({ ...workingSite, vehicleSets: [{ ...newVehicleSet }] });
  };

  const handleSiteChange = (value, key) => {
    setSite({ ...workingSite, [key]: value });
  };

  const handleChangeReplacementVehicle = (newData) => {
    setVehicleSet({
      ...workingVehicleSet,
      vehicle_id: newData.id,
      vehicle: {
        ...(workingVehicleSet.vehicle || {}),
        ...newData,
      },
    });
  };

  const handleChangeVehicleSet = (newData) => {
    if (newData.fossilVehicleId !== undefined) {
      const fossilVehicle =
        VEHICLES.find((v) => v.id === newData['vehicle_id']) ||
        transformArchetypeToFossilVehicle(findArchetypeById(newData.fossilVehicleId));
      setVehicleSet({
        ...workingVehicleSet,
        fossilVehicle: fossilVehicle,
        fossilVehicleId: fossilVehicle.id,
      });
    } else {
      setVehicleSet({
        ...workingVehicleSet,
        ...newData,
      });
    }
  };

  const handleSave = () => {
    const firstVehicleSet = {
      ...workingVehicleSet,
    };
    const firstSite = {
      ...workingSite,
      vehicleSets: [firstVehicleSet],
    };
    const newSites = sites;
    newSites[0] = firstSite;
    setInput({ sites: omitComputedData(newSites) });
    setInput({ isOnboarded: true });
  };

  const steps = [
    {
      step: 'site',
      content: <SiteInputs site={workingSite} handleSiteChange={handleSiteChange} />,
    },
    {
      step: 'vehicle',
      content: (
        <Box maxWidth="791px" m="0 auto">
          <VehicleInfoInputs
            vehicleSet={workingVehicleSet}
            onChangeVehicleSet={handleChangeVehicleSet}
          />
        </Box>
      ),
    },
    {
      step: 'usage',
      content: (
        <VehicleUsageInputs
          vehicleSet={workingVehicleSet}
          onChangeVehicleSet={handleChangeVehicleSet}
        />
      ),
    },
    {
      step: 'recommend',
      content: (
        <RecommendedVehicle
          vehicleSet={workingVehicleSet}
          site={workingSite}
          handleReplaceVehicle={handleChangeReplacementVehicle}
          onChangeVehicleSet={handleChangeVehicleSet}
        />
      ),
    },
  ];

  const stepIndex = steps.findIndex((s) => s.step === onboardingStep);

  return (
    <Grid container mt="40px">
      <Grid item xs={12}>
        {steps[stepIndex].content}
      </Grid>
      <Grid item xs={12}>
        <OnboardingButtonRow handleSave={handleSave} />
      </Grid>
    </Grid>
  );
}
