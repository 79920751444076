import React from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import getDefaultsWithApi from '../../use-inputs/getDefaults';
import { useIntl } from 'react-intl';
import ButtonInput from '../inputs/ButtonInput';

export default function ResetInputs() {
  const { formatMessage } = useIntl();
  const { setInput } = useInputs();

  const handleResetInputs = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(formatMessage({ id: 'panels.assessment.confirmResetInputs' }))) {
      setInput({ isResetting: 'true' });
      getDefaultsWithApi().then((data) =>
        setInput({ ...data, isOnboarded: false, isResetting: false }),
      );
    }
  };
  return (
    <ButtonInput variant="outlined" onClick={handleResetInputs}>
      {formatMessage({ id: 'panels.assessment.resetInputs' })}
    </ButtonInput>
  );
}
