import React from 'react';
import { ToolTipIcon } from '../../assets/icons/icons';
import { Box, Tooltip as MuiTooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popper: {},
  tooltip: {
    padding: '16px',
    border: `2px solid ${theme.palette.common.accentBlue}`,
    borderRadius: '5px',
    backgroundColor: 'white',
    color: theme.palette.common.DTEPrimary,
    fontSize: '16px',
  },
  arrow: {
    '&::before': {
      border: `2px solid ${theme.palette.common.accentBlue}`,
      backgroundColor: 'white',
    },
  },
}));

export default function Tooltip({ content, children, withIcon }) {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={content}
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      placement="right"
      arrow
    >
      <Box
        sx={{
          display: 'inline-flex',
          gap: '4px',
          alignItems: 'center',
          marginLeft: '8px',
          cursor: 'pointer',
        }}
      >
        {children}
        {(withIcon || !children?.length) && <ToolTipIcon />}
      </Box>
    </MuiTooltip>
  );
}
