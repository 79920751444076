import { useState, useCallback, useMemo } from 'react';
import { useInputs } from '@bellawatt/use-inputs';

const sortConfig = {
  rangeDesc: {
    field: 'range',
    method: 'desc',
  },
  rangeAsc: {
    field: 'range',
    method: 'asc',
  },
};

const useVehiclesByType = (isFossilVehicle) => {
  const inputs = useInputs();
  const apiVehicles = isFossilVehicle ? inputs.apiFossilVehicles : inputs.apiElectricVehicles;

  return apiVehicles
    .sort((a, b) => b.range - a.range)
    .reduce(
      (acc, vehicle) => {
        return {
          ...acc,
          all: {
            ...acc.all,
            all: [...(acc.all.all || []), { ...vehicle }],
            [vehicle.subtype]: [...(acc[vehicle.type]?.[vehicle.subtype] || []), { ...vehicle }],
          },
          [vehicle.type]: {
            ...(acc[vehicle.type] || []),
            all: [...(acc[vehicle.type]?.all || []), { ...vehicle }],
            [vehicle.subtype]: [...(acc[vehicle.type]?.[vehicle.subtype] || []), { ...vehicle }],
          },
        };
      },
      { all: apiVehicles },
    );
};

const SHOW_COUNT = 24;

const useFilteredVehicles = (type, isFossilVehicle = false) => {
  const vehiclesByType = useVehiclesByType(isFossilVehicle);
  const [showCount, setShowCount] = useState(SHOW_COUNT);

  const showMore = () => setShowCount(showCount + SHOW_COUNT);
  const resetShowCount = () => setShowCount(SHOW_COUNT);

  const [filters, setFilters] = useState({
    availableNowOnly: false,
    type: vehiclesByType[type] ? type : 'all',
    subtype: 'all',
    msrp: 0,
    ...(isFossilVehicle
      ? {}
      : {
          hybrid: false,
          allElectric: true,
        }),
  });
  const resetFilters = () => {
    setFilters({
      availableNowOnly: false,
      type: vehiclesByType[type] ? type : 'all',
      subtype: 'all',
      msrp: 0,
      ...(isFossilVehicle
        ? {}
        : {
            hybrid: false,
            allElectric: true,
          }),
    });
  };

  const [sortOrder, _setSortOrder] = useState('rangeDesc');
  const setSortOrder = (value) => {
    resetShowCount();
    _setSortOrder(value);
  };

  const updateFilter = useCallback(
    (name, value) => {
      resetShowCount();
      if (
        (name === 'type' && !vehiclesByType[value]) ||
        (name === 'subtype' && !vehiclesByType[filters.type][value])
      ) {
        setFilters({ ...filters, [name]: 'all' });
      } else if (name === 'allElectric' && value === true && filters.hybrid) {
        setFilters({
          ...filters,
          allElectric: true,
          hybrid: false,
        });
      } else if (name === 'hybrid' && value === true && filters.allElectric) {
        setFilters({
          ...filters,
          allElectric: false,
          hybrid: true,
        });
      } else if (name === 'type') {
        setFilters({
          ...filters,
          type: value,
          subtype: 'all',
        });
      } else {
        setFilters({ ...filters, [name]: value });
      }
    },
    [filters, vehiclesByType],
  );

  const fuelTypes = useMemo(() => {
    if (isFossilVehicle) {
      return ['gasoline', 'diesel'];
    }
    const types = [];
    if (!filters.hybrid || filters.allElectric) {
      types.push('BEV');
    }
    if (filters.hybrid || !filters.allElectric) {
      types.push('PHEV');
    }
    return types;
  }, [filters.allElectric, filters.hybrid, isFossilVehicle]);

  const filteredVehicles = useMemo(
    () =>
      vehiclesByType[filters.type][filters.subtype].filter(
        ({ msrp, availableNow, fuel }) =>
          (!filters.availableNowOnly || availableNow === true) &&
          (filters.msrp === 0 || filters.msrp > msrp) &&
          fuelTypes.includes(fuel),
      ),
    [
      filters.availableNowOnly,
      filters.msrp,
      filters.subtype,
      filters.type,
      fuelTypes,
      vehiclesByType,
    ],
  );

  // Vehicles are sorted descending by range at the start of useVehiclesByType
  // We can skip sorting again as long as 'range' is the only way to sort
  /** @type Array<Vehicle> */
  const vehicles =
    sortConfig[sortOrder].method === 'asc' ? filteredVehicles.reverse() : filteredVehicles;

  const types = useMemo(() => Object.keys(vehiclesByType), [vehiclesByType]);
  const subtypes = useMemo(
    () => Object.keys(vehiclesByType['Passenger']),
    ['Passenger', vehiclesByType],
  );

  return {
    vehicles,
    sortOrder,
    sortConfig,
    setSortOrder,
    filters,
    updateFilter,
    resetFilters,
    types,
    subtypes,
    showCount,
    showMore,
  };
};

export default useFilteredVehicles;
