import React from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import ConnectAllSites from './ConnectAllSites';
import ConnectIndividualSite from './ConnectIndividualSite';
import PanelIntro from '../PanelIntro/PanelIntro';
import { Box } from '@mui/material';

export default function Connect() {
  const { isAllSites } = useInputs();

  return (
    <Box>
      <PanelIntro title="panels.connect.title" summary="panels.connect.summary" />
      {isAllSites ? <ConnectAllSites /> : <ConnectIndividualSite />}
    </Box>
  );
}
