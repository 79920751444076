import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  DolarIconNew,
  GasIcon,
  CloudIcon,
  BatteryIcon,
  ChargerIcon,
  ElectricFuelIcon,
} from '../../assets/icons/icons';
import PanelIntro from '../PanelIntro/PanelIntro';
import TakeAwayCard from '../TakeAwayCard/TakeAwayCard';
import { useInputs } from '@bellawatt/use-inputs';
import Totals from '../../calculations/Totals';
import { formatAsDollars, formatAsThousands } from '../../utils/formatters';
import { ChargerTimeline, VehicleTimeline } from './OverviewCharts';
import VehicleSets from '../../calculations/VehicleSets';
import sum from 'lodash/sum';
import times from 'lodash/times';
import min from 'lodash/min';
import Site from '../../calculations/Site';

const getYearlyReplacedVehicleCounts = (sites, years) => {
  const allVehicleSets = sites.flatMap(({ vehicleSets }) => vehicleSets);
  return years.map((year) => {
    // sum vehicle counts from sets whose replacement year matches the year iterator
    const replaceSets = allVehicleSets.filter(({ replacementYear }) => replacementYear === year);
    return sum(replaceSets.map(({ vehicleCount }) => parseInt(vehicleCount) || 0));
  });
};

const getYearlyReplacedChargerCounts = (sites, years) => {
  return years.map((year) => {
    const siteChargerCounts = sites.map((site) => {
      // for each site, get vehicle sets being replaced and group them by minimum number of chargers required
      const replaceVehicleSets = site.vehicleSets.filter(
        ({ replacementYear }) => replacementYear === year,
      );
      const groupedChargers = VehicleSets.groupedChargers(replaceVehicleSets);
      return sum(groupedChargers.map(({ minimumRequired }) => minimumRequired));
    });
    // for each year, sum the charger counts of every site
    return sum(siteChargerCounts);
  });
};

const getTotalChargerRebates = (sites) => {
  // grouped chargers for every site, flattened into 1 array
  const chargers = sites.map(({ vehicleSets }) => VehicleSets.groupedChargers(vehicleSets)).flat();

  // sum of rebate * num chargers for every charger type
  return sum(
    chargers.map(({ minimumRequired, charger: { rebate } }) => minimumRequired * (rebate || 0)),
  );
};

const styles = {
  futurecard: (theme) => ({
    border: `1px solid ${theme.palette.common.borderGrey}`,
    height: '99px',
    flex: '1 1',
    minWidth: '200px',
    borderRadius: '4px',
    paddingLeft: '15px',
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  }),
  cardContainer: (theme) => ({
    height: '350px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '30px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      height: 'auto',
      gap: '16px',
      margin: '16px 0',
    },
  }),
  futureCardText: (theme) => ({
    width: '320px',
    fontSize: '18px',
    height: 'fit-content',
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  }),
};

export default function Overview() {
  const { formatMessage } = useIntl();

  const { startYear, selectedSite, sites, isAllSites, gasolinePrice, dieselPrice } = useInputs();

  const years = times(10, (i) => startYear + i);

  const emissionsSavingsInTons = Totals.emissionsSavings(sites, startYear);

  const totalFossilCosts = isAllSites
    ? Totals.totalCosts(
        sites,
        startYear,
        true,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      )
    : Site.totalCosts(
        selectedSite,
        startYear,
        true,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      );

  const totalElectrifiedCosts = isAllSites
    ? Totals.totalCosts(
        sites,
        startYear,
        false,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      )
    : Site.totalCosts(
        selectedSite,
        startYear,
        false,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      );

  // difference between retiring fleet and electrified fleet, can be negative if EVs are more expensive
  const lifetimeSavings =
    sum(Object.values(totalFossilCosts.byCategory)) -
    sum(Object.values(totalElectrifiedCosts.byCategory));
  // Use final year, because all vehicles will be electrified by then
  const maxDemand = Totals.maxDemand(sites, startYear + 9);

  const replacedVehicleCounts = getYearlyReplacedVehicleCounts(sites, years);

  const selectedSites = isAllSites ? sites : [selectedSite];

  const firstReplacementYear = isAllSites
    ? min(sites.flatMap((site) => site.vehicleSets).map(({ replacementYear }) => replacementYear))
    : min(selectedSite.vehicleSets.map(({ replacementYear }) => replacementYear));

  const yearRange = times(10, (i) => startYear + i);

  const tenYearData = yearRange.reduce(
    (acc, year) => ({
      ...acc,
      [year]:
        year < firstReplacementYear
          ? {}
          : Totals.getAnnualFleetFuelCosts(selectedSites, year, {
              dieselPrice,
              gasolinePrice,
            }),
    }),
    {},
  );

  let tenYearFuelCost = 0;
  let tenYearElectricCost = 0;

  for (const year of yearRange) {
    const { dieselCost, gasolineCost, bevElectricCost, phevElectricCost, phevGasCost } =
      tenYearData[year];

    tenYearFuelCost += dieselCost || 0;
    tenYearFuelCost += gasolineCost || 0;
    tenYearElectricCost += bevElectricCost || 0;
    tenYearElectricCost += phevElectricCost || 0;
    tenYearElectricCost += phevGasCost || 0;
  }

  const tenYearSavings = (tenYearFuelCost - tenYearElectricCost) / 10;

  const tcoString = lifetimeSavings >= 0 ? 'tcoSaved' : 'negativeSaved';
  const emissionsString = emissionsSavingsInTons >= 0 ? 'co2Saved' : 'co2Negative';
  const emissionsTotal =
    emissionsSavingsInTons >= 0
      ? `${formatAsThousands(Math.round(emissionsSavingsInTons))} ${formatMessage({
          id: 'tons',
        })}`
      : '--';

  const totalSumGallons = sites.reduce((totalAccumulator, currentSite) => {
    const siteSum = currentSite.vehicleSets.reduce((siteAccumulator, currentSet) => {
      const mathResult =
        (currentSet.vehicleCount * currentSet.milesPerWorkday * 365) /
        currentSet.fossilVehicle.milesPerGallon;
      return siteAccumulator + mathResult;
    }, 0);

    return totalAccumulator + siteSum;
  }, 0);

  const summaries = [
    {
      name: 'tcoSaved',
      icon: <DolarIconNew />,
      total: formatAsDollars(Math.floor(Math.abs(lifetimeSavings))),
      text: formatMessage({ id: `panels.overview.${tcoString}` }),
      iconWidth: '155px',
    },
    {
      name: 'fuelSaved',
      icon: <ElectricFuelIcon />,
      total: formatAsDollars(Math.floor(Math.abs(tenYearSavings))),
      text: formatMessage({ id: 'panels.overview.fuelSaved' }),
      iconWidth: '100px',
    },
    {
      name: 'co2Saved',
      icon: <CloudIcon />,
      total: emissionsTotal,
      text: `${formatMessage(
        { id: `panels.overview.${emissionsString}` },
        { numTrees: formatAsThousands(Math.round(emissionsSavingsInTons / 48)) },
      )}`,
      iconWidth: '124px',
    },
  ];

  return (
    <Box>
      <PanelIntro title="panels.overview.title" summary="panels.overview.summary" />
      <VehicleTimeline vehicleCounts={replacedVehicleCounts} />
      <Box fontSize="20px" pt="20px" fontWeight="bold" textAlign="center">
        {formatMessage(
          { id: 'panels.overview.takeAway' },
          { number: sum(replacedVehicleCounts), year: startYear + 9 },
        )}
      </Box>
      <Box sx={styles.cardContainer}>
        {summaries.map((item) => (
          <TakeAwayCard item={item} key={item.name} />
        ))}
      </Box>
      <ChargerTimeline chargerCounts={getYearlyReplacedChargerCounts(sites, years)} />
      <Box sx={styles.cardContainer}>
        <Box sx={styles.futurecard}>
          <Box height="fit-content" pt="10px">
            <BatteryIcon />
          </Box>
          <Box sx={styles.futureCardText}>
            <span>
              {formatMessage({ id: 'panels.overview.expected' })} <b>{Math.round(maxDemand)} kW </b>{' '}
              {formatMessage({ id: 'panels.overview.allYourSites' })}
            </span>
          </Box>
        </Box>
        <Box sx={styles.futurecard}>
          <Box height="fit-content" pt="10px">
            <ChargerIcon />
          </Box>
          <Box sx={styles.futureCardText}>
            <span>
              {formatMessage({ id: 'panels.overview.willReceive' })}
              <b>${formatAsThousands(getTotalChargerRebates(sites))} </b>
              {formatMessage({ id: 'panels.overview.inRebates' })}{' '}
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
