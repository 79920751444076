import React from 'react';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatAsDollars } from '../../utils/formatters';
import { BoltIcon, SmallGasIcon, StarIcon } from '../../assets/icons/icons';

export default function VehicleCard({ vehicle }) {
  const { formatMessage } = useIntl();

  const {
    imageUrl,
    make,
    model,
    range,
    electricRange,
    msrp,
    msrpIsEstimated,
    weightClass,
    efficiencyInMilesPerKwh,
    batteryCapacityInKwh,
  } = vehicle;

  let totalRangeShow = true;
  if (range == electricRange) {
    totalRangeShow = false;
  }

  const renderVehicleValue = () => {
    let value = msrp ? formatAsDollars(msrp) : '-';

    if (msrpIsEstimated) value = `${value} *`;

    return <Box fontSize="22px"> {value} </Box>;
  };

  return (
    <Box
      sx={{
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
        padding: '15px',
        overflow: 'hidden',
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item sx={{ fontSize: '22px', fontWeight: 'bold' }}>
          {make}
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            {vehicle.fuel === 'PHEV' && <SmallGasIcon />}
            <Box width="14px" sx={{ marginLeft: '5px' }}>
              <BoltIcon />
            </Box>
            {!vehicle.availableNow && <StarIcon />}
          </Grid>
        </Grid>
      </Grid>

      <Box textAlign="left">{model}</Box>

      <Box sx={{ textAlign: 'center', minHeight: '100px' }}>
        <img src={imageUrl} alt={make} style={{ maxWidth: '75%' }} />
      </Box>

      <Box>
        {totalRangeShow && (
          <SpecItem
            title={formatMessage({ id: 'replacementVehicle.totalRange' })}
            value={`${range} ${formatMessage({ id: 'replacementVehicle.miles' })}`}
          />
        )}
        <SpecItem
          title={formatMessage({ id: 'replacementVehicle.electricRange' })}
          value={`${electricRange} ${formatMessage({ id: 'replacementVehicle.miles' })}`}
        />
        <SpecItem
          title={formatMessage({ id: 'replacementVehicle.weightClass' })}
          value={weightClass}
        />
        <SpecItem
          title={formatMessage({ id: 'replacementVehicle.milesPerKwh' })}
          value={efficiencyInMilesPerKwh?.toFixed(2)}
        />
        <SpecItem
          title={formatMessage({ id: 'replacementVehicle.battery' })}
          value={`${batteryCapacityInKwh} ${formatMessage({ id: 'replacementVehicle.kwh' })}`}
        />
        <hr />
        <SpecItem
          title={
            msrpIsEstimated
              ? formatMessage({ id: 'replacementVehicle.estimatedMsrp' })
              : formatMessage({ id: 'replacementVehicle.msrp' })
          }
          value={renderVehicleValue()}
        />
      </Box>
    </Box>
  );
}

function SpecItem({ title, value }) {
  return (
    <Box display="flex" justifyContent="space-between" fontSize="14px">
      <Box>{title}</Box>
      <Box sx={{ fontWeight: 'bold' }}>{value}</Box>
    </Box>
  );
}
