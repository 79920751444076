export const insuranceRates = {
  bev_drive_system: {
    liabilityPerVehicle: 2800,
    comprehensivePerThousandDollars: 51.25,
  },
  box_truck: {
    liabilityPerVehicle: 2800,
    comprehensivePerThousandDollars: 51.25,
  },
  cargo_van: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  coupe: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  crossover: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  cutaway_chassis: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  hatchback: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  minivan: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  motor_coach: {
    liabilityPerVehicle: 3800,
    comprehensivePerThousandDollars: 35.75,
  },
  pickup_truck: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  refuse_truck: {
    liabilityPerVehicle: 7900,
    comprehensivePerThousandDollars: 24.5,
  },
  school_bus: {
    liabilityPerVehicle: 2900,
    comprehensivePerThousandDollars: 31.25,
  },
  sedan: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  shuttle_bus: {
    liabilityPerVehicle: 3800,
    comprehensivePerThousandDollars: 35.75,
  },
  specialty: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  'station wagon': {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  step_van: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  suv: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
  trailer_truck: {
    liabilityPerVehicle: 15000,
    comprehensivePerThousandDollars: 59.75,
  },
  transit_bus: {
    liabilityPerVehicle: 3800,
    comprehensivePerThousandDollars: 35.75,
  },
  utility_truck: {
    liabilityPerVehicle: 1834,
    comprehensivePerThousandDollars: 66.5,
  },
};
