import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import TextInput from '../../components/inputs/TextInput';

export default function SiteInfo({ site, handleChange }) {
  const { formatMessage } = useIntl();

  const { name, zipCode, address } = site;

  return (
    <Grid container direction="column" alignItems="center" pt="40px" rowSpacing={2}>
      <Grid item width="350px">
        <TextInput
          label={formatMessage({ id: 'editSites.name' }).toUpperCase()}
          labelTooltip={formatMessage({ id: 'editSites.siteNameTip' })}
          defaultValue={name}
          placeholder=""
          onChange={(value) => handleChange(value, 'name')}
          required
        />
      </Grid>
      <Grid item width="350px">
        <TextInput
          label={formatMessage({ id: 'panels.assessment.street' }).toUpperCase()}
          defaultValue={address}
          placeholder=""
          showTip={true}
          onChange={(value) => handleChange(value, 'address')}
        />
      </Grid>
      <Grid item width="350px">
        <TextInput
          mask="99999"
          label={formatMessage({ id: 'zipcode' }).toUpperCase()}
          labelTooltip={formatMessage({ id: 'editSites.zipCode' })}
          defaultValue={zipCode}
          placeholder=""
          onChange={(value) => handleChange(value, 'zipCode')}
        />
      </Grid>
    </Grid>
  );
}
