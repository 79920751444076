import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Banner from '../../assets/images/Banner.jpg';
import { useIntl } from 'react-intl';
import { TruckIcon, DollarIcon, WrenchIcon, GasIcon, CloudIcon } from '../../assets/icons/icons';
import ButtonInput from '../inputs/ButtonInput';

const Benefit = ({ icon, title, text }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
          alignItems: 'center',
          textAlign: 'center',
          margin: '0 16px',
        },
      }}
    >
      <Box height={100}>{icon}</Box>
      <Typography
        fontWeight="600"
        fontSize="20px"
        color={theme.palette.common.DTEPrimary}
        mb="1rem"
      >
        {title}
      </Typography>
      <Typography fontSize="1rem">{text}</Typography>
    </Box>
  );
};

const IconBox = ({ icon, message }) => {
  return (
    <Grid
      container
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          border: `1px solid ${theme.palette.common.borderGrey}`,
          borderRadius: '4px',
          padding: '16px',
        },
      })}
    >
      <Grid item xs={3}>
        <Box width="54px" height="52px" textAlign="right">
          {icon}
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box fontSize="16px" color="#505357" textAlign="left">
          {message}
        </Box>
      </Grid>
    </Grid>
  );
};

export default function Homepage() {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const styles = {
    hero: {
      height: '343px',
      backgroundImage: `url(${Banner})`,
      backgroundRepeat: 'no-repeat',
    },
  };

  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Grid container justifyContent="center" maxWidth={'1440px'} mb="200px">
        <Grid item xs={12} style={styles.hero}>
          <Grid container direction="row" justifyContent="center" spacing={4} mt={4}>
            <Grid item sm={5}>
              <Box fontSize="32px" color="white" fontWeight="600">
                {formatMessage({ id: 'homepage.willGuide' })}
              </Box>
            </Grid>
            {isLargeScreen && (
              <Grid item xs={12}>
                <Box>
                  <a style={{ textDecoration: 'none' }} href="/fleets">
                    <ButtonInput>{formatMessage({ id: 'homepage.getStarted' })}</ButtonInput>
                  </a>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container maxWidth="1076px" rowSpacing={10} columnSpacing={4} pt="50px">
          <Grid item xs={12}>
            <Box fontSize="32px" color="#252525">
              {formatMessage({ id: 'homepage.afterComplete' })}
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Benefit
              icon={<TruckIcon />}
              title={formatMessage({ id: 'homepage.vehicleRec' })}
              text={formatMessage({ id: 'homepage.getRec' })}
            />
          </Grid>
          <Grid item sm={4}>
            <Benefit
              icon={<DollarIcon />}
              title={formatMessage({ id: 'homepage.analysis' })}
              text={formatMessage({ id: 'homepage.seeSavings' })}
            />
          </Grid>
          <Grid item sm={4}>
            <Benefit
              icon={<WrenchIcon />}
              title={formatMessage({ id: 'homepage.costEstimate' })}
              text={formatMessage({ id: 'homepage.generateCost' })}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={12} fontSize="20px" color="#2B2B2B" fontWeight="600" textAlign="left">
                {formatMessage({ id: 'homepage.oneTruck' })}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="column"
                  flexWrap="wrap"
                  justifyContent="start"
                  mt="40px"
                  gap="32px"
                >
                  <Grid item sm={6}>
                    <IconBox
                      icon={<GasIcon />}
                      message={formatMessage({ id: 'homepage.avgSavings' })}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <IconBox
                      icon={<CloudIcon />}
                      message={formatMessage({ id: 'homepage.greenhouse' })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isLargeScreen && (
            <Grid item xs={12}>
              <Box mt={10}>
                <a style={{ textDecoration: 'none' }} href="/fleets">
                  <ButtonInput>{formatMessage({ id: 'homepage.getStarted' })}</ButtonInput>
                </a>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
