import { React, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import Dropdown from '../../inputs/Dropdown';
import { useInputs } from '@bellawatt/use-inputs';
import uniq from 'lodash/uniq';

export default function FilterSidebar({ handleChange, activeFilters, currentCharger }) {
  const { formatMessage } = useIntl();
  const { apiChargers } = useInputs();

  // filtering states
  const [chargerType, setChargerType] = useState('All');
  const [chargeRate, setChargeRate] = useState('All');

  let chargerTypes = [];
  let chargeRates = [];

  if (apiChargers.length > 0) {
    chargerTypes = uniq(apiChargers.map(({ chargerType }) => chargerType)).map((chargerType) => ({
      value: chargerType,
      children: chargerType,
    }));

    chargeRates = uniq(
      apiChargers
        .filter((v) => chargerType === 'All' || v.chargerType === chargerType)
        .map(({ chargeRate }) => chargeRate),
    )
      .map((chargeRate) => ({ value: chargeRate, children: chargeRate }))
      .sort((a, b) => a.value - b.value);
  }

  chargerTypes.splice(0, 0, { value: 'All', children: 'All' });
  chargeRates.splice(0, 0, { value: 'All', children: 'All' });

  const convertChargerRate = (rate) => {
    return parseFloat(rate.slice(0, -3));
  };

  useEffect(() => {
    if (currentCharger) {
      setChargerType(currentCharger.type);
      handleChange('chargerType', currentCharger.type);
      let rate = convertChargerRate(currentCharger.rate);
      setChargeRate(rate);
      handleChange('chargeRate', rate);
    }
  }, [currentCharger]);

  const newChargeRates = [
    {
      value: 'All',
      children: 'All',
    },
    {
      value: 7.2,
      children: '> 7.2',
    },
    {
      value: 7.7,
      children: '> 7.7',
    },
    {
      value: 9.6,
      children: '> 9.6',
    },
    {
      value: 11.5,
      children: '> 11.5',
    },
    {
      value: 12,
      children: '> 12',
    },
    {
      value: 15.4,
      children: '> 15.4',
    },
    {
      value: 16.8,
      children: '> 16.8',
    },
    {
      value: 25,
      children: '> 25',
    },
    {
      value: 50,
      children: '> 50',
    },
    {
      value: 60,
      children: '> 60',
    },
    {
      value: 62.5,
      children: '> 62.5',
    },
    {
      value: 75,
      children: '> 75',
    },
    {
      value: 90,
      children: '> 90',
    },
    {
      value: 100,
      children: '> 100',
    },
    {
      value: 120,
      children: '> 120',
    },
    {
      value: 125,
      children: '> 125',
    },
    {
      value: 150,
      children: '> 150',
    },
    {
      value: 175,
      children: '> 175',
    },
    {
      value: 200,
      children: '> 200',
    },
    {
      value: 350,
      children: '> 350',
    },
  ];

  const ChargerDetails = ({ charger }) => {
    const { formatMessage } = useIntl();

    return (
      <>
        <Box sx={{ fontSize: '20px' }} mb="10px">
          {formatMessage({ id: 'replacementCharger.title' })}
        </Box>
        <Box
          sx={{
            border: '1px solid #D9D9D9',
            borderRadius: '5px',
            padding: '10px',
          }}
        >
          <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>{`${charger.make}`}</Box>
          <Box sx={{ fontSize: '14px' }}>
            <Grid container justifyContent="space-between">
              <Grid item>{formatMessage({ id: 'replacementCharger.chargerModel' })}</Grid>
              <Grid item sx={{ fontWeight: 'bold' }}>
                {charger.model}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>{formatMessage({ id: 'replacementCharger.chargerType' })}</Grid>
              <Grid item sx={{ fontWeight: 'bold' }}>
                {charger.type}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box sx={{ paddingRight: '16px' }}>
      {currentCharger && (
        <Box mb="35px">
          <ChargerDetails charger={currentCharger} />
        </Box>
      )}
      <Box
        sx={{
          marginBottom: '35px',
          textTransform: 'uppercase',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        {formatMessage({ id: 'replacementCharger.filters' })}
      </Box>

      <Box sx={{ marginTop: '25px' }}>
        <Dropdown
          value={chargerType}
          label={formatMessage({ id: 'replacementCharger.chargerType' })}
          onChange={(e) => {
            setChargerType(e.target.value);
            handleChange('chargerType', e.target.value);
          }}
          options={chargerTypes}
          labelTooltip={formatMessage({ id: 'replacementCharger.chargerTypeDescription' })}
        />
      </Box>

      <Box sx={{ marginTop: '25px' }}>
        <Dropdown
          sx={{
            marginTop: '25px',
          }}
          value={chargeRate}
          label={formatMessage({ id: 'replacementCharger.chargeRate' })}
          onChange={(e) => {
            setChargeRate(e.target.value);
            handleChange('chargeRate', e.target.value);
          }}
          options={newChargeRates}
          labelTooltip={formatMessage({ id: 'replacementCharger.chargeRateDescription' })}
        />
      </Box>

      <Box sx={{ marginTop: '12px', textAlign: 'start' }}>
        {formatMessage({ id: 'replacementCharger.msrpMessage' })}
      </Box>
    </Box>
  );
}
