import React, { useMemo } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import FilterSidebar from './FilterSidebar';
import VehicleList from './VehicleList';
import Dropdown from '../../inputs/Dropdown';
import { SmallGasIcon, BoltIcon, StarIcon } from '../../../assets/icons/icons';
import { titleCase } from '../../../utils/formatters';
import useFilteredVehicles from './useFilteredVehicles';
import ButtonInput from '../../inputs/ButtonInput';

export default function VehicleCatalogue({
  vehicleSet,
  handleSelect,
  selectedVehicle,
  isFossilVehicle,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const {
    vehicles,
    sortOrder,
    sortConfig,
    setSortOrder,
    filters,
    updateFilter,
    types,
    subtypes,
    showCount,
    showMore,
  } = useFilteredVehicles(vehicleSet.fossilVehicle.type, isFossilVehicle);
  const sortOptions = Object.keys(sortConfig).map((key) => ({
    value: key,
    children: formatMessage({ id: `replacementVehicle.sortOptions.${key}` }),
  }));

  const typeOptions = useMemo(() => {
    return types.map((type) => ({ value: type, children: titleCase(type) }));
  }, [types]);

  const subtypeOptions = useMemo(() => {
    return subtypes.map((subtype) => ({ value: subtype, children: titleCase(subtype) }));
  }, [subtypes]);

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={3} sx={{ padding: '15px' }}>
          {!isFossilVehicle && (
            <Box
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: theme.palette.common.darkGrey,
              }}
            >
              {formatMessage(
                { id: 'replacementVehicle.pickReplacementVehicle' },
                { vehicleSetName: vehicleSet.name },
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={6} sx={{ padding: '15px' }}>
          {!isFossilVehicle && (
            <Box
              sx={{
                padding: '2px 5px',
                display: 'inline-block',
                border: '1px solid #D9D9D9',
                borderRadius: '5px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ textTransform: 'uppercase', padding: '0 5px' }}
                  >
                    <Grid item width="14px">
                      <BoltIcon />
                    </Grid>
                    <Grid item>{formatMessage({ id: 'replacementVehicle.electricVehicle' })}</Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ textTransform: 'uppercase', padding: '0 5px' }}
                  >
                    <Grid item>
                      <SmallGasIcon />
                    </Grid>
                    <Grid width="14px" item>
                      <BoltIcon />
                    </Grid>
                    <Grid item>{formatMessage({ id: 'replacementVehicle.hybridVehicle' })}</Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ textTransform: 'uppercase', padding: '0 5px' }}
                  >
                    <Grid item>
                      <StarIcon />
                    </Grid>
                    <Grid item>{formatMessage({ id: 'replacementVehicle.upcoming' })}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={3} sx={{ padding: '15px' }}>
          <Dropdown
            value={sortOrder}
            label={formatMessage({ id: 'replacementVehicle.sortBy' })}
            onChange={(e) => setSortOrder(e.target.value)}
            options={sortOptions}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <FilterSidebar
            vehicleSet={vehicleSet}
            onChange={updateFilter}
            selectedVehicle={selectedVehicle}
            filters={filters}
            typeOptions={typeOptions}
            subtypeOptions={subtypeOptions}
            isFossilVehicle={isFossilVehicle}
          />
        </Grid>
        <Grid item xs={9}>
          <VehicleList
            vehicles={vehicles.slice(0, showCount)}
            handleSelect={handleSelect}
            selectedVehicle={selectedVehicle}
            isFossilVehicle={isFossilVehicle}
          />
          {vehicles.length > showCount && (
            <Box width="100%" p="16px" textAlign="center">
              <ButtonInput onClick={showMore}>
                {formatMessage({ id: 'replacementVehicle.showMore' })}
              </ButtonInput>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
