import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Box, Typography } from '@mui/material';
import ReplacementVehicleCard from './ReplacementVehicleCard';
import { formatAsDollars } from '../../../utils/formatters';
import { BoltIcon, SmallGasIcon, StarIcon } from '../../../assets/icons/icons';

export default function VehicleList({ vehicles, handleSelect, selectedVehicle, isFossilVehicle }) {
  const { formatMessage } = useIntl();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: '15px',
      }}
    >
      {vehicles.length === 0 && (
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px">
            {formatMessage({ id: 'replacementVehicle.noMatches' })}
          </Typography>
        </Grid>
      )}
      {vehicles.map((vehicle) => {
        const renderVehicleValue = () => {
          let value = vehicle.msrp ? formatAsDollars(vehicle.msrp) : '-';

          if (vehicle.msrpIsEstimated) value = `${value} *`;

          return <Box fontSize="22px"> {value} </Box>;
        };

        return (
          <Grid item xs={4} key={vehicle.id}>
            <ReplacementVehicleCard
              imageUrl={vehicle.imageUrl}
              make={vehicle.make || ''}
              model={vehicle.model || ''}
              electricRange={vehicle.electricRange}
              range={vehicle.range || 0}
              msrp={renderVehicleValue()}
              msrpIsEstimated={vehicle.msrpIsEstimated}
              icon={
                <Grid container alignItems="center">
                  {vehicle.fuel === 'PHEV' && <SmallGasIcon />}
                  {!isFossilVehicle && (
                    <Box width="14px" sx={{ marginLeft: '5px' }}>
                      <BoltIcon />
                    </Box>
                  )}
                  {!vehicle.availableNow && <StarIcon />}
                </Grid>
              }
              handleSelect={() => handleSelect(vehicle)}
              active={selectedVehicle?.id === vehicle.id}
              isFossilVehicle={isFossilVehicle}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
