import { Box } from '@mui/material';

export default function SubHeader({ children }) {
  return (
    <Box
      sx={{
        marginTop: '32px',
        verticalAlign: 'bottom',
        borderBottom: '1px solid',
        borderColor: 'common.grey300',
        color: 'common.DTEPrimary',
        fontSize: '18px',
        textAlign: 'left',
      }}
    >
      {children}
    </Box>
  );
}
