import React, { useState, useEffect } from 'react';
import { Box, Input } from '@mui/material';
import InputMask from 'react-input-mask';
import Tooltip from '../Tooltip/Tooltip';
import { LabelErrorIcon } from '../../assets/icons/icons';

export default function TextInput(props) {
  const { defaultValue, label, labelTooltip, error, required, mask, onChange } = props;

  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleBlur = () => onChange(value);

  return (
    <Box
      component="label"
      sx={{
        width: '100%',
        color: error ? 'common.errorBorder' : 'common.secondaryGrey',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        textAlign: 'left',
      }}
    >
      <Box
        sx={{
          fontSize: '14px',
          fontWeight: '600',
          display: 'flex',
          gap: '4px',
          color: error ? 'common.errorBorder' : 'common.secondaryGrey',
          fill: error ? 'common.errorBorder' : 'common.secondaryGrey',
        }}
      >
        {error && (
          <Box sx={{ fill: 'common.errorBorder', mr: '2px' }}>
            <LabelErrorIcon />
          </Box>
        )}
        {required && <Box sx={{ color: 'common.errorBorder', mr: '2px' }}>*</Box>}
        {label}
        {/* {!required && <Box sx={{ color: 'common.grey500', ml: '2px' }}>(Optional)</Box>} */}
        {labelTooltip && <Tooltip content={labelTooltip}></Tooltip>}
      </Box>
      {mask ? (
        <MaskedInput {...props} value={value} onChange={setValue} onBlur={handleBlur} />
      ) : (
        <PlainInput {...props} value={value} onChange={setValue} onBlur={handleBlur} />
      )}
      {error && <Box sx={{ minHeight: '24px', lineHeight: '1.1' }}>{error}</Box>}
    </Box>
  );
}

const inputStyle = {
  border: '1px solid',
  borderColor: 'common.grey400',
  width: '100%',
  paddingLeft: '12px',
  color: 'common.DTEPrimary',
  '&.Mui-focused': {
    borderColor: 'common.darkBlue',
  },
  '&.Mui-error': {
    borderColor: 'common.errorBorder',
  },
  '&.Mui-disabled': {
    borderColor: 'common.grey300',
    backgroundColor: 'common.grey200',
  },
};

function PlainInput({ placeHolder, onChange, onBlur, error, disabled, step, value }) {
  return (
    <Input
      value={value}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
      placeholder={placeHolder}
      disabled={disabled}
      error={error}
      className="input"
      type="text"
      disableUnderline
      sx={inputStyle}
      inputProps={{
        step,
      }}
    />
  );
}

function MaskedInput({
  placeHolder,
  onChange,
  onBlur,
  error,
  disabled,
  step,
  mask,
  maskChar,
  value,
}) {
  return (
    <InputMask
      mask={mask}
      maskChar={maskChar || ' '}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
      placeholder={placeHolder}
      disabled={disabled}
      error={error}
      className="input"
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          type="text"
          disableUnderline
          sx={inputStyle}
          inputProps={{
            step,
          }}
        />
      )}
    </InputMask>
  );
}
