import React, { useState } from 'react';
import { Select, Input, MenuItem, Box, FormControl } from '@mui/material';
import Tooltip from '../Tooltip/Tooltip';
import { ChevronIcon, CheckIcon } from '../../assets/icons/icons';

export default function Dropdown({
  selectProps,
  onChange,
  options,
  value,
  label,
  labelInline = false,
  labelTooltip,
  error,
  disabled,
  required,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormControl fullWidth>
      <Box
        component="label"
        sx={{
          width: '100%',
          color: error ? 'common.errorBorder' : 'common.secondaryGrey',
          display: 'flex',
          flexDirection: labelInline ? 'row' : 'column',
          gap: labelInline ? '8px' : '4px',
          textAlign: 'left',
        }}
      >
        {label && (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              ...(labelInline
                ? {
                    fontSize: '16px',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                  }
                : {
                    fontSize: '14px',
                    fontWeight: '600',
                  }),
            }}
          >
            {required && <Box sx={{ color: 'common.errorBorder', mr: '2px' }}>*</Box>}
            {label}
            {/* {!required && <Box sx={{ color: 'common.grey500', ml: '2px' }}>(Optional)</Box>} */}
            {labelTooltip && <Tooltip content={labelTooltip}></Tooltip>}
          </Box>
        )}
        <Select
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          disabled={disabled}
          error={error}
          value={value}
          onChange={onChange}
          {...selectProps}
          disableUnderline
          IconComponent={({ className }) => {
            return (
              <Box
                className={className}
                sx={{
                  top: '3px !important',
                  right: '13px !important',
                  transform: 'unset !important',
                  '> svg': {
                    pointerEvents: 'none',
                    transform: isOpen ? 'rotate(0)' : 'rotate(180deg)',
                    fill: (theme) => {
                      if (isOpen) return 'white';
                      if (error) return theme.palette.common.errorBorder;
                      return theme.palette.common.accentBlue;
                    },
                  },
                }}
              >
                <ChevronIcon />
              </Box>
            );
          }}
          input={
            <Input
              disableUnderline
              onChange={(ev) => onChange(ev.currentTarget.value)}
              className="input"
              sx={{
                border: '1px solid',
                borderColor: 'common.grey400',
                width: '100%',
                paddingLeft: '12px',
                backgroundColor: isOpen ? 'primary.main' : 'white',
                color: (theme) => {
                  if (isOpen) return 'white';
                  if (error) return theme.palette.common.errorBorder;
                  return theme.palette.common.DTEPrimary;
                },
                '.MuiSelect-icon': (theme) => {
                  if (isOpen) return { fill: 'white' };
                  if (disabled) return { fill: theme.palette.common.grey500 };
                  if (error) return { fill: theme.palette.common.errorBorder };
                  return { fill: theme.palette.common.accentBlue };
                },
                '&.Mui-focused': {
                  borderColor: 'common.darkBlue',
                },
                '&.Mui-error': {
                  borderColor: 'common.errorBorder',
                },
                '&.Mui-disabled': {
                  borderColor: 'common.grey300',
                  backgroundColor: 'common.grey200',
                },
              }}
            />
          }
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'white',
                    color: 'primary.main',
                    fontWeight: '600',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '> svg': {
                      display: 'block',
                    },
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'common.accentBlue',
                    color: 'white',
                    fontWeight: '600',
                    '> svg': {
                      stroke: 'white',
                    },
                  },
                  '&:focus': {
                    backgroundColor: 'common.accentBlue',
                    color: 'white',
                    fontWeight: '600',
                  },
                  '&:hover': {
                    backgroundColor: 'common.accentBlue',
                    color: 'white',
                    fontWeight: '600',
                  },
                }}
              >
                {option.children}
                {isOpen && option.value === value && <CheckIcon />}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </FormControl>
  );
}
