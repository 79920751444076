import React from 'react';
import { Box } from '@mui/material';

export default function Section({ title, description, rightHeaderSlot, children }) {
  return (
    <Box
      sx={{
        mt: '32px',
        mb: '64px',
        textAlign: 'left',
        breakInside: 'avoid-page',
      }}
    >
      {(title || description) && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {title && <Box sx={{ fontSize: '18px', fontWeight: '600', mb: '5px' }}>{title}</Box>}
            {description && (
              <Box
                sx={{
                  fontSize: '16px',
                  color: 'common.secondaryGrey',
                }}
              >
                {description}
              </Box>
            )}
          </Box>
          {rightHeaderSlot && <Box>{rightHeaderSlot}</Box>}
        </Box>
      )}
      <Box mt="32px">{children}</Box>
    </Box>
  );
}
