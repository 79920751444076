import times from 'lodash/times';
import moment from 'moment-timezone';

const translations = {
  en: {
    translation: {
      en: 'English',
      header: {
        slogan: 'Charging Forward eFleets Advisory Services',
        visit: 'Visit DTEEnergy.com',
        visitLink: 'https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home',
        greyBox: {
          eFleets: {
            text: 'eFleets Homepage',
            url: '/',
          },
          chargerRebates: {
            text: 'Apply for Charger Rebates',
            url: 'https://dte-efleets.powerclerk.com/MvcAccount/Login',
          },
          advisoryServices: {
            text: 'Enroll in Advisory Services',
            url: 'https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/business/electric/electric-vehicles/pev-biz-fleet#fleet-infographic',
          },
        },
        mobileWarning:
          'This application is calculation-heavy and not meant to be used on a small screen. For best results, please view this on a desktop device.',
        fleetSummary: {
          leadText: 'Full Fleet Summary',
          size: 'size',
          fuelSavings: '{years} Fuel Savings',
          emissionsSavings: 'CO2 Emissions Savings',
          totalVehicles: '{totalVehicles} vehicles',
        },
        topInputs: {
          viewingInformation: 'Viewing information for',
          allSites: 'All Sites',
          mySite: 'My Site',
          editInputs: 'Edit Inputs',
          addNewSite: 'Add New Site',
        },
      },
      tabs: {
        overview: 'Overview',
        assessment: 'Operational Assessment',
        vehicles: 'Vehicles',
        fuel: 'Fuel',
        charge: 'Charge',
        connect: 'Connect',
        tco: 'TCO',
        implement: 'implement',
        warning: 'Warning!',
        insufficientChargeWarning: 'The following vehicles deplete their batteries:',
        insufficientChargeCTA:
          'Please Edit Inputs to add more charging times, increase the charging levels, and/or decrease the daily miles.',
      },
      footer: {
        footerButtons: {
          chargerRebates: {
            url: 'https://dte-efleets.powerclerk.com/MvcAccount/Login',
            text: 'Apply for Charger Rebates',
          },
          advisoryServices: {
            url: 'https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/business/electric/electric-vehicles/pev-biz-fleet#fleet-infographic',
            text: 'Contact Advisory Services',
          },
          printResults: {
            url: '/print',
            text: 'Print your Results',
          },
          youtube: {
            url: 'https://www.youtube.com/user/DTEEnergyCompany',
          },
          linkedin: {
            url: 'https://www.linkedin.com/company/dte-energy/',
          },
          twitter: {
            url: 'https://twitter.com/DTE_Energy?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
          },
          facebook: {
            url: 'https://www.facebook.com/dteenergy/',
          },
        },
        termsAndConditions: {
          text: 'Terms & Conditions',
          url: 'https://newlook.dteenergy.com/wps/wcm/connect/dte-web/quicklinks/footer/terms-and-conditions',
        },
        privacyPolicy: {
          text: 'Online Privacy Policy',
          url: 'https://newlook.dteenergy.com/wps/wcm/connect/dte-web/quicklinks/footer/01+online+privacy+policy',
        },
        contactUs: {
          text: 'Contact Us',
          url: 'https://newlook.dteenergy.com/wps/wcm/connect/dte-web/quicklinks/footer/03+contact+us',
        },
        copyright: '© {year} DTE Energy. All rights reserved.',
      },
      modal: {
        cancel: 'Cancel',
        confirm: 'Confirm',
        delete: 'Delete',
      },
      panels: {
        overview: {
          title: 'Project Overview',
          summary:
            'Below is a summary of benefits estimated for your fleet electrification project. Navigate through the seven categories of benefits. If you need to change any inputs, click on Edit Inputs in the top right.',
          vehiclesGraphLabel:
            'Over the next {timePeriod}, you are electrifying {numVehicles} in your fleet across all sites.',
          chargersGraphLabel:
            'Over the next {timePeriod}, you are adding {numChargers} at all sites.',
          tcoSaved: 'saved over the life of the vehicles in total cost of ownership (TCO)',
          negativeSaved:
            'more expensive over the life of the vehicles in total cost of ownership (TCO)',
          fuelSaved: 'in fuel saved per year of operation of all vehicles',
          co2Saved: 'of CO2 saved, which is comparable to {numTrees} trees planted',
          co2Negative: 'With this configuration, the EVs will emit more than the ICE fleet.',
          takeAway: 'Electrifying {number} vehicles in your {year} fleet will result in:',
          expected: 'You are expected to add',
          allYourSites: 'total to all your sites',
          willReceive: 'You will receive  ',
          inRebates: 'in charger rebates after implementation',
          gasolineAvoided: 'of gasoline avoided over the life of the vehicles',
        },
        fuel: {
          title: '3. eFleet Fueling Requirements',
          summary:
            'Below is your personalized fuel and load forecasting model that can account for varying eFleet adoption rates.',
          totalFuelSavings: 'Total Fuel Savings',
          totalFuelCostLess:
            'Based on your selections, your new electric fleet will cost {totalCost} less over its 10-year lifetime than your current fleet.',
          totalFuelCostMore:
            'Based on your selections, your new electric fleet will cost {totalCost} more over its 10-year lifetime than your current fleet.',
          fuelCostAssumptions:
            'Calculations are based on a variety of assumptions about electricity costs, gasoline costs and load profiles that can be detailed here. You can update some of these inputs on the “Edit Assumptions” menu at the top of the tool.',
          annualFuelSavingsOverTime: 'Annual Fuel Savings Over Time',
          electrificationTimeline:
            'Your fleet electrification timeline will determine the timeline of expected fuel savings. View the projected annual savings below.',
          electricFuelCompared: 'Annual Fuel & Electricity Costs Compared',
          electrifictationTimeline:
            'As per your electrification timeline, your fuel costs will decrease and shift to electricity costs over time. Electricity costs will be lower than equivalent fuel costs, ultimately leading to the savings you see.',
          detailedCostTable: {
            year: 'Year',
            diesel: 'Diesel',
            gas: 'Gasoline',
            electrified: 'Electric Fleet (EV)',
            ev: 'Electric',
            phevGas: 'PHEV Gasoline',
          },
          annualFuelCosts: 'Annual Fuel Costs',
          summarizedFuelCosts: 'We summarized expected fossil fuel costs for each site, per year.',
          tableHeaders: {
            fuelFleetBySite: 'Current Fleet By Site',
            electricFleetBySite: 'Electrified Fleet by Site',
            electricFleetBySitePhev: 'Electrified Fleet by Site (PHEV)',
            fleetSize: 'Fleet Size',
            averageEffeciency: 'Average Efficiency (MPG)',
            energyUse: 'Energy Use (kWh)',
            annualMiles: 'Annual Miles',
            totalAnnualCost: 'Total Annual Cost',
            fossilCosts: 'Fossil Cost',
            electricCosts: 'Electric Cost',
          },
          fuelPriceSource: 'Gasoline & Diesel Price Source: ',
          fuelPriceSourceLinkText: 'AAA',
          fuelPriceSourceLinkURL: 'https://gasprices.aaa.com/state-gas-price-averages/',
          changeGasPricesInfo: 'To change prices, click the “Edit Inputs" button above ',
          annualElectricityCosts: 'Annual Electricity Costs',
          summarizedElectricityCosts:
            'We summarized expected electricity costs for each site, per year.',
          electricPriceSource: 'Electric Price Source: ',
          electricPriceSourceLinkText: 'DTE D3 Commercial Rate',
          electricPriceSourceLinkURL:
            'https://www.newlook.dteenergy.com/wps/wcm/connect/ce9f2527-b94f-479c-b986-f500f0047489/BusinessElectricRates.pdf?MOD=AJPERES',
          year: 'Year',
          fuelSavingsLabels: {
            currentFleet: 'Current Fleet',
            estimatedCosts: '(Estimated Costs)',
            electrifiedFleet: 'Electrified Fleet',
            yourSelection: '(Your selection)',
            phevGas: 'PHEV Gasoline',
          },
        },
        assessment: {
          title: '1. Operational Assessment',
          summary:
            'We summarized your number of fleet assets, fleet locations and vehicle use cases, as gathered in the Operational Assessment wizard.',
          viewOrEdit: 'View or edit the summary of your sites below.',
          sites: 'Sites',
          siteSets: 'Sites and Vehicle Sets',
          editSiteSets:
            'View or edit the summary of your sites and their corresponding  vehicle sets below.',
          addNewSet: 'Add new vehicle set',
          street: 'Street Address',
          editSite: 'Edit Site',
          vehicles: {
            set: 'Vehicle Set',
            type: 'Type',
            subtype: 'Subtype',
            replaceYear: 'Replacement Year',
            miles: 'Miles / Day',
            range: 'Total Range',
            electricRange: 'Electric Range',
            payloadCapacity: 'Payload Capacity',
            milesPerGallon: 'Miles per Gallon',
            mpge: 'MPGe',
            mpgempg: 'MPGe / MPG',
          },
          assumptions: 'Assumptions',
          editAssumptions: 'View or edit the summary of your assumptions.',
          powerSupply: 'Power supply energy ($/kwh)',
          deliveryCharge: 'Delivery Charge ($/kwh)',
          serviceCharge: 'Service Charge ($/month)',
          gasPrice: 'Gasoline Price ($/gal)',
          dieselPrice: 'Diesel Price ($/gal)',
          editAssumption: 'Edit Assumptions',
          resetInputs: 'Reset Inputs',
          confirmResetInputs: 'Are you sure you want to reset all your inputs?',
        },
        vehicles: {
          title: '2. EV Recommendation',
          summary:
            'We summarized and compared your current fleet vehicles as entered to the EV which will replace them. To edit your current fleet vehicles or their chosen replacements, click on Edit Assumptions in the top right.',
          existingFleet: 'Existing Fossil Fuel Fleet',
          electrifiedFleet: 'Electrified Fleet',
          changeReplacement: 'Change Replacement Vehicle',
          vehicleUsage: 'Vehicle usage',
          workdays: 'workdays',
          monthsOfOperation: 'months of operation',
          typicalWorkday: 'typical workday',
          chargingWindows: 'charging window(s)',
          evRecommendation: 'Review your EV and charger recommendations',
        },
        charge: {
          title: '4. Charging and Facility Needs',
          summary:
            'Our team identified the applicable electric vehicle supply equipment (EVSE) for your use case.',
          chargingTitle: 'Charging Your Fleet',
          chargingSummary:
            'To charge your new electrified fleet, you will need the following charging equipment. You can pick a DTE-approved charger or keep the charger recommendations generic. Note: not all charger sizes used in calculations qualify for charger rebates from DTE. See eligible chargers on the charger catalog and choose something that is at least as large as recommended by the calculations.',
          chargerMake: 'Charger Make',
          chargerModel: 'Charger Model',
          type: 'Type',
          rate: 'Charge Rate',
          plug: 'Plug Type',
          rebate: 'rebate',
          quantity: 'quantity',
          pickCharger: 'Pick your charger',
          totalEstimatedCharger: 'Total Estimated Charger MSRP',
          totalEstimatedRebates: 'Total Estimated DTE Rebates for Current Site View',
          installer: 'licensed installer',
          advisory: 'DTE Advisory Services',
          rebatesWarning:
            'Total estimated DTE Rebates for your current site view are limited to a combined charger capacity of 200kW, however actual results may vary pending your site’s capacity assessment results from a {installer} and final selection of charger hardware. Contact {advisory} for more information.',
          chargingAssignments: 'Charging Assignments',
          selectSite: 'Select Site to view charger usage',
          dailyUsage: 'Daily Charger Usage',
          dailySummary:
            'We show you which chargers are active at what time each day for each vehicle set. This is another way of viewing the charging windows you entered.',
          equipmentSchedule: 'Charging Equipment Schedule',
          chargeAnalysis: 'State of Charge Analysis',
          selectVehicle: 'Select vehicle set to view charger Schedule',
          typicalWorkday: 'Battery Charge Throughout a Typical Workday',
          typicalSummary:
            'For optimal daily operation, state of charge should not fall below 20%. Your recommended charger usage is included below.',
        },
        connect: {
          title: '5. Connecting Your EVSE to the Electric Grid',
          summary:
            'We will provide guidance in determining the optimal charging for your eFleet so you can significantly lower electricity as a vehicle fuel cost.',
          gridConnections: 'Site Grid Connections',
          connectionSummary:
            'Below we summarize the key connection action items for each of your sites. Some sites may require infrastructure upgrades, while others do not. Contact DTE Advisory Services to discuss.',
          siteExceeds: 'Your site’s load from selected chargers is projected to exceed 200 kW,',
          warningLimit:
            '{siteExceeds} however actual results may vary pending your site assessment results from a {installer} . Contact {advisory} for more information.',
          siteBelow:
            'Your site’s load from selected chargers is projected to stay below 200 kW, however actual results may vary pending your site assessment results from a {installer}.',
          numberOfVehicles: 'Number of Vehicles:',
          chargingLoads: 'Charging Equipment Loads',
          chargeLoadSummary:
            'Your charging schedule will increase the electricity loads on your site. We add all these new charging equipment loads and compare them against the typical capacity constraint of 200KW. ',
          addedCharger: 'Added Charger Energy Use (Kwh)',
          allChargers: 'All Added Chargers',
          siteUse: 'Site Energy Use (kW)',
        },
        tco: {
          title: '6. Financial Analysis',
          summary: 'Financial viability of fleet electrification',
          totalCost: 'Total Cost of Ownership',
          chartDisclaimer:
            'Maintenance explained: We apply an internal and proprietary formula that uses AFLET data as a baseline ({link}). This accounts for vehicle type, age, mileage, and maintenance cost increases over time.',
          incentiveDisclaimer:
            'Incentives explained: This tool calculates applicable incentives based on the “Charging Forward eFleets” charger rebate program, which requires chargers to be networked and publicly-accessible. Contact DTE eFleets Advisory Services to learn more.',
          willSave:
            'Your fleet electrification project will save {value} over the next {years} including {totalYears} of operation.',
          currentFleet: 'Fossil Fleet',
          payback: 'Payback Period',
          vehiclePurchase: 'Vehicle/Charger Equipment Costs',
          fossilVehiclePurchase: 'Vehicle Cost',
          vehicleCost: 'Vehicle Cost After Incentives: {value}',
          chargerCost: 'Charger Cost: {value}',
          incentivesCost: 'Incentives: {value}',
          chargerIncentives: 'Charger Incentives: {value}',
          total: 'Total Cost: {value}',
          electricity: 'Electricity',
          gas: 'Gasoline',
          gasoline: 'Gasoline',
          diesel: 'Diesel',
          maintenance: 'Maintenance',
          insurance: 'Insurance',
          initialInvestment:
            'Your initial investment in fleet electrification will pay back in {years}. Additional investments will pay back over time.',
          overTime: 'Total Costs over Time',
          scenarioSavings:
            'Based on your scenario so far, you will save {value} over the life of your fleet in total costs of ownership (TCO).',
          operations: 'Operations',
          incentives: 'Incentives',
          infrastructure: 'Infrastructure',
          paybackCurrentFleet: 'Fossil Fleet',
          paybackElectrifiedFleet: 'Electric Fleet',
        },
        implement: {
          title: '7. Apply & Project Implementation',
          summary:
            'Apply for Charging Forward eFleets rebates and put your Advisory Roadmap into action.',
          projectImplementation: 'Project Implementation',
          projectSummary:
            'The information gathered from this tool can be used to generate an estimated project plan for you to work with DTE on launching your fleet electrification project and apply for DTE’s rebates.',
          apply: 'Apply For Charger Rebates',
          timeline: 'Project Timeline',
          milestones: 'Key Project Milestones',
          design: 'Project Design',
          permitting: 'Permitting',
          procurement: 'Procurement & Installation',
          utility: 'Utility Permitting',
          weeks: 'Weeks',
          typicalConstraint:
            '{siteExceeds} a typical capacity constraint, so we show a longer Utility Permitting phase of 12-16 weeks.',
          step: 'Step',
          step1: {
            title: 'Start EV procurement process',
            content: 'Begin the procurement process and place your EV order.',
          },
          step2: {
            title: 'Select an electrical contractor',
            directory: 'directory',
            content:
              'Find an electrician using DTE’s {directory} or select an electrical contractor of your choice.',
          },
          step3: {
            title: 'Purchase EV Chargers',
            content: 'Work with your electrician to place your order for chargers.',
          },
          step4: {
            title: 'Gather documents',
            content: {
              read: 'Read the {terms} and gather your proof of EV purchase and charger site property deed. For the company receiving the rebate check, have a W9 ready and complete the {questionnaire}.',
              terms: 'Terms & Conditions',
              questionnaire: 'Foreign Corrupt Practices Act questionnaire',
            },
          },
          step5: {
            title: 'Submit EV fleet application (initial review)',
            content: {
              consult:
                'Consult with your fleet OEM and/or electrical contractor to prepare and complete the "Step 1 – Initial Application" form in the Charging Forward eFleets application. {link}',
              link: 'Apply Now',
              pleaseNote: 'Please note: ',
              initialApp:
                ' {note} The Initial Application form is necessary for you to receive approval for the installation of the chargers.',
              yourInfo:
                'Your information will be saved and ready for you to complete the Rebate Form, which happens after your chargers have been installed.',
            },
          },
          step6: {
            title: 'Receive initial review approval from dte',
            content:
              'Wait for an email confirmation after submission and an approval email after DTE has had the chance to review your application.',
          },
          step7: {
            title: 'Start infrastructure design',
            content:
              'Your chosen electrical contractor designs your charging system infrastructure behind the meter, which includes charging stations.',
          },
          step8: {
            title: 'Obtain required permits',
            content:
              'Have your electrical contractor work with the local jurisdiction to obtain required permits.',
          },
          step9: {
            title: 'Begin Construction',
            content:
              'Work with you electrician to construct the EV supply infrastructure (behind the utility meter), install the charging equipment and complete municipal inspections.',
          },
          step10: {
            title: 'Commission Chargers',
            content:
              'Ensure equipment is functioning as intended, test for voltage and ensure connectivity to equipment manufacturer network.',
          },
          step11: {
            title: 'Complete the rebate form',
            content: {
              ensureFunction:
                'Take pictures of the installed, functioning chargers once in service and go to {link} to complete your rebate application:',
              selectProject: 'a. Select “View/Edit Project” to get to your project',
              goToForms: 'b. Go to the “Available Forms” section',
              finalizeRebate: 'c. Find  “Step 2 – Finalize Rebate” and click “Begin”',
              submitForm: 'd. Click "Submit" to receive check',
            },
          },
          step12: {
            title: 'Receive Rebate Check',
            content: 'Wait 3-4 weeks after email confirmation for check to arrive.',
          },
          selectSite: 'Select Site',
        },
      },
      editVehicleSets: {
        title: 'Edit Vehicle Set',
        averageDailyMiles:
          'Include both commercial and personal miles. If some vehicles have above-average use, place them in a different vehicle set with a higher average mileage.',
        vehicleCount:
          'This is the total number of vehicles modeled in this set. Separate vehicles with different levels of use into different sets.',
        tabs: {
          customVehicle: 'Custom Vehicle',
          fromCatalog: 'Select From Catalog',
        },
        info: {
          title: 'Vehicle Information',
          typeGuideLink: 'Vehicle Type & Subtype Guide',
          selectVehicle: 'Select a vehicle from catalog',
          selectAnotherVehicle: 'Select another vehicle',
          set: 'Vehicle Set',
          site: 'Site',
          type: 'Vehicle Type',
          typeTooltip: 'Used to match vehicle recommendations for your existing fleet.',
          subtype: 'Subtype',
          subtypeTooltip: 'Used to match vehicle recommendations for your existing fleet.',
          subtypePickupTooltip:
            'We use this to match your vehicle needs. Most common pickup trucks are light duty, or Class 2A on the GVWR scale. Class 1-3 trucks are considered light duty, 4-6 are medium duty, 7-8 are heavy duty. ',
          make: 'Make and Model',
          fuel: 'Fuel Type',
          replacement: 'Replacement Year',
          gas: 'Gasoline',
          diesel: 'Diesel',
          currentPlanned: 'Current / Planned Vehicle',
          miles: 'miles',
          mpg: 'mpg',
          weightClass: 'Weight Class',
          advancedProperties: 'Advanced Vehicle Properties',
          msrp: 'MSRP',
          fuelType: 'Fuel Type',
          fossilMilesPerGallon: 'ICE Fuel Efficiency (MPG)',
          maintenanceCostPerMile: 'Maintenance Cost ($/MI)',
        },
        usage: {
          title: 'Vehicle Usage',
          miles: 'AVG. Daily miles / vehicle',
          count: '# of vehicles',
          days: 'Days in Operation',
          months: 'Months in Operation',
        },
        times: {
          title: 'Charging Times',
          start: 'start time',
          end: 'end time',
          location: 'Location',
          type: 'charger type',
          add: 'Add charging window',
          warning: 'Warning:',
          windowError:
            '{warning} With this configuration, the batteries will be depleted. Please add more charging times, increase the charging level(s), and/or decrease the daily miles.',
        },
        cancel: 'Cancel',
        confirm: 'Confirm',
        delete: 'Delete Set',
        chargerSelectedMessage:
          'A specific charger was selected. To edit this selection, please go to "Step 4 - Chargers" tab.',
      },
      editSites: {
        title: 'Going electric starts with us understanding your operational needs',
        summary:
          'Tell us about the site where these fleet vehicles will be based. Hover over the question mark icon to learn more about what the information is used for.',
        name: 'Site Name',
        siteNameTip: 'Used only for your reference when naming sites.',
        zipCode:
          "We use zip code to pull in starting assumptions on fuel prices and other regional data points used in calculations. Valid zip codes include only DTE's service coverage region.",
      },
      replacementVehicle: {
        title: 'Select Replacement Vehicle',
        pickReplacementVehicle: 'Pick a Replacement Vehicle for {vehicleSetName}:',
        electricVehicle: 'Electric Vehicle',
        hybridVehicle: 'Hybrid Vehicle',
        upcoming: 'Upcoming',
        sortBy: 'Sort By:',
        sortOptions: {
          rangeDesc: 'Range (decreasing)',
          rangeAsc: 'Range (increasing)',
        },
        showMore: 'Show More',
        showing: 'Showing {showCount} of {total} vehicles',
        allElectric: 'All Electric',
        plugInHybrid: 'Plug-in Hybrid',
        type: 'Type',
        subtype: 'Subtype',
        milesPerDay: 'Miles / Day',
        filters: 'Filters',
        fuelType: 'Fuel Type',
        vehicleType: 'VEHICLE TYPE',
        vehicleSubtype: 'VEHICLE SUBTYPE',
        msrp: 'MSRP',
        estimatedMsrp: 'Estimated',
        availableNow: 'Available Now',
        electricRange: 'ELECTRIC RANGE',
        totalRange: 'Total Range',
        range: 'Range',
        milesPerWorkday: 'Miles per workday for this vehicle',
        payloadCapacity: 'PAYLOAD CAPACITY',
        selectFossil: 'Select Vehicle',
        selectedFossil: 'Selected Vehicle',
        selectAsReplacement: 'Select As Replacement',
        selectedAsReplacement: 'Selected As Replacement',
        miles: 'miles',
        noMatches: 'No vehicles match the current filters',
        electricRange: 'Electric Range',
        totalRange: 'Total Range',
        weightClass: 'Weight Class',
        milesPerKwh: 'Miles/kWh',
        battery: 'Battery',
        kwh: 'kWh',
        msrpMessage: '* Denotes estimated MSRP',
      },
      replacementCharger: {
        title: 'Select Replacement Charger',
        chargerModel: 'Charger Model',
        chargerType: 'Charger Type',
        chargerTypeDescription:
          'Level 2 chargers use 240V AC electricity and are the most common charger types. Direct current fast chargers (DCFC) require 400V AC or more to charge at least at a rate of 50kW or higher, but are not compatible with as many vehicles and are generally used for topping up.',
        chargeRate: 'Charge Rate (kW)',
        chargeRateDescription:
          'A higher power rating lets you charge faster, within certain vehicle limits. Choose a charger which can charge at a rate at least as large as recommended.',
        type: 'Type',
        plugType: 'Plug Type',
        msrp: 'MSRP',
        msrpEstimated: 'Estimated MSRP',
        dteRebate: 'DTE Rebate',
        selectedAsReplacement: 'Selected',
        selectAsReplacement: 'Select as Replacement',
        sortOptions: {
          msrpDesc: 'MSRP (decreasing)',
          msrpAsc: 'MSRP (increasing)',
        },
        sortBy: 'Sort By',
        filters: 'Filters',
        showMore: 'Show More',
        showing: 'Showing {showCount} of {total} chargers',
        msrpMessage: '* Denotes estimated MSRP',
      },
      timePeriods: {
        days: {
          weekdays: {
            long: 'Weekdays',
            abbreviated: 'Weekdays',
          },
          weekends: {
            long: 'Weekends',
            abbreviated: 'Weekends',
          },
          0: {
            long: 'Sunday',
            abbreviated: 'Sun',
          },
          1: {
            long: 'Monday',
            abbreviated: 'Mon',
          },
          2: {
            long: 'Tuesday',
            abbreviated: 'Tue',
          },
          3: {
            long: 'Wednesday',
            abbreviated: 'Wed',
          },
          4: {
            long: 'Thursday',
            abbreviated: 'Thu',
          },
          5: {
            long: 'Friday',
            abbreviated: 'Fri',
          },
          6: {
            long: 'Saturday',
            abbreviated: 'Sat',
          },
        },
        months: {
          0: {
            long: 'January',
            abbreviated: 'Jan',
          },
          1: {
            long: 'February',
            abbreviated: 'Feb',
          },
          2: {
            long: 'March',
            abbreviated: 'Mar',
          },
          3: {
            long: 'April',
            abbreviated: 'Apr',
          },
          4: {
            long: 'May',
            abbreviated: 'May',
          },
          5: {
            long: 'June',
            abbreviated: 'Jun',
          },
          6: {
            long: 'July',
            abbreviated: 'Jul',
          },
          7: {
            long: 'August',
            abbreviated: 'Aug',
          },
          8: {
            long: 'September',
            abbreviated: 'Sep',
          },
          9: {
            long: 'October',
            abbreviated: 'Oct',
          },
          10: {
            long: 'November',
            abbreviated: 'Nov',
          },
          11: {
            long: 'December',
            abbreviated: 'Dec',
          },
        },
        workdays: {
          weekdays: 'Weekdays',
          weekends: 'Week',
        },
        labels: {
          month: 'Month',
          day: 'Day',
          hour: 'Hour',
        },
      },
      chargingWindows: {
        title: 'Charging Behavior',
        description:
          'Think ahead to when you expect to charge your new electrified fleet. Enter each daily window of time you expect to charge your vehicles and what general charger type you think you might use, and we will calculate if you have enough charging time. Once you complete this step, you can pick specific charger makes and models later.',
        tooltip:
          'Level 2 chargers use 240V AC electricity and are the most common charger types. Direct current fast chargers (DCFC) require 400V AC or more to charge at least at a rate of 50kW or higher, but are not compatible with as many vehicles and are generally used for topping up.',
        warning: 'Warning',
        warningInsufficientRange:
          'The miles traveled per day are greater than the range for this vehicle. This is sustainable only with multiple charging windows during the day.',
        warningInsufficientCharge:
          'With this configuration, the batteries will be depleted. Please add more charging times, increase the charging level(s), and/or decrease the daily miles.',
        chargingTimes: {
          ...times(24, (i) => moment(i, 'H').format('h a')),
        },
      },
      onboardingSteps: {
        site: {
          summary:
            'Tell us about the site where these fleet vehicles will be based. Hover over the question mark icon to learn more about how the information is used.',
          next: 'Next: Vehicle Types',
        },
        vehicle: {
          summary:
            'Tell us which vehicle you plan to replace. If you have several vehicles of this make/model, we’ll create vehicle sets based on your fleet’s usage patterns (gathered on the next screen).',
          next: 'Next: Vehicle Usage',
        },
        usage: {
          summary:
            'Tell us how you use this fleet vehicle so we can recommend replacement EVs and matching chargers. When entering multiple vehicles of the same make/model, only group those that have similar cargo and range needs. You can rename, add, or change vehicle sets later.',
          next: 'Next: Pick Fleet Replacement',
          weekdays: 'Weekdays Only',
          allMonths: 'All Months',
          customDays: 'Have custom days in operation?',
          customMonths: 'Have custom months in operation?',
          selectMonths: 'Specify Months',
          selectDays: 'Specify Days',
        },
        recommend: {
          summary:
            'Here is your recommended EV match for this vehicle set and the minimum charging capabilities needed. You can choose your specific charger makes and models later and shop for alternative EV or hybrid matches.',
          next: 'Done',
          noVehicle: 'No Replacement Vehicle Selected',
          output: 'Minimum Output',
          plugType: 'Plug Type',
          chargerPending: 'Charger Pending Vehicle Selection',
        },
        back: 'Back',
      },
      chargerTypes: {
        level_2: 'Level 2',
        dc_fast_charging: 'DCFC',
      },
      chargerPorts: {
        1: 'Single',
        2: 'Dual',
      },
      homepage: {
        willGuide: 'We will guide you on a seven-step roadmap to electrify your fleet',
        afterComplete: 'After completing the roadmap, you will receive the following benefits:',
        vehicleRec: 'Vehicle and Charger Recommendations',
        getRec: 'Get recommendations that match your fleet’s mileage and power demands.',
        analysis: 'Financial Analysis and Savings',
        seeSavings: 'See fuel savings and available incentives for your entire fleet.',
        costEstimate: 'Cost Estimates and Workplan for Installing',
        generateCost: 'Generate cost estimates and workplans for installing infrastructure.',
        oneTruck: 'One medium-duty EV pickup truck:',
        avgSavings: 'Has a projected average annual fuel savings of $9,000',
        greenhouse: 'Saves a projected average greenhouse gas emissions of 32.5 tons',
        getStarted: 'Get Started',
      },
      errorBoundary: {
        header: 'Please reset your inputs',
        body: 'You have either modified a valid url, or we have updated our vehicle data as part of our effort to stay up-to-date with a fast changing industry. Please reset your inputs using the button below to resolve this issue.',
      },
      vehicleTypesPage: {
        title: 'Vehicle Type & Subtype Guide',
        summary:
          'Reference this guide to discover the range of vehicle type & subtypes used in this tool.',
        group: 'Vehicle Type',
        manufacturer: 'Manufacturer',
        type: 'Type',
        subtype: 'Subtype',
        description: 'Description',
        navBack: 'Back',
      },
      years: 'years',
      year: 'year',
      vehicles: 'Vehicles',
      chargers: 'Chargers',
      tons: 'tons',
      edit: 'Edit',
      quantity: 'quantity',
      total: 'total',
      zipcode: 'Zipcode',
      save: 'Save Changes',
      vehicleCatalogLink: 'Vehicle Catalog',
      chargerCatalogLink: 'Charger Catalog',
    },
  },
};

export default translations;
