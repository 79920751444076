import React from 'react';
import { Box } from '@mui/material';
import StyledCollapse from '../../../Panels/StyledCollapse/StyledCollapse';
import { useIntl } from 'react-intl';
import { sum } from 'lodash';
import ReplacementSummary from './ReplacementSummary';
import ChargingWindows from '../../../Panels/EditVehicleSetDialog/ChargingWindows';
import VehicleSet from '../../../calculations/VehicleSet';
import InfoCard from '../../../Panels/InfoCard/InfoCard';

const MenuHeader = (site) => {
  const { formatMessage } = useIntl();

  const numVehicles = sum(site.vehicleSets.map(({ vehicleCount }) => vehicleCount));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <Box sx={{ mr: '50px' }}>
        <b>{site.name}</b>
      </Box>
      <Box sx={{ mr: '50px' }}>
        <b>{formatMessage({ id: 'zipcode' })}</b> {site.zipCode}
      </Box>
      <Box>
        <b>{formatMessage({ id: 'panels.connect.numberOfVehicles' })}</b> {numVehicles}
      </Box>
    </Box>
  );
};

export default function RecommendedVehicle({
  vehicleSet,
  site,
  onChangeVehicleSet,
  handleReplaceVehicle,
}) {
  const { formatMessage } = useIntl();

  const ev = VehicleSet.getElectricVehicle(vehicleSet);
  const insufficientRange = vehicleSet.milesPerWorkday > ev.rangeInMiles;
  const minStateOfCharge = VehicleSet.minimumStateOfCharge(vehicleSet, vehicleSet.replacementYear);

  return (
    <Box sx={{ width: '100%', maxWidth: '1100px', margin: '0 auto' }}>
      <StyledCollapse title={MenuHeader(site)} styleName="primary" leftIcon defaultOpen>
        <Box
          sx={{
            padding: '0 60px 16px 60px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            maxWidth: '760px',
            margin: '0 auto',
          }}
        >
          <ReplacementSummary
            vehicleSet={vehicleSet}
            site={site}
            vehicleInd={0}
            handleReplaceVehicle={handleReplaceVehicle}
          />
          {insufficientRange && (
            <div style={{ marginBottom: '16px' }}>
              <InfoCard
                variant={'warning'}
                message={
                  <>
                    <b>{formatMessage({ id: 'chargingWindows.warning' })}: </b>
                    {formatMessage({ id: 'chargingWindows.warningInsufficientRange' })}
                  </>
                }
              />
            </div>
          )}
          <Box textAlign="left" my="20px">
            <h4>{formatMessage({ id: 'chargingWindows.title' })}</h4>
            <Box>{formatMessage({ id: 'chargingWindows.description' })}</Box>
          </Box>
          <ChargingWindows
            vehicleSet={vehicleSet}
            onChangeVehicleSet={onChangeVehicleSet}
            isOnboarding
          />
          {ev.fuel === 'BEV' && minStateOfCharge < 0 && (
            <InfoCard
              variant={'error'}
              message={
                <>
                  <b>{formatMessage({ id: 'chargingWindows.warning' })}: </b>
                  {formatMessage({ id: 'chargingWindows.warningInsufficientCharge' })}
                </>
              }
            />
          )}
        </Box>
      </StyledCollapse>
    </Box>
  );
}
