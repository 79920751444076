// Soure: https://docs.google.com/spreadsheets/d/1wl9rJFzY0Tk08oVnZycXK6WBy_wumwtCXgLMb2YY4Y8/edit?userstoinvite=milena.marku@dteenergy.com&actionButton=1#gid=1483609171
// See excluded rate elements in column G

import { D3_ENERGY_RATE } from '../../utils/assumptions';

const dteD3 = {
  name: 'dteD3',
  title: 'DTE D3',
  rateElements: [
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Marginal Energy Charges',
      classification: 'energy',
      rateComponents: [
        {
          charge: D3_ENERGY_RATE,
          name: 'Marginal $/kWh',
        },
      ],
    },
  ],
};

export default dteD3;
