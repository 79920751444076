import React from 'react';
import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TrashIcon } from '../../assets/icons/icons';
import ButtonInput from '../../components/inputs/ButtonInput';

export default function ModalButtonRow({ onClose, onDelete, onSaveAndClose, showDelete }) {
  const { formatMessage } = useIntl();

  return (
    <Box
      mt="130px"
      sx={{ borderTop: '1px solid #E4E4E4', gap: '8px' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="115px"
    >
      <ButtonInput variant="contained" onClick={() => onSaveAndClose()}>
        {formatMessage({ id: 'editVehicleSets.confirm' })}
      </ButtonInput>
      {showDelete && (
        <ButtonInput onClick={onDelete} variant="link" alert>
          <TrashIcon />
          {formatMessage({ id: 'editVehicleSets.delete' })}
        </ButtonInput>
      )}
      <ButtonInput variant="outlined" onClick={onClose}>
        {formatMessage({ id: 'editVehicleSets.cancel' })}
      </ButtonInput>
    </Box>
  );
}
