export const MAX_KW_REBATE = 200;

/**
 * @typedef ChargerRebate
 * @property {number} rebatePerCharger
 * @property {number} vehicleCount
 * @property {number} chargeRate
 * @property {number} rebate
 */

/**
 * One rebate per vehicle set, highest rebate value picked
 *
 * @param {Array<Site>} sites
 * @returns {Array<ChargerRebate>}
 */
export const getChargerRebates = (sites) => {
  return sites.reduce((acc, site) => {
    return [
      ...acc,
      ...site.vehicleSets.map((set) => {
        const rebateCharger = set.chargingWindows.sort((a, b) =>
          a.charger.rebate > b.charger.rebate ? -1 : 1,
        )[0].charger;
        return {
          rebatePerCharger: rebateCharger.rebate,
          vehicleCount: set.vehicleCount,
          chargeRate: rebateCharger.chargeRate,
          totalChargeRate: rebateCharger.chargeRate * set.vehicleCount,
        };
      }),
    ];
  }, []);
};

/**
 * Sort by highest rebate per kW and combine rebates until max charge rate reached
 * @param {Array<ChargerRebate>} rebates
 */
export const getMaxRebate = (rebates) => {
  let totalChargerRebate = 0;
  let rebatedKW = 0;

  rebates
    .sort((a, b) =>
      a.rebatePerCharger / a.chargeRate > b.rebatePerCharger / b.chargeRate ? -1 : 1,
    )
    .forEach((rebate) => {
      const { chargeRate, vehicleCount, rebatePerCharger } = rebate;
      if (rebatedKW + chargeRate > MAX_KW_REBATE) return;

      for (let i = 0; i < vehicleCount; i++) {
        if (rebatedKW + chargeRate > MAX_KW_REBATE) return;
        rebatedKW += chargeRate;
        totalChargerRebate += rebatePerCharger;
      }
    });
  return {
    totalChargerRebate,
    rebatedKW,
  };
};
