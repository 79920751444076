import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export default function StyledTable(props) {
  const { headings, rows, styleName, centered, boldBottom } = props;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headings.map((heading, index) => (
              <TableCell
                key={index}
                align={centered && 'center'}
                sx={{
                  borderBottom: 0,
                  fontWeight: '600',
                  color: styleName === 'primary' && '#FFF',
                  backgroundColor: styleName === 'primary' && 'common.brandBlue',
                }}
              >
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, iRow) => (
            <TableRow
              key={iRow}
              sx={{
                backgroundColor: iRow % 2 === 0 ? '#F6F6F6' : '',
              }}
            >
              {row.map((col, iCol) => (
                <TableCell
                  key={iCol}
                  align={centered && 'center'}
                  sx={{
                    borderBottom: 0,
                    fontWeight: boldBottom && iRow === rows.length - 1 ? '600' : '',
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
