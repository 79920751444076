import { React, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import FilterSidebar from './FilterSidebar';
import Dropdown from '../../inputs/Dropdown';
import ChargerList from './ChargerList';
import { useInputs } from '@bellawatt/use-inputs';

const sortConfig = {
  msrpDesc: {
    field: 'msrp',
    method: 'desc',
  },
  msrpAsc: {
    field: 'msrp',
    method: 'asc',
  },
};

const useChargers = (filters, sort) => {
  const { apiChargers } = useInputs();

  const filteredChargers = apiChargers.filter(
    ({ chargerType, chargeRate }) =>
      (filters.chargerType === 'All' || filters.chargerType === chargerType) &&
      (filters.chargeRate === 'All' || filters.chargeRate === chargeRate),
  );

  const { field, method } = sort;

  switch (method) {
    case 'asc':
      filteredChargers.sort((a, b) => a[field] - b[field]);
      break;
    case 'desc':
      filteredChargers.sort((a, b) => b[field] - a[field]);
      break;
    default:
  }

  return filteredChargers;
};

export default function ChargerCatalogue({ onSelect, selectedCharger, currentCharger }) {
  const { formatMessage } = useIntl();
  const [activeFilters, setActiveFilters] = useState({
    chargerType: 'All',
    chargeRate: 'All',
  });
  const [sortOrder, setSortOrder] = useState('msrpAsc');

  const updateFilter = (name, value) => setActiveFilters({ ...activeFilters, [name]: value });

  const sortOptions = Object.keys(sortConfig).map((key) => ({
    value: key,
    children: formatMessage({ id: `replacementCharger.sortOptions.${key}` }),
  }));

  const chargers = useChargers(activeFilters, sortConfig[sortOrder]);

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={9} />
        <Grid item xs={3} sx={{ padding: '15px' }}>
          <Dropdown
            value={sortOrder}
            label={formatMessage({ id: 'replacementCharger.sortBy' })}
            onChange={(e) => setSortOrder(e.target.value)}
            options={sortOptions}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <FilterSidebar
            handleChange={updateFilter}
            activeFilters={activeFilters}
            currentCharger={currentCharger}
          />
        </Grid>
        <Grid item xs={9}>
          <ChargerList chargers={chargers} onSelect={onSelect} selectedCharger={selectedCharger} />
        </Grid>
      </Grid>
    </Box>
  );
}
