import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DownArrow, AddIcon, MinusIcon, PencilIcon } from '../../assets/icons/icons';
import SubHeader from '../../components/subHeader/SubHeader';
import { useDisplay } from '../../DisplayProvider/DisplayProvider';

function UnderlinedCollapse({ children, title, defaultOpen, leftIcon }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Box component="details" open={defaultOpen} onToggle={() => setIsOpen((prev) => !prev)}>
      <Box
        component="summary"
        sx={{
          cursor: 'pointer',
          listStyle: 'none',
          '&::-webkit-details-marker': {
            display: 'none',
          },
        }}
      >
        <SubHeader>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {title}
            {!leftIcon && (
              <Box sx={{ transform: `rotate(${isOpen ? '180' : '0'}deg)` }}>
                <DownArrow />
              </Box>
            )}
          </Box>
        </SubHeader>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}

function BoxCollapse({ children, title, leftIcon, pencilIcon, defaultOpen, extraInfo }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Box
      component="details"
      open={defaultOpen}
      onToggle={() => setIsOpen((prev) => !prev)}
      sx={{
        border: '1px solid #D9D9D9',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        marginTop: '15px',
        borderBottom: (theme) => isOpen && `4px solid ${theme.palette.common.brandBlue}`,
      }}
    >
      <Box
        component="summary"
        sx={{
          display: 'flex',
          alignItems: 'center',
          margin: '0',
          padding: '0 32px',
          fontWeight: '600',
          backgroundColor: isOpen ? 'common.brandBlue' : 'white',
          color: isOpen ? 'white' : 'common.darkGrey',
          height: '80px',
          cursor: 'pointer',
          transition: 'all ease 0.15s',
          '&::-webkit-details-marker': {
            display: 'none',
          },
          justifyContent: 'space-between',
        }}
      >
        <Box display={'flex'}>
          {leftIcon && (
            <Box mr="18px" sx={{ '> svg': { display: 'block', height: '21px', width: '21px' } }}>
              {isOpen ? <MinusIcon /> : <AddIcon />}
            </Box>
          )}
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: 1,
            }}
          >
            {title}
          </Box>
        </Box>
        {pencilIcon && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              ml: '15px',
              mt: '13px',
            }}
          >
            <PencilIcon
              strokeColor={[isOpen ? 'white' : '#0072CE', isOpen ? '#1E3575' : 'white']}
              fillColor={isOpen ? 'white' : '#0072CE'}
            />
          </Box>
        )}
        {!leftIcon && (
          <Box sx={{ transform: `rotate(${isOpen ? '180' : '0'}deg)` }}>
            <DownArrow />
          </Box>
        )}
        {extraInfo && leftIcon && <Box>{extraInfo}</Box>}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}

export default function StyledCollapse(props) {
  const { mode } = useDisplay();

  let newProps = { ...props };
  if (mode === 'print') {
    newProps.defaultOpen = true;
    newProps.styleName = 'underlined';
  }

  return newProps.styleName === 'primary' ? (
    <BoxCollapse {...newProps} />
  ) : (
    <UnderlinedCollapse {...newProps} />
  );
}
