import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@material-ui/core';
import EditVehicleSetDialog from '../EditVehicleSetDialog/EditVehicleSetDialog';
import SelectVehicleDialog from '../SelectVehicleDialog/SelectVehicleDialog';
import { useInputs } from '@bellawatt/use-inputs';
import cloneDeep from 'lodash/cloneDeep';
import VehicleContent from './VehicleContent';
import EmptyContent from './EmptyContent';
import { omitComputedData } from '../../utils/computedData';

export default function VehicleCard({ vehicleSet, isElectric, siteInd, vehicleInd }) {
  const theme = useTheme();
  const [openEdit, setOpenEdit] = useState(false);
  const { sites, setInput } = useInputs();
  const canRemove = sites[siteInd]?.vehicleSets?.length > 1;

  const handleDeleteVehicleSet = () => {
    const newSites = cloneDeep(sites);
    const newVehicleSets = cloneDeep(newSites[siteInd].vehicleSets);
    newVehicleSets.splice(vehicleInd, 1);
    newSites[siteInd].vehicleSets = newVehicleSets;
    setInput({ sites: omitComputedData(newSites) });
    setOpenEdit(false);
  };

  const handleChangeVehicleSet = (vehicleData, workingSiteInd) => {
    const newSites = cloneDeep(sites);
    const newVehicleSets = newSites[siteInd] ? cloneDeep(newSites[siteInd].vehicleSets) : {};

    //If the site changed, remove from old site, and push into new site.
    if (workingSiteInd !== siteInd) {
      const newSiteSets = cloneDeep(newSites[workingSiteInd].vehicleSets);
      newSiteSets.push({ ...vehicleData });
      newSites[workingSiteInd].vehicleSets = newSiteSets;

      newVehicleSets.splice(vehicleInd, 1);
    } else {
      newVehicleSets[vehicleInd] = {
        ...vehicleData,
      };
    }

    newSites[siteInd].vehicleSets = newVehicleSets;

    setInput({ sites: omitComputedData(newSites) });
    setOpenEdit(false);
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.common.borderGrey}`,
        borderRadius: '4px',
        height: '100%',
        minHeight: '366px',
        maxWidth: '350px',
        minWidth: '280px',
        padding: '12px 18px',
        textAlign: 'center',
      }}
    >
      {isElectric && !vehicleSet.vehicle ? (
        <EmptyContent vehicleSet={vehicleSet} handleEdit={setOpenEdit} isElectric={isElectric} />
      ) : (
        <VehicleContent vehicleSet={vehicleSet} handleEdit={setOpenEdit} isElectric={isElectric} />
      )}
      {isElectric ? (
        <SelectVehicleDialog
          isOpen={openEdit}
          vehicleSet={vehicleSet}
          onClose={() => setOpenEdit(false)}
          onSave={(vehicleData) => handleChangeVehicleSet(vehicleData, siteInd)}
          siteIndex={siteInd}
        />
      ) : (
        <EditVehicleSetDialog
          isOpen={openEdit}
          vehicleSet={vehicleSet}
          onClose={() => setOpenEdit(false)}
          onSave={(vehicleData, siteInd) => handleChangeVehicleSet(vehicleData, siteInd)}
          onDelete={handleDeleteVehicleSet}
          siteIndex={siteInd}
          canRemove={canRemove}
        />
      )}
    </Box>
  );
}
