/* eslint-disable import/prefer-default-export */
import chargerRebates from '../data/chargerRebates';
import VEHICLES from '../data/VEHICLES';

const typeConversions = {
  // Passenger vehicles:
  Sedan: 'Passenger',
  Crossover: 'Passenger',
  SUV: 'Passenger',
  Hatchback: 'Passenger',
  Coupe: 'Passenger',
  Minivan: 'Passenger',
  'Station Wagon': 'Passenger',

  // Non-Passenger vehicles
  transit_bus: 'Transit Bus',
  step_van: 'Step Van',
  pickup_truck: 'Pick-up Truck', // Note this picks up both `type='Commercial'` and `type='Passenger'`
  school_bus: 'School Bus',
  cutaway_chassis: 'Cutaway Chassis',
  utility_truck: 'Utility Truck',
  box_truck: 'Box Truck',
  refuse_truck: 'Refuse Truck',
  trailer_truck: 'Trailer Truck',
  terminal_tractor: 'Terminal Tractor',
  motor_coach: 'Motor Coach',
  forklift: 'Forklift',
  cargo_van: 'Cargo Van',
  shuttle_bus: 'Shuttle Bus',
  specialty: 'Specialty',
  bev_drive_system: 'BEV Drive System',
  tru: 'TRU',
};

const passengerSubtypeConversions = {
  Sedan: 'Sedan',
  Crossover: 'SUV', // There is no crossover archetype, so including this conversion keeps all crossovers from the API within the app
  SUV: 'SUV',
  Hatchback: 'Hatchback',
  Coupe: 'Coupe',
  Minivan: 'Minivan',
  'Station Wagon': 'Station Wagon',
  pickup_truck: 'Light Duty', // Pickups are on both passanger and commercial vehicles. Commercial have no problem, but because we have to adjust when `type='Passenger'` we also pick up Light Duty here
};

export const transformApiCharger = (charger) => {
  const rebateIndex = chargerRebates.findIndex(({ handle }) => handle === charger.charger_id);

  return {
    fromApi: true,
    id: charger.charger_id,
    make: charger.make,
    model: charger.model,
    chargerType: charger.kw_ac ? 'Level 2' : 'DCFC',
    chargeRate: charger.kw_ac ? charger.kw_ac : charger.kw_dc,
    isEstimatedPrice: charger.is_estimated_price,
    msrp: charger.price,
    plugType: charger.plug_types,
    rebate: chargerRebates[rebateIndex]?.rebate,
    imageUrl: charger.img,
    ports: charger.num_of_ports,
  };
};

/**
 *
 * @param {ApiVehicle} vehicle
 * @returns Vehicle
 */
export const transformApiVehicle = (vehicle) => ({
  fromApi: true,
  id: vehicle.handle,
  availableNow: vehicle.availability === 'released',
  category: vehicle.form_factor === 'tru' ? 'TRU' : vehicle.is_offroad ? 'Off-Road' : 'On-Road',
  name: `${vehicle.make} ${vehicle.model} ${vehicle.trim}`.trim(),
  type: typeConversion(vehicle),
  subtype: subtypeConversion(vehicle),
  formFactor: vehicle.form_factor,
  make: vehicle.make,
  year: vehicle.model_year,
  model: vehicle.model,
  modelYear: vehicle.modelYear,
  duty: vehicle.duty,
  trim: vehicle.trim,
  imageUrl: vehicle.images.find(({ url_thumbnail }) => url_thumbnail)?.url_full || null,
  rangeInMiles: vehicle.total_range,
  rangeInHours: 0, // off-road vehicles are NOT included
  range: vehicle.total_range,
  electricRange: vehicle.electric_range,
  fuel: vehicle.fuel === 'gas' ? 'gasoline' : vehicle.fuel,
  liftCapacity: vehicle.lift_capacity || 0,
  batteryCapacityInKwh: vehicle.battery_capacity,
  weightClass: vehicle.weight_class,
  msrp: vehicle.msrp || 0,
  milesPerGallon: vehicle.fossil_fuel_efficiency,
  msrpIsEstimated: vehicle.is_estimated_price,
  maintenanceCostPerMile: maintenanceCostPerMile(vehicle),
  maintenanceCostPerHour: vehicle.is_offroad ? vehicle.maintenance_cost : 0,
  efficiencyInMilesPerKwh: toPrecision(milesPerKwh(vehicle), 2) || null,
});

/**
 *
 * @param {Archetype} archetype
 * @returns Vehicle
 */
export const transformArchetypeToFossilVehicle = (archetype) => ({
  fromApi: false,
  id: archetype.id,
  availableNow: true,
  category: archetype.category,
  name: archetype.name,
  type: archetype.type,
  subtype: archetype.subtype,
  formFactor: archetype.formFactor,
  make: null,
  year: null,
  model: null,
  modelYear: null,
  duty: archetype.duty,
  trim: null,
  imageUrl: archetype.imageUrl,
  rangeInMiles: archetype.rangeInMiles,
  rangeInHours: archetype.rangeInHours,
  range: archetype.rangeInMiles,
  fuel: archetype.fossilFuelType,
  liftCapacity: archetype.liftCapacity,
  batteryCapacityInKwh: archetype.batteryCapacityInKwh,
  weightClass: archetype.weightClass,
  msrp: archetype.fossilFuelMsrp,
  milesPerGallon: archetype.fossilMilesPerGallon,
  msrpIsEstimated: true,
  maintenanceCostPerMile: archetype.fossilMaintenanceCostPerMile,
  maintenanceCostPerHour: null,
  efficiencyInMilesPerKwh: null,
});

/**
 *
 * @param {ApiVehicle} vehicle
 * @returns {String}
 */
function typeConversion(vehicle) {
  return typeConversions[vehicle.form_factor] || vehicle.form_factor;
}

/**
 *
 * @param {ApiVehicle} vehicle
 * @returns {String}
 */
function subtypeConversion(vehicle) {
  return vehicle.type === 'Passenger'
    ? passengerSubtypeConversions[vehicle.form_factor]
    : vehicle.subtype;
}

/**
 *
 * @param {ApiVehicle} vehicle
 * @returns {Number}
 */
function maintenanceCostPerMile(vehicle) {
  if (vehicle.is_offroad) return 0;
  const isFossil = ['gasoline', 'gas', 'diesel'].includes(vehicle.fuel);
  if (vehicle.maintenance_cost && vehicle.maintenance_cost > 0) {
    return vehicle.maintenance_cost;
  }
  const archetype = VEHICLES.find(
    ({ type, subtype }) =>
      type === typeConversion(vehicle) && subtype === subtypeConversion(vehicle),
  );
  if (archetype) {
    return isFossil ? archetype.fossilMaintenanceCostPerMile : archetype.maintenanceCostPerMile;
  }
  return null;
}

/**
 *
 * @param {ApiVehicle} vehicle
 * returns {Number}
 */
function milesPerKwh(vehicle) {
  if (!vehicle.electric_efficiency) return;

  if (vehicle.electric_efficiency_unit === 'kwh per 100 miles') {
    return (1 / vehicle.electric_efficiency) * 100;
  } else if (vehicle.electric_efficiency_unit === 'kwh per mile') {
    return 1 / vehicle.electric_efficiency;
  }
}

function toPrecision(value, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces);
  // Number.EPSILON is a really tiny number used to get around JS floating point issues
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
}

export function transformLocation(location) {
  return {
    gasolinePrice: location.national_fuel_cost[0].gasoline.toFixed(2),
    dieselPrice: location.national_fuel_cost[0].diesel.toFixed(2),
  };
}
