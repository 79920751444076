import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import Dropdown from '../components/inputs/Dropdown';
import FilterSidebar from '../components/Onboarding/VehicleCatalogue/FilterSidebar';
import { SmallGasIcon, BoltIcon, StarIcon } from '../assets/icons/icons';
import { titleCase } from '../utils/formatters';
import useFilteredVehicles from '../components/Onboarding/VehicleCatalogue/useFilteredVehicles';
import VehicleList from './vehicleList/VehicleList';
import ButtonInput from '../components/inputs/ButtonInput';

export default function VehiclePage() {
  const { formatMessage } = useIntl();
  const {
    vehicles,
    sortOrder,
    sortConfig,
    setSortOrder,
    filters,
    updateFilter,
    types,
    subtypes,
    showCount,
    showMore,
  } = useFilteredVehicles('all');

  const sortOptions = Object.keys(sortConfig).map((key) => ({
    value: key,
    children: formatMessage({ id: `replacementVehicle.sortOptions.${key}` }),
  }));

  const typeOptions = useMemo(() => {
    return types.map((type) => ({ value: type, children: titleCase(type) }));
  }, [types]);

  const subtypeOptions = useMemo(() => {
    return subtypes.map((subtype) => ({ value: subtype, children: titleCase(subtype) }));
  }, [subtypes]);

  return (
    <Box m="0 auto" width="100%" maxWidth="1175px" padding="20px">
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={12} md={6} sx={{ padding: '15px' }}>
          <Box
            sx={{
              padding: '2px 5px',
              display: 'inline-block',
              border: '1px solid #D9D9D9',
              borderRadius: '5px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            <Grid container alignItems="center">
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  sx={{ textTransform: 'uppercase', padding: '0 5px' }}
                >
                  <Grid item width="14px">
                    <BoltIcon />
                  </Grid>
                  <Grid item>{formatMessage({ id: 'replacementVehicle.electricVehicle' })}</Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  sx={{ textTransform: 'uppercase', padding: '0 5px' }}
                >
                  <Grid item>
                    <SmallGasIcon />
                  </Grid>
                  <Grid width="14px" item>
                    <BoltIcon />
                  </Grid>
                  <Grid item>{formatMessage({ id: 'replacementVehicle.hybridVehicle' })}</Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  sx={{ textTransform: 'uppercase', padding: '0 5px' }}
                >
                  <Grid item>
                    <StarIcon />
                  </Grid>
                  <Grid item>{formatMessage({ id: 'replacementVehicle.upcoming' })}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            value={sortOrder}
            label={formatMessage({ id: 'replacementVehicle.sortBy' })}
            onChange={(e) => setSortOrder(e.target.value)}
            options={sortOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="8px" position="relative">
        <Grid item xs={12} sm={4} md={3}>
          <Box position="sticky" top="10px">
            <FilterSidebar
              onChange={updateFilter}
              filters={filters}
              typeOptions={typeOptions}
              subtypeOptions={subtypeOptions}
            />
            <Box mt="16px" fontSize="14px" fontWeight="600">
              {formatMessage(
                { id: 'replacementVehicle.showing' },
                { showCount: showCount, total: vehicles.length },
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <VehicleList vehicles={vehicles.slice(0, showCount)} />
          {vehicles.length > showCount && (
            <Grid xs={12} p="16px" textAlign="center">
              <ButtonInput onClick={showMore}>
                {formatMessage({ id: 'replacementVehicle.showMore' })}
              </ButtonInput>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
