import React from 'react';
import { Box } from '@mui/material';
import ChargerCard from '../ChargerCard/ChargerCard';
import { useIntl } from 'react-intl';

export default function ChargerList({ chargingWindows, onPickCharger }) {
  const { formatMessage } = useIntl();

  const chargerData = chargingWindows.map(
    ({ charger, isGeneric, minimumRequired, siteInd, vehicleSetInd, chargingWindowInd }) => ({
      siteInd,
      vehicleSetInd,
      chargingWindowInd,
      make: isGeneric ? 'Generic Charger' : charger.make,
      model: isGeneric ? charger.type : charger.model,
      qty: minimumRequired,
      isEstimatedPrice: charger.isEstimatedPrice,
      msrp: charger.msrp,
      image: charger.imageUrl,
      type: isGeneric
        ? formatMessage({ id: `chargerTypes.${charger.chargerType}` })
        : charger.chargerType,
      plugType: charger.plugType,
      rate: `${charger.chargeRate} kW`,
      rebate: charger.rebate,
    }),
  );

  return (
    <Box
      sx={{
        mt: '28px',
        display: 'flex',
        flexWrap: 'wrap',
        mb: '32px',
      }}
    >
      {chargerData.map((charger) => {
        return (
          <ChargerCard
            charger={charger}
            key={`${charger.make}-${charger.model}`}
            onPickCharger={onPickCharger}
          />
        );
      })}
    </Box>
  );
}
