import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ChargerCatalogue from './ChargerCatalogue/ChargerCatalogue';
import Modal from '../modal/Modal';

export default function ChargerDialog({ isOpen, onClose, onSave, currentCharger }) {
  const { formatMessage } = useIntl();
  const [selectedCharger, setSelectedCharger] = useState();

  const handleSelectCharger = (charger) => {
    setSelectedCharger(charger);
  };

  const handleSave = () => {
    onSave(selectedCharger);
  };

  return (
    <Modal
      title={formatMessage({ id: 'replacementCharger.title' })}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSave}
    >
      <ChargerCatalogue
        onSelect={handleSelectCharger}
        selectedCharger={selectedCharger}
        currentCharger={currentCharger}
      />
    </Modal>
  );
}
