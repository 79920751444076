import { formatAsDollars } from '../../../../utils/formatters';

const CarouselItem = ({ item, onDelete, onEdit, allItems, setShowModal, vehicleCount }) => {
  const allIncentivesSum = allItems.reduce((accumulator, current) => {
    return accumulator + (Number(current.Value * vehicleCount) || 0);
  }, 0);

  return (
    <div className="incentiveCard">
      <div className="incentiveWrapper">
        <div className="top">
          {item.id === 'extra' ? (
            <p className="text-left">Summary Page</p>
          ) : (
            <p className="text-left">{item.Grantor}</p>
          )}
          {item.id === 'extra' ? (
            <p className="text-right">{formatAsDollars(allIncentivesSum)}</p>
          ) : (
            <p className="text-right">{formatAsDollars(item.Value)} / Vehicle </p>
          )}
        </div>
        <div className="mid">
          {item.id === 'extra' ? (
            <>
              <div className="container-left-extra">
                <p>Total Expected Incentives</p>
                <h1>{formatAsDollars(allIncentivesSum)}</h1>
                <span>
                  This is the total expected payout of all [{allItems.length}] incentives.
                </span>
              </div>
              <div className="container-right-extra">
                {allItems.map((item) => (
                  <div key={item.id}>
                    <p>{item.Incentive}</p>
                    <input value={formatAsDollars(item.Value * vehicleCount)} disabled />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="container-left">
                <p>Expected Incentive Payoult</p>
                <input value={formatAsDollars(item.Value * vehicleCount)} disabled />
              </div>
              <div className="container-right">
                <p className="title">My Custom Incentive</p>
                <p className="desc">{item.Description}</p>

                <p className="title">Notes</p>
                <p className="desc">{item.Notes}</p>
              </div>
            </>
          )}
        </div>
        <div className="bottom">
          {item.id !== 'extra' && (
            <button className="btn-edit" onClick={() => onEdit(item)}>
              EDIT
            </button>
          )}
          {item.id !== 'extra' && (
            <button className="btn-delete" onClick={() => onDelete(item)}>
              DELETE
            </button>
          )}
          {item.id === 'extra' && (
            <>
              <button className="btn-add" onClick={setShowModal}>
                ADD CUSTOM INCENTIVE
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
