import { Box } from '@mui/material';
import SiteCard from '../SiteCard/SiteCard';
import AddNewVehicleButton from './AddNewVehicleButton';
import StyledCollapse from '../StyledCollapse/StyledCollapse';
import VehicleCard from '../VehicleCard/VehicleCard';

export default function SiteList({ sites }) {
  return (
    <Box>
      {sites.map((site, index) => (
        <>
          <StyledCollapse
            styleName="primary"
            title={site.name}
            key={site.id}
            leftIcon
            defaultOpen={index === 0}
          >
            <Box sx={{ padding: '32px' }}>
              <SiteCard site={site} />
              <Box
                component="ul"
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
                gap="30px"
                margin="48px 0 24px 0"
                padding="0"
              >
                {site.vehicleSets.map((vehicleSet, index) => {
                  return (
                    <Box
                      component="li"
                      sx={{ listStyleType: 'none' }}
                      key={`${site.id}-${vehicleSet.vehicle_id}`}
                    >
                      <VehicleCard vehicleSet={vehicleSet} siteInd={site.id} vehicleInd={index} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </StyledCollapse>
          <AddNewVehicleButton siteId={site.id} />
        </>
      ))}
    </Box>
  );
}
