export const vehicleTypeDescriptions = {
  'Passenger Cars': [
    {
      group: 'Passenger Cars',
      type: 'Passenger',
      subtype: 'Coupe',
      description: 'Smaller car with a fixed roof, two doors, and a sloping rear.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Coupe.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Coupe.png',
    },
    {
      group: 'Passenger Cars',
      type: 'Passenger',
      subtype: 'Sedan',
      description: 'Most common passenger car.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Sedan.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Sedan.png',
    },
    {
      group: 'Passenger Cars',
      type: 'Passenger',
      subtype: 'Station Wagon',
      description: 'Passenger with a longer body, no trunk, and large rear carrying area.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Station_Wagon.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Station_Wagon.png',
    },
    {
      group: 'Passenger Cars',
      type: 'Passenger',
      subtype: 'Hatchback',
      description: '4-door vehicle with a tailgate that flips up.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/SUV.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/SUV.png',
    },
    {
      group: 'Passenger Cars',
      type: 'Passenger',
      subtype: 'Minivan',
      description: 'Small van with rear passenger seats.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Minivan.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Minivan.png',
    },
    {
      group: 'Passenger Cars',
      type: 'Passenger',
      subtype: 'SUV',
      description:
        'A rugged vehicle similar to a station wagon but built on a light-truck chassis (Sport-utility vehicle).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/SUV.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/SUV.png',
    },
  ],
  Trucks: [
    {
      group: 'Trucks',
      type: 'Pick-up Truck',
      subtype: 'Light Duty',
      description:
        'Enclosed cabin vehicle with an open cargo area, low sides, and a tailgate. Duty: Class 1-2 (combined vehicle and maximum payload weight below 10,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Pickup_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Light_Duty_Pickup_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Pick-up Truck',
      subtype: 'Medium Duty',
      description:
        'Enclosed cabin vehicle with an open cargo area, low sides, and a tailgate.\nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Pickup_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Pickup_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Pick-up Truck',
      subtype: 'Heavy Duty',
      description:
        'Enclosed cabin vehicle with an open cargo area, low sides, and a tailgate. \nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Pickup_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Pickup_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Box Truck',
      subtype: 'Medium Duty',
      description:
        'Chassis cab truck with enclosed box cargo area. \nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Box_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Box_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Box Truck',
      subtype: 'Heavy Duty',
      description:
        'Chassis cab truck with enclosed box cargo area. \nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Box_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Box_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Utility Truck',
      subtype: 'Heavy Duty',
      description:
        'Cargo truck with small drawers and compartments in cargo area. \nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Bucket_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Bucket_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Trailer Truck',
      subtype: 'Heavy Duty',
      description:
        'Combination tractor unit with a separate cabin and a trailer for carrying freight connected via a hitch.\nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Trailer_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Trailer_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Refuse Truck',
      subtype: 'Medium Duty',
      description:
        'Road vehicle for collecting domestic refuse. \nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Pickup_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Pickup_Truck.png',
    },
    {
      group: 'Trucks',
      type: 'Refuse Truck',
      subtype: 'Heavy Duty',
      description:
        'Road vehicle for collecting domestic refuse. \nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Refuse_Truck.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Refuse_Truck.png',
    },
  ],
  Vans: [
    {
      group: 'Vans',
      type: 'Cargo Van',
      subtype: 'Light Duty',
      description:
        'Enclosed boxlike vehicle for transportation of goods or cargo, with a driver and passenger seat, but no rear seating (cargo area only).\nDuty: Class 1-2 (combined vehicle and maximum payload weight below 10,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Cargo_Van.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Light_Duty_Cargo_Van.png',
    },
    {
      group: 'Vans',
      type: 'Cargo Van',
      subtype: 'Medium Duty',
      description:
        'Enclosed boxlike vehicle for transportation of goods or cargo, with a driver and passenger seat, but no rear seating (cargo area only).\nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cargo_Van.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Cargo_Van.png',
    },
    {
      group: 'Vans',
      type: 'Cargo Van',
      subtype: 'Heavy Duty',
      description:
        'Enclosed boxlike vehicle for transportation of goods or cargo, with a driver and passenger seat, but no rear seating (cargo area only).\nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Cargo_Van.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Cargo_Van.png',
    },
    {
      group: 'Vans',
      type: 'Step Van',
      subtype: 'Light Duty',
      description:
        'Panel trucks that allow the driver to stand or sit while driving the vehicle. Duty: Class 1-2 (combined vehicle and maximum payload weight below 10,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Step_Van.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Light_Duty_Step_Van.png',
    },
    {
      group: 'Vans',
      type: 'Step Van',
      subtype: 'Medium Duty',
      description:
        'Panel trucks that allow the driver to stand or sit while driving the vehicle. Duty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Step_Van.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Step_Van.png',
    },
    {
      group: 'Vans',
      type: 'TRU',
      subtype: 'TRU Cargo Van',
      description:
        'Cargo van retrofitted with a transportation refrigeration unit (TRU) for cold mobile storage. TRU may be above the vehicle or contained.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Cargo_Van_ Roof_Mount_TRU.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Cargo_Van_Roof_Mount_TRU.png',
    },
  ],
  'Buses & Coaches': [
    {
      group: 'Buses & Coaches',
      type: 'School Bus',
      subtype: 'Type A',
      description:
        'Bus body constructed upon a cutaway front-section vehicle with a left driver’s door, designed to carry 10 or more people.\nDuty: Type A-1, with combined vehicle and payload weight of 10,000 pounds or less; Type A-2, with combined vehicle and payload weight of 10,000 pounds or more.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_A_School_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Type_A_School_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'School Bus',
      subtype: 'Type C',
      description:
        'Often called a “conventional” bus, this is the most common bus used by schools. \nDuty: Combined vehicle and payload weight more than 10,000 pounds, designed for carrying more than 10 persons.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_C_School_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Type_C_School_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'School Bus',
      subtype: 'Type D',
      description:
        'A "transit-style” school bus with a body installed upon a chassis without a cowled or curved front. Duty: Combined vehicle and payload weight of more than 10,000 pounds, designed for carrying more than 10 persons.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_D_School_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Type_D_School_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Shuttle Bus',
      subtype: 'Medium Duty',
      description:
        'Bus designed to carry at least 15 passengers over short distances, such as between hotels and airports.\nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Shuttle_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Shuttle_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Shuttle Bus',
      subtype: 'Passenger Van',
      description:
        'A large van used as a bus carrying fewer than 15 passengers over short distances, such as between hotels and airports.\nDuty: Class 1-4 (combined vehicle and maximum payload weight below 16,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Passenger_Van_Shuttle_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Passenger_Van_Shuttle_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Transit Bus',
      subtype: 'Medium Duty',
      description:
        'Bus designed to provide public transport, carrying passengers over shorter distances on a low-deck vehicle.\nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Transit_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Transit_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Transit Bus',
      subtype: 'Heavy Duty',
      description:
        'Bus designed to provide public transport, carrying passengers over shorter distances on a low-deck vehicle.\nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Mass_Transit_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Mass_Transit_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Transit Bus',
      subtype: 'Double Decker',
      description:
        'A mass transit bus with two storeys or decks for carrying passengers shorter distances.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Double_Decker_Transit_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Double_Decker_Transit_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Transit Bus',
      subtype: 'Articulated Bus',
      description:
        'Also called a tandem bus, this bus type featuures two rigid single-deck bus sections linked by a pivoting joint.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Articulated_Bus.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Articulated_Bus.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Motor Coach',
      subtype: 'Medium Duty',
      description:
        'A bus designed with an elevated passenger deck located over a baggage compartment.\nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Motor_Coach.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Motor_Coach.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Motor Coach',
      subtype: 'Heavy Duty',
      description:
        'A bus designed with an elevated passenger deck located over a baggage compartment.\nDuty: Class 7-8 (combined vehicle and payload weight above 26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Motor_Coach.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Motor_Coach.png',
    },
    {
      group: 'Buses & Coaches',
      type: 'Motor Coach',
      subtype: 'Double Decker',
      description:
        'A bus designed with a primary elevated passenger deck located over a baggage compartment, and a secondary deck of adittional storage and seating.\nDuty: Class 3-6 (combined vehicle and payload weight between 10,001 -  26,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Double_Decker_Motor_Coach.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Double_Decker_Motor_Coach.png',
    },
  ],
  Offroad: [
    {
      group: 'Offroad',
      type: 'Forklift',
      subtype: 'Low Capacity',
      description: 'Forklift with 4,000 - 6,000 lbs of capacity',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Forklift.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Light_Duty_Forklift.png',
    },
    {
      group: 'Offroad',
      type: 'Forklift',
      subtype: 'Medium Capacity',
      description: 'Forklift with 6,001 - 10,000 lbs of capacity',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Forklift.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Forklift.png',
    },
    {
      group: 'Offroad',
      type: 'Forklift',
      subtype: 'High Capacity',
      description: 'Forklift with 10,001 - 16,000 lbs of capacity',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Forklift.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Forklift.png',
    },
    {
      group: 'Offroad',
      type: 'Terminal Tractor',
      subtype: 'Heavy Duty',
      description:
        'A terminal tractor is a type of truck used to move semi-trailers as well as cargo containers over short distances. These tend to have a shorter wheelbase for maximum maneuverability.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Terminal_Tractor.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Terminal_Tractor.png',
    },
  ],
  'Chassis & Drive Systems': [
    {
      group: 'Chassis & Drive Systems',
      type: 'Cutaway Chassis',
      subtype: 'Light Duty',
      description:
        'An incomplete vehicle with a front end and cab design in which the body ends immediately behind the driver and front passenger seats.\nDuty: Class 1-2 (combined vehicle and maximum payload weight below 10,000 lbs).',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Cutaway_Chassis.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Light_Duty_Cutaway_Chassis.png',
    },
    {
      group: 'Chassis & Drive Systems',
      type: 'Cutaway Chassis',
      subtype: 'Medium Duty',
      description:
        'An incomplete vehicle with a front end and cab design in which the body ends immediately behind the driver and front passenger seats.\nDuty: Class 3-6, or gross vehicle weight rating (GVWR) of 10,001-26,000 lbs.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cutaway_Chassis.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_Cutaway_Chassis.png',
    },
    {
      group: 'Chassis & Drive Systems',
      type: 'Cutaway Chassis',
      subtype: 'Heavy Duty',
      description:
        'An incomplete vehicle with a front end and cab design in which the body ends immediately behind the driver and front passenger seats.\nDuty: Class 3-6, or gross vehicle weight rating (GVWR) of 10,001-26,000 lbs.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Cutaway_Chassis.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_Cutaway_Chassis.png',
    },
    {
      group: 'Chassis & Drive Systems',
      type: 'BEV Drive System',
      subtype: 'Medium Duty',
      description: 'A battery-electric powertrain packaged for use in medium-duty chassis systems.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_BEV_Drive_System.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Medium_Duty_BEV_Drive_System.png',
    },
    {
      group: 'Chassis & Drive Systems',
      type: 'BEV Drive System',
      subtype: 'Heavy Duty',
      description: 'A battery-electric powertrain packaged for use in heavy-duty chassis systems.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_BEV_Drive_System.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Heavy_Duty_BEV_Drive_System.png',
    },
  ],
  'Specialty Vehicles': [
    {
      group: 'Specialty Vehicles',
      type: 'Specialty',
      subtype: 'Mobile Outreach Vehicle',
      description:
        'Large vans, trailers, or campers that are customized to provide health care and harm reduction services in targeted communities.',
      imageLarge:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Mobile_Outreach_Vehicle.png',
      imageSmall:
        'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/thumbnails/Mobile_Outreach_Vehicle.png',
    },
  ],
};
