import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import OnboardingTitle from './OnboardingTitle';
import ProgressBar from './ProgressBar';
import OnboardingContent from './OnboardingContent';
import { useAnalytics } from '../../utils/useAnalytics';

export default function OnboardingLayout() {
  const { onboardingStep } = useInputs();
  const { GaTracker } = useAnalytics();

  useEffect(() => {
    GaTracker.trackEvent({
      category: 'Navigation',
      action: 'Onboarding progress',
      value: onboardingStep,
    });
  }, [onboardingStep]);

  return (
    <Grid container justifyContent="center" alignItems="center" mt="60px">
      <Grid item xs={7}>
        <ProgressBar currentStep={onboardingStep} />
      </Grid>
      <Grid item xs={12}>
        <OnboardingTitle />
      </Grid>
      <Grid item xs={12}>
        <OnboardingContent />
      </Grid>
    </Grid>
  );
}
