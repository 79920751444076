import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TabContent from '../components/TabContent/TabContent';
import Overview from '../Panels/Overview/Overview';
import Assessment from '../Panels/Assessment/Assessment';
import Vehicles from '../Panels/Vehicles/Vehicles';
import Fuel from '../Panels/Fuel/Fuel';
import Charge from '../Panels/Charge/Charge';
import Connect from '../Panels/Connect/Connect';
import TCO from '../Panels/Tco/Tco';
import Implement from '../Panels/Implement/Implement';
import { useDepletedBatterySites } from '../utils/useDepletedBatterySites';
import { InsufficientChargeBlocker } from '../components/insufficientChargeBlocker/InsufficientChargeBlocker';

export default function ContentPane() {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { depletedBatterySites } = useDepletedBatterySites();
  const showBatteryWarning = depletedBatterySites.length;

  const tabs = [
    {
      label: formatMessage({ id: 'tabs.overview' }),
      content: <Overview />,
    },
    {
      label: `1. ${formatMessage({ id: 'tabs.assessment' })}`,
      content: <Assessment />,
    },
    {
      label: `2. ${formatMessage({ id: 'tabs.vehicles' })}`,
      content: <Vehicles />,
    },
    {
      label: `3. ${formatMessage({ id: 'tabs.fuel' })}`,
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker sites={depletedBatterySites} />
      ) : (
        <Fuel />
      ),
    },
    {
      label: `4. ${formatMessage({ id: 'tabs.charge' })}`,
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker sites={depletedBatterySites} />
      ) : (
        <Charge />
      ),
    },
    {
      label: `5. ${formatMessage({ id: 'tabs.connect' })}`,
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker sites={depletedBatterySites} />
      ) : (
        <Connect />
      ),
    },
    {
      label: `6. ${formatMessage({ id: 'tabs.tco' })}`,
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker sites={depletedBatterySites} />
      ) : (
        <TCO />
      ),
    },
    {
      label: `7. ${formatMessage({ id: 'tabs.implement' })}`,
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker sites={depletedBatterySites} />
      ) : (
        <Implement />
      ),
    },
  ];

  return (
    <Box>
      <Grid container width="100%" justifyContent="center">
        <Box width="100%" maxWidth="1175px" padding="0 20px 20px 20px">
          {isLargeScreen ? (
            <TabContent tabList={tabs}></TabContent>
          ) : (
            <Box role="main" className="container">
              <Overview />
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
}
