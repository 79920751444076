import React from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import times from 'lodash/times';
import { useTheme } from '@material-ui/core';
import StyledBarGraph from '../Charts/StyledBarGraph/StyledBarGraph';
import StyledLineGraph from '../Charts/StyledLineGraph/StyledLineGraph';
import { formatHours } from '../../utils/formatters';
import { Box } from '@mui/material';
import VehicleSet from '../../calculations/VehicleSet';
import ChargingAssignmentsTable from '../ChargingAssignmentsTable/ChargingAssignmentsTable';
import VehicleSets from '../../calculations/VehicleSets';
import { useIntl } from 'react-intl';

const labels = times(24);

// sum multiple arrays by index. e.g. [[1, 2, 3], [10, 20, 30]] => [11, 22, 33]
const sumArrays = (arrays) =>
  arrays.reduce((sums, current) => sums.map((value, i) => value + current[i]));

export const DailyUseChart = ({ selectedSite }) => {
  const theme = useTheme();

  const groupedChargers = VehicleSets.groupedChargers(selectedSite.vehicleSets);

  const usages = groupedChargers.flatMap((charger) => {
    return charger.vehicles.map(({ usage }) => usage);
  });

  const dailyUsage = sumArrays(usages);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dailyUsage,
        backgroundColor: theme.palette.common.lightAqua,
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };

  const xTicks = {
    maxRotation: 35,
    minRotation: 35,
    min: 0,
    callback: function (value) {
      if (value % 2 === 0) {
        return formatHours(value);
      }
    },
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
  };

  const yTicks = {
    min: 0,
    max: Math.max(dailyUsage) + 1,
    stepSize: 1,
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
  };

  return (
    <Box maxWidth="1068px" width="100%">
      <StyledBarGraph
        data={data}
        yTicks={yTicks}
        xTicks={xTicks}
        showLegend={false}
        indexAxis={'x'}
        stacked={false}
        tooltipTitleFormatter={(data) => formatHours(data[0].dataIndex)}
        tooltipLabelFormatter={(data) => `${data.parsed.y} chargers`}
        large
        showHorizontalGrid
      />
    </Box>
  );
};

export const BatteryChargeChart = ({ selectedVehicle }) => {
  const { startYear, selectedSite } = useInputs();
  const theme = useTheme();

  const chargeSchedule = VehicleSet.averageWorkdayBatterySchedule(
    selectedSite.vehicleSets[selectedVehicle],
    startYear,
  );

  const xTicks = {
    maxRotation: 35,
    minRotation: 35,
    min: 0,
    callback: function (value) {
      if (value % 2 === 0) {
        return formatHours(value);
      }
    },
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
  };

  const yTicks = {
    min: 0,
    stepSize: 25,
    callback: function (value, index, values) {
      value = value.toString();
      return `${value}%`;
    },
    font: {
      size: 16,
    },
    padding: 10,
  };

  const chargeData = {
    labels,
    datasets: [
      {
        fill: true,
        label: selectedSite.vehicleSets[selectedVehicle].name,
        data: chargeSchedule,
        backgroundColor: theme.palette.common.lightAqua,
        pointRadius: 0,
      },
    ],
  };

  return (
    <Box>
      <StyledLineGraph
        data={chargeData}
        yTitle=""
        graphTitle=""
        showLegend
        showHorizontalGrid
        yTicks={yTicks}
        xTicks={xTicks}
      />
    </Box>
  );
};

export const ChargingTable = () => {
  const { sites } = useInputs();
  const { formatMessage } = useIntl();

  const rows = sites
    .map(({ vehicleSets, name }) => {
      return VehicleSets.groupedChargers(vehicleSets).map((charger) => [
        name,
        'Generic Charger',
        `${charger.minimumRequired}x`,
        formatMessage({ id: `chargerTypes.${charger.chargerType}` }),
        `${charger.charger.chargeRate}kW`,
        '',
        formatMessage({ id: `chargerPorts.${charger.charger.ports}` }),
      ]);
    })
    .flat();

  const tableData = {
    columns: ['Site', 'Charger', 'QTY', 'Power', 'Min', 'Type', 'Ports'],
    rows,
  };

  return (
    <Box>
      <ChargingAssignmentsTable tableData={tableData} />
    </Box>
  );
};
