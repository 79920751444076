import { useState, React } from 'react';
import { Box, Typography } from '@mui/material';
import Dropdown from '../../../components/inputs/Dropdown';
import { useIntl } from 'react-intl';
import StyledTable from '../../../components/StyledTable/StyledTable';
import { useInputs } from '@bellawatt/use-inputs';
import Site from '../../../calculations/Site';
import { times } from 'lodash';
import { formatAsThousands, formatAsDollars } from '../../../utils/formatters';
import Section from '../../../components/Section/Section';

export default function AnnualFuelCostsTable({ defaultYear }) {
  const { sites, dieselPrice, gasolinePrice, startYear } = useInputs();
  const [year, setYear] = useState(defaultYear);

  const { formatMessage } = useIntl();

  let totalCost = 0;
  const fuelCostsTableRows = sites.map((site) => {
    const annualFuelCosts = Site.getTotalAnnualFuelCosts(site, year, {
      dieselPrice,
      gasolinePrice,
    });
    totalCost += annualFuelCosts;

    const displayEfficiency = formatAsThousands(Site.getAnnualTotalMiles(site, year)) !== '0';

    return [
      `Current Fleet at ${site.name}`,
      Site.getTotalVehicles(site),
      displayEfficiency && Site.getAverageMPG(site).toFixed(0),
      formatAsThousands(Site.getAnnualTotalMiles(site, year)),
      formatAsDollars(annualFuelCosts),
    ];
  });

  fuelCostsTableRows.push(['Total', null, null, null, formatAsDollars(totalCost)]);

  const fuelCostsTable = {
    headings: [
      formatMessage({ id: 'panels.fuel.tableHeaders.fuelFleetBySite' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.fleetSize' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.averageEffeciency' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.annualMiles' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.totalAnnualCost' }),
    ],
    rows: fuelCostsTableRows,
  };

  let totalPhevCost = 0;
  const phevCostsTableRows = sites
    .map((site) => {
      const hasPhev = site.vehicleSets.some((set) => set.vehicle?.fuel === 'PHEV');
      if (hasPhev) {
        const { total, fossil, electric } = Site.getTotalAnnualElectricFleetCosts(site, year, {
          dieselPrice,
          gasolinePrice,
        });
        totalPhevCost += fossil;
        const displayEfficiency = formatAsThousands(Site.getAnnualTotalMiles(site, year)) !== '0';
        return [
          `Current Fleet at ${site.name}`,
          Site.getTotalVehicles(site),
          displayEfficiency && Site.getAverageMPGElectric(site).toFixed(0),
          formatAsThousands(Site.getAnnualTotalMiles(site, year)),
          formatAsDollars(fossil),
        ];
      } else {
        return null;
      }
    })
    .filter((row) => row !== null);

  phevCostsTableRows.push(['Total', null, null, null, formatAsDollars(totalPhevCost)]);

  const phevCostsTable = {
    headings: [
      formatMessage({ id: 'panels.fuel.tableHeaders.electricFleetBySitePhev' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.fleetSize' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.averageEffeciency' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.annualMiles' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.totalAnnualCost' }),
    ],
    rows: phevCostsTableRows,
  };

  let totalFuelCost = 0;
  const electricCostsTableRows = sites.map((site) => {
    const hasPhev = site.vehicleSets.some((set) => set.vehicle?.fuel === 'PHEV');
    const { total, fossil, electric } = Site.getTotalAnnualElectricFleetCosts(site, year, {
      dieselPrice,
      gasolinePrice,
    });
    totalFuelCost += total;

    // const isPhev = Site.vehicleSets
    return [
      `Current Fleet at ${site.name}`,
      Site.getTotalVehicles(site),
      formatAsThousands(Site.getAnnualTotalMiles(site)),
      hasPhev ? formatAsDollars(fossil) : Site.getAverageMPGElectric(site),
      hasPhev ? formatAsDollars(electric) : formatAsThousands(Site.getAnnualKwhUsage(site, year)),
      formatAsDollars(total),
    ];
  });

  electricCostsTableRows.push(['Total', null, null, null, null, formatAsDollars(totalFuelCost)]);

  const anySiteHasPhev = sites.some((site) =>
    site.vehicleSets.some((set) => set.vehicle?.fuel === 'PHEV'),
  );
  const electricCostsTable = {
    headings: [
      formatMessage({ id: 'panels.fuel.tableHeaders.electricFleetBySite' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.fleetSize' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.annualMiles' }),
      anySiteHasPhev
        ? formatMessage({ id: 'panels.fuel.tableHeaders.fossilCosts' })
        : formatMessage({ id: 'panels.fuel.tableHeaders.averageEffeciency' }),
      anySiteHasPhev
        ? formatMessage({ id: 'panels.fuel.tableHeaders.electricCosts' })
        : formatMessage({ id: 'panels.fuel.tableHeaders.energyUse' }),
      formatMessage({ id: 'panels.fuel.tableHeaders.totalAnnualCost' }),
    ],
    rows: electricCostsTableRows,
  };

  const yearOptions = times(10, (i) => ({
    value: startYear + i,
    children: startYear + i,
  }));

  return (
    <>
      <Section
        title={formatMessage({ id: 'panels.fuel.annualFuelCosts' })}
        description={formatMessage({ id: 'panels.fuel.summarizedFuelCosts' })}
        rightHeaderSlot={
          <Dropdown
            label={formatMessage({ id: 'panels.fuel.year' })}
            value={year}
            options={yearOptions}
            onChange={(e) => setYear(e.target.value)}
          />
        }
      >
        <Box sx={{ mt: '15px', ml: '50px' }}>
          <StyledTable
            headings={fuelCostsTable.headings}
            rows={fuelCostsTable.rows}
            styleName="primary"
            boldBottom={true}
          />
          {phevCostsTable.rows.length > 1 && (
            <StyledTable
              headings={phevCostsTable.headings}
              rows={phevCostsTable.rows}
              styleName="primary"
              boldBottom={true}
            />
          )}
          <Box sx={{ mt: '15px', mb: '15px' }}>
            <Typography align="left" fontSize="16px">
              {formatMessage({ id: 'panels.fuel.fuelPriceSource' })}
              <a
                href={formatMessage({ id: 'panels.fuel.fuelPriceSourceLinkURL' })}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: 'panels.fuel.fuelPriceSourceLinkText' })}
              </a>
              <br />
              {formatMessage({ id: 'panels.fuel.changeGasPricesInfo' })}
            </Typography>
          </Box>
        </Box>
      </Section>
      <Section
        title={formatMessage({ id: 'panels.fuel.annualElectricityCosts' })}
        description={formatMessage({ id: 'panels.fuel.summarizedElectricityCosts' })}
      >
        <Box sx={{ mt: '60px', ml: '50px' }}>
          <StyledTable
            headings={electricCostsTable.headings}
            rows={electricCostsTable.rows}
            styleName="primary"
            boldBottom={true}
          />
          <Box sx={{ mt: '15px', mb: '15px' }}>
            <Typography align="left" fontSize="16px">
              {formatMessage({ id: 'panels.fuel.electricPriceSource' })}
              <a
                href={formatMessage({ id: 'panels.fuel.electricPriceSourceLinkURL' })}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: 'panels.fuel.electricPriceSourceLinkText' })}
              </a>
              <br />
            </Typography>
          </Box>
        </Box>
      </Section>
    </>
  );
}
