import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@material-ui/core';

const Loading = () => (
  <Box
    sx={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress size={100} />
  </Box>
);

export default Loading;
