import React, { createContext } from 'react';
import useEvaluatedApiIncentives from './useEvaluatedApiIncentives';

export const IncentivesContext = createContext();

export default function Context({ children }) {
  const incentivesData = useEvaluatedApiIncentives();

  return <IncentivesContext.Provider value={incentivesData}>{children}</IncentivesContext.Provider>;
}
