import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@material-ui/core';
import { useInputs } from '@bellawatt/use-inputs';
import { useIntl } from 'react-intl';

export default function OnboardingTitle() {
  const { onboardingStep } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const stepNumberTitle =
    onboardingStep !== 'recommend' ? 'panels.assessment.title' : 'panels.vehicles.title';
  const stepNumberSummary =
    onboardingStep !== 'recommend' ? 'editSites.title' : 'panels.vehicles.evRecommendation';

  return (
    <Grid container justifyContent="center" spacing={0} direction="row" mt="40px">
      <Grid item xs={12}>
        <Box color={theme.palette.common.DTEPrimary} fontSize="18px" fontWeight="600">
          {formatMessage({ id: stepNumberTitle }).toUpperCase()}
        </Box>
      </Grid>
      <Grid item xs={7} justifyContent="center" width="100%">
        <Box color={theme.palette.common.DTEPrimary} fontSize="32px" fontWeight="600">
          {formatMessage({ id: stepNumberSummary })}
        </Box>
        <Box color={theme.palette.common.secondaryGrey} fontSize="16px">
          {formatMessage({ id: `onboardingSteps.${onboardingStep}.summary` })}
        </Box>
      </Grid>
    </Grid>
  );
}
