import React from 'react';
import { Box, Grid, useTheme } from '@material-ui/core';
import { steps } from '../../data/onboardingSteps';

export default function ProgressBar({ currentStep }) {
  const theme = useTheme();

  return (
    <Grid container spacing={4} height="10px" justifyContent="center">
      {steps.map((step) => {
        return (
          <Grid item xs={2} key={step}>
            <Box
              height="8px"
              sx={{
                backgroundColor:
                  step === currentStep
                    ? theme.palette.common.brandBlue
                    : theme.palette.common.grey300,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
