import React, { useEffect, useState } from 'react';
import './Carousel.scss';
import { CarretLeft } from '../../../../assets/icons/icons';
import CarouselItem from './CarouselItem';
import Modal from '../Modal/Modal';

const Carousel = ({ vehicleCount, handleChangeVehicleSet, set }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [newItemValues, setNewItemValues] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const newItems = [...set.customIncentives, { isCustom: true }];

  const goToIndex = (index) => {
    setActiveIndex(index);
  };

  const goToNext = () => {
    if (activeIndex < newItems.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const goToPrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleDelete = (item) => {
    const keyItem = item.Description + item.Value;
    const newVehicleSet = {
      ...set,
      customIncentives: set.customIncentives.filter((i) => i.Description + i.Value !== keyItem),
    };
    handleChangeVehicleSet(newVehicleSet);
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setSelectedItem(item);
    setShowModal(true);
  };

  const extraItem = {
    id: 'extra',
    number: set.customIncentives.map((i) => i.number).join(', '),
    canEdit: false,
    canDelete: false,
    setNewItemValues,
  };

  const modalAdd = (data) => {
    if (isEditing) {
      const keyItem = selectedItem.Description + selectedItem.Value;
      const newVehicleSet = {
        ...set,
        customIncentives: set.customIncentives.map((insentive) =>
          insentive.Description + insentive.Value === keyItem
            ? {
                ...insentive,
                ...data,
              }
            : insentive,
        ),
      };

      handleChangeVehicleSet(newVehicleSet);
      setIsEditing(false);
      setSelectedItem({});
    } else {
      const newVehicleSet = { ...set, customIncentives: [...set.customIncentives, data] };
      handleChangeVehicleSet(newVehicleSet);
    }
  };

  useEffect(() => {
    if (Object.keys(newItemValues).length > 0) {
      setNewItemValues([]);
    }
  }, [newItemValues]);

  return (
    <>
      <div className="carousel">
        <div className="carousel__slides" style={{ left: `-${activeIndex * 102}%` }}>
          {set.customIncentives.concat(extraItem).map((item, index) => (
            <div key={index} className={`carousel__slide ${activeIndex === index ? 'active' : ''}`}>
              <CarouselItem
                item={item}
                onDelete={handleDelete}
                onEdit={handleEdit}
                allItems={set.customIncentives}
                setShowModal={setShowModal}
                vehicleCount={vehicleCount}
              />
            </div>
          ))}
        </div>
        <button className="carousel__arrow carousel__arrow--prev" onClick={goToPrev}>
          <span className="carousel__arrow__icon">
            {' '}
            <CarretLeft />{' '}
          </span>
        </button>
        <button className="carousel__arrow carousel__arrow--next" onClick={goToNext}>
          <span className="carousel__arrow__icon">
            {' '}
            <CarretLeft color="#1E3575" />{' '}
          </span>
        </button>
        <div className="carousel__dots">
          {newItems.map((item, index) => (
            <span
              key={index}
              className={`carousel__dots__dot ${activeIndex === index ? 'active' : ''}`}
              onClick={() => goToIndex(index)}
            />
          ))}
        </div>
      </div>
      {showModal && (
        <Modal
          title={isEditing ? 'Edit Custom Incentive' : 'Add Custom Incentive'}
          onClose={() => setShowModal(false)}
          handle={modalAdd}
          isEditing={isEditing}
          item={selectedItem}
          setIsEditing={() => setIsEditing()}
          setSelectedItem={() => setSelectedItem()}
        />
      )}
    </>
  );
};

export default Carousel;
