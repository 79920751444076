import { useInputs } from '@bellawatt/use-inputs';
import { useState, useEffect } from 'react';
import { post } from './api';
import VehicleSets from '../calculations/VehicleSets';

const FEDERAL_TAX_CREDIT_HANDLE = 'federal_pev_tax_credit';
const DTE_HANDLE = 'dte_charging_forward_efleets';

const getPayload = (site) => {
  const vehicleSets = site.vehicleSets;
  const chargers = VehicleSets.groupedChargers(vehicleSets);

  const isTransitParkingFacility = vehicleSets.some(
    ({ vehicle }) => vehicle?.formFactor === 'transit_bus',
  );

  return {
    project: {
      project_type: 'fleets',
      locationEnum: isTransitParkingFacility ? 'transit_parking_facility' : 'workplace_facility',

      organization: {},

      site: {
        address: {
          postcode: 48014,
          region: 'MI',
          power_supplier: {
            name: 'DTE',
          },
        },
        in_a_dac: false,
      },

      chargers: chargers.map((charger) => ({
        count: charger.minimumRequired,
        identifier: charger.type,
        is_open_to_public: false,
        is_networked: true,
        details: {
          type: 'charger',
          is_energy_star_certified: true,
          purchase_price: charger.estimatedCost,
          charger_power_level: charger.portKw,
          number_of_connections: charger.ports,
          charger_type: charger.chargerType,
        },
      })),

      vehicles: vehicleSets.map(({ vehicle, fossilVehicle, vehicleCount }) => ({
        count: vehicleCount,
        type: 'vehicle',
        details: vehicle
          ? {
              type: 'vehicle',
              subtype: vehicle.subtype,
              make: vehicle.make || 'Custom',
              model: vehicle.model,
              trim: vehicle.trim,
              fuel: vehicle.fuel || 'bev',
              weight_class: vehicle.weightClass,
              model_year: vehicle.year,
              vehicle_type: vehicle.type,
              form_factor: vehicle.formFactor,
              msrp: vehicle.msrp,
              purchase_price: vehicle.msrp,
              lift_capacity: vehicle.liftCapacity,
              battery_capacity: vehicle.batteryCapacityInKwh,
              replaces_ice_vehicle: true,
              identifier: vehicle.id,
              lease_length: null,
              eligible_for_ca_rebate: false,
            }
          : {
              type: 'vehicle',
              subtype: fossilVehicle.subtype,
              make: 'Custom',
              model: 'Custom',
              trim: 'Custom',
              fuel: 'bev',
              weight_class: fossilVehicle.weightClass,
              model_year: new Date(Date.now()).getYear(),
              vehicle_type: fossilVehicle.type,
              form_factor: fossilVehicle.formFactor,
              msrp: fossilVehicle.msrp,
              purchase_price: fossilVehicle.msrp,
              lift_capacity: fossilVehicle.liftCapacity,
              battery_capacity: fossilVehicle.batteryCapacityInKwh,
              replaces_ice_vehicle: true,
              identifier: fossilVehicle.id,
              lease_length: null,
              eligible_for_ca_rebate: false,
            },
      })),
    },
  };
};

/**
 * @typedef {object} EvaluatedIncentivesData
 * @property {boolean} loading
 * @property {boolean} error
 * @property {Array<Incentive>} evaluatedIncentives
 */

/**
 * @returns EvaluatedIncentivesData
 */
export default function useEvaluatedApiIncentives() {
  const { loading: inputsLoading, ...restOfInputs } = useInputs();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  /** @type {[Array<EvaluatedIncentivesData>, Function]} */
  const [evaluatedIncentives, setEvaluatedIncentives] = useState([]);

  const { selectedSite, isAllSites, sites } = restOfInputs;
  const payloadSites = isAllSites ? sites : [selectedSite];
  const payloads = inputsLoading ? [] : payloadSites.map(getPayload);

  useEffect(() => {
    if (inputsLoading) return;

    setLoading(true);

    // use /project API for each site in view (either single site, or all sites)
    Promise.all(payloads.map((payload) => post('/project', payload)))
      .then((responses) => {
        const incentives = responses.map((res) =>
          // filter for just the federal tax credit
          res.data.evaluations.incentives.filter(({ handle }) => handle === DTE_HANDLE),
        );
        setEvaluatedIncentives(incentives);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(payloads)]);

  return { loading, error, evaluatedIncentives };
}
