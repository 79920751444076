import React from 'react';
import { Button, Link, useTheme } from '@mui/material';

export default function ButtonInput({
  onClick,
  alert,
  variant = 'contained',
  dark,
  children,
  href,
  className,
  fullWidth,
  newTab,
}) {
  const theme = useTheme();

  const styles = {
    base: {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      fontWeight: '600',
      borderRadius: '0',
      textTransform: 'none',
      fontSize: '18px',
      boxShadow: 'unset',
      '&:hover': {
        boxShadow: 'unset',
      },
    },
    contained: {
      color: 'white',
      backgroundColor: theme.palette.common.accentBlue,
      outline: '1px solid white',
      '&:hover': {
        backgroundColor: theme.palette.common.brandBlue,
        color: 'white',
        boxShadow: 'unset',
      },
      '&:disabled': {
        backgroundColor: theme.palette.common.grey500,
        color: 'white',
      },
    },
    containedDark: {
      color: 'white',
      backgroundColor: theme.palette.common.brandBlue,
      outline: '1px solid white',
      '&:hover': {
        backgroundColor: theme.palette.common.accentBlue,
        color: 'white',
        boxShadow: 'unset',
      },
      '&:disabled': {
        backgroundColor: theme.palette.common.grey500,
        color: 'white',
      },
    },
    outlined: {
      backgroundColor: 'white',
      color: theme.palette.common.accentBlue,
      borderColor: theme.palette.common.accentBlue,
      '&:hover': {
        backgroundColor: theme.palette.common.brandBlue,
        borderColor: theme.palette.common.brandBlue,
        color: 'white',
        boxShadow: 'unset',
      },
      '&:disabled': {
        backgroundColor: theme.palette.common.grey200,
        borderColor: theme.palette.common.grey200,
        color: theme.palette.common.grey500,
      },
    },
    outlinedDark: {
      backgroundColor: 'white',
      color: theme.palette.common.grey500,
      borderColor: theme.palette.common.grey500,
      '&:hover': {
        backgroundColor: theme.palette.common.accentBlue,
        borderColor: theme.palette.common.accentBlue,
        color: 'white',
        boxShadow: 'unset',
      },
      '&:disabled': {
        backgroundColor: theme.palette.common.grey200,
        borderColor: theme.palette.common.grey200,
        color: theme.palette.common.grey500,
      },
    },
    link: {
      fontSize: '16px',
      textDecoration: 'underline',
      padding: '12px',
      color: alert ? theme.palette.common.errorBorder : theme.palette.common.accentBlue,
      fill: alert ? theme.palette.common.errorBorder : theme.palette.common.accentBlue,
    },
  };

  if (variant === 'link') {
    return (
      <Link
        component={href ? 'a' : 'button'}
        onClick={onClick}
        style={{
          ...styles.base,
          ...styles[variant],
        }}
        href={href}
        {...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        {children}
      </Link>
    );
  }

  return (
    <Button
      variant={variant}
      fullWidth={fullWidth}
      onClick={onClick}
      href={href}
      sx={{
        ...styles.base,
        height: '40px',
        lineHeight: 1,
        overflow: 'hidden',
        padding: '0 40px',
        ...(dark ? styles[`${variant}Dark`] : styles[variant]),
      }}
      className={className}
    >
      {children}
    </Button>
  );
}
