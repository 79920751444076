export const formatAsThousands = (val) =>
  Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Formats number to USD with dollar sign and commas: $xx,xxx,xxx
 * @param {number} val
 * @returns {string}
 */
export const formatAsDollars = (val) => {
  if (typeof val === 'number') {
    return `$${formatAsThousands(val)}`;
  } else if (typeof val === 'string') {
    return `$${formatAsThousands(parseInt(val.replace(/[^.\d]/g, '')) || '')}`;
  }
  return '';
};

export const formatAsCents = (val) => {
  if (typeof val === 'number') {
    return `$${val.toFixed(2)}`;
  } else if (typeof val === 'string') {
    return `$${parseFloat(val.replace(/[^.\d]/g, '')).toFixed(2) || ''}`;
  }
  return '';
};

export const formatHours = (hours24) => {
  const hours = ((hours24 + 11) % 12) + 1;
  const amPm = hours24 > 11 ? ' p.m.' : ' a.m.';
  return hours + amPm;
};

export const titleCase = (string) => {
  if (!string) return '';
  const str = string.toString();

  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const formatVehicleName = ({ make, model, type, subtype }) => {
  const name = make ? `${make} ${model}` : `${type} (${subtype})`;
  return titleCase(name).replaceAll('_', ' ');
};

export const formatVehicleSetTitle = ({ vehicleCount, vehicle }) =>
  `${vehicleCount}x ${formatVehicleName(vehicle)}`;

/**
 * Particularly useful for onChange of `<input type="number"/>` which allows `e` and `.`.
 * This function in the `onChange` of the input will prevent those characters and round to the nearest whole numbers
 * @param {string} value
 * @returns number
 */
export const stringToInt = (value) => {
  return Math.round(stringToFloat(value));
};

/**
 * Particularly useful for onChange of `<input type="number"/>` which allows `e`.
 * This function in the `onChange` of the input will prevent exponents
 * @param {string} value
 * @param {number} precision How many digits past the decimal point the float should go
 * @returns number
 */
export const stringToFloat = (value) => {
  if (value) {
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
      return parseFloat(value.replace(/[^.0-9]/g, '')) || 0;
    }
  }
  return 0;
};
