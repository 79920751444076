import React, { useState } from 'react';
import PanelIntro from '../PanelIntro/PanelIntro';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import Dropdown from '../../components/inputs/Dropdown';
import InfoCard from '../InfoCard/InfoCard';
import TimelineChart from '../Charts/TimelineChart/TimelineChart';
import { useInputs } from '@bellawatt/use-inputs';
import StepList from './StepList';
import Site from '../../calculations/Site';
import ButtonInput from '../../components/inputs/ButtonInput';
import Section from '../../components/Section/Section';

export default function Implement() {
  const { formatMessage } = useIntl();
  const { sites, isAllSites, selectedSite, startYear } = useInputs();
  const [dropDownValue, setDropDownValue] = useState(0);

  const selectSite = (ev) => {
    setDropDownValue(ev.target.value);
  };

  const site = isAllSites ? sites[dropDownValue] : selectedSite;
  const exceedsLimit = Site.maxDemand(site, startYear + 9) > 200;

  return (
    <Box>
      <PanelIntro title="panels.implement.title" summary="panels.implement.summary" />
      <Section
        title={formatMessage({ id: 'panels.implement.projectImplementation' })}
        description={formatMessage({ id: 'panels.implement.projectSummary' })}
      >
        <Box sx={{ textAlign: 'left', mt: '40px', mb: '40px' }}>
          <a
            style={{ textDecoration: 'none' }}
            href="http://dte-efleets.powerclerk.com"
            target="_blank"
            rel="noreferrer"
          >
            <ButtonInput variant="outlined">
              {formatMessage({ id: 'panels.implement.apply' })}
            </ButtonInput>
          </a>
        </Box>
      </Section>
      <Section
        title={formatMessage({ id: 'panels.implement.timeline' })}
        rightHeaderSlot={
          isAllSites && (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <Dropdown
                label={formatMessage({ id: 'panels.implement.selectSite' }).toUpperCase()}
                onChange={selectSite}
                options={sites.map(({ id, name }) => ({
                  value: id,
                  children: name,
                }))}
                value={dropDownValue}
              />
            </Box>
          )
        }
      >
        <TimelineChart selectedSite={dropDownValue} />
        {exceedsLimit && (
          <Box pt="40px">
            <InfoCard
              variant="warning"
              message={formatMessage(
                { id: 'panels.implement.typicalConstraint' },
                { siteExceeds: <b>{formatMessage({ id: 'panels.connect.siteExceeds' })}</b> },
              )}
            />
          </Box>
        )}
      </Section>
      <StepList />
    </Box>
  );
}
