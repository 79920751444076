import React from 'react';
import { Box } from '@mui/material';
import { ChargeWarningIcon, SuccessIcon, CircleXIcon, InfoIcon } from '../../assets/icons/icons';

const icons = {
  warning: <ChargeWarningIcon />,
  blocker: <ChargeWarningIcon />,
  error: <CircleXIcon />,
  info: <InfoIcon />,
  success: <SuccessIcon />,
  default: <SuccessIcon />,
};

export default function InfoCard({ message, variant }) {
  const styleObj = {
    borderRadius: '5px',
    maxWidth: '100%',
    px: '27px',
    py: '20px',
    lineHeight: '22px',
    display: 'flex',
    minHeight: '87px',
    border: '2px solid',
    borderColor: (theme) => theme.palette.common[`${variant}Border`],
    background: (theme) => theme.palette.common[variant],
    color: (theme) => theme.palette.common.DTEPrimary,
    ...(variant === 'blocker'
      ? {
          borderColor: (theme) => theme.palette.common.errorBorder,
          background: (theme) => theme.palette.common.error,
          '& svg': {
            stroke: (theme) => theme.palette.common.errorBorder,
          },
        }
      : {}),
    ...(variant === 'info'
      ? {
          borderColor: (theme) => theme.palette.common.warningBorder,
          background: (theme) => theme.palette.common.warning,
          '& svg': {
            stroke: 'none',
            fill: (theme) => theme.palette.common.warningBorder,
          },
        }
      : {}),
  };

  return (
    <Box sx={styleObj} alignItems="center">
      <Box>{icons[variant] || icons.default}</Box>
      <Box sx={{ ml: '12px', justifyContent: 'stretch', textAlign: 'left' }}>
        <Box>{message}</Box>
      </Box>
    </Box>
  );
}
