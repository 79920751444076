import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import cloneDeep from 'lodash/cloneDeep';
import ModalButtonRow from '../ModalButtonRow/ModalButtonRow';
import { CloseIcon } from '../../assets/icons/icons';
import SiteInfo from './SiteInfo';
import ButtonInput from '../../components/inputs/ButtonInput';
import { omitComputedData } from '../../utils/computedData';

export default function EditSiteDialog({ isOpen, onClose, siteId, canRemove }) {
  const { formatMessage } = useIntl();
  const { sites, setInput } = useInputs();
  const [workingSite, setWorkingSite] = useState(sites.find((s) => s.id === siteId));
  const handleSiteChange = (value, key) => {
    setWorkingSite({ ...workingSite, [key]: value });
  };

  const handleSave = () => {
    const newSites = cloneDeep(sites);
    newSites[siteId] = workingSite;
    setInput({ sites: omitComputedData(newSites) });
    onClose();
  };

  const onDelete = () => {
    const newSites = cloneDeep(sites);

    newSites.splice(siteId, 1);
    setInput({ selectedSite: 'all' });
    setInput({ sites: omitComputedData(newSites) });
    onClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="lg">
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '28px' }}>
          {formatMessage({ id: 'panels.assessment.editSite' })}
          <ButtonInput variant="link" onClick={onClose}>
            <span aria-label={formatMessage({ id: 'editVehicleSets.cancel' })} />
            <CloseIcon />
          </ButtonInput>
        </Box>
      </DialogTitle>
      <DialogContent>
        <SiteInfo site={workingSite} handleChange={handleSiteChange} />
        <ModalButtonRow
          onClose={onClose}
          onDelete={onDelete}
          showDelete={canRemove}
          onSaveAndClose={handleSave}
        />
      </DialogContent>
    </Dialog>
  );
}
