const chargerRebates = [
  { handle: 'Blink_IQ_200_Advanced', rebate: 2500 },
  { handle: 'Blink_IQ_200_Smart', rebate: 2500 },
  { handle: 'ChargePoint_CPF50_Single_Pedestal', rebate: 2500 },
  { handle: 'Chargepoint_CPH50_Single', rebate: 2500 },
  { handle: 'Chargepoint_CP6000_Single', rebate: 2500 },
  { handle: 'Leviton_Evr-Green_4000_Single_Port_Pedestal', rebate: 2500 },
  { handle: 'Leviton_Evr-Green_4000_Single_Port_Pedestal', rebate: 2500 },
  { handle: 'Enel_X_JuiceBox_Pro_48_Hardwire', rebate: 2500 },
  { handle: 'BTC_Power_L2-30-Wall_Single', rebate: 2500 },
  { handle: 'BTC_Power_L2-30-Pedestal_Single', rebate: 2500 },
  { handle: 'BTC_Power_L2-30-Wall_Dual', rebate: 2500 },
  { handle: 'BTC_Power_L2-30-Pedestal_Dual', rebate: 2500 },
  { handle: 'BTC_Power_L2-40-Wall_Single', rebate: 2500 },
  { handle: 'BTC_Power_L2-40-Pedestal_Single', rebate: 2500 },
  { handle: 'BTC_Power_L2-40-Wall_Dual', rebate: 2500 },
  { handle: 'BTC_Power_L2-40-Pedestal_Dual', rebate: 2500 },
  { handle: 'BTC_Power_L2-70-Wall_Single', rebate: 2500 },
  { handle: 'BTC_Power_L2-70-Pedestal_Single', rebate: 2500 },
  { handle: 'CMI_3703_Chargeworks_30A_Wall_Single', rebate: 2500 },
  { handle: 'EvoCharge_iEVSE Plus_25_ft_Commercial_Wall', rebate: 2500 },
  { handle: 'Mid-Cour_AW32A_Hardwired', rebate: 2500 },
  { handle: 'Mid-Cour_AX48A', rebate: 2500 },
  { handle: 'Mid-Cour_Phihong_AX80A', rebate: 2500 },
  { handle: 'PowerCharge_Energy_', rebate: 2500 },
  { handle: 'Powercharge_Pro_Lightning', rebate: 2500 },
  { handle: 'Powercharge_Pro_Series', rebate: 2500 },
  { handle: 'Flo_Core+_Single', rebate: 2500 },
  { handle: 'Flo_Core+_Max', rebate: 2500 },
  { handle: 'Flo_SmartTWO_30a', rebate: 2500 },
  { handle: 'Ford_Charge_Station_Pro_48a', rebate: 2500 },
  { handle: 'Ford_Charge_Station_Pro_80a', rebate: 2500 },
  { handle: 'Wallbox_Pulsar_Plus', rebate: 2500 },
  { handle: 'Autel_MaxiCharger_AC_Commercial', rebate: 2500 },
  { handle: 'Autel_MaxiCharger_Floor', rebate: 2500 },
  { handle: 'Tellus_Power_Green_60kW_DC_Fast_Charger_Dual', rebate: 18000 },
  { handle: 'Tellus_Power_Green_120kW_DC_Fast_Charger_Dual', rebate: 30000 },
  { handle: 'Tellus_Power_Green_160kW_DC_Fast_Charger_Dual', rebate: 55000 },
  { handle: 'Tellus_Power_Green_180kW_DC_Fast_Charger_Dual', rebate: 55000 },
  { handle: 'Tellus_Power_Green_300kW_DC_Fast_Charger_Dual', rebate: 70000 },
  { handle: 'Tellus_Power_Green_360kW_DC_Fast_Charger_Dual', rebate: 70000 },
  { handle: 'ChargePoint_CPE250C_625_CCS1_CHD_Dual', rebate: 25000 },
  { handle: 'ChargePoint_CPE250C_625_CCS1_CHD_Single', rebate: 55000 },
  { handle: 'ChargePoint_EXPP', rebate: 70000 },
  { handle: 'Tritium_RT_50', rebate: 18000 },
  { handle: 'Tritium_RTM75', rebate: 25000 },
  { handle: 'Enel_X_JuicePump_175_kw_Dual', rebate: 55000 },
  { handle: 'Enel_X_JuicePump_100', rebate: 30000 },
  { handle: 'Enel_X_JuicePump_150_kw', rebate: 55000 },
  { handle: 'Enel_X_JuicePump_200', rebate: 70000 },
  { handle: 'Enel_X_JuicePump_350', rebate: 70000 },
  { handle: 'ABB_Terra_24_DC_Wallbox', rebate: 12000 },
  { handle: 'ABB_Terra_54_HV', rebate: 18000 },
  { handle: 'ABB_Terra_94_Single_Port', rebate: 25000 },
  { handle: 'ABB_Terra_124_Dual_Port', rebate: 30000 },
  { handle: 'ABB_Terra_HP_175', rebate: 55000 },
  { handle: 'ABB_Terra_184_Dual_Port', rebate: 55000 },
  { handle: 'ABB_Terra_HP_350', rebate: 70000 },
  { handle: 'BTC_Power_L4T_100_480_2_Engine', rebate: 70000 },
  { handle: 'BTC_Power_L4T_150_480_3_Engine', rebate: 55000 },
  { handle: 'BTC_Power_L4T_200_480_4_Engine', rebate: 70000 },
  { handle: 'BTC_Power_L4D_350_Dispenser_CCS1_CHAdeMO', rebate: 70000 },
  { handle: 'FreeWire_Boost_Charger_160', rebate: 55000 },
  { handle: 'Phihong_DS60_Series_UL', rebate: 18000 },
  { handle: 'Phihong_DS120_Series_UL', rebate: 30000 },
  { handle: 'Phihong_DS180_Series_UL', rebate: 55000 },
  { handle: 'Rhombus_RES-DCVC60-480', rebate: 18000 },
  { handle: 'Rhombus_RES-DCVC60-480_V2G', rebate: 18000 },
  { handle: 'Rhombus_RES-DCVC125-480', rebate: 30000 },
  { handle: 'Rhombus_RES-DCVC125-480_V2G', rebate: 30000 },
  { handle: 'Tritium_RTM50', rebate: 18000 },
  { handle: 'Tritium_RTM75', rebate: 25000 },
  { handle: 'Tritium_RT175-S_Single', rebate: 55000 },
  { handle: 'Tritium_PKM_150', rebate: 55000 },
  { handle: 'Phihong_DS60_Series_UL', rebate: 18000 },
  { handle: 'Phihong_DS120_Series_UL', rebate: 30000 },
  { handle: 'Phihong_DS180_Series_UL', rebate: 55000 },
  { handle: 'Flo_SmartDC_50KW', rebate: 18000 },
  { handle: 'Flo_SmartDC_100KW', rebate: 30000 },
  { handle: 'Ford_Pro_DC_60kw_Single', rebate: 18000 },
  { handle: 'Ford_Pro_DC_120kw_Single', rebate: 30000 },
  { handle: 'Ford_Pro_DC_60kw_Cabinet', rebate: 18000 },
  { handle: 'Ford_Pro_DC_120kw_Cabinet', rebate: 30000 },
  { handle: 'Ford_Pro_DC_150kw_Cabinet', rebate: 55000 },
  { handle: 'Ford_Pro_DC_180kw_Cabinet', rebate: 55000 },
  { handle: 'Ford_Pro_DC_180kW_Dispenser_200A', rebate: 55000 },
  { handle: 'Ford_Pro_DC_180kW_Dispenser_300A', rebate: 55000 },
  { handle: 'Tellus_Power_Green_TP-EVPD-160KW', rebate: 55000 },
  { handle: 'Tellus_Power_Green_TP-EVPD-300KW', rebate: 70000 },
  { handle: 'BTC_Power_EVPC_150', rebate: 55000 },
  { handle: 'BTC_MODULAR_HPC_SYSTEM_180_KW', rebate: 55000 },
  { handle: 'BTC_MODULAR_HPC_SYSTEM_360_KW', rebate: 70000 },
  { handle: 'ADS_Tec_HPC_Booster', rebate: 70000 },
  { handle: 'Tritium_PKM_150', rebate: 55000 },
  { handle: 'Tritium_PKM_175', rebate: 55000 },
  { handle: 'Autel_MaxiCharger_DC_Fast', rebate: 70000 },
];
export default chargerRebates;
