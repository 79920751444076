import { findArchetypeById } from '../data/VEHICLES';
import CHARGERS from '../data/CHARGERS';
import { transformArchetypeToFossilVehicle } from '../utils/conversions';
/**
 *
 * @param {Site} site
 * @param {Array<Vehicle>} apiElectricVehicles
 * @param {Array<Vehicle>} apiFossilVehicles
 * @param {Array<Charger>} chargers
 * @returns
 */
const addVehiclesToSite = (site, apiElectricVehicles, apiFossilVehicles, apiChargers) => {
  if (site === undefined) {
    return;
  }
  const { vehicleSets } = site;

  const computedVehicleSets = vehicleSets.map((vehicleSet) => {
    // We don't want to include the entire vehicle object in the stored vehicle set,
    // so we just keep its ID, and do a lookup of the full vehicle whenever accessing the vehicle set.
    const vehicle = apiElectricVehicles.find((v) => v.id === vehicleSet.vehicle_id);
    const fossilVehicle = {
      ...(vehicleSet.fossilVehicle ||
        (vehicleSet.fossilVehicleId &&
          apiFossilVehicles.find((v) => v.id === vehicleSet.fossilVehicleId)) ||
        transformArchetypeToFossilVehicle(
          findArchetypeById(vehicleSet.fossilVehicleId) ||
            findArchetypeById(vehicleSet.archetype_id) ||
            {},
        )),
      ...Object.keys(vehicleSet.customFossil || {}).reduce((acc, key) => {
        if (vehicleSet.customFossil[key]) {
          return {
            ...acc,
            [key]: vehicleSet.customFossil[key],
          };
        }
        return acc;
      }, {}),
    };

    // Same idea for the full charger object, which is part of each of the charging windows array
    const chargingWindows = vehicleSet.chargingWindows.map((chargingWindow) => ({
      ...chargingWindow,
      charger: chargingWindow.isGeneric
        ? CHARGERS.find(({ type }) => type === chargingWindow.type)
        : apiChargers.find(({ id }) => id === chargingWindow.id),
    }));

    return {
      ...vehicleSet,
      chargingWindows,
      fossilVehicle,
      ...(vehicle ? { vehicle } : {}),
    };
  });

  return {
    ...site,
    vehicleSets: computedVehicleSets,
  };
};

const computed = {
  // only store the selected site ID, return the actual site object
  selectedSite: ({ selectedSite, sites, apiElectricVehicles, apiFossilVehicles, apiChargers }) =>
    selectedSite === 'all'
      ? { id: 'all' }
      : addVehiclesToSite(
          sites.find(({ id }) => selectedSite === id),
          apiElectricVehicles,
          apiFossilVehicles,
          apiChargers,
        ),
  sites: ({ sites, apiElectricVehicles, apiFossilVehicles, apiChargers }) =>
    sites.map((site) =>
      addVehiclesToSite(site, apiElectricVehicles, apiFossilVehicles, apiChargers),
    ),
  isAllSites: ({ selectedSite }) => selectedSite === 'all',
};

export default computed;
