import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SiteIcon, PencilIcon } from '../../assets/icons/icons';
import { useIntl } from 'react-intl';
import EditSiteDialog from '../EditSiteDialog/EditSiteDialog';
import { useInputs } from '@bellawatt/use-inputs';
import ButtonInput from '../../components/inputs/ButtonInput';

const style = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: '6px',
  },
};

export default function SiteCard({ site }) {
  const { formatMessage } = useIntl();
  const [openEdit, setOpenEdit] = useState(false);
  const { sites } = useInputs();
  const canRemove = sites.length > 1;

  return (
    <Box>
      <Box sx={style.flexRow}>
        <Box>
          <SiteIcon height={50} width={50} />
        </Box>
        {(site.address || site.zipCode) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '140px' }}>
            {site.address && (
              <Box sx={style.flexCol}>
                <Box sx={{ color: 'common.secondaryGrey' }}>
                  {formatMessage({ id: 'panels.assessment.street' }).toUpperCase()}
                </Box>
                <Box fontWeight="600">{site.address}</Box>
              </Box>
            )}
            {site.zipCode && (
              <Box sx={style.flexCol}>
                <Box sx={{ color: 'common.secondaryGrey' }}>
                  {formatMessage({ id: 'zipcode' }).toUpperCase()}
                </Box>
                <Box fontWeight="600">{site.zipCode}</Box>
              </Box>
            )}
          </Box>
        )}
        <Box justifyContent="space-between" sx={style.flexRow}>
          <ButtonInput variant="link" onClick={() => setOpenEdit(true)}>
            <PencilIcon />
            {formatMessage({ id: 'panels.assessment.editSite' })}
          </ButtonInput>
        </Box>
      </Box>
      <EditSiteDialog
        isOpen={openEdit}
        onClose={() => setOpenEdit(false)}
        siteId={site.id}
        canRemove={canRemove}
      />
    </Box>
  );
}
