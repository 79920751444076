import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Footer from '../footer/Footer';
import ResetInputs from '../ResetInputs/ResetInputs';

function ErrorFallback({ error }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  // TODO: Send error to reporting tool
  // reportError(error)

  return (
    <Box width="100%" minHeight="100vh" display="flex" flexDirection="column">
      <Box
        p="20px"
        backgroundColor={theme.palette.primary.main}
        display="flex"
        justifyContent="center"
      >
        <img src={theme.header.logoPath} alt="DTE" />
      </Box>
      <Box flex="1 1" textAlign="center" maxWidth="800px" mx="auto" p="40px">
        <div role="alert">
          <h1>{formatMessage({ id: 'errorBoundary.header' })}</h1>
          <p>{formatMessage({ id: 'errorBoundary.body' })}</p>
          <Box mt="40px">
            <ResetInputs />
          </Box>
        </div>
      </Box>
      <Footer hideRebates />
    </Box>
  );
}

export default function ErrorBoundary({ fallback = ErrorFallback, children }) {
  return <ReactErrorBoundary FallbackComponent={fallback}>{children}</ReactErrorBoundary>;
}
