import { get } from '../utils/api';
import {
  transformApiVehicle,
  transformArchetypeToFossilVehicle,
  transformLocation,
  transformApiCharger,
} from '../utils/conversions';
import { findArchetype, findArchetypeById } from '../data/VEHICLES';
import sampleApiVehicles from '../data/sampleApiVehicles';
import chargerRebates from '../data/chargerRebates';

export const DEFAULT_CHARGING_WINDOW = {
  start: 0,
  finish: 5,
  chargingApproach: 'Max',
  type: 'Level 2 - 7.7kW',
  isGeneric: true,
};

// used after DEFAULT_CHARGING_WINDOW has already been added
export const SECONDARY_DEFAULT_CHARGING_WINDOW = {
  start: 12,
  finish: 14,
  chargingApproach: 'Max',
  type: 'DCFC - 50kW',
  isGeneric: true,
};

export const DEFAULT_VEHICLE_SET = {
  name: 'Vehicle Set #1',
  vehicleCount: 10,
  milesPerWorkday: 80,
  hoursPerWorkday: 8,
  workdays: [1, 2, 3, 4, 5],
  workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
  replacementYear: 2025,
  fossilFuelType: 'gasoline',
  fossilVehicleId: 'on_road_sedan_n_a',
  customFossil: {},
  customIncentives: [],
};

const DEFAULT_VEHICLE_SET_2 = {
  name: 'Vehicle Set #2',
  vehicle_id: 'Tesla_Model_3_Long_Range_AWD_BEV_2022',
  vehicleCount: 2,
  milesPerWorkday: 50,
  hoursPerWorkday: 8,
  workdays: [0, 1, 2, 3, 4, 5, 6],
  workmonths: [0, 1, 2, 3, 4, 8, 9, 10, 11],
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
  replacementYear: 2023,
  fossilFuelType: 'gasoline',
  customFossil: {},
  customIncentives: [],
};

const DEFAULT_VEHICLE_SET_3 = {
  name: 'Vehicle Set #3',
  vehicle_id: 'Tesla_Model_3_Long_Range_AWD_BEV_2022',
  vehicleCount: 1,
  milesPerWorkday: 100,
  hoursPerWorkday: 8,
  workdays: [0, 1, 2, 3, 4],
  workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
  replacementYear: 2026,
  fossilFuelType: 'diesel',
  customFossil: {},
  customIncentives: [],
};

export const DEFAULT_SITE = {
  id: 0,
  name: 'My Site #1',
  address: '',
  zipCode: '',
  vehicleSets: [DEFAULT_VEHICLE_SET],
};

const DEFAULT_SITE_2 = {
  id: 1,
  name: 'My Site #2',
  address: '',
  zipCode: '',
  vehicleSets: [DEFAULT_VEHICLE_SET_2, DEFAULT_VEHICLE_SET_3],
};

const testingDefaults = {
  sites: [DEFAULT_SITE, DEFAULT_SITE_2],
  selectedSite: 0,
  selectedTab: 0,
  dieselPrice: 3.1,
  gasolinePrice: 3.3,
  startYear: new Date().getFullYear() + 1,
  // utility piece of state, computed value is (selectedSite === 'all')
  isAllSites: false,
  isOnboarded: false,
  onboardingStep: 'site',
};

const defaults = {
  sites: [DEFAULT_SITE],
  selectedSite: 0,
  selectedTab: 0,
  dieselPrice: 5.58,
  gasolinePrice: 4.59,
  startYear: new Date().getFullYear() + 1,
  // utility piece of state, computed value is (selectedSite === 'all')
  isAllSites: false,
  isOnboarded: false,
  onboardingStep: 'site',
};

// ONLY USED FOR TESTS
export const getTestingDefaults = () => ({
  ...testingDefaults,
  apiFossilVehicles: sampleApiVehicles,
  apiElectricVehicles: sampleApiVehicles,
});

const DEFAULT_ZIP_CODE = process.env.REACT_APP_DEFAULT_ZIPCODE;

const getElectricVehicles = async () => {
  const response = await get(`/commercial/vehicles?postcode=${DEFAULT_ZIP_CODE}`);
  return response.data.vehicles
    .map(transformApiVehicle)
    .filter((vehicle) => findArchetype(vehicle));
};

const getFossilVehicles = async () => {
  const response = await get(`/commercial/vehicles?postcode=${DEFAULT_ZIP_CODE}&fuel_type=gas`);
  return response.data.vehicles
    .map(transformApiVehicle)
    .filter((vehicle) => findArchetype(vehicle));
};

const getFuelPrices = async () => {
  const response = await get(`/location?postcode=${DEFAULT_ZIP_CODE}`);
  return transformLocation(response.data.location);
};

const getChargers = async () => {
  const chargers = (await get(`/commercial/chargers?postcode=48014`)).data.chargers.map(
    transformApiCharger,
  );
  const chargerIdsWithRebates = chargerRebates.map(({ handle }) => handle);
  const chargersWithRebates = chargers.filter(({ id }) => chargerIdsWithRebates.includes(id));

  return chargersWithRebates;
};

const getDefaultsWithApi = async () => {
  const [apiElectricVehicles, apiFossilVehicles, apiChargers, apiLocation] = await Promise.all([
    getElectricVehicles(),
    getFossilVehicles(),
    getChargers(),
    getFuelPrices(),
  ]);

  const fossilVehicle = transformArchetypeToFossilVehicle(findArchetypeById('on_road_sedan_n_a'));
  // use vehicles from API response
  const sites = [
    {
      ...DEFAULT_SITE,
      vehicleSets: [
        {
          ...DEFAULT_VEHICLE_SET,
          fossilVehicle,
        },
      ],
    },
  ];

  return {
    ...defaults,
    sites,
    apiChargers,
    apiElectricVehicles,
    apiFossilVehicles,
    gasolinePrice: apiLocation.gasolinePrice,
    dieselPrice: apiLocation.dieselPrice,
  };
};

export default getDefaultsWithApi;
