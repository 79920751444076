import React, { useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import TextInput from '../../components/inputs/TextInput';
import ModalButtonRow from '../ModalButtonRow/ModalButtonRow';
import { CloseIcon } from '../../assets/icons/icons';
import { Dialog, DialogContent, DialogTitle, useTheme } from '@material-ui/core';
import ButtonInput from '../../components/inputs/ButtonInput';
import { stringToFloat } from '../../utils/formatters';

export default function EditAssumptionsDialog({ isOpen, onClose }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { gasolinePrice, dieselPrice, setInput } = useInputs();
  const [workingGas, setWorkingGas] = useState(gasolinePrice);
  const [workingDiesel, setWorkingDiesel] = useState(dieselPrice);

  const onSaveAndClose = () => {
    setInput({ dieselPrice: workingDiesel });
    setInput({ gasolinePrice: workingGas });
    onClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="lg">
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '28px' }}>
          {formatMessage({ id: 'panels.assessment.editAssumption' })}
          <ButtonInput variant="link" onClick={onClose}>
            <span aria-label={formatMessage({ id: 'editVehicleSets.cancel' })} />
            <CloseIcon />
          </ButtonInput>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box color={theme.palette.common.DTEPrimary} fontSize="18px" textAlign="left" pt="40px">
              {formatMessage({ id: 'tabs.fuel' })}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              mask="$9.99"
              maskChar="0"
              label={formatMessage({ id: 'panels.assessment.gasPrice' }).toUpperCase()}
              defaultValue={stringToFloat(workingGas).toFixed(2)}
              placeholder=""
              onChange={(value) => setWorkingGas(stringToFloat(value).toFixed(2))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              mask="$9.99"
              maskChar="0"
              label={formatMessage({ id: 'panels.assessment.dieselPrice' }).toUpperCase()}
              defaultValue={stringToFloat(workingDiesel, 2)}
              placeholder=""
              onChange={(value) => setWorkingDiesel(stringToFloat(value, 2))}
            />
          </Grid>
          <Grid item xs={12}>
            <ModalButtonRow onClose={onClose} onSaveAndClose={onSaveAndClose} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
