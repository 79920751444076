import React from 'react';
import { generateTicks } from '../../../utils/functions';
import { useIntl } from 'react-intl';
import StyledLineGraph from '../../Charts/StyledLineGraph/StyledLineGraph';
import Section from '../../../components/Section/Section';

const generateAnnualFuelSavingsData = (labels, data) => {
  return {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: 'rgba(89, 190, 201, 0.6)',
        borderColor: 'rgba(89, 190, 201, 0.6)',
        borderWidth: 4,
        pointRadius: 0,
        pointBorderWidth: 2,
        fill: true,
      },
    ],
  };
};

export default function AnnualFuelSavingsChart({ labels, data }) {
  const { formatMessage } = useIntl();

  const afcData = generateAnnualFuelSavingsData(labels, data);
  const afcTicks = generateTicks({ datasets: afcData.datasets, formatThousands: true });

  return (
    <Section
      title={formatMessage({ id: 'panels.fuel.annualFuelSavingsOverTime' })}
      description={formatMessage({ id: 'panels.fuel.electrificationTimeline' })}
    >
      <StyledLineGraph
        data={afcData}
        yTicks={afcTicks}
        showLegend={false}
        showHorizontalGrid
        showVerticalGrid
      />
    </Section>
  );
}
