import React from 'react';
import { Box, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useIntl } from 'react-intl';
import Dropdown from '../../inputs/Dropdown';
import CheckboxButtonInput from '../../inputs/CheckboxButtonInput';

const msrpOptions = [
  {
    value: 0,
    children: 'All',
  },
  {
    value: 50000,
    children: '< $50,000',
  },
  {
    value: 100000,
    children: '< $100,000',
  },
  {
    value: 200000,
    children: '< $200,000',
  },
  {
    value: 300000,
    children: '< $300,000',
  },
  {
    value: 500000,
    children: '< $500,000',
  },
  {
    value: 750000,
    children: '< $750,000',
  },
  {
    value: 1000000,
    children: '< $1,000,000',
  },
  {
    value: 2000000,
    children: '< $2,000,000',
  },
];

const VehicleSetDetails = ({ vehicleSet }) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
        padding: '10px',
      }}
    >
      <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>
        {`${vehicleSet.vehicleCount}x ${vehicleSet.fossilVehicle.name}`}
      </Box>
      <Box sx={{ fontSize: '14px' }}>
        <Grid container justifyContent="space-between">
          <Grid item>{formatMessage({ id: 'replacementVehicle.type' })}</Grid>
          <Grid item sx={{ fontWeight: 'bold' }}>
            {vehicleSet.fossilVehicle.type}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>{formatMessage({ id: 'replacementVehicle.subtype' })}</Grid>
          <Grid item sx={{ fontWeight: 'bold' }}>
            {vehicleSet.fossilVehicle.subtype}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>{formatMessage({ id: 'replacementVehicle.milesPerDay' })}</Grid>
          <Grid item sx={{ fontWeight: 'bold' }}>
            {vehicleSet.milesPerWorkday}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default function FilterSidebar({
  vehicleSet,
  onChange,
  filters,
  typeOptions,
  subtypeOptions,
  isFossilVehicle,
}) {
  const { formatMessage } = useIntl();

  return (
    <Box>
      {!isFossilVehicle && vehicleSet && (
        <Box mb="35px">
          <VehicleSetDetails vehicleSet={vehicleSet} />
        </Box>
      )}
      <Box
        sx={{
          textTransform: 'uppercase',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        {formatMessage({ id: 'replacementVehicle.filters' })}
      </Box>

      {!isFossilVehicle && (
        <>
          <Box sx={{ textTransform: 'uppercase', fontSize: '14px' }}>
            {formatMessage({ id: 'replacementVehicle.fuelType' })}
          </Box>

          <Box sx={{ display: 'flex' }}>
            <CheckboxButtonInput
              checked={filters.allElectric}
              onClick={() => {
                onChange('allElectric', !filters.allElectric);
              }}
              unfixedWidth={true}
            >
              {formatMessage({ id: 'replacementVehicle.allElectric' })}
            </CheckboxButtonInput>
            <CheckboxButtonInput
              checked={filters.hybrid}
              onClick={() => {
                onChange('hybrid', !filters.hybrid);
              }}
              unfixedWidth={true}
            >
              {formatMessage({ id: 'replacementVehicle.plugInHybrid' })}
            </CheckboxButtonInput>
          </Box>
        </>
      )}

      <Box sx={{ marginTop: '25px' }}>
        <Dropdown
          value={filters.type}
          label={formatMessage({ id: 'replacementVehicle.vehicleType' })}
          onChange={(e) => {
            onChange('type', e.target.value);
          }}
          options={typeOptions}
        />
      </Box>

      <Box sx={{ marginTop: '25px' }}>
        <Dropdown
          sx={{
            marginTop: '25px',
          }}
          value={filters.subtype}
          label={formatMessage({ id: 'replacementVehicle.vehicleSubtype' })}
          onChange={(e) => {
            onChange('subtype', e.target.value);
          }}
          options={subtypeOptions}
        />
      </Box>

      <Box sx={{ marginTop: '25px' }}>
        <Dropdown
          sx={{
            marginTop: '25px',
          }}
          value={filters.msrp}
          label={formatMessage({ id: 'replacementVehicle.msrp' })}
          onChange={(e) => {
            onChange('msrp', e.target.value);
          }}
          options={msrpOptions}
        />
      </Box>

      <FormControlLabel
        sx={{
          marginTop: '25px',
          marginLeft: '0px',
        }}
        label={formatMessage({ id: 'replacementVehicle.availableNow' })}
        control={
          <Checkbox
            checked={filters.availableNowOnly}
            onChange={() => {
              onChange('availableNowOnly', !filters.availableNowOnly);
            }}
          />
        }
      />
    </Box>
  );
}
