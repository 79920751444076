import React, { useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import { useDisplay } from '../DisplayProvider/DisplayProvider';
import Overview from '../Panels/Overview/Overview';
import Assessment from '../Panels/Assessment/Assessment';
import Vehicles from '../Panels/Vehicles/Vehicles';
import Fuel from '../Panels/Fuel/Fuel';
import Charge from '../Panels/Charge/Charge';
import Connect from '../Panels/Connect/Connect';
import TCO from '../Panels/Tco/Tco';
import Implement from '../Panels/Implement/Implement';
import Copyright from '../components/copyright/Copyright';
import { useInputs } from '@bellawatt/use-inputs';

const sections = [
  <Overview />,
  <Assessment />,
  <Vehicles />,
  <Fuel />,
  <Charge />,
  <Connect />,
  <TCO />,
  <Implement />,
];

export default function ContentPrint() {
  const { selectedSite, setInput } = useInputs();
  const theme = useTheme();
  const { mode, setMode } = useDisplay();

  useEffect(() => {
    const priorSelectedSite = selectedSite;
    setMode('print');
    setInput({ selectedSite: 'all' });

    return () => {
      setMode('screen');
      setInput({ selectedSite: priorSelectedSite });
    };
    // We only want mount and unmount here otherwise infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout;

    if (mode === 'print') {
      // Delay gives time for charts to render
      timeout = setTimeout(() => window.print(), 5000);
    }

    return () => clearTimeout(timeout);
  }, [mode]);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          minHeight: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img height="32" width="93" src={theme.header.logoPath} alt="DTE" />
      </Box>
      {sections.map((section, index) => (
        <Box sx={{ breakAfter: 'page' }}>
          {section}
          {index === sections.length - 1 && (
            <Box p="20px" mt="40px" textAlign="center">
              <Copyright />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
