import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Box } from '@mui/material';
import Dropdown from '../../components/inputs/Dropdown';
import { useInputs } from '@bellawatt/use-inputs';
import TextInput from '../../components/inputs/TextInput';
import VehicleSelect from '../../Panels/EditVehicleSetDialog/VehicleSelect';
import { times } from 'lodash';
import StyledCollapse from '../../Panels/StyledCollapse/StyledCollapse';
import ButtonInput from '../inputs/ButtonInput';
import { titleCase, stringToFloat } from '../../utils/formatters';
import SelectVehicleDialog from '../../Panels/SelectVehicleDialog/SelectVehicleDialog';
import { findArchetype } from '../../data/VEHICLES';
import { transformArchetypeToFossilVehicle } from '../../utils/conversions';

export default function VehicleInfoInputs({ vehicleSet, onChangeVehicleSet, onChangeSite }) {
  const { formatMessage } = useIntl();
  const { startYear, selectedSite, sites } = useInputs();
  /** @type VehicleSet */
  const { name, replacementYear, fossilVehicle, customFossil } = vehicleSet;

  /** @type {['custom'|'catalog', (tab: 'custom'|'catalog') => void]} */
  const [vehicleTab, _setVehicleTab] = useState(fossilVehicle.fromApi ? 'catalog' : 'custom');

  const setVehicleTab = (newTab) => {
    if (newTab === 'custom' && newTab !== vehicleTab) {
      const archetype = transformArchetypeToFossilVehicle(findArchetype(fossilVehicle));
      onChangeVehicleSet({
        fossilVehicle: archetype,
        fossilVehicleId: archetype.id,
        customFossil: Object.keys(customFossil).reduce((acc, key) => ({ ...acc, [key]: null }), {}),
      });
    }
    _setVehicleTab(newTab);
  };

  /** @type {[Vehicle|null, (vehicle: Vehicle|null) => void]} */
  const [catalogVehicle, setCatalogVehicle] = useState();

  useEffect(() => {
    if (vehicleTab === 'catalog') {
      if (catalogVehicle && !fossilVehicle.fromApi) {
        onChangeVehicleSet({
          fossilVehicle: catalogVehicle,
          fossilVehicleId: catalogVehicle.id,
        });
      } else if (!catalogVehicle && fossilVehicle.fromApi) {
        setCatalogVehicle(fossilVehicle);
      }
    }
  }, [catalogVehicle, fossilVehicle, onChangeVehicleSet, vehicleTab]);

  /** @type {[boolean, (open: boolean) => void]} */
  const [showCatalog, setShowCatalog] = useState(false);

  const yearOptions = times(10, (i) => ({
    value: startYear + i,
    children: startYear + i,
  }));

  const fuelTypes = [
    {
      value: 'diesel',
      children: formatMessage({ id: 'editVehicleSets.info.diesel' }),
    },
    {
      value: 'gasoline',
      children: `${formatMessage({ id: 'editVehicleSets.info.gas' })}`,
    },
  ];

  const handleCustomFieldChange = (fieldName, value) => {
    const customFossilArray = {
      msrp: fossilVehicle.msrp,
      fuel: fossilVehicle.fuel,
      milesPerGallon: fossilVehicle.milesPerGallon,
      maintenanceCostPerMile: fossilVehicle.maintenanceCostPerMile,
    };

    if (customFossilArray.hasOwnProperty(fieldName)) {
      customFossilArray[fieldName] = value;
    }

    onChangeVehicleSet({ customFossil: customFossilArray });
  };

  return (
    <Box display="flex" gap="48px" flexDirection="column">
      <Box>
        <Box textAlign="right">
          <ButtonInput variant="link" href="/vehicle-descriptions" newTab>
            {formatMessage({ id: 'editVehicleSets.info.typeGuideLink' })}
          </ButtonInput>
        </Box>
        <Box display="flex" justifyContent="stretch">
          <ButtonInput
            variant={vehicleTab === 'custom' ? 'contained' : 'outlined'}
            dark
            fullWidth
            onClick={() => setVehicleTab('custom')}
          >
            {formatMessage({ id: 'editVehicleSets.tabs.customVehicle' })}
          </ButtonInput>
          <ButtonInput
            variant={vehicleTab === 'catalog' ? 'contained' : 'outlined'}
            dark
            fullWidth
            onClick={() => setVehicleTab('catalog')}
          >
            {formatMessage({ id: 'editVehicleSets.tabs.fromCatalog' })}
          </ButtonInput>
        </Box>
      </Box>
      <Box padding="16px 32px" sx={{ border: '1px solid #E4E4E4', minHeight: '140px' }}>
        {(fossilVehicle.fromApi || vehicleTab === 'custom') && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <img
              src={fossilVehicle.imageUrl}
              alt={name}
              style={{
                maxWidth: '265px',
                maxHeight: '110px',
                height: 'auto',
              }}
            />
            <Box
              textAlign="right"
              fontSize="12px"
              fontWeight="700"
              display="flex"
              flexDirection="column"
              gap="6px"
            >
              <Box fontSize="14px" sx={{ color: (theme) => theme.palette.grey[700] }}>
                {formatMessage({ id: 'editVehicleSets.info.currentPlanned' })}
              </Box>
              {fossilVehicle.fromApi && <Box>{fossilVehicle.name}</Box>}
              <Box>
                {fossilVehicle.rangeInMiles} {formatMessage({ id: 'editVehicleSets.info.miles' })}
              </Box>
              <Box>{titleCase(fossilVehicle.fuel)}</Box>
              <Box>
                {fossilVehicle.milesPerGallon} {formatMessage({ id: 'editVehicleSets.info.mpg' })}
              </Box>
              <Box sx={{ borderBottom: (theme) => `2px solid ${theme.palette.grey[700]}` }} />
              <Box display="flex" justifyContent="space-between">
                <Box fontWeight="300" sx={{ color: (theme) => theme.palette.grey[700] }}>
                  {formatMessage({ id: 'editVehicleSets.info.weightClass' })}
                </Box>
                <Box>{fossilVehicle.weightClass}</Box>
              </Box>
            </Box>
          </Box>
        )}
        {vehicleTab === 'catalog' && (
          <Box flex="1 1" textAlign="center">
            <ButtonInput variant="link" onClick={() => setShowCatalog(true)}>
              {fossilVehicle.fromApi
                ? formatMessage({ id: 'editVehicleSets.info.selectAnotherVehicle' })
                : formatMessage({ id: 'editVehicleSets.info.selectVehicle' })}
            </ButtonInput>
            <SelectVehicleDialog
              isOpen={showCatalog}
              vehicleSet={vehicleSet}
              onClose={() => setShowCatalog(false)}
              onSave={(vehicleSet) => {
                setShowCatalog(false);
                onChangeVehicleSet({
                  ...vehicleSet,
                  fossilVehicleId: vehicleSet.fossilVehicle.id,
                  custom: null,
                });
              }}
              isFossilVehicle
            />
          </Box>
        )}
      </Box>
      <Box maxWidth="732px" m="0 auto">
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item xs={6}>
            <TextInput
              label={formatMessage({ id: 'editVehicleSets.info.set' })}
              defaultValue={name}
              showTip={true}
              onChange={(value) => onChangeVehicleSet({ name: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              label={formatMessage({ id: 'editVehicleSets.info.site' })}
              disabled={!onChangeSite}
              onChange={(ev) => onChangeSite?.({ site: ev.target.value })}
              options={sites.map((site) => ({ value: site.id, children: site.name }))}
              value={selectedSite.id}
            />
          </Grid>
          <VehicleSelect
            vehicleSet={vehicleSet}
            onChange={onChangeVehicleSet}
            isFossilVehicle={true}
            disabled={vehicleTab === 'catalog'}
          />
          <Grid item xs={6}>
            <Dropdown
              onChange={(ev) => onChangeVehicleSet({ replacementYear: ev.target.value })}
              options={yearOptions}
              value={replacementYear}
              label={formatMessage({ id: 'editVehicleSets.info.replacement' })}
              disabled={vehicleTab === 'catalog'}
            />
          </Grid>
        </Grid>
      </Box>
      <StyledCollapse
        title={formatMessage({ id: 'editVehicleSets.info.advancedProperties' })}
        styleName="primary"
        leftIcon
        defaultOpen={customFossil && Object.keys(customFossil).length > 0}
      >
        <Box padding="16px 32px">
          <Grid container rowSpacing={4} columnSpacing={4}>
            <Grid item xs={6}>
              <TextInput
                label={formatMessage({ id: 'editVehicleSets.info.msrp' })}
                defaultValue={customFossil.msrp || fossilVehicle.msrp}
                onChange={(value) => handleCustomFieldChange('msrp', stringToFloat(value))}
                disabled={vehicleTab === 'catalog'}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                onChange={(ev) => handleCustomFieldChange('fuel', ev.target.value)}
                options={fuelTypes}
                value={customFossil.fuel || fossilVehicle.fuel}
                label={formatMessage({ id: 'editVehicleSets.info.fuelType' })}
                disabled={vehicleTab === 'catalog'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label={formatMessage({ id: 'editVehicleSets.info.fossilMilesPerGallon' })}
                defaultValue={customFossil.milesPerGallon || fossilVehicle.milesPerGallon}
                onChange={(value) =>
                  handleCustomFieldChange('milesPerGallon', stringToFloat(value))
                }
                disabled={vehicleTab === 'catalog'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label={formatMessage({ id: 'editVehicleSets.info.maintenanceCostPerMile' })}
                defaultValue={
                  customFossil.maintenanceCostPerMile || fossilVehicle.maintenanceCostPerMile
                }
                onChange={(value) =>
                  handleCustomFieldChange('maintenanceCostPerMile', stringToFloat(value))
                }
                disabled={vehicleTab === 'catalog'}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledCollapse>
    </Box>
  );
}
