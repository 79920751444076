import React from 'react';
import { useIntl } from 'react-intl';
import StyledBarGraph from '../Charts/StyledBarGraph/StyledBarGraph';
import StyledLineGraph from '../Charts/StyledLineGraph/StyledLineGraph';
import { useTheme } from '@mui/material';
import times from 'lodash/times';
import { formatAsDollars } from '../../utils/formatters';
import { generateTicks, largestDatasetValue } from '../../utils/functions';

export const TotalCostProjectChart = ({
  retiringFleetCosts,
  electrifiedFleetCosts,
  vehicleSets,
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const overTimeLabels = [
    formatMessage({ id: 'panels.tco.currentFleet' }),
    formatMessage({ id: 'panels.vehicles.electrifiedFleet' }),
  ];

  const overTimeData = {
    labels: overTimeLabels,
    datasets: [
      {
        data: [
          retiringFleetCosts.msrp,
          electrifiedFleetCosts.msrp + electrifiedFleetCosts.chargerPurchase,
        ],
        backgroundColor: theme.palette.common.lightGreen,
        label: formatMessage({ id: 'panels.tco.vehiclePurchase' }),
        barThickness: 35,
        id: 'vehicle',
      },
      {
        data: [retiringFleetCosts.electricity, electrifiedFleetCosts.electricity],
        backgroundColor: theme.palette.common.lightAqua,
        label: formatMessage({ id: 'panels.tco.electricity' }),
        barThickness: 35,
      },
      retiringFleetCosts.gasoline && {
        data: [retiringFleetCosts.gasoline, electrifiedFleetCosts.gasoline],
        backgroundColor: theme.palette.common.grey500,
        label: formatMessage({
          id: `panels.tco.gasoline`,
        }),
        barThickness: 35,
      },
      retiringFleetCosts.diesel && {
        data: [retiringFleetCosts.diesel, electrifiedFleetCosts.diesel],
        backgroundColor: theme.palette.common.grey300,
        label: formatMessage({ id: 'panels.tco.diesel' }),
        barThickness: 35,
      },
      {
        data: [retiringFleetCosts.maintenance, electrifiedFleetCosts.maintenance],
        backgroundColor: theme.palette.common.darkPurple,
        label: formatMessage({ id: 'panels.tco.maintenance' }),
        barThickness: 35,
      },
      {
        data: [retiringFleetCosts.insurance, electrifiedFleetCosts.insurance],
        backgroundColor: theme.palette.common.brandBlue,
        label: formatMessage({ id: 'panels.tco.insurance' }),
        barThickness: 35,
      },
    ].filter((x) => !!x),
  };

  const incentives = vehicleSets.reduce((totalAcc, vehicleSet) => {
    const siteSum = vehicleSet.customIncentives.reduce((siteAcc, obj) => {
      const value = parseFloat(obj.Value * vehicleSet.vehicleCount);
      return siteAcc + value;
    }, 0);

    return totalAcc + siteSum;
  }, 0);

  const getVehicleBreakdownValue = (totalCost) => {
    const tcoFinalValue = parseFloat(totalCost.replace(/\D+/g, ''));

    return [
      formatMessage(
        { id: 'panels.tco.vehicleCost' },
        {
          value: formatAsDollars(electrifiedFleetCosts.msrp),
        },
      ),
      formatMessage(
        { id: 'panels.tco.chargerCost' },
        { value: formatAsDollars(electrifiedFleetCosts.chargerPurchase) },
      ),
      formatMessage({ id: 'panels.tco.incentivesCost' }, { value: formatAsDollars(incentives) }),
      '',
      formatMessage(
        { id: 'panels.tco.total' },
        {
          value: `${formatAsDollars(tcoFinalValue)}`,
        },
      ),
    ];
  };

  const tcoTicks = generateTicks({
    datasets: overTimeData.datasets,
    formatThousands: true,
    graphRangeMultiplier: 2, // adds space for bar totals
  });

  const fleetTicks = {
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
    padding: 20,
  };

  const dataLabelOptions = {
    formatter: (_, ctx) => {
      return formatAsDollars(ctx.chart.$stackedTotals.totals[ctx.dataIndex]);
    },
    display: function (ctx) {
      return ctx.datasetIndex === ctx.chart.$stackedTotals.utmost;
    },
  };

  const legendOptions = {
    align: 'start',
  };

  return (
    <StyledBarGraph
      data={overTimeData}
      xTicks={tcoTicks}
      yTicks={fleetTicks}
      showLegend={true}
      legendOptions={legendOptions}
      indexAxis={'y'}
      stacked={true}
      tooltipTitleFormatter={() => ''}
      tooltipLabelFormatter={(data) => {
        const { id } = data.dataset;
        switch (id) {
          case 'vehicle':
            if (data.dataIndex === 0) {
              return `${formatMessage({
                id: 'panels.tco.fossilVehiclePurchase',
              })}: ${formatAsDollars(data.formattedValue)}`;
            } else {
              return getVehicleBreakdownValue(data.formattedValue);
            }
          default:
            return `${data.dataset.label}: ${formatAsDollars(data.formattedValue)}`;
        }
      }}
      showDataLabels={true}
      largestLabelValue={largestDatasetValue(overTimeData.datasets)}
      dataLabelOptions={dataLabelOptions}
      showHorizontalGrid
      showVerticalGrid
    />
  );
};

export const PaybackPeriodChart = ({
  retiringFleetCosts,
  electrifiedFleetCosts,
  startYear,
  firstReplacementYear,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const labels = times(10, function (i) {
    return [i + startYear];
  });

  const paybackData = {
    labels,
    datasets: [
      {
        data: retiringFleetCosts.map((value) => (value ? parseInt(value) : null)),
        label: formatMessage({ id: 'panels.tco.paybackCurrentFleet' }),
        backgroundColor: 'white',
        borderColor: theme.palette.common.brandBlue,
        borderWidth: 4,
        pointRadius: 6,
        pointBorderWidth: 2,
      },
      {
        data: electrifiedFleetCosts.map((value) => (value ? parseInt(value) : null)),
        label: formatMessage({ id: 'panels.tco.paybackElectrifiedFleet' }),
        backgroundColor: 'white',
        borderColor: theme.palette.common.lightAqua,
        borderWidth: 4,
        pointRadius: 6,
        pointBorderWidth: 2,
      },
    ],
  };

  const paybackTicks = generateTicks({ datasets: paybackData.datasets, formatThousands: true });
  const yearTicks = {
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
    padding: 20,
  };

  const plugins = [
    {
      afterDatasetsDraw: function (chart) {
        var ctx = chart.ctx;
        chart.data.datasets.forEach(function (dataset, index) {
          var datasetMeta = chart.getDatasetMeta(index);
          if (datasetMeta.hidden) return;
          datasetMeta.data.forEach(function (point, index) {
            var value = dataset.data[index],
              x = point.getCenterPoint().x,
              y = point.getCenterPoint().y,
              radius = 10,
              fontSize = 14,
              fontColor = 'black';
            ctx.save();
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.fillStyle = fontColor;
            if (value) {
              ctx.fillText(
                index + 1 - (firstReplacementYear - startYear),
                x,
                y - radius - fontSize,
              );
              ctx.restore();
            }
          });
        });
      },
    },
    {
      beforeInit(chart) {
        const originalFit = chart.legend.fit;
        chart.legend.fit = function fit() {
          originalFit.bind(chart.legend)();
          this.height += 50;
        };
      },
    },
  ];

  return (
    <StyledLineGraph
      data={paybackData}
      yTicks={paybackTicks}
      xTicks={yearTicks}
      plugins={plugins}
      showHorizontalGrid
    />
  );
};

export const TotalCostFleetsChart = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const tcoLabels = [
    formatMessage({ id: 'tabs.fuel' }),
    formatMessage({ id: 'tabs.vehicles' }),
    formatMessage({ id: 'panels.tco.operations' }),
    formatMessage({ id: 'panels.tco.incentives' }),
    formatMessage({ id: 'panels.tco.infrastructure' }),
  ];

  const tcoData = {
    labels: tcoLabels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.tco.currentFleet' }),
        data: [290000, 250000, 200000, 0, 0],
        backgroundColor: theme.palette.common.brandBlue,
      },
      {
        label: formatMessage({ id: 'panels.vehicles.electrifiedFleet' }),
        data: [100000, 200000, 100000, 10000, 125000],
        backgroundColor: theme.palette.common.lavender,
      },
    ],
  };

  const tcoTicks = {
    min: 0,
    stepSize: 100000,
    color: theme.palette.common.secondaryGrey,
    font: {
      size: 16,
    },
    callback: (value) => formatAsDollars(value),
  };

  return (
    <StyledBarGraph
      data={tcoData}
      yTitle={'Total Costs'}
      yTicks={tcoTicks}
      showLegend={true}
      indexAxis={'x'}
      stacked={false}
      large={true}
    />
  );
};
