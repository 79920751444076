import React from 'react';
import { Box } from '@mui/material';
import { makeStyles, useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { MultiplyIcon, EqualsIcon } from '../../assets/icons/icons';
import ButtonInput from '../../components/inputs/ButtonInput';
import { formatAsDollars } from '../../utils/formatters';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.common.borderGrey}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '350px',
    marginTop: '20px',
    padding: '17px',
    marginRight: '15px',
    textAlign: 'center',
  },
}));

export default function ChargerCard({ charger, onPickCharger }) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          textAlign: 'left',
          color: theme.palette.common.DTEPrimary,
          fontSize: '22px',
          fontWeight: '600',
          lineHeight: '30px',
        }}
      >
        {charger.make}
      </Box>
      <Box
        sx={{
          color: theme.palette.common.secondaryGrey,
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          lineHeight: '22px',
          mb: '18px',
        }}
      >
        {charger.model}
        <Box>Qty: {charger.qty}</Box>
      </Box>
      <Box sx={{ mb: '18px' }}>
        <img src={charger.image} alt={charger.model} height={135} />
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '3px',
            color: 'common.secondaryGrey',
          }}
        >
          <Box>{formatMessage({ id: 'panels.charge.type' })}</Box>
          <Box sx={{ color: theme.palette.common.DTEPrimary }}>{charger.type}</Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '3px' }}>
          <Box sx={{ color: theme.palette.common.secondaryGrey, fontWeight: '400' }}>
            {formatMessage({ id: 'panels.charge.rate' })}
          </Box>
          <Box sx={{ color: theme.palette.common.DTEPrimary }}>{charger.rate}</Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '3px' }}>
          <Box sx={{ color: theme.palette.common.secondaryGrey, fontWeight: '400' }}>
            {formatMessage({ id: 'panels.charge.plug' })}
          </Box>
          <Box sx={{ color: theme.palette.common.DTEPrimary }}>{charger.plugType}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          mt: '40px',
          borderTop: `1px solid ${theme.palette.common.dividerGrey}`,
          borderBottom: `1px solid ${theme.palette.common.dividerGrey}`,
          height: '80px',
          mx: '-18px',
        }}
      >
        <Box
          sx={{
            borderRight: `1px solid ${theme.palette.common.dividerGrey}`,
            width: '50%',
            color: theme.palette.common.secondaryGrey,
            fontSize: '16px',
            fontWeight: '400',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {`${charger.isEstimatedPrice ? 'Estimated' : ''} MSRP`}
          <Box sx={{ color: theme.palette.common.DTEPrimary, fontSize: '22px', fontWeight: '600' }}>
            {formatAsDollars(charger.msrp)}
          </Box>
        </Box>
        <Box
          sx={{
            width: '50%',
            color: theme.palette.common.secondaryGrey,
            fontSize: '16px',
            fontWeight: '400',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          DTE {formatMessage({ id: 'panels.charge.rebate' })}
          <Box sx={{ color: theme.palette.common.DTEPrimary, fontSize: '22px', fontWeight: '600' }}>
            {formatAsDollars(charger.rebate)}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: '20px',
          mb: '30px',
        }}
      >
        <Box
          sx={{ color: theme.palette.common.secondaryGrey, fontSize: '16px', fontWeight: '400' }}
        >
          AVG MSRP
          <Box sx={{ color: theme.palette.common.DTEPrimary, fontSize: '22px', fontWeight: '600' }}>
            {formatAsDollars(charger.msrp)}
          </Box>
        </Box>
        <Box pt="10px">
          <MultiplyIcon />
        </Box>
        <Box
          sx={{ color: theme.palette.common.secondaryGrey, fontSize: '16px', fontWeight: '400' }}
        >
          {formatMessage({ id: 'quantity' }).toUpperCase()}
          <Box sx={{ color: theme.palette.common.DTEPrimary, fontSize: '22px', fontWeight: '600' }}>
            {charger.qty}
          </Box>
        </Box>
        <Box pt="10px">
          <EqualsIcon />
        </Box>
        <Box
          sx={{ color: theme.palette.common.secondaryGrey, fontSize: '16px', fontWeight: '400' }}
        >
          {formatMessage({ id: 'total' }).toUpperCase()}
          <Box sx={{ color: theme.palette.common.DTEPrimary, fontSize: '22px', fontWeight: '600' }}>
            {formatAsDollars(charger.msrp * charger.qty)}
          </Box>
        </Box>
      </Box>
      <ButtonInput onClick={() => onPickCharger(charger)} variant="link">
        {formatMessage({ id: 'panels.charge.pickCharger' })}
      </ButtonInput>
    </Box>
  );
}
