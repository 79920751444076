import { useIntl } from 'react-intl';
import { Link, Box } from '@material-ui/core';

const Step2Content = () => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box>
        {formatMessage(
          { id: 'panels.implement.step2.content' },
          {
            directory: (
              <Link
                href="https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/business/electric/electric-vehicles/pev-biz-installers"
                underline="always"
                color="secondary"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: 'panels.implement.step2.directory' })}
              </Link>
            ),
          },
        )}
      </Box>
    </Box>
  );
};

const Step4Content = () => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box>
        {formatMessage(
          { id: 'panels.implement.step4.content.read' },
          {
            terms: (
              <Link
                href="https://www.newlook.dteenergy.com/wps/wcm/connect/8012f4e1-3905-4fab-9f65-975fd15cacae/ChargingForward-eFleetsRebate-Terms-and-Conditions.pdf?MOD=AJPERES"
                underline="always"
                color="secondary"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: 'panels.implement.step4.content.terms' })}
              </Link>
            ),
            questionnaire: (
              <Link
                href="https://newlook.dteenergy.com/wps/wcm/connect/be51ad49-7f64-4a55-ab72-d050066f0237/FCPA_Questionnaire.pdf?MOD=AJPERES"
                underline="always"
                color="secondary"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: 'panels.implement.step4.content.questionnaire' })}
              </Link>
            ),
          },
        )}
      </Box>
    </Box>
  );
};

const Step5Content = () => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box>
        {formatMessage(
          { id: 'panels.implement.step5.content.consult' },
          {
            link: (
              <Link
                href="http://dte-efleets.powerclerk.com"
                underline="always"
                color="secondary"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: 'panels.implement.step5.content.link' })}
              </Link>
            ),
          },
        )}
      </Box>
      <Box pt="20px">
        {formatMessage(
          { id: 'panels.implement.step5.content.initialApp' },
          { note: <b>{formatMessage({ id: 'panels.implement.step5.content.pleaseNote' })}</b> },
        )}
      </Box>
      <Box pt="20px">{formatMessage({ id: 'panels.implement.step5.content.yourInfo' })}</Box>
    </Box>
  );
};

const Step11Content = () => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box>
        {formatMessage(
          { id: 'panels.implement.step11.content.ensureFunction' },
          {
            link: (
              <Link
                href="http://dte-efleets.powerclerk.com"
                underline="always"
                color="secondary"
                target="_blank"
                rel="noreferrer"
              >
                dte-efleets.powerclerk.com
              </Link>
            ),
          },
        )}
      </Box>

      <Box pt="20px">{formatMessage({ id: 'panels.implement.step11.content.selectProject' })}</Box>
      <Box>{formatMessage({ id: 'panels.implement.step11.content.goToForms' })}</Box>
      <Box>{formatMessage({ id: 'panels.implement.step11.content.finalizeRebate' })}</Box>
      <Box>{formatMessage({ id: 'panels.implement.step11.content.submitForm' })}</Box>
    </Box>
  );
};

const steps = [
  {
    title: 'panels.implement.step1.title',
    content: 'panels.implement.step1.content',
  },
  {
    title: 'panels.implement.step2.title',
    content: <Step2Content />,
  },
  {
    title: 'panels.implement.step3.title',
    content: 'panels.implement.step3.content',
  },
  {
    title: 'panels.implement.step4.title',
    content: <Step4Content />,
  },
  {
    title: 'panels.implement.step5.title',
    content: <Step5Content />,
  },
  {
    title: 'panels.implement.step6.title',
    content: 'panels.implement.step6.content',
  },
  {
    title: 'panels.implement.step7.title',
    content: 'panels.implement.step7.content',
  },
  {
    title: 'panels.implement.step8.title',
    content: 'panels.implement.step8.content',
  },
  {
    title: 'panels.implement.step9.title',
    content: 'panels.implement.step9.content',
  },
  {
    title: 'panels.implement.step10.title',
    content: 'panels.implement.step10.content',
  },
  {
    title: 'panels.implement.step11.title',
    content: <Step11Content />,
  },
  {
    title: 'panels.implement.step12.title',
    content: 'panels.implement.step12.content',
  },
];

export default steps;
