import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Row = ({ row, isHeader = false }) => {
  const headerStyling = {
    backgroundColor: '#1E3575',
    color: 'white',
  };

  return (
    <TableRow>
      {row.map((cell) => {
        return (
          <TableCell key={cell} sx={isHeader ? headerStyling : {}}>
            {cell}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default function ChargingAssignmentsTable(props) {
  const { tableData } = props;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <Row row={tableData.columns} isHeader />
        </TableHead>
        <TableBody>
          {tableData.rows.map((row, index) => (
            <Row key={`${row[0]}-${index}`} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
