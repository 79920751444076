import { React } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import VehicleCard from '../../../Panels/VehicleCard/VehicleCard';
import { RightArrow } from '../../../assets/icons/icons';
import { BoltIcon, NoGasIcon } from '../../../assets/icons/icons';

export default function ReplacementSummary({ vehicleSet, site, vehicleInd, handleReplaceVehicle }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <Box display="flex" flexDirection="column">
        <Grid
          container
          justifyContent="center"
          alignItems="end"
          color={theme.palette.common.DTEPrimary}
          mb="16px"
        >
          <NoGasIcon />
          <Box mt="32px" ml="8px" fontSize="18px">
            {formatMessage({ id: 'panels.vehicles.existingFleet' }).toUpperCase()}
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="end"
          flexWrap="wrap"
          height="100%"
          color={theme.palette.common.DTEPrimary}
        >
          <VehicleCard
            vehicleSet={vehicleSet}
            key={`${site.id}-${vehicleSet.id}`}
            vehicleInd={vehicleInd}
            siteInd={site.id}
          />
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', '> svg': { width: '44px' } }}>
        <RightArrow />
      </Box>
      <Box display="flex" flexDirection="column">
        <Grid
          container
          justifyContent="center"
          alignItems="end"
          color={theme.palette.common.DTEPrimary}
          mb="16px"
        >
          <Box width="13px" height="26px">
            <BoltIcon />
          </Box>
          <Box mt="32px" ml="8px" fontSize="18px">
            {formatMessage({ id: 'panels.vehicles.electrifiedFleet' }).toUpperCase()}
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="end"
          flexWrap="wrap"
          height="100%"
          color={theme.palette.common.DTEPrimary}
        >
          <VehicleCard
            vehicleSet={vehicleSet}
            key={`${site.name}-${vehicleSet.id}`}
            vehicleInd={vehicleInd}
            siteInd={site.id}
            isElectric={true}
            handleReplaceVehicle={handleReplaceVehicle}
          />
        </Grid>
      </Box>
    </Box>
  );
}
