import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import { Workdays, WorkMonths } from './WorkdaysMonths';
import { formatHours } from '../../../utils/formatters';

export default function UsageOutput({ vehicleSet }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { workdays, workmonths, milesPerWorkday, chargingWindows } = vehicleSet;

  return (
    <Grid container direction="row" spacing={2} textAlign="left">
      <Grid item xs={12}>
        <Box color={theme.palette.common.DTEPrimary} fontSize="18px" fontWeight="600">
          {formatMessage({ id: 'panels.vehicles.vehicleUsage' })}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box mb="24px" color={theme.palette.common.secondaryGrey} fontSize="16px">
          {formatMessage({ id: 'panels.vehicles.workdays' }).toUpperCase()}
        </Box>
        <Workdays days={workdays} />
      </Grid>
      <Grid item xs={3}>
        <Box mb="24px" color={theme.palette.common.secondaryGrey} fontSize="16px">
          {formatMessage({ id: 'panels.vehicles.monthsOfOperation' }).toUpperCase()}
        </Box>
        <WorkMonths months={workmonths} />
      </Grid>
      <Grid item xs={3}>
        <Box mb="24px" color={theme.palette.common.secondaryGrey} fontSize="16px">
          {formatMessage({ id: 'panels.vehicles.typicalWorkday' }).toUpperCase()}
        </Box>
        {milesPerWorkday} miles
      </Grid>
      <Grid item xs={3}>
        <Box mb="24px" color={theme.palette.common.secondaryGrey} fontSize="16px">
          {formatMessage({ id: 'panels.vehicles.chargingWindows' }).toUpperCase()}
        </Box>
        {chargingWindows.map(({ start, finish, type }, index) => (
          <Box
            display="flex"
            flexDirection="column"
            width="160px"
            height="80px"
            marginTop="20px"
            justifyContent="center"
            textAlign="center"
            fontWeight="400"
            border={`1px solid ${theme.palette.common.grey300}`}
            color={theme.palette.common.DTEPrimary}
            key={index}
          >
            <Box lineHeight="22px">{`${formatHours(start)} - ${formatHours(finish)}`}</Box>
            <Box>{type}</Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
