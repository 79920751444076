import React from 'react';
import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useIntl } from 'react-intl';
import StyledBarGraph from '../Charts/StyledBarGraph/StyledBarGraph';
import { useInputs } from '@bellawatt/use-inputs';
import times from 'lodash/times';
import sum from 'lodash/sum';

export function ChargerTimeline({ chargerCounts }) {
  const { startYear } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const years = times(10, (i) => (isLargeScreen ? startYear + i : `'${startYear + i - 2000}`));

  const ChargerGraphLabel = ({ numChargers }) => {
    const { formatMessage } = useIntl();
    return (
      <span style={{ fontSize: '18px' }}>
        {formatMessage(
          { id: 'panels.overview.chargersGraphLabel' },
          { timePeriod: <b>10 years</b>, numChargers: <b>{numChargers} chargers</b> },
        )}
      </span>
    );
  };

  const chargerData = {
    labels: years,
    datasets: [
      {
        label: '',
        data: chargerCounts,
        backgroundColor: theme.palette.common.lavender,
      },
    ],
  };

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '100%',
        border: `1px solid ${theme.palette.common.borderGrey}`,
        mt: '25px',
        mb: '25px',
        pt: '30px',
        pl: '10px',
        borderRadius: '4px',
        pb: '35px',
        [theme.breakpoints.down('sm')]: {
          pb: '80px',
        },
      })}
    >
      <StyledBarGraph
        data={chargerData}
        yTitle={formatMessage({ id: 'chargers' })}
        graphTitle={<ChargerGraphLabel numChargers={sum(chargerCounts)} />}
        showLegend={false}
        indexAxis={'x'}
        stacked={false}
        showDataLabels
        tooltipEnabled={false}
      />
    </Box>
  );
}

export function VehicleTimeline({ vehicleCounts }) {
  const { formatMessage } = useIntl();
  const { startYear } = useInputs();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const years = times(10, (i) => (isLargeScreen ? startYear + i : `'${startYear + i - 2000}`));

  const vehicleData = {
    labels: years,
    datasets: [
      {
        label: '',
        data: vehicleCounts,
        backgroundColor: theme.palette.common.brandBlue,
      },
    ],
  };

  const VehicleGraphLabel = ({ numVehicles }) => {
    const { formatMessage } = useIntl();
    return (
      <span style={{ fontSize: '18px' }}>
        {formatMessage(
          { id: 'panels.overview.vehiclesGraphLabel' },
          { timePeriod: <b>10 years</b>, numVehicles: <b>{numVehicles} vehicles</b> },
        )}
      </span>
    );
  };

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.common.borderGrey}`,
        mt: '25px',
        mb: '20px',
        pt: '30px',
        pl: '10px',
        borderRadius: '4px',
        pb: '35px',
        [theme.breakpoints.down('sm')]: {
          pb: '80px',
        },
      })}
    >
      <StyledBarGraph
        data={vehicleData}
        yTitle={formatMessage({ id: 'vehicles' })}
        graphTitle={<VehicleGraphLabel numVehicles={sum(vehicleCounts)} />}
        showLegend={false}
        indexAxis={'x'}
        stacked={false}
        showDataLabels
        tooltipEnabled={false}
      />
    </Box>
  );
}
