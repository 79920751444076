import React from 'react';
import { Box, Grid } from '@mui/material';

export default function TakeAwayCard(props) {
  const { item } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.common.borderGrey}`,
        mt: '20px',
        height: '323px',
        width: '255px',
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
          mt: '0',
          flex: '1 1',
          minWidth: '250px',
        },
      })}
    >
      <Grid item xs={6}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Box width={item.iconWidth || '100px'} margin="auto">
            {item.icon}
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <Box fontSize={'32px'} fontWeight={'600'}>
            {item.total}
          </Box>
          <Box p="12px">{item.text}</Box>
        </Box>
      </Grid>
    </Grid>
  );
}
