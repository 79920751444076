import { useIntl } from 'react-intl';
import InfoCard from '../../Panels/InfoCard/InfoCard';

/**
 * @typedef BlockerProps
 * @property {Array<Site>} sites
 * */

/**
 *
 * @param {BlockerProps} params
 * @returns
 */
export const InsufficientChargeBlocker = ({ sites }) => {
  const { formatMessage } = useIntl();
  const siteVehicles = sites.map((site) => {
    const vehicleNames = site.vehicleSets.map((set) => {
      return set.vehicle
        ? `${set.vehicle.make} ${set.vehicle.model}`
        : `${set.fossilVehicle.type} ${set.fossilVehicle.subtype}`;
    });

    return {
      name: site.name,
      vehicles: vehicleNames.join(', '),
    };
  });

  return (
    <InfoCard
      variant="blocker"
      message={
        <>
          <div>
            <b>{formatMessage({ id: 'tabs.warning' })}</b>{' '}
            {formatMessage({ id: 'tabs.insufficientChargeWarning' })}{' '}
            {siteVehicles.length === 1 && <b>{siteVehicles[0].vehicles}</b>}
          </div>
          {siteVehicles.length > 1 && (
            <ul>
              {siteVehicles.map((site) => (
                <li style={{ fontWeight: '600' }}>
                  {site.name} {site.vehicles}
                </li>
              ))}
            </ul>
          )}
          <div>{formatMessage({ id: 'tabs.insufficientChargeCTA' })}</div>
        </>
      }
    />
  );
};
