import { Box, Typography } from '@mui/material';
import StyledCollapse from '../../../StyledCollapse/StyledCollapse';
import { useInputs } from '@bellawatt/use-inputs';
import Carousel from '../Carousel/Carousel';
import { omitComputedData } from '../../../../utils/computedData';
import { formatAsDollars } from '../../../../utils/formatters';

export default function IncentivesSection({ selectedSite }) {
  const { setInput, sites } = useInputs();

  const goBackToAssessment = (e) => {
    e.stopPropagation();
    setInput({ selectedTab: 1 });
  };

  const setVehicleSet = (newVehicleSet) => {
    const index = sites.findIndex((site) => site.id === selectedSite.id);
    const indexVS = sites[index].vehicleSets.findIndex(
      (vehicleSet) => vehicleSet.name === newVehicleSet.name,
    );

    sites[index].vehicleSets[indexVS] = newVehicleSet;
    setInput({ sites: omitComputedData(sites) });
  };

  const handleChangeVehicleSet = (newData) => {
    setVehicleSet(newData);
  };

  return (
    <Box>
      {selectedSite.vehicleSets.map((vehicleSet) => {
        const ExtraData = () => {
          return (
            <Box display={'flex'} gap={'24px'}>
              <Typography fontWeight={600}> {vehicleSet.fossilVehicle.name} </Typography>
              <Typography fontWeight={300}> Qty: {vehicleSet.vehicleCount} </Typography>
              <Typography onClick={(e) => goBackToAssessment(e)}>Edit Vehicle Set</Typography>
            </Box>
          );
        };

        const allValuesSum = vehicleSet.customIncentives.reduce((acc, obj) => {
          const value = parseFloat(obj.Value * vehicleSet.vehicleCount);
          return acc + value;
        }, 0);

        const Title = () => {
          return (
            <div style={{ height: '21px', display: 'flex', fontSize: '22px' }}>
              <p style={{ margin: 0, fontWeight: 600, marginRight: '8px' }}>
                {formatAsDollars(allValuesSum)}:{' '}
              </p>
              <p style={{ margin: 0 }}>{vehicleSet.name}</p>
            </div>
          );
        };

        return (
          <StyledCollapse
            styleName="primary"
            title={<Title />}
            key={vehicleSet.name}
            leftIcon
            extraInfo={<ExtraData />}
          >
            <Box sx={{ padding: '16px 64px 35px', position: 'relative' }}>
              <Typography sx={{ lineHeight: '32px', fontSize: '14px', fontStyle: 'italic' }}>
                {' '}
                {Object.keys(vehicleSet.customIncentives).length} incentives found
              </Typography>
              <Carousel
                vehicleCount={vehicleSet.vehicleCount}
                handleChangeVehicleSet={handleChangeVehicleSet}
                set={vehicleSet}
              />
            </Box>
          </StyledCollapse>
        );
      })}
    </Box>
  );
}
