import { useInputs } from '@bellawatt/use-inputs';
import VehicleSet from '../calculations/VehicleSet';
import min from 'lodash/min';

export const useDepletedBatterySites = () => {
  const { sites, selectedSite, isAllSites } = useInputs();

  const firstReplacementYear = isAllSites
    ? min(sites.flatMap((site) => site.vehicleSets).map(({ replacementYear }) => replacementYear))
    : min(selectedSite.vehicleSets.map(({ replacementYear }) => replacementYear));

  const depletedBatterySites = (isAllSites ? sites : [selectedSite])
    .map((site) => {
      const depletedSets = site.vehicleSets.filter(
        (set) =>
          set.vehicle?.fuel !== 'PHEV' &&
          VehicleSet.minimumStateOfCharge(set, firstReplacementYear) < 0,
      );
      if (depletedSets.length) {
        return { ...site, vehicleSets: depletedSets };
      }
      return null;
    })
    .filter((site) => site && site.vehicleSets.length > 0);

  return {
    firstReplacementYear,
    depletedBatterySites,
  };
};
