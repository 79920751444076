import { formatAsThousands } from './formatters';

export const largestDatasetValue = (datasets) => {
  let max = 0;
  datasets.forEach((dataset) => {
    if (max === 0 || max < Math.max(...dataset.data)) {
      max = Math.max(...dataset.data);
    }
  });
  return max;
};

export const generateTicks = ({
  datasets,
  stepSize,
  color = '#000',
  formatThousands = false,
  graphRangeMultiplier = 1,
}) => {
  const largestValue = largestDatasetValue(datasets);
  const multiplier = 10 ** (`${Math.floor(largestValue)}`.length - 1 || 1);
  const max = Math.ceil(largestValue / multiplier) * multiplier * graphRangeMultiplier;
  return {
    min: 0,
    max: max,
    stepSize: stepSize || max / 4,
    color: color,
    callback: (value) => (formatThousands ? `$${formatAsThousands(+value)}` : value),
  };
};
