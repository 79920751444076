import React, { useState, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import Dropdown from '../components/inputs/Dropdown';
import FilterSidebar from '../components/ChargerDialog/ChargerCatalogue/FilterSidebar';
import ChargerList from './chargerList/ChargerList';
import uniqBy from 'lodash/uniqBy';
import ButtonInput from '../components/inputs/ButtonInput';

const sortConfig = {
  msrpDesc: {
    field: 'msrp',
    method: 'desc',
  },
  msrpAsc: {
    field: 'msrp',
    method: 'asc',
  },
};

const SHOW_COUNT = 24;

const useChargers = () => {
  const { apiChargers } = useInputs();
  const [showCount, setShowCount] = useState(SHOW_COUNT);

  const showMore = () => setShowCount(showCount + SHOW_COUNT);
  const resetShowCount = () => setShowCount(SHOW_COUNT);
  const [filters, setFilters] = useState({
    chargerType: 'All',
    chargeRate: 'All',
  });

  const [sortOrder, _setSortOrder] = useState('msrpAsc');
  const setSortOrder = (value) => {
    resetShowCount();
    _setSortOrder(value);
  };

  const updateFilter = (name, value) => {
    resetShowCount();
    setFilters({ ...filters, [name]: value });
  };

  const filteredChargers = useMemo(() => {
    return uniqBy(apiChargers, 'id').filter(
      ({ chargerType, chargeRate }) =>
        (filters.chargerType === 'All' || filters.chargerType === chargerType) &&
        (filters.chargeRate === 'All' || filters.chargeRate === chargeRate),
    );
  }, [apiChargers, filters.chargeRate, filters.chargerType]);

  const { field, method } = sortConfig[sortOrder];

  switch (method) {
    case 'asc':
      filteredChargers.sort((a, b) => a[field] - b[field]);
      break;
    case 'desc':
      filteredChargers.sort((a, b) => b[field] - a[field]);
      break;
    default:
  }

  return {
    chargers: filteredChargers,
    filters,
    sortOrder,
    setSortOrder,
    updateFilter,
    showCount,
    showMore,
  };
};

export default function ChargerPage() {
  const { formatMessage } = useIntl();

  const sortOptions = Object.keys(sortConfig).map((key) => ({
    value: key,
    children: formatMessage({ id: `replacementCharger.sortOptions.${key}` }),
  }));

  const { chargers, filters, updateFilter, sortOrder, setSortOrder, showCount, showMore } =
    useChargers();

  return (
    <Box m="0 auto" width="100%" maxWidth="1175px" padding="20px">
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={12} md={9} />
        <Grid item xs={12} md={3} sx={{ padding: '15px' }}>
          <Dropdown
            value={sortOrder}
            label={formatMessage({ id: 'replacementCharger.sortBy' })}
            onChange={(e) => setSortOrder(e.target.value)}
            options={sortOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="8px" position="relative">
        <Grid item xs={12} sm={4} md={3}>
          <Box position="sticky" top="10px">
            <FilterSidebar handleChange={updateFilter} filters={filters} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <ChargerList chargers={chargers.slice(0, showCount)} />
          {chargers.length > showCount && (
            <Grid xs={12} p="16px" textAlign="center">
              <ButtonInput onClick={showMore}>
                {formatMessage({ id: 'replacementCharger.showMore' })}
              </ButtonInput>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
