import React from 'react';
import { Grid } from '@mui/material';
import ChargerCard from './ChargerCard';

export default function ChargerList({ chargers, onSelect, selectedCharger }) {
  return (
    <Grid
      container
      spacing={2}
      sc={{
        padding: '15px',
      }}
    >
      {chargers.map((charger) => {
        return (
          <Grid item sm={6} md={4} key={charger.id}>
            <ChargerCard
              id={charger.id}
              imageUrl={charger.imageUrl}
              make={charger.make || ''}
              model={charger.model || ''}
              type={charger.chargerType || ''}
              chargeRate={charger.chargeRate || ''}
              plugType={charger.plugType || ''}
              isEstimatedPrice={charger.isEstimatedPrice}
              msrp={charger.msrp || ''}
              rebate={charger.rebate || 0}
              onSelect={() => onSelect(charger)}
              active={selectedCharger?.id === charger.id}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
