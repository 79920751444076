import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { CloseIcon } from '../../assets/icons/icons';
import ButtonInput from '../inputs/ButtonInput';
import { TrashIcon } from '../../assets/icons/icons';

export default function Modal({
  isOpen,
  cancelButtonText,
  deleteButtonText,
  confirmButtonText,
  onClose,
  onDelete,
  onConfirm,
  title,
  children,
}) {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={isOpen} maxWidth="lg" fullWidth sx={{ overflow: 'hidden' }}>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '25px' }}>
          <Box sx={{ width: 'fit-content', borderBottom: '8px solid #1E3575' }}>{title}</Box>
          <ButtonInput variant="link" onClick={onClose}>
            <span aria-label={formatMessage({ id: 'editVehicleSets.cancel' })} />
            <CloseIcon />
          </ButtonInput>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          overflow: 'hidden',
          padding: '0',
        }}
      >
        <Box sx={{ flex: '1 1', padding: '0 0 20px 20px', overflowY: 'auto' }}>{children}</Box>
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.common.dividerGrey}`,
            gap: '8px',
            padding: '20px',
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ButtonInput variant="contained" onClick={() => onConfirm()}>
            {confirmButtonText || formatMessage({ id: 'modal.confirm' })}
          </ButtonInput>
          {onDelete && (
            <ButtonInput onClick={onDelete} variant="link" alert>
              <TrashIcon />
              {deleteButtonText || formatMessage({ id: 'modal.delete' })}
            </ButtonInput>
          )}
          <ButtonInput variant="outlined" onClick={onClose}>
            {cancelButtonText || formatMessage({ id: 'modal.cancel' })}
          </ButtonInput>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
