import React from 'react';
import { Box, Link, makeStyles, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#DBDBDB',
    padding: theme.spacing(1),
    '& a': {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '16px',
      color: theme.palette.common.black,
    },
  },
}));

export default function GreyBox({ handleLinkClick }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Box className={classes.root}>
      <Grid container className="containers" justifyContent="center">
        <Grid item xs={2} style={{ textAlign: 'end' }}>
          <Link
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'header.greyBox.eFleets.url' }),
                formatMessage({ id: 'header.greyBox.eFleets.text' }),
              )
            }
          >
            {formatMessage({ id: 'header.greyBox.eFleets.text' })}
          </Link>
        </Grid>
        <Grid item xs={2}>
          <Link
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'header.greyBox.chargerRebates.url' }),
                formatMessage({ id: 'header.greyBox.chargerRebates.text' }),
                true,
              )
            }
          >
            {formatMessage({ id: 'header.greyBox.chargerRebates.text' })}
          </Link>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'start' }}>
          <Link
            href="#"
            onClick={() =>
              handleLinkClick(
                formatMessage({ id: 'header.greyBox.advisoryServices.url' }),
                formatMessage({ id: 'header.greyBox.advisoryServices.text' }),
                true,
              )
            }
          >
            {formatMessage({ id: 'header.greyBox.advisoryServices.text' })}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
