import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatVehicleSetTitle } from '../../utils/formatters';
import { useInputs } from '@bellawatt/use-inputs';
import ButtonInput from '../../components/inputs/ButtonInput';
import VehicleSet from '../../calculations/VehicleSet';
import { KWH_TO_MPGE } from '../../utils/assumptions';

export default function VehicleContent({ vehicleSet, handleEdit, isElectric }) {
  const { isOnboarded, setInput } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { vehicle, vehicleCount, fossilVehicle } = vehicleSet;
  const { name, type, subtype, imageUrl } = isElectric && vehicle ? vehicle : fossilVehicle;
  const vehicleSetTitle = formatVehicleSetTitle({
    vehicle: isElectric ? vehicle : fossilVehicle,
    vehicleCount,
  });

  const isPhev = vehicle?.fuel === 'PHEV';

  const handleVehicleEdit = () => {
    if (isOnboarded) {
      handleEdit(true);
    } else {
      setInput({ onboardingStep: 'vehicle' });
    }
  };

  const handleReplacementVehicleEdit = () => {
    handleEdit(true);
  };

  const getEfficiency = (vehicleSet) => {
    if (isElectric) {
      return vehicleSet.vehicle
        ? VehicleSet.electricEfficiencyInMilesPerEquivalent(vehicleSet).toFixed(0)
        : fossilVehicle.efficiencyInMilesPerKwh.toFixed(2);
    }
    return fossilVehicle.milesPerGallon;
  };

  const evEfficiencyLabel =
    vehicle && vehicle.fuel === 'PHEV'
      ? formatMessage({ id: 'panels.assessment.vehicles.mpgempg' })
      : formatMessage({ id: 'panels.assessment.vehicles.mpge' });
  const evEfficiencyValue =
    vehicle && vehicle.fuel === 'PHEV'
      ? (vehicle.efficiencyInMilesPerKwh * KWH_TO_MPGE).toFixed(1) + ' / ' + vehicle.milesPerGallon
      : ((getEfficiency(vehicleSet) * KWH_TO_MPGE) / 100).toFixed(1);

  return (
    <Box>
      <Box textAlign="left" fontWeight="600" fontSize="18px" paddingBottom="12px">
        {vehicleSet.name}
      </Box>
      <Box>
        <Box
          backgroundColor={theme.palette.common.greyF4}
          display="flex"
          justifyContent="space-between"
        >
          <Box padding="10px 15px" flexShrink={0}>
            Qty: {vehicleCount}
          </Box>
          <Box padding="10px 15px" fontWeight="700">
            {name}
          </Box>
        </Box>
        <Box paddingTop="17px" paddingBottom="12px">
          <img src={imageUrl} alt={vehicleSetTitle} style={{ maxWidth: '50%', height: 'auto' }} />
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          flexDirection="column"
          paddingLeft="17px"
          paddingRight="17px"
        >
          <VehicleCardStat
            title={formatMessage({ id: 'panels.assessment.vehicles.type' })}
            value={type}
          />
          <VehicleCardStat
            title={formatMessage({ id: 'panels.assessment.vehicles.subtype' })}
            value={subtype}
          />
          <VehicleCardStat
            title={formatMessage({ id: 'panels.assessment.vehicles.replaceYear' })}
            value={vehicleSet.replacementYear}
          />
          {isElectric && isPhev && (
            <VehicleCardStat
              title={formatMessage({ id: 'panels.assessment.vehicles.electricRange' })}
              value={vehicle.electricRange}
            />
          )}
          <VehicleCardStat
            title={
              isElectric
                ? formatMessage({ id: 'panels.assessment.vehicles.range' })
                : formatMessage({ id: 'panels.assessment.vehicles.miles' })
            }
            value={!isElectric ? vehicleSet.milesPerWorkday : vehicle.range}
          />
          <VehicleCardStat
            title={
              isElectric
                ? evEfficiencyLabel
                : formatMessage({ id: 'panels.assessment.vehicles.milesPerGallon' })
            }
            value={isElectric ? evEfficiencyValue : getEfficiency(vehicleSet)}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonInput
            variant="link"
            onClick={isElectric ? handleReplacementVehicleEdit : handleVehicleEdit}
          >
            {isElectric
              ? formatMessage({ id: 'panels.vehicles.changeReplacement' })
              : formatMessage({ id: 'edit' })}
          </ButtonInput>
        </Box>
      </Box>
    </Box>
  );
}

function VehicleCardStat({ title, value }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ color: 'common.siteGrey', fontWeight: '400', lineHeight: '22px' }}>{title}</Box>
      <Box>{value}</Box>
    </Box>
  );
}
