import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginRight: '5px',
  },
  checkBoxSpan: {
    width: '36px',
    display: 'inline-block',
    padding: '0px 0px 0px 2px',
  },
}));

export default function CheckboxButtonInput({
  checked,
  children,
  onClick,
  variant,
  unfixedWidth,
  ...rest
}) {
  const classes = useStyles();

  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        role="checkbox"
        aria-checked={checked}
        aria-label={children}
        onChange={onClick}
        className={classes.checkBox}
        {...rest}
      />
      <span className={classes.checkBoxSpan}>{children}</span>
    </>
  );
}
