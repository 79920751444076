import React from 'react';
import PanelIntro from '../PanelIntro/PanelIntro';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import AssumptionCard from '../AssumptionCard/AssumptionCard';
import { useInputs } from '@bellawatt/use-inputs';
import SiteList from './SiteList';
import ResetInputs from '../../components/ResetInputs/ResetInputs';
import Section from '../../components/Section/Section';

export default function Assessment() {
  const { formatMessage } = useIntl();
  const { sites, selectedSite, isAllSites } = useInputs();
  const subTitle = ['panels.assessment.sites', 'panels.assessment.viewOrEdit'];

  return (
    <Box>
      <PanelIntro
        title="panels.assessment.title"
        summary="panels.assessment.summary"
        subTitle={subTitle}
      />
      <Section
        title={formatMessage({ id: 'panels.assessment.siteSets' })}
        description={formatMessage({ id: 'panels.assessment.editSiteSets' })}
      >
        <SiteList sites={isAllSites ? sites : [selectedSite]} />
      </Section>
      <Section
        title={formatMessage({ id: 'panels.assessment.assumptions' })}
        description={formatMessage({ id: 'panels.assessment.editAssumptions' })}
      >
        <AssumptionCard />
      </Section>
      <Box textAlign="right" mt="30px">
        <ResetInputs />
      </Box>
    </Box>
  );
}
