import { Box } from '@mui/material';
import { useState } from 'react';
import Dropdown from '../components/inputs/Dropdown';
import { vehicleTypeDescriptions } from '../data/vehicleTypeDescriptions';
import StyledTable from '../components/StyledTable/StyledTable';
import PanelIntro from '../Panels/PanelIntro/PanelIntro';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ButtonInput from '../components/inputs/ButtonInput';

export default function VehicleDescriptionsPage() {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [group, setGroup] = useState('all');

  const groupOptions = [
    { value: 'all', children: 'All' },
    ...Object.keys(vehicleTypeDescriptions).map((key) => ({ value: key, children: key })),
  ];

  const descriptions =
    group === 'all'
      ? Object.values(vehicleTypeDescriptions).flatMap((group) => group)
      : vehicleTypeDescriptions[group];

  return (
    <Box container width="100%">
      <Box m="0 auto" width="100%" maxWidth="1175px" padding="0 20px 20px 20px">
        <PanelIntro title="vehicleTypesPage.title" summary="vehicleTypesPage.summary" />
        <Box maxWidth="200px" py={3}>
          <Dropdown
            label={formatMessage({ id: 'vehicleTypesPage.group' })}
            options={groupOptions}
            value={group}
            onChange={(event) => setGroup(event.target.value)}
          />
        </Box>
        <StyledTable
          styleName="primary"
          headings={[
            formatMessage({ id: 'vehicleTypesPage.manufacturer' }),
            formatMessage({ id: 'vehicleTypesPage.type' }),
            formatMessage({ id: 'vehicleTypesPage.subtype' }),
            formatMessage({ id: 'vehicleTypesPage.description' }),
          ]}
          rows={descriptions.map((row) => [
            <Box textAlign="center" px={2}>
              <img height="96" alt={row.subtype} src={row.imageSmall} />
            </Box>,
            <Box whiteSpace="nowrap">{row.type}</Box>,
            <Box whiteSpace="nowrap">{row.subtype}</Box>,
            row.description,
          ])}
        />
        <Box py={8} textAlign="left">
          <ButtonInput variant="outlined" onClick={() => history.goBack()}>
            {formatMessage({ id: 'vehicleTypesPage.navBack' })}
          </ButtonInput>
        </Box>
      </Box>
    </Box>
  );
}
