import BatterySchedule from './BatterySchedule';
import { RateCalculator, LoadProfile } from '@bellawatt/electric-rate-engine';
import dteD3 from '../data/rates/dteD3';
import { DEFAULT_LOAD_PROFILE_YEAR } from '../utils/assumptions';
import { insuranceRates } from '../data/insurance';
import { addComputedData } from '../utils/computedData';
import { findArchetype, findArchetypeById } from '../data/VEHICLES';
import { transformArchetypeToFossilVehicle } from '../utils/conversions';
import VehicleSets from './VehicleSets';

const VehicleSet = {
  /**
   * Includes miles for all vehicles in the vehicle set
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns number
   */
  annualMiles(vehicleSet, year = DEFAULT_LOAD_PROFILE_YEAR) {
    if (vehicleSet.vehicle?.fuel === 'PHEV') {
      return (
        (vehicleSet.milesPerWorkday *
          vehicleSet.workdays.length *
          vehicleSet.vehicleCount *
          365.25) /
        7
      );
    }
    return this.annualKwhUsage(vehicleSet, year) * this.electricEfficiencyInMilesPerKwh(vehicleSet);
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @returns number
   */
  electricEfficiencyInMilesPerKwh(vehicleSet) {
    const { batteryCapacityInKwh, rangeInMiles } = VehicleSet.getElectricVehicle(vehicleSet);
    return rangeInMiles / batteryCapacityInKwh;
  },

  electricEfficiencyInMilesPerEquivalent(vehicleSet) {
    const { batteryCapacityInKwh, rangeInMiles } = VehicleSet.getElectricVehicle(vehicleSet);
    return (rangeInMiles / batteryCapacityInKwh) * 33.705;
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @param {FuelPrices} fuelPrices
   * @param {boolean} ignoreYearCompare
   * @returns number
   */
  annualFossilFuelCosts(
    vehicleSet,
    year,
    { dieselPrice, gasolinePrice },
    ignoreYearCompare = false,
  ) {
    const {
      fossilVehicle: { milesPerGallon, fuel },
      customFossil,
      replacementYear,
    } = vehicleSet;

    if (!ignoreYearCompare && replacementYear > year) return 0;

    const gallons = this.annualMiles(vehicleSet) / (customFossil.milesPerGallon || milesPerGallon);

    const priceDictionary = {
      diesel: dieselPrice,
      gasoline: gasolinePrice,
    };
    const price = priceDictionary[customFossil.fuel || fuel];

    return price * gallons;
  },

  // TODO: (maybe) explicitly define parameters
  annualBatterySchedule: BatterySchedule.annualBatterySchedule,

  averageWorkdayBatterySchedule: BatterySchedule.averageWorkdayBatterySchedule,

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns
   */
  annualLoadProfile(vehicleSet, year) {
    const rawData = this.annualBatterySchedule(vehicleSet, year).map(
      ({ chargedKwh }) => chargedKwh,
    );

    return new LoadProfile(rawData, { year });
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @param {boolean} ignoreYearCompare
   * @returns number
   */
  annualElectricityCosts(vehicleSet, year, ignoreYearCompare = false) {
    if (!ignoreYearCompare && vehicleSet.replacementYear > year) return 0;
    const loadProfile = this.annualLoadProfile(vehicleSet, DEFAULT_LOAD_PROFILE_YEAR);

    // @ts-ignore
    const rateCalculator = new RateCalculator({
      ...dteD3,
      loadProfile,
    });
    return rateCalculator.annualCost();
  },

  /**
   *
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @param {FuelPrices} fuelPrices
   * @param {boolean} ignoreYearCompare
   * @returns
   */
  annualPhevFuelCosts(vehicleSet, year, fuelPrices, ignoreYearCompare = false) {
    if (!ignoreYearCompare && vehicleSet.replacementYear > year) return { electric: 0, fossil: 0 };

    const ev = VehicleSet.getElectricVehicle(vehicleSet);
    const electricRange = ev.electricRange;
    const electricMilesPerDay =
      vehicleSet.milesPerWorkday > electricRange ? electricRange : vehicleSet.milesPerWorkday;
    const fossilMilesPerDay =
      vehicleSet.milesPerWorkday > electricRange ? vehicleSet.milesPerWorkday - electricRange : 0;

    return {
      electric: this.annualElectricityCosts(
        {
          ...vehicleSet,
          milesPerWorkday: electricMilesPerDay,
        },
        year,
        (ignoreYearCompare = false),
      ),
      fossil: this.annualFossilFuelCosts(
        {
          ...vehicleSet,
          milesPerWorkday: fossilMilesPerDay,
        },
        year,
        fuelPrices,
        (ignoreYearCompare = false),
      ),
    };
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @returns number
   */
  annualKwhUsage(vehicleSet, year = DEFAULT_LOAD_PROFILE_YEAR) {
    return this.annualLoadProfile(vehicleSet, year).sum();
  },

  /**
   *
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns number
   */
  annualChargerPurchaseCosts(vehicleSet, year) {
    if (vehicleSet.replacementYear === year) {
      return vehicleSet.chargingWindows.reduce(
        (acc, { charger }) => acc + charger.msrp - charger.rebate,
        0,
      );
    }
    return 0;
  },

  /**
   *
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns number
   */
  totalChargerPurchaseCost(vehicleSet, year) {
    if (vehicleSet.replacementYear === year) {
      const groupedChargers = VehicleSets.groupedChargers([vehicleSet]);
      return groupedChargers.reduce(
        (acc, { charger, minimumRequired }) => acc + charger.msrp * minimumRequired,
        0,
      );
    }
    return 0;
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns number
   */
  annualEvMaintenanceCosts(vehicleSet, year) {
    if (year < vehicleSet.replacementYear) return 0;
    const { maintenanceCostPerMile } = this.getElectricVehicle(vehicleSet);
    const milesPerYear = this.annualMiles(vehicleSet);
    const yearsSinceAdoption = year - vehicleSet.replacementYear;
    const costFactor =
      yearsSinceAdoption === 0
        ? 1
        : 0.0004 * yearsSinceAdoption ** 4 -
          0.0128 * yearsSinceAdoption ** 3 +
          0.126 * yearsSinceAdoption ** 2 -
          0.1762 * yearsSinceAdoption +
          1.0563;
    return maintenanceCostPerMile * milesPerYear * costFactor;
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns number
   */
  annualFossilMaintenanceCosts(vehicleSet, year) {
    if (year < vehicleSet.replacementYear) return 0;

    const milesPerYear = this.annualMiles(vehicleSet);
    const yearsSinceAdoption = year - vehicleSet.replacementYear;
    const costFactor =
      yearsSinceAdoption === 0
        ? 1
        : 0.0002 * yearsSinceAdoption ** 4 -
          0.0065 * yearsSinceAdoption ** 3 +
          0.0547 * yearsSinceAdoption ** 2 +
          0.1258 * yearsSinceAdoption +
          0.7821;

    const maintenanceCostPerMile =
      vehicleSet.customFossil.maintenanceCostPerMile ||
      vehicleSet.fossilVehicle.maintenanceCostPerMile;
    return maintenanceCostPerMile * milesPerYear * costFactor;
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns number
   */
  annualEmissionsSaved(vehicleSet, year) {
    const { replacementYear, fossilVehicle, customFossil } = vehicleSet;
    if (replacementYear > year) return 0;

    const EMISSIONS_GRAMS_CO2_PER_KWH = 388; // pulled from /location?postcode=48014
    const GRAMS_PER_LB = 453.59; // Constant
    const LBS_PER_TON = 2000; // Constant

    const milesPerYear = this.annualMiles(vehicleSet);
    const emissionsPerGallon = fossilVehicle.fuel === 'diesel' ? 22.37 : 19.64;

    const { rangeInMiles, batteryCapacityInKwh } = this.getElectricVehicle(vehicleSet);

    const efficiencyInMilesPerKwh = rangeInMiles / batteryCapacityInKwh;
    const efficiencyInMilesPerGal = customFossil.milesPerGallon || fossilVehicle.milesPerGallon;

    const evEmissionsInGrams =
      (milesPerYear * EMISSIONS_GRAMS_CO2_PER_KWH) / efficiencyInMilesPerKwh;
    const gasolineEmissionsInGrams =
      (milesPerYear / efficiencyInMilesPerGal) * emissionsPerGallon * GRAMS_PER_LB;

    const emissionsSavedInTons =
      (gasolineEmissionsInGrams - evEmissionsInGrams) / (GRAMS_PER_LB * LBS_PER_TON);

    return emissionsSavedInTons;
  },

  /**
   * @param {number} vehicleCount
   * @param {number} msrp
   * @param {string} formFactor
   * @returns number
   */
  annualInsuranceCosts(vehicleCount, msrp, formFactor) {
    const rateKey = Object.keys(insuranceRates).find(
      (key) => key.toLowerCase() === formFactor.toLowerCase(),
    );
    if (!rateKey) return 0;

    const { liabilityPerVehicle, comprehensivePerThousandDollars } = insuranceRates[rateKey];
    const insurancePerVehicle =
      liabilityPerVehicle + (comprehensivePerThousandDollars * msrp) / 1000;
    return insurancePerVehicle * vehicleCount;
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @param {number} startYear
   * @param {boolean} isFossilFleet
   * @param {FuelPrices} fuelPrices
   * @returns
   */
  totalAnnualCosts(vehicleSet, year, startYear, isFossilFleet, fuelPrices, isTco = false) {
    const { replacementYear, vehicleCount, fossilFuelType, fossilVehicle, customFossil, vehicle } =
      vehicleSet;
    if (year < replacementYear) {
      return {
        msrp: 0,
        insurance: 0,
        maintenance: 0,
        electricity: 0,
        gasoline: 0,
        diesel: 0,
        chargerPurchase: 0,
      };
    }

    let vehicleCost = 0;
    if (isFossilFleet) {
      vehicleCost = customFossil.msrp || fossilVehicle.msrp; // generic ICE
    } else if (vehicle) {
      vehicleCost = vehicle.msrp; // selected EV
    } else {
      vehicleCost = fossilVehicle.msrp; // generic EV
    }
    const vehicleSetValue = vehicleCost * vehicleCount;
    const insurance = this.annualInsuranceCosts(
      vehicleCount,
      vehicleCost,
      vehicle && !isFossilFleet ? vehicle.formFactor : fossilVehicle.formFactor,
    );

    const maintenance = isFossilFleet
      ? this.annualFossilMaintenanceCosts(vehicleSet, year)
      : this.annualEvMaintenanceCosts(vehicleSet, year);

    const phevCosts =
      vehicleSet.vehicle?.fuel === 'PHEV' &&
      this.annualPhevFuelCosts(vehicleSet, year, fuelPrices, true);

    const electricity = isFossilFleet
      ? 0
      : phevCosts
      ? phevCosts.electric
      : this.annualElectricityCosts(vehicleSet, replacementYear);

    let fuel = fossilFuelType;

    if (isTco) {
      fuel = fossilVehicle.fuel;
    }

    const gasoline =
      isFossilFleet && fuel === 'gasoline'
        ? this.annualFossilFuelCosts(vehicleSet, year, fuelPrices, true)
        : phevCosts
        ? phevCosts.fossil
        : 0;

    const diesel =
      isFossilFleet && fuel === 'diesel'
        ? this.annualFossilFuelCosts(vehicleSet, year, fuelPrices, true)
        : 0;

    const chargerPurchase = isFossilFleet
      ? 0
      : isTco
      ? this.totalChargerPurchaseCost(vehicleSet, year)
      : this.annualChargerPurchaseCosts(vehicleSet, year);

    return {
      msrp: year === replacementYear ? vehicleSetValue : 0,
      insurance,
      maintenance,
      electricity,
      gasoline,
      diesel,
      chargerPurchase,
    };
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @param {number} year
   * @returns number
   */
  minimumStateOfCharge(vehicleSet, year) {
    const batterySchedule = this.annualBatterySchedule(vehicleSet, year);
    const minCharge = Math.min(...batterySchedule.map((x) => x.startKwh));
    const maxCharge = Math.max(...batterySchedule.map((x) => x.startKwh));
    return Math.round((minCharge / maxCharge) * 100);
  },

  /**
   * @param {VehicleSet} vehicleSet
   * @returns Vehicle | ApiVehicle
   */
  getElectricVehicle(vehicleSet) {
    /** @type VehicleSet */
    const newVehicleSet = addComputedData({ ...vehicleSet });
    return (
      newVehicleSet.vehicle ??
      transformArchetypeToFossilVehicle(
        findArchetypeById(newVehicleSet.fossilVehicleId) ||
          findArchetype(newVehicleSet.fossilVehicle),
      )
    );
  },
};

export default VehicleSet;
