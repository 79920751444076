import React from 'react';
import { useTheme } from '@mui/material';
import { generateTicks } from '../../../utils/functions';
import { useIntl } from 'react-intl';
import StyledBarGraph from '../../Charts/StyledBarGraph/StyledBarGraph';
import StyledCollapse from '../../StyledCollapse/StyledCollapse';
import StyledTable from '../../../components/StyledTable/StyledTable';
import { formatAsDollars } from '../../../utils/formatters';
import Section from '../../../components/Section/Section';

export default function FuelAndElectricPricesCompared({ chartLabels, chartData, tableData }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const generateElectricFuelComparisonData = (labels, { diesel, gasoline, electric, phevGas }) => {
    return {
      labels: labels,
      datasets: [
        {
          title: '',
          label: formatMessage({ id: 'panels.fuel.detailedCostTable.gas' }),
          data: gasoline,
          backgroundColor: theme.palette.common.grey500,
          stack: 'Stack 0',
        },
        {
          label: formatMessage({ id: 'panels.fuel.detailedCostTable.diesel' }),
          data: diesel,
          backgroundColor: theme.palette.common.grey300,
          stack: 'Stack 1',
        },
        {
          label: formatMessage({ id: 'panels.fuel.detailedCostTable.electrified' }),
          data: electric,
          backgroundColor: theme.palette.common.lightAqua,
          stack: 'Stack 2',
        },
        {
          label: formatMessage({ id: 'panels.fuel.detailedCostTable.gas' }),
          hideFromLegend: true,
          data: phevGas,
          backgroundColor: theme.palette.common.grey500,
          stack: 'Stack 2',
        },
      ],
    };
  };

  const efComparedData = generateElectricFuelComparisonData(chartLabels, chartData);
  const efComparedTicks = generateTicks({
    datasets: efComparedData.datasets,
    formatThousands: true,
  });
  const legendOptions = {
    position: 'top',
    labels: {
      font: {
        size: 16,
      },
      filter: (legendItem, chartData) => {
        return !chartData?.datasets?.[legendItem?.datasetIndex]?.hideFromLegend;
      },
    },
  };

  return (
    <Section
      title={formatMessage({ id: 'panels.fuel.electricFuelCompared' })}
      description={formatMessage({ id: 'panels.fuel.electrificationTimeline' })}
    >
      <StyledBarGraph
        data={efComparedData}
        yTicks={efComparedTicks}
        showLegend={true}
        legendOptions={legendOptions}
        indexAxis={'x'}
        stacked={false}
        xStacked={true}
        large={true}
        tooltipTitleFormatter={() => {}}
        tooltipLabelFormatter={(data) =>
          `${data.dataset.label}: ${formatAsDollars(data.formattedValue)}`
        }
        showHorizontalGrid
        showVerticalGrid
      />
      <StyledCollapse
        title={'Detailed Cost Table'}
        styleName="primary"
        leftIcon={true}
        pencilIcon={false}
      >
        <StyledTable headings={tableData.headings} rows={tableData.rows} centered={true} />
      </StyledCollapse>
    </Section>
  );
}
