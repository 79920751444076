import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import carIcon from '../../assets/images/icons/Car.svg';
import jerrycanIcon from '../../assets/images/icons/Jerrycan.svg';
import co2Icon from '../../assets/images/icons/CO2.svg';
import { min, times } from 'lodash';
import { formatAsDollars, formatAsThousands } from '../../utils/formatters';
import { useInputs } from '@bellawatt/use-inputs';
import sum from 'lodash/sum';
import Totals from '../../calculations/Totals';

const styles = {
  root: {
    backgroundColor: (theme) => theme.palette.common.brandBlue,
    padding: (theme) => theme.spacing(3),
  },
  summaryContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  summary: (theme) => ({
    width: '100%',
    maxWidth: '1135px',
    [theme.breakpoints.down('sm')]: {
      gap: '24px',
    },
  }),
  headerText: (theme) => ({
    textTransform: 'uppercase',
    color: '#FFF',
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: theme.spacing(2),
    paddingLeft: '35px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
      paddingLeft: '10px',
    },
  }),
  iconImg: {
    paddingTop: '10px',
  },
  smallHeader: {
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '400',
    color: '#FFF',
  },
  bigText: {
    textAlign: 'left',
    color: '#FFF',
    fontSize: '32px',
    lineHeight: '50px',
    fontWeight: '600',
  },
};

export default function FleetSummary() {
  const { formatMessage } = useIntl();

  const { selectedSite, sites, isAllSites, startYear, gasolinePrice, dieselPrice } = useInputs();
  const totalVehicles = sum(
    sites.map(({ vehicleSets }) => sum(vehicleSets.map(({ vehicleCount }) => vehicleCount))),
  );

  const emissionsSavingsInTons = Totals.emissionsSavings(sites, startYear);

  const selectedSites = isAllSites ? sites : [selectedSite];

  // first year that a vehicle is electrified for given site (or all sites)
  const firstReplacementYear = isAllSites
    ? min(sites.flatMap((site) => site.vehicleSets).map(({ replacementYear }) => replacementYear))
    : min(selectedSite.vehicleSets.map(({ replacementYear }) => replacementYear));
  const yearRange = times(10, (i) => startYear + i);

  const tenYearData = yearRange.reduce(
    (acc, year) => ({
      ...acc,
      [year]:
        year < firstReplacementYear
          ? {}
          : Totals.getAnnualFleetFuelCosts(selectedSites, year, {
              dieselPrice,
              gasolinePrice,
            }),
    }),
    {},
  );

  let tenYearFuelCost = 0;
  let tenYearElectricCost = 0;

  for (const year of yearRange) {
    const { dieselCost, gasolineCost, bevElectricCost, phevElectricCost, phevGasCost } =
      tenYearData[year];

    tenYearFuelCost += dieselCost || 0;
    tenYearFuelCost += gasolineCost || 0;
    tenYearElectricCost += bevElectricCost || 0;
    tenYearElectricCost += phevElectricCost || 0;
    tenYearElectricCost += phevGasCost || 0;
  }

  const tenYearSavings = tenYearFuelCost - tenYearElectricCost;

  const SummaryItem = ({ imgSrc, altText, headerText, bigText }) => (
    <Grid item xs={12} sm={3}>
      <Grid container spacing={{ xs: 0, sm: 2 }} justifyContent={{ sm: 'flex-end' }}>
        <Grid item xs={3}>
          <Box sx={styles.iconImg}>
            <img src={imgSrc} alt={altText} />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={styles.smallHeader}>{headerText}</Typography>
          <Typography sx={styles.bigText}>{bigText}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Box sx={styles.root}>
      <Box sx={styles.summaryContainer}>
        <Grid container justifyContent="space-between" sx={styles.summary}>
          <Grid item xs={11} sx={styles.headerText}>
            {formatMessage({ id: 'header.fleetSummary.leadText' })}
          </Grid>
          <SummaryItem
            imgSrc={carIcon}
            altText="car"
            headerText={formatMessage({ id: 'header.fleetSummary.size' })}
            bigText={formatMessage({ id: 'header.fleetSummary.totalVehicles' }, { totalVehicles })}
          />
          <SummaryItem
            imgSrc={jerrycanIcon}
            altText="jerrycan"
            headerText={formatMessage(
              { id: 'header.fleetSummary.fuelSavings' },
              { years: `${yearRange.length}-year` },
            )}
            bigText={formatAsDollars(tenYearSavings)}
          />
          <SummaryItem
            imgSrc={co2Icon}
            altText="CO2"
            headerText={formatMessage({ id: 'header.fleetSummary.emissionsSavings' })}
            bigText={`${formatAsThousands(Math.round(emissionsSavingsInTons))} tons`}
          />
        </Grid>
      </Box>
    </Box>
  );
}
