import React from 'react';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatAsDollars } from '../../utils/formatters';

export default function ChargerCard({ charger }) {
  const { formatMessage } = useIntl();

  /** @type Charger */
  const { make, model, imageUrl, type, chargeRate, plugType, isEstimatedPrice, msrp, rebate } =
    charger;

  const renderChargerValue = () => {
    let value = msrp ? formatAsDollars(msrp) : '-';

    if (isEstimatedPrice) value = `${value} *`;

    return <Box sx={{ fontWeight: 'bold', fontSize: '22px' }}> {value} </Box>;
  };

  return (
    <Box
      sx={{
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
      }}
    >
      <Box sx={{ padding: '15px' }}>
        <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>{make}</Box>

        <Box>{model}</Box>

        <Box sx={{ textAlign: 'center', minHeight: '250px' }}>
          <img src={imageUrl} alt={make} style={{ maxWidth: '75%' }} />
        </Box>

        <Box sx={{ fontSize: '14px' }}>
          <Grid container justifyContent="space-between">
            <Grid item>{formatMessage({ id: 'replacementCharger.type' })}</Grid>
            <Grid item sx={{ fontWeight: 'bold' }}>
              {type}
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>{formatMessage({ id: 'replacementCharger.chargeRate' })}</Grid>
            <Grid item sx={{ fontWeight: 'bold' }}>
              {`${chargeRate} kW`}
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>{formatMessage({ id: 'replacementCharger.plugType' })}</Grid>
            <Grid item sx={{ fontWeight: 'bold' }}>
              {plugType}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid container>
        <Grid
          item
          sx={{
            textAlign: 'center',
            width: '50%',
            padding: '15px 5px',
            border: '1px solid #D9D9D9',
            borderLeft: 'none',
          }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            {formatMessage({ id: `replacementCharger.msrp${isEstimatedPrice ? 'Estimated' : ''}` })}
          </Box>
          {renderChargerValue()}
        </Grid>
        <Grid
          item
          sx={{
            textAlign: 'center',
            width: '50%',
            padding: '15px 5px',
            border: '1px solid #D9D9D9',
            borderRight: 'none',
            borderLeft: 'none',
          }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>{formatMessage({ id: 'replacementCharger.dteRebate' })}</Box>
          <Box sx={{ fontWeight: 'bold', fontSize: '22px' }}>{formatAsDollars(rebate)}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}
