const sampleApiVehicles = [
  {
    fromApi: true,
    id: 'arrival_bus__bev',
    category: 'On-Road',
    name: 'Arrival Bus',
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Arrival',
    year: 2021,
    model: 'Bus',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/arrival_bus__bev/arrival_bus__bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 400,
    weightClass: 7,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'arrival_van_111_kwh_bev',
    category: 'On-Road',
    name: 'Arrival Van 111 kWh',
    type: 'Step Van',
    subtype: 'Light Duty',
    formFactor: 'step_van',
    make: 'Arrival',
    year: 2021,
    model: 'Van',
    duty: 'light',
    trim: '111 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/arrival_van_111_kwh_bev/arrival_van_111_kwh_bev.png',
    rangeInMiles: 180,
    rangeInHours: 0,
    range: 180,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 111,
    weightClass: 2,
    msrp: 65000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'arrival_van_67_kwh_bev',
    category: 'On-Road',
    name: 'Arrival Van 67 kWh',
    type: 'Step Van',
    subtype: 'Light Duty',
    formFactor: 'step_van',
    make: 'Arrival',
    year: 2021,
    model: 'Van',
    duty: 'light',
    trim: '67 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/arrival_van_67_kwh_bev/arrival_van_67_kwh_bev.png',
    rangeInMiles: 112,
    rangeInHours: 0,
    range: 112,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 67,
    weightClass: 2,
    msrp: 55000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'arrival_van_89_kwh_bev',
    category: 'On-Road',
    name: 'Arrival Van 89 kWh',
    type: 'Step Van',
    subtype: 'Light Duty',
    formFactor: 'step_van',
    make: 'Arrival',
    year: 2021,
    model: 'Van',
    duty: 'light',
    trim: '89 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/arrival_van_89_kwh_bev/arrival_van_89_kwh_bev.png',
    rangeInMiles: 149,
    rangeInHours: 0,
    range: 149,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 89,
    weightClass: 2,
    msrp: 60000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'atlis_xt_pickup_300_mile_range_bev',
    category: 'On-Road',
    name: 'Atlis XT Pickup 300 Mile Range',
    type: 'Pick-up Truck',
    subtype: 'Medium Duty',
    formFactor: 'pickup_truck',
    make: 'Atlis',
    year: 2021,
    model: 'XT Pickup',
    duty: 'medium',
    trim: '300 Mile Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/atlis_xt_pickup_300_mile_range_bev/atlis_xt_pickup_300_mile_range_bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 125,
    weightClass: 6,
    msrp: 45000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'atlis_xt_pickup_400_mile_range_bev',
    category: 'On-Road',
    name: 'Atlis XT Pickup 400 Mile Range',
    type: 'Pick-up Truck',
    subtype: 'Medium Duty',
    formFactor: 'pickup_truck',
    make: 'Atlis',
    year: 2021,
    model: 'XT Pickup',
    duty: 'medium',
    trim: '400 Mile Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/atlis_xt_pickup_400_mile_range_bev/atlis_xt_pickup_400_mile_range_bev.png',
    rangeInMiles: 400,
    rangeInHours: 0,
    range: 400,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 175,
    weightClass: 6,
    msrp: 58000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'atlis_xt_pickup_500_mile_range_bev',
    category: 'On-Road',
    name: 'Atlis XT Pickup 500 Mile Range',
    type: 'Pick-up Truck',
    subtype: 'Medium Duty',
    formFactor: 'pickup_truck',
    make: 'Atlis',
    year: 2021,
    model: 'XT Pickup',
    duty: 'medium',
    trim: '500 Mile Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/atlis_xt_pickup_500_mile_range_bev/atlis_xt_pickup_500_mile_range_bev.png',
    rangeInMiles: 500,
    rangeInHours: 0,
    range: 500,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 250,
    weightClass: 6,
    msrp: 69000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Audi_e-tron__BEV_2022',
    category: 'On-Road',
    name: 'Audi e-tron',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Audi',
    year: 2022,
    model: 'e-tron',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Audi_e-tron__BEV_2022/116.png',
    rangeInMiles: 222,
    rangeInHours: 0,
    range: 222,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 95,
    weightClass: 2,
    msrp: 65900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Audi_e-tron_GT__BEV_2022',
    category: 'On-Road',
    name: 'Audi e-tron GT',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Audi',
    year: 2022,
    model: 'e-tron GT',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Audi_e-tron_GT__BEV_2022/116.png',
    rangeInMiles: 238,
    rangeInHours: 0,
    range: 238,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 93.4,
    weightClass: 2,
    msrp: 102400,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Audi_e-tron_Sportback__BEV_2022',
    category: 'On-Road',
    name: 'Audi e-tron Sportback',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Audi',
    year: 2022,
    model: 'e-tron Sportback',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Audi_e-tron_Sportback__BEV_2022/116.png',
    rangeInMiles: 218,
    rangeInHours: 0,
    range: 218,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 95,
    weightClass: 2,
    msrp: 69100,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'blue_bird_all_american_re_electric_bev',
    category: 'On-Road',
    name: 'Blue Bird All American RE Electric',
    type: 'School Bus',
    subtype: 'Type D',
    formFactor: 'school_bus',
    make: 'Blue Bird',
    year: 2021,
    model: 'All American',
    duty: 'heavy',
    trim: 'RE Electric',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/blue_bird_all_american_re_electric_bev/blue_bird_all_american_re_electric_bev.png',
    rangeInMiles: 120,
    rangeInHours: 0,
    range: 120,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 155,
    weightClass: 8,
    msrp: 400000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'blue_bird_micro_bird_g5_electric_bev',
    category: 'On-Road',
    name: 'Blue Bird Micro Bird G5 Electric',
    type: 'School Bus',
    subtype: 'Type A',
    formFactor: 'school_bus',
    make: 'Blue Bird',
    year: 2021,
    model: 'Micro Bird',
    duty: 'medium',
    trim: 'G5 Electric',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/blue_bird_micro_bird_g5_electric_bev/blue_bird_micro_bird_g5_electric_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 88,
    weightClass: 4,
    msrp: 225000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.12,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'blue_bird_vision_electric_bev',
    category: 'On-Road',
    name: 'Blue Bird Vision Electric',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'Blue Bird',
    year: 2021,
    model: 'Vision',
    duty: 'heavy',
    trim: 'Electric',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/blue_bird_vision_electric_bev/blue_bird_vision_electric_bev.png',
    rangeInMiles: 120,
    rangeInHours: 0,
    range: 120,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 155,
    weightClass: 7,
    msrp: 350000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'bollinger_b1__bev',
    category: 'On-Road',
    name: 'Bollinger B1',
    type: 'Utility Truck',
    subtype: 'Medium Duty',
    formFactor: 'utility_truck',
    make: 'Bollinger',
    year: 2021,
    model: 'B1',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/bollinger_b1__bev/bollinger_b1__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 142,
    weightClass: 3,
    msrp: 125000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'bollinger_b2__bev',
    category: 'On-Road',
    name: 'Bollinger B2',
    type: 'Pick-up Truck',
    subtype: 'Medium Duty',
    formFactor: 'pickup_truck',
    make: 'Bollinger',
    year: 2021,
    model: 'B2',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/bollinger_b2__bev/bollinger_b2__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 142,
    weightClass: 3,
    msrp: 125000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'bollinger_b2_chassis-e_cab_awd_bev',
    category: 'On-Road',
    name: 'Bollinger B2 Chassis-E Cab AWD',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    formFactor: 'cutaway_chassis',
    make: 'Bollinger',
    year: 2021,
    model: 'B2',
    duty: 'medium',
    trim: 'Chassis-E Cab AWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/bollinger_b2_chassis-e_cab_awd_bev/bollinger_b2_chassis-e_cab_awd_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 180,
    weightClass: 3,
    msrp: 100000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'bollinger_b2_chassis-e_cab_drw_bev',
    category: 'On-Road',
    name: 'Bollinger B2 Chassis-E Cab DRW',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    formFactor: 'cutaway_chassis',
    make: 'Bollinger',
    year: 2021,
    model: 'B2',
    duty: 'medium',
    trim: 'Chassis-E Cab DRW',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/bollinger_b2_chassis-e_cab_drw_bev/bollinger_b2_chassis-e_cab_drw_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 140,
    weightClass: 3,
    msrp: 72500,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'bollinger_b2_chassis-e_cab_rwd_bev',
    category: 'On-Road',
    name: 'Bollinger B2 Chassis-E Cab RWD',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    formFactor: 'cutaway_chassis',
    make: 'Bollinger',
    year: 2021,
    model: 'B2',
    duty: 'medium',
    trim: 'Chassis-E Cab RWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/bollinger_b2_chassis-e_cab_rwd_bev/bollinger_b2_chassis-e_cab_rwd_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 120,
    weightClass: 3,
    msrp: 70000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'BrightDrop_EV600_BEV',
    category: 'On-Road',
    name: 'BrightDrop EV600',
    type: 'Step Van',
    subtype: 'Light Duty',
    formFactor: 'step_van',
    make: 'BrightDrop',
    year: 2022,
    model: 'EV600',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/BrightDrop_EV600_BEV/116.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 2,
    msrp: 145000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_6f_281_kwh_bev',
    category: 'On-Road',
    name: 'BYD 6F',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'BYD',
    year: 2021,
    model: '6F',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_6f_281_kwh_bev/byd_6f_281_kwh_bev.png',
    rangeInMiles: 190,
    rangeInHours: 0,
    range: 190,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 281,
    weightClass: 6,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_6f_343_kwh_bev',
    category: 'On-Road',
    name: 'BYD 6F ER',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'BYD',
    year: 2021,
    model: '6F',
    duty: 'medium',
    trim: 'ER',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_6f_343_kwh_bev/byd_6f_343_kwh_bev.png',
    rangeInMiles: 235,
    rangeInHours: 0,
    range: 235,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 343,
    weightClass: 6,
    msrp: 218000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_6r__bev',
    category: 'On-Road',
    name: 'BYD 6R',
    type: 'Refuse Truck',
    subtype: 'Medium Duty',
    formFactor: 'refuse_truck',
    make: 'BYD',
    year: 2021,
    model: '6R',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_6r__bev/byd_6r__bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 211,
    weightClass: 6,
    msrp: 220000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_8r_295_kwh_bev',
    category: 'On-Road',
    name: 'BYD 8R',
    type: 'Refuse Truck',
    subtype: 'Heavy Duty',
    formFactor: 'refuse_truck',
    make: 'BYD',
    year: 2021,
    model: '8R',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_8r_295_kwh_bev/byd_8r_295_kwh_bev.png',
    rangeInMiles: 90,
    rangeInHours: 0,
    range: 90,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 281,
    weightClass: 8,
    msrp: 460000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_8r_403_kwh_bev',
    category: 'On-Road',
    name: 'BYD 8R ER',
    type: 'Refuse Truck',
    subtype: 'Heavy Duty',
    formFactor: 'refuse_truck',
    make: 'BYD',
    year: 2021,
    model: '8R',
    duty: 'heavy',
    trim: 'ER',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_8r_403_kwh_bev/byd_8r_403_kwh_bev.png',
    rangeInMiles: 125,
    rangeInHours: 0,
    range: 125,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 403,
    weightClass: 8,
    msrp: 470000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_8tt_422_kwh_bev',
    category: 'On-Road',
    name: 'BYD 8TT',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'BYD',
    year: 2021,
    model: '8TT',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_8tt_422_kwh_bev/byd_8tt_422_kwh_bev.png',
    rangeInMiles: 190,
    rangeInHours: 0,
    range: 190,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 422,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_8tt_563_kwh_bev',
    category: 'On-Road',
    name: 'BYD 8TT',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'BYD',
    year: 2021,
    model: '8TT',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_8tt_563_kwh_bev/byd_8tt_563_kwh_bev.png',
    rangeInMiles: 255,
    rangeInHours: 0,
    range: 255,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 563,
    weightClass: 8,
    msrp: 320000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_c10m_45_ft_bev',
    category: 'On-Road',
    name: "BYD C10M 45'",
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    formFactor: 'motor_coach',
    make: 'BYD',
    year: 2021,
    model: 'C10M',
    duty: 'heavy',
    trim: "45'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_c10m_45_ft_bev/byd_c10m_45_ft_bev.png',
    rangeInMiles: 172,
    rangeInHours: 0,
    range: 172,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 446,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_c10ms_45_ft_bev',
    category: 'On-Road',
    name: "BYD C10MS 45'",
    type: 'Motor Coach',
    subtype: 'Double Decker',
    formFactor: 'motor_coach',
    make: 'BYD',
    year: 2021,
    model: 'C10MS',
    duty: 'heavy',
    trim: "45'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_c10ms_45_ft_bev/byd_c10ms_45_ft_bev.png',
    rangeInMiles: 159,
    rangeInHours: 0,
    range: 159,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 446,
    weightClass: 8,
    msrp: 1050000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_c6_23_ft_bev',
    category: 'On-Road',
    name: "BYD C6M 23'",
    type: 'Motor Coach',
    subtype: 'Medium Duty',
    formFactor: 'motor_coach',
    make: 'BYD',
    year: 2021,
    model: 'C6M',
    duty: 'medium',
    trim: "23'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_c6_23_ft_bev/byd_c6_23_ft_bev.png',
    rangeInMiles: 141,
    rangeInHours: 0,
    range: 141,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 141,
    weightClass: 6,
    msrp: 210000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.25,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_c8m_35_ft_bev',
    category: 'On-Road',
    name: "BYD C8M 35'",
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    formFactor: 'motor_coach',
    make: 'BYD',
    year: 2021,
    model: 'C8M',
    duty: 'heavy',
    trim: "35'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_c8m_35_ft_bev/byd_c8m_35_ft_bev.png',
    rangeInMiles: 149,
    rangeInHours: 0,
    range: 149,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 313,
    weightClass: 8,
    msrp: 700000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_c8ms_35_ft_bev',
    category: 'On-Road',
    name: "BYD C8MS 35'",
    type: 'Motor Coach',
    subtype: 'Double Decker',
    formFactor: 'motor_coach',
    make: 'BYD',
    year: 2021,
    model: 'C8MS',
    duty: 'heavy',
    trim: "35'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_c8ms_35_ft_bev/byd_c8ms_35_ft_bev.png',
    rangeInMiles: 125,
    rangeInHours: 0,
    range: 125,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 313,
    weightClass: 8,
    msrp: 1050000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_c9m_40_ft_bev',
    category: 'On-Road',
    name: "BYD C9M 40'",
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    formFactor: 'motor_coach',
    make: 'BYD',
    year: 2021,
    model: 'C9M',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_c9m_40_ft_bev/byd_c9m_40_ft_bev.png',
    rangeInMiles: 186,
    rangeInHours: 0,
    range: 186,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 446,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_k11m_60_ft_bev',
    category: 'On-Road',
    name: "BYD K11M 60'",
    type: 'Transit Bus',
    subtype: 'Articulated Bus',
    formFactor: 'transit_bus',
    make: 'BYD',
    year: 2021,
    model: 'K11M',
    duty: 'heavy',
    trim: "60'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_k11m_60_ft_bev/byd_k11m_60_ft_bev.png',
    rangeInMiles: 193,
    rangeInHours: 0,
    range: 193,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 578,
    weightClass: 8,
    msrp: 1150000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_k7_30_ft_bev',
    category: 'On-Road',
    name: "BYD K7M 30'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'BYD',
    year: 2021,
    model: 'K7M',
    duty: 'heavy',
    trim: "30'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_k7_30_ft_bev/byd_k7_30_ft_bev.png',
    rangeInMiles: 158,
    rangeInHours: 0,
    range: 158,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 215,
    weightClass: 7,
    msrp: 700000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'byd_k9_40_ft_bev',
    category: 'On-Road',
    name: "BYD K9 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'BYD',
    year: 2021,
    model: 'K9',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/byd_k9_40_ft_bev/byd_k9_40_ft_bev.png',
    rangeInMiles: 156,
    rangeInHours: 0,
    range: 156,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 313,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Chevrolet_Bolt_EUV_BEV_2022',
    category: 'On-Road',
    name: 'Chevrolet Bolt EUV',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Chevrolet',
    year: 2022,
    model: 'Bolt EUV',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Chevrolet_Bolt_EUV_BEV_2022/116.png',
    rangeInMiles: 247,
    rangeInHours: 0,
    range: 247,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 65,
    weightClass: 1,
    msrp: 33000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Chevrolet_Bolt__BEV_2022',
    category: 'On-Road',
    name: 'Chevrolet Bolt EV',
    type: 'Passenger',
    subtype: 'Hatchback',
    formFactor: 'Hatchback',
    make: 'Chevrolet',
    year: 2022,
    model: 'Bolt EV',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Chevrolet_Bolt__BEV_2022/116.png',
    rangeInMiles: 259,
    rangeInHours: 0,
    range: 259,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 65,
    weightClass: 1,
    msrp: 31000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.01,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'evt_urban_truck_class_3_bev',
    category: 'On-Road',
    name: 'EVT Urban Truck Class 3',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'EVT',
    year: 2021,
    model: 'Urban Truck',
    duty: 'medium',
    trim: 'Class 3',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/evt_urban_truck_class_3_bev/evt_urban_truck_class_3_bev.png',
    rangeInMiles: 174,
    rangeInHours: 0,
    range: 174,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 92.5,
    weightClass: 3,
    msrp: 119900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'evt_urban_truck_class_4_bev',
    category: 'On-Road',
    name: 'EVT Urban Truck Class 4',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'EVT',
    year: 2021,
    model: 'Urban Truck',
    duty: 'medium',
    trim: 'Class 4',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/evt_urban_truck_class_4_bev/evt_urban_truck_class_4_bev.png',
    rangeInMiles: 174,
    rangeInHours: 0,
    range: 174,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 92.5,
    weightClass: 4,
    msrp: 119900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'evt_urban_truck_class_5_bev',
    category: 'On-Road',
    name: 'EVT Urban Truck Class 5',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'EVT',
    year: 2021,
    model: 'Urban Truck',
    duty: 'medium',
    trim: 'Class 5',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/evt_urban_truck_class_5_bev/evt_urban_truck_class_5_bev.png',
    rangeInMiles: 174,
    rangeInHours: 0,
    range: 174,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 145,
    weightClass: 5,
    msrp: 139900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ford_e-transit_cargo_van_extended_high_bev_2022',
    category: 'On-Road',
    name: 'Ford E-Transit Cargo Van Extended Body High Roof',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Ford',
    year: 2022,
    model: 'E-Transit',
    duty: 'light',
    trim: 'Cargo Van Extended Body High Roof',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ford_e-transit_cargo_van_extended_high_bev_2022/116.png',
    rangeInMiles: 108,
    rangeInHours: 0,
    range: 108,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 67,
    weightClass: 2,
    msrp: 52690,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ford_e-transit_cargo_van_long_high_bev_2022',
    category: 'On-Road',
    name: 'Ford E-Transit Cargo Van Long Body High Roof',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Ford',
    year: 2022,
    model: 'E-Transit',
    duty: 'light',
    trim: 'Cargo Van Long Body High Roof',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ford_e-transit_cargo_van_long_high_bev_2022/116.png',
    rangeInMiles: 108,
    rangeInHours: 0,
    range: 108,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 67,
    weightClass: 2,
    msrp: 51530,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ford_e-transit_cargo_van_long_low_bev_2022',
    category: 'On-Road',
    name: 'Ford E-Transit Cargo Van Long Body Low Roof',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Ford',
    year: 2022,
    model: 'E-Transit',
    duty: 'light',
    trim: 'Cargo Van Long Body Low Roof',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ford_e-transit_cargo_van_long_low_bev_2022/116.png',
    rangeInMiles: 126,
    rangeInHours: 0,
    range: 126,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 67,
    weightClass: 2,
    msrp: 48395,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ford_e-transit_cargo_van_long_medium_bev_2022',
    category: 'On-Road',
    name: 'Ford E-Transit Cargo Van Long Body Medium Roof',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Ford',
    year: 2022,
    model: 'E-Transit',
    duty: 'light',
    trim: 'Cargo Van Long Body Medium Roof',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ford_e-transit_cargo_van_long_medium_bev_2022/116.png',
    rangeInMiles: 116,
    rangeInHours: 0,
    range: 116,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 67,
    weightClass: 2,
    msrp: 49490,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ford_e-transit__bev',
    category: 'On-Road',
    name: 'Ford E-Transit Cargo Van Regular Body Low Roof',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Ford',
    year: 2022,
    model: 'E-Transit',
    duty: 'light',
    trim: 'Cargo Van Regular Body Low Roof',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ford_e-transit__bev/ford_e-transit__bev.png',
    rangeInMiles: 126,
    rangeInHours: 0,
    range: 126,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 67,
    weightClass: 2,
    msrp: 47185,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ford_e-transit_cargo_van_regular_medium_bev_2022',
    category: 'On-Road',
    name: 'Ford E-Transit Cargo Van Regular Body Medium Roof',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Ford',
    year: 2022,
    model: 'E-Transit',
    duty: 'light',
    trim: 'Cargo Van Regular Body Medium Roof',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ford_e-transit_cargo_van_regular_medium_bev_2022/116.png',
    rangeInMiles: 116,
    rangeInHours: 0,
    range: 116,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 67,
    weightClass: 2,
    msrp: 48280,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Ford_Mustang_Mach_E_California_Rt_1_BEV_2022',
    category: 'On-Road',
    name: 'Ford Mustang Mach E California Rt. 1',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Ford',
    year: 2022,
    model: 'Mustang Mach E',
    duty: 'light',
    trim: 'California Rt. 1',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Ford_Mustang_Mach_E_California_Rt_1_BEV_2022/116.png',
    rangeInMiles: 305,
    rangeInHours: 0,
    range: 305,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 91,
    weightClass: 1,
    msrp: 50775,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Ford_Mustang_Mach_E_GT_BEV_2022',
    category: 'On-Road',
    name: 'Ford Mustang Mach E GT',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Ford',
    year: 2022,
    model: 'Mustang Mach E',
    duty: 'light',
    trim: 'GT',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Ford_Mustang_Mach_E_GT_BEV_2022/116.png',
    rangeInMiles: 270,
    rangeInHours: 0,
    range: 270,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 91,
    weightClass: 1,
    msrp: 59995,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Ford_Mustang_Mach_E_Premium_BEV_2022',
    category: 'On-Road',
    name: 'Ford Mustang Mach E Premium',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Ford',
    year: 2022,
    model: 'Mustang Mach E',
    duty: 'light',
    trim: 'Premium',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Ford_Mustang_Mach_E_Premium_BEV_2022/116.png',
    rangeInMiles: 230,
    rangeInHours: 0,
    range: 230,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 70,
    weightClass: 1,
    msrp: 48100,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Ford_Mustang_Mach_E_Select_BEV_2022',
    category: 'On-Road',
    name: 'Ford Mustang Mach E Select',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Ford',
    year: 2022,
    model: 'Mustang Mach E',
    duty: 'light',
    trim: 'Select',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Ford_Mustang_Mach_E_Select_BEV_2022/116.png',
    rangeInMiles: 230,
    rangeInHours: 0,
    range: 230,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 70,
    weightClass: 1,
    msrp: 42895,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'freightliner_ecascadia__bev',
    category: 'On-Road',
    name: 'Freightliner eCascadia',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Freightliner',
    year: 2021,
    model: 'eCascadia',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/freightliner_ecascadia__bev/freightliner_ecascadia__bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 475,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'freightliner_econic__bev',
    category: 'On-Road',
    name: 'Freightliner Econic',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Freightliner',
    year: 2021,
    model: 'Econic',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/freightliner_econic__bev/freightliner_econic__bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 216,
    weightClass: 8,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'freightliner_em2__bev',
    category: 'On-Road',
    name: 'Freightliner eM2',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Freightliner',
    year: 2021,
    model: 'eM2',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/freightliner_em2__bev/freightliner_em2__bev.png',
    rangeInMiles: 230,
    rangeInHours: 0,
    range: 230,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 315,
    weightClass: 6,
    msrp: 220000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'fuso_ecanter__bev',
    category: 'On-Road',
    name: 'FUSO eCanter',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'FUSO',
    year: 2021,
    model: 'eCanter',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/fuso_ecanter__bev/fuso_ecanter__bev.png',
    rangeInMiles: 80,
    rangeInHours: 0,
    range: 80,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 82.8,
    weightClass: 5,
    msrp: 180000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'fuso_vision_one__bev',
    category: 'On-Road',
    name: 'FUSO Vision One',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'FUSO',
    year: 2021,
    model: 'Vision One',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/fuso_vision_one__bev/fuso_vision_one__bev.png',
    rangeInMiles: 350,
    rangeInHours: 0,
    range: 350,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 300,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'gillig_eplus_29_ft_bev',
    category: 'On-Road',
    name: "Gillig ePlus 29'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Gillig',
    year: 2021,
    model: 'ePlus',
    duty: 'heavy',
    trim: "29'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/gillig_eplus_29_ft_bev/gillig_eplus_29_ft_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 296,
    weightClass: 8,
    msrp: 209000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.28,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'gillig_eplus_35_ft_bev',
    category: 'On-Road',
    name: "Gillig ePlus 35'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Gillig',
    year: 2021,
    model: 'ePlus',
    duty: 'heavy',
    trim: "35'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/gillig_eplus_35_ft_bev/gillig_eplus_35_ft_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 444,
    weightClass: 8,
    msrp: 697000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'gillig_eplus_40_ft_bev',
    category: 'On-Road',
    name: "Gillig ePlus 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Gillig',
    year: 2021,
    model: 'ePlus',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/gillig_eplus_40_ft_bev/gillig_eplus_40_ft_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 444,
    weightClass: 8,
    msrp: 788000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'gmc_hummer_ev_pickup_2_bev',
    category: 'On-Road',
    name: 'GMC Hummer EV Pickup 2',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'GMC',
    year: 2021,
    model: 'Hummer EV',
    duty: 'light',
    trim: 'Pickup 2',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/gmc_hummer_ev_pickup_2_bev/gmc_hummer_ev_pickup_2_bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 200,
    weightClass: 2,
    msrp: 79995,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'gmc_hummer_ev_pickup_2x_bev',
    category: 'On-Road',
    name: 'GMC Hummer EV Pickup 2X',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'GMC',
    year: 2021,
    model: 'Hummer EV',
    duty: 'light',
    trim: 'Pickup 2X',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/gmc_hummer_ev_pickup_2x_bev/gmc_hummer_ev_pickup_2x_bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 200,
    weightClass: 2,
    msrp: 89995,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'gmc_hummer_ev_pickup_3x_bev',
    category: 'On-Road',
    name: 'GMC Hummer EV Pickup 3X',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'GMC',
    year: 2021,
    model: 'Hummer EV',
    duty: 'light',
    trim: 'Pickup 3X',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/gmc_hummer_ev_pickup_3x_bev/gmc_hummer_ev_pickup_3x_bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 200,
    weightClass: 2,
    msrp: 99995,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_av_star__bev',
    category: 'On-Road',
    name: 'GreenPower AV STAR',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    formFactor: 'shuttle_bus',
    make: 'GreenPower',
    year: 2021,
    model: 'AV STAR',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_av_star__bev/greenpower_av_star__bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 118,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_beast__bev',
    category: 'On-Road',
    name: 'GreenPower BEAST',
    type: 'School Bus',
    subtype: 'Type D',
    formFactor: 'school_bus',
    make: 'GreenPower',
    year: 2021,
    model: 'BEAST',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_beast__bev/greenpower_beast__bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 194,
    weightClass: 8,
    msrp: 400000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_ev_250_30_ft_bev',
    category: 'On-Road',
    name: "GreenPower EV 250 30'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'GreenPower',
    year: 2021,
    model: 'EV 250',
    duty: 'heavy',
    trim: "30'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_ev_250_30_ft_bev/greenpower_ev_250_30_ft_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 260,
    weightClass: 7,
    msrp: 700000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_ev_350_40_ft_bev',
    category: 'On-Road',
    name: "GreenPower EV 350 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'GreenPower',
    year: 2021,
    model: 'EV 350',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_ev_350_40_ft_bev/greenpower_ev_350_40_ft_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 400,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_ev_550_45_ft_bev',
    category: 'On-Road',
    name: "GreenPower EV 550 45'",
    type: 'Transit Bus',
    subtype: 'Double Decker',
    formFactor: 'transit_bus',
    make: 'GreenPower',
    year: 2021,
    model: 'EV 550',
    duty: 'heavy',
    trim: "45'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_ev_550_45_ft_bev/greenpower_ev_550_45_ft_bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 600,
    weightClass: 8,
    msrp: 1050000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_ev_star__bev',
    category: 'On-Road',
    name: 'GreenPower EV Star',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    formFactor: 'shuttle_bus',
    make: 'GreenPower',
    year: 2021,
    model: 'EV Star',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_ev_star__bev/greenpower_ev_star__bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 118,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_ev_star_ada_bev',
    category: 'On-Road',
    name: 'GreenPower EV Star ADA',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    formFactor: 'shuttle_bus',
    make: 'GreenPower',
    year: 2021,
    model: 'EV Star',
    duty: 'medium',
    trim: 'ADA',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_ev_star_ada_bev/greenpower_ev_star_ada_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 118,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_ev_star_cargo_bev',
    category: 'On-Road',
    name: 'GreenPower EV Star Cargo',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'GreenPower',
    year: 2021,
    model: 'EV Star',
    duty: 'medium',
    trim: 'Cargo',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_ev_star_cargo_bev/greenpower_ev_star_cargo_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 118,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'greenpower_ev_star_cargo_plus_bev',
    category: 'On-Road',
    name: 'GreenPower EV Star Cargo+',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'GreenPower',
    year: 2021,
    model: 'EV Star',
    duty: 'medium',
    trim: 'Cargo+',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/greenpower_ev_star_cargo_plus_bev/greenpower_ev_star_cargo_plus_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 118,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Hyundai_Ioniq_Electric__BEV_2021',
    category: 'On-Road',
    name: 'Hyundai Ioniq Electric',
    type: 'Passenger',
    subtype: 'Hatchback',
    formFactor: 'Hatchback',
    make: 'Hyundai',
    year: 2021,
    model: 'Ioniq Electric',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Hyundai_Ioniq_Electric__BEV_2021/116.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 38.3,
    weightClass: 1,
    msrp: 33245,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Hyundai_Kona_Electric__BEV_2022',
    category: 'On-Road',
    name: 'Hyundai Kona Electric',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Hyundai',
    year: 2022,
    model: 'Kona Electric',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Hyundai_Kona_Electric__BEV_2022/116.png',
    rangeInMiles: 258,
    rangeInHours: 0,
    range: 258,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 64,
    weightClass: 1,
    msrp: 34000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ic_bus_ce_series_electric_105_kwh_bev',
    category: 'On-Road',
    name: 'IC Bus CE Series Electric 105 kWh',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'IC Bus',
    year: 2021,
    model: 'CE Series Electric',
    duty: 'medium',
    trim: '105 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ic_bus_ce_series_electric_105_kwh_bev/ic_bus_ce_series_electric_105_kwh_bev.png',
    rangeInMiles: 70,
    rangeInHours: 0,
    range: 70,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 105,
    weightClass: 6,
    msrp: 350000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ic_bus_ce_series_electric_210_kwh_bev',
    category: 'On-Road',
    name: 'IC Bus CE Series Electric 210 kWh',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'IC Bus',
    year: 2021,
    model: 'CE Series Electric',
    duty: 'heavy',
    trim: '210 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ic_bus_ce_series_electric_210_kwh_bev/ic_bus_ce_series_electric_210_kwh_bev.png',
    rangeInMiles: 135,
    rangeInHours: 0,
    range: 135,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 210,
    weightClass: 7,
    msrp: 370000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'ic_bus_ce_series_electric_315_kwh_bev',
    category: 'On-Road',
    name: 'IC Bus CE Series Electric 315 kWh',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'IC Bus',
    year: 2021,
    model: 'CE Series Electric',
    duty: 'heavy',
    trim: '315 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/ic_bus_ce_series_electric_315_kwh_bev/ic_bus_ce_series_electric_315_kwh_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 315,
    weightClass: 7,
    msrp: 390000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'international_emv_107_kwh_bev',
    category: 'On-Road',
    name: 'International eMV 107 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'International',
    year: 2021,
    model: 'eMV',
    duty: 'medium',
    trim: '107 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/international_emv_107_kwh_bev/international_emv_107_kwh_bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 107,
    weightClass: 6,
    msrp: 220000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'international_emv_214_kwh_bev',
    category: 'On-Road',
    name: 'International eMV 214 kWh',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'International',
    year: 2021,
    model: 'eMV',
    duty: 'heavy',
    trim: '214 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/international_emv_214_kwh_bev/international_emv_214_kwh_bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 214,
    weightClass: 7,
    msrp: 235000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'international_emv_321_kwh_bev',
    category: 'On-Road',
    name: 'International eMV 321 kWh',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'International',
    year: 2021,
    model: 'eMV',
    duty: 'heavy',
    trim: '321 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/international_emv_321_kwh_bev/international_emv_321_kwh_bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 321,
    weightClass: 7,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Jaguar_I-Pace__BEV_2022',
    category: 'On-Road',
    name: 'Jaguar I-Pace',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Jaguar',
    year: 2022,
    model: 'I-Pace',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Jaguar_I-Pace__BEV_2022/116.png',
    rangeInMiles: 234,
    rangeInHours: 0,
    range: 234,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 90,
    weightClass: 1,
    msrp: 69900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'kenworth_k270e_141_kwh_bev',
    category: 'On-Road',
    name: 'Kenworth K270E 141 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Kenworth',
    year: 2021,
    model: 'K270E',
    duty: 'medium',
    trim: '141 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/kenworth_k270e_141_kwh_bev/kenworth_k270e_141_kwh_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 141,
    weightClass: 6,
    msrp: 220000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'kenworth_k270e_282_kwh_bev',
    category: 'On-Road',
    name: 'Kenworth K270E 282 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Kenworth',
    year: 2021,
    model: 'K270E',
    duty: 'medium',
    trim: '282 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/kenworth_k270e_282_kwh_bev/kenworth_k270e_282_kwh_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 282,
    weightClass: 6,
    msrp: 240000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'kenworth_k370e_141_kwh_bev',
    category: 'On-Road',
    name: 'Kenworth K370E 141 kWh',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'Kenworth',
    year: 2021,
    model: 'K370E',
    duty: 'heavy',
    trim: '141 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/kenworth_k370e_141_kwh_bev/kenworth_k370e_141_kwh_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 141,
    weightClass: 7,
    msrp: 248000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'kenworth_k370e_282_kwh_bev',
    category: 'On-Road',
    name: 'Kenworth K370E 282 kWh',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'Kenworth',
    year: 2021,
    model: 'K370E',
    duty: 'heavy',
    trim: '282 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/kenworth_k370e_282_kwh_bev/kenworth_k370e_282_kwh_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 282,
    weightClass: 7,
    msrp: 268000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'kenworth_t680e__bev',
    category: 'On-Road',
    name: 'Kenworth T680E',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Kenworth',
    year: 2021,
    model: 'T680E',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/kenworth_t680e__bev/kenworth_t680e__bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 396,
    weightClass: 7,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Kia_Niro_EV__BEV_2022',
    category: 'On-Road',
    name: 'Kia Niro EV',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Kia',
    year: 2022,
    model: 'Niro EV',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Kia_Niro_EV__BEV_2022/116.png',
    rangeInMiles: 239,
    rangeInHours: 0,
    range: 239,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 64,
    weightClass: 1,
    msrp: 39990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_class_6_low_cab_forward_122_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric Class 6 Low Cab Forward 122 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Lightning',
    year: 2021,
    model: 'Electric Class 6 Low Cab Forward',
    duty: 'medium',
    trim: '122 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_class_6_low_cab_forward_122_kwh_bev/lightning_electric_class_6_low_cab_forward_122_kwh_bev.png',
    rangeInMiles: 88,
    rangeInHours: 0,
    range: 88,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 122,
    weightClass: 6,
    msrp: 129000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_class_6_low_cab_forward_153_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric Class 6 Low Cab Forward 153 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Lightning',
    year: 2021,
    model: 'Electric Class 6 Low Cab Forward',
    duty: 'medium',
    trim: '153 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_class_6_low_cab_forward_153_kwh_bev/lightning_electric_class_6_low_cab_forward_153_kwh_bev.png',
    rangeInMiles: 110,
    rangeInHours: 0,
    range: 110,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 153,
    weightClass: 6,
    msrp: 189000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_class_6_low_cab_forward_184_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric Class 6 Low Cab Forward 184 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Lightning',
    year: 2021,
    model: 'Electric Class 6 Low Cab Forward',
    duty: 'medium',
    trim: '184 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_class_6_low_cab_forward_184_kwh_bev/lightning_electric_class_6_low_cab_forward_184_kwh_bev.png',
    rangeInMiles: 130,
    rangeInHours: 0,
    range: 130,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 184,
    weightClass: 6,
    msrp: 159000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_e-450_cutaway_129_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric E-450 Cutaway 129 kWh',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    formFactor: 'cutaway_chassis',
    make: 'Lightning',
    year: 2021,
    model: 'Electric E-450',
    duty: 'medium',
    trim: 'Cutaway 129 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_e-450_cutaway_129_kwh_bev/lightning_electric_e-450_cutaway_129_kwh_bev.png',
    rangeInMiles: 120,
    rangeInHours: 0,
    range: 120,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 129,
    weightClass: 4,
    msrp: 195000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_e-450_cutaway_86_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric E-450 Cutaway 86 kWh',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    formFactor: 'cutaway_chassis',
    make: 'Lightning',
    year: 2021,
    model: 'Electric E-450',
    duty: 'medium',
    trim: 'Cutaway 86 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_e-450_cutaway_86_kwh_bev/lightning_electric_e-450_cutaway_86_kwh_bev.png',
    rangeInMiles: 80,
    rangeInHours: 0,
    range: 80,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 86,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_e-450_shuttle_bus_129_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric E-450 Shuttle Bus 129 kWh',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Lightning',
    year: 2021,
    model: 'Electric E-450',
    duty: 'medium',
    trim: 'Shuttle Bus 129 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_e-450_shuttle_bus_129_kwh_bev/lightning_electric_e-450_shuttle_bus_129_kwh_bev.png',
    rangeInMiles: 120,
    rangeInHours: 0,
    range: 120,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 129,
    weightClass: 4,
    msrp: 218000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_e-450_shuttle_bus_86_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric E-450 Shuttle Bus 86 kWh',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Lightning',
    year: 2021,
    model: 'Electric E-450',
    duty: 'medium',
    trim: 'Shuttle Bus 86 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_e-450_shuttle_bus_86_kwh_bev/lightning_electric_e-450_shuttle_bus_86_kwh_bev.png',
    rangeInMiles: 80,
    rangeInHours: 0,
    range: 80,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 86,
    weightClass: 4,
    msrp: 210000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_f-53_f-59_van_128_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric F-53 / F-59 Van 128 kWh',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'Lightning',
    year: 2021,
    model: 'Electric F-53 / F-59 Van',
    duty: 'medium',
    trim: '128 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_f-53_f-59_van_128_kwh_bev/lightning_electric_f-53_f-59_van_128_kwh_bev.png',
    rangeInMiles: 110,
    rangeInHours: 0,
    range: 110,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 128,
    weightClass: 6,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_f-53_f-59_van_160_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric F-53 / F-59 Van 160 kWh',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'Lightning',
    year: 2021,
    model: 'Electric F-53 / F-59 Van',
    duty: 'medium',
    trim: '160 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_f-53_f-59_van_160_kwh_bev/lightning_electric_f-53_f-59_van_160_kwh_bev.png',
    rangeInMiles: 140,
    rangeInHours: 0,
    range: 140,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 160,
    weightClass: 6,
    msrp: 203000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_f-53_f-59_van_192_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric F-53 / F-59 Van 192 kWh',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'Lightning',
    year: 2021,
    model: 'Electric F-53 / F-59 Van',
    duty: 'medium',
    trim: '192 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_f-53_f-59_van_192_kwh_bev/lightning_electric_f-53_f-59_van_192_kwh_bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 192,
    weightClass: 6,
    msrp: 208000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_f-550_bus__bev',
    category: 'On-Road',
    name: 'Lightning Electric F-550 Bus',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Lightning',
    year: 2021,
    model: 'Electric F-550',
    duty: 'medium',
    trim: 'Bus',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_f-550_bus__bev/lightning_electric_f-550_bus__bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 122,
    weightClass: 5,
    msrp: 210000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_transit_cargo_van_105_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric Transit Cargo Van 105 kWh',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Lightning',
    year: 2021,
    model: 'Electric Transit',
    duty: 'medium',
    trim: 'Cargo Van 105 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_transit_cargo_van_105_kwh_bev/lightning_electric_transit_cargo_van_105_kwh_bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 105,
    weightClass: 3,
    msrp: 169900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_transit_cargo_van_86_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric Transit Cargo Van 86 kWh',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'Lightning',
    year: 2021,
    model: 'Electric Transit',
    duty: 'medium',
    trim: 'Cargo Van 86 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_transit_cargo_van_86_kwh_bev/lightning_electric_transit_cargo_van_86_kwh_bev.png',
    rangeInMiles: 140,
    rangeInHours: 0,
    range: 140,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 86,
    weightClass: 3,
    msrp: 129900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_transit_passenger_van_105_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric Transit Passenger Van 105 kWh',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    formFactor: 'shuttle_bus',
    make: 'Lightning',
    year: 2021,
    model: 'Electric Transit',
    duty: 'medium',
    trim: 'Passenger Van 105 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_transit_passenger_van_105_kwh_bev/lightning_electric_transit_passenger_van_105_kwh_bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 105,
    weightClass: 3,
    msrp: 155000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lightning_electric_transit_passenger_van_86_kwh_bev',
    category: 'On-Road',
    name: 'Lightning Electric Transit Passenger Van 86 kWh',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    formFactor: 'shuttle_bus',
    make: 'Lightning',
    year: 2021,
    model: 'Electric Transit',
    duty: 'medium',
    trim: 'Passenger Van 86 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lightning_electric_transit_passenger_van_86_kwh_bev/lightning_electric_transit_passenger_van_86_kwh_bev.png',
    rangeInMiles: 140,
    rangeInHours: 0,
    range: 140,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 86,
    weightClass: 3,
    msrp: 115000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_6__bev',
    category: 'On-Road',
    name: 'Lion 6',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Lion',
    year: 2021,
    model: '6',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_6__bev/lion_6__bev.png',
    rangeInMiles: 180,
    rangeInHours: 0,
    range: 180,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 252,
    weightClass: 6,
    msrp: 220000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_8_refuse_truck_bev',
    category: 'On-Road',
    name: 'Lion 8 Refuse Truck',
    type: 'Refuse Truck',
    subtype: 'Heavy Duty',
    formFactor: 'refuse_truck',
    make: 'Lion',
    year: 2021,
    model: '8',
    duty: 'heavy',
    trim: 'Refuse Truck',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_8_refuse_truck_bev/lion_8_refuse_truck_bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 336,
    weightClass: 8,
    msrp: 460000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_8_t_bev',
    category: 'On-Road',
    name: 'Lion 8 T',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Lion',
    year: 2021,
    model: '8',
    duty: 'heavy',
    trim: 'T',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_8_t_bev/lion_8_t_bev.png',
    rangeInMiles: 260,
    rangeInHours: 0,
    range: 260,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 653,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_8_urban_truck_bev',
    category: 'On-Road',
    name: 'Lion 8 Urban Truck',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'Lion',
    year: 2021,
    model: '8',
    duty: 'heavy',
    trim: 'Urban Truck',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_8_urban_truck_bev/lion_8_urban_truck_bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 336,
    weightClass: 8,
    msrp: 400000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_8_utility_truck_bev',
    category: 'On-Road',
    name: 'Lion 8 Utility Truck',
    type: 'Specialty',
    subtype: 'Bucket Truck',
    formFactor: 'specialty',
    make: 'Lion',
    year: 2021,
    model: '8',
    duty: 'heavy',
    trim: 'Utility Truck',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_8_utility_truck_bev/lion_8_utility_truck_bev.png',
    rangeInMiles: 130,
    rangeInHours: 0,
    range: 130,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 336,
    weightClass: 8,
    msrp: 457000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_a_168_kwh_bev',
    category: 'On-Road',
    name: 'Lion A 168 kWh',
    type: 'School Bus',
    subtype: 'Type A',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'A',
    duty: 'medium',
    trim: '168 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_a_168_kwh_bev/lion_a_168_kwh_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 168,
    weightClass: 6,
    msrp: 293000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.13,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_a_84_kwh_bev',
    category: 'On-Road',
    name: 'Lion A 84 kWh',
    type: 'School Bus',
    subtype: 'Type A',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'A',
    duty: 'medium',
    trim: '84 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_a_84_kwh_bev/lion_a_84_kwh_bev.png',
    rangeInMiles: 75,
    rangeInHours: 0,
    range: 75,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 84,
    weightClass: 6,
    msrp: 278000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.13,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_c_126_kwh_bev',
    category: 'On-Road',
    name: 'Lion C 126 kWh',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'C',
    duty: 'heavy',
    trim: '126 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_c_126_kwh_bev/lion_c_126_kwh_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 126,
    weightClass: 7,
    msrp: 350000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_c_168_kwh_bev',
    category: 'On-Road',
    name: 'Lion C 168 kWh',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'C',
    duty: 'heavy',
    trim: '168 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_c_168_kwh_bev/lion_c_168_kwh_bev.png',
    rangeInMiles: 125,
    rangeInHours: 0,
    range: 125,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 168,
    weightClass: 7,
    msrp: 370000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_c_210_kwh_bev',
    category: 'On-Road',
    name: 'Lion C 210 kWh',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'C',
    duty: 'heavy',
    trim: '210 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_c_210_kwh_bev/lion_c_210_kwh_bev.png',
    rangeInMiles: 155,
    rangeInHours: 0,
    range: 155,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 210,
    weightClass: 7,
    msrp: 390000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_d_126_kwh_bev',
    category: 'On-Road',
    name: 'Lion D 126 kWh',
    type: 'School Bus',
    subtype: 'Type D',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'D',
    duty: 'heavy',
    trim: '126 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_d_126_kwh_bev/lion_d_126_kwh_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 126,
    weightClass: 8,
    msrp: 400000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_d_168_kwh_bev',
    category: 'On-Road',
    name: 'Lion D 168 kWh',
    type: 'School Bus',
    subtype: 'Type D',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'D',
    duty: 'heavy',
    trim: '168 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_d_168_kwh_bev/lion_d_168_kwh_bev.png',
    rangeInMiles: 125,
    rangeInHours: 0,
    range: 125,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 168,
    weightClass: 8,
    msrp: 420000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_d_210_kwh_bev',
    category: 'On-Road',
    name: 'Lion D 210 kWh',
    type: 'School Bus',
    subtype: 'Type D',
    formFactor: 'school_bus',
    make: 'Lion',
    year: 2021,
    model: 'D',
    duty: 'heavy',
    trim: '210 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_d_210_kwh_bev/lion_d_210_kwh_bev.png',
    rangeInMiles: 155,
    rangeInHours: 0,
    range: 155,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 210,
    weightClass: 8,
    msrp: 440000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lion_m_26_ft_bev',
    category: 'On-Road',
    name: "Lion M 26'",
    type: 'Transit Bus',
    subtype: 'Medium Duty',
    formFactor: 'transit_bus',
    make: 'Lion',
    year: 2021,
    model: 'M',
    duty: 'medium',
    trim: "26'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lion_m_26_ft_bev/lion_m_26_ft_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 160,
    weightClass: 6,
    msrp: 210000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.28,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'lordstown_endurance__bev',
    category: 'On-Road',
    name: 'Lordstown Endurance',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'Lordstown',
    year: 2021,
    model: 'Endurance',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/lordstown_endurance__bev/lordstown_endurance__bev.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 109,
    weightClass: 2,
    msrp: 52500,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Mazda_MX-30_EV__BEV_2022',
    category: 'On-Road',
    name: 'Mazda MX-30 EV',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Mazda',
    year: 2022,
    model: 'MX-30 EV',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Mazda_MX-30_EV__BEV_2022/116.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 35.5,
    weightClass: 1,
    msrp: 33470,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'mci_d45_crte_charge_45_ft_bev',
    category: 'On-Road',
    name: "MCI D45 CRTe Charge 45'",
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    formFactor: 'motor_coach',
    make: 'MCI',
    year: 2021,
    model: 'D45 CRTe',
    duty: 'heavy',
    trim: "Charge 45'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/mci_d45_crte_charge_45_ft_bev/mci_d45_crte_charge_45_ft_bev.png',
    rangeInMiles: 230,
    rangeInHours: 0,
    range: 230,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 544,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'mci_d45_crte_le_charge_45_ft_bev',
    category: 'On-Road',
    name: "MCI D45 CRTe LE Charge 45'",
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    formFactor: 'motor_coach',
    make: 'MCI',
    year: 2021,
    model: 'D45 CRTe',
    duty: 'heavy',
    trim: "LE Charge 45'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/mci_d45_crte_le_charge_45_ft_bev/mci_d45_crte_le_charge_45_ft_bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 389,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'mci_j4500e_45_ft_bev',
    category: 'On-Road',
    name: "MCI J4500e 45'",
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    formFactor: 'motor_coach',
    make: 'MCI',
    year: 2021,
    model: 'J4500e',
    duty: 'heavy',
    trim: "45'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/mci_j4500e_45_ft_bev/mci_j4500e_45_ft_bev.png',
    rangeInMiles: 230,
    rangeInHours: 0,
    range: 230,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 544,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'MINI_Cooper_S_E_Electric_BEV_2022',
    category: 'On-Road',
    name: 'MINI Electric Hardtop 2 Door',
    type: 'Passenger',
    subtype: 'Hatchback',
    formFactor: 'Hatchback',
    make: 'MINI',
    year: 2022,
    model: 'Electric Hardtop 2 Door',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/MINI_Cooper_S_E_Electric_BEV_2022/116.png',
    rangeInMiles: 114,
    rangeInHours: 0,
    range: 114,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 32.6,
    weightClass: 1,
    msrp: 29900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_e-450_box_truck_bev',
    category: 'On-Road',
    name: 'Motiv EPIC E-450 Box Truck',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC E-450',
    duty: 'medium',
    trim: 'Box Truck',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_e-450_box_truck_bev/motiv_epic_e-450_box_truck_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_e-450_chassis_bev',
    category: 'On-Road',
    name: 'Motiv EPIC E-450 Chassis',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    formFactor: 'cutaway_chassis',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC E-450',
    duty: 'medium',
    trim: 'Chassis',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_e-450_chassis_bev/motiv_epic_e-450_chassis_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_e-450_shuttle_bus_bev',
    category: 'On-Road',
    name: 'Motiv EPIC E-450 Shuttle Bus',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC E-450',
    duty: 'medium',
    trim: 'Shuttle Bus',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_e-450_shuttle_bus_bev/motiv_epic_e-450_shuttle_bus_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 4,
    msrp: 210000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_e-450_type_a_school_bus_bev',
    category: 'On-Road',
    name: 'Motiv EPIC E-450 Type A School Bus',
    type: 'School Bus',
    subtype: 'Type A',
    formFactor: 'school_bus',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC E-450',
    duty: 'medium',
    trim: 'Type A School Bus',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_e-450_type_a_school_bus_bev/motiv_epic_e-450_type_a_school_bus_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 4,
    msrp: 224000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.12,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_e-450_work_truck_bev',
    category: 'On-Road',
    name: 'Motiv EPIC E-450 Work Truck',
    type: 'Pick-up Truck',
    subtype: 'Medium Duty',
    formFactor: 'pickup_truck',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC E-450',
    duty: 'medium',
    trim: 'Work Truck',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_e-450_work_truck_bev/motiv_epic_e-450_work_truck_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_f-53_specialty_vehicle_bev',
    category: 'On-Road',
    name: 'Motiv EPIC F-53 Specialty Vehicle',
    type: 'Specialty',
    subtype: 'Mobile Outreach Vehicle',
    formFactor: 'specialty',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC F-53',
    duty: 'medium',
    trim: 'Specialty Vehicle',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_f-53_specialty_vehicle_bev/motiv_epic_f-53_specialty_vehicle_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 6,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_f-53_trolley_bev',
    category: 'On-Road',
    name: 'Motiv EPIC F-53 Trolley',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC F-53',
    duty: 'medium',
    trim: 'Trolley',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_f-53_trolley_bev/motiv_epic_f-53_trolley_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 6,
    msrp: 228000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'motiv_epic_f-59_step_van_bev',
    category: 'On-Road',
    name: 'Motiv EPIC F-59 Step Van',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'Motiv',
    year: 2021,
    model: 'EPIC F-59',
    duty: 'medium',
    trim: 'Step Van',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/motiv_epic_f-59_step_van_bev/motiv_epic_f-59_step_van_bev.png',
    rangeInMiles: 105,
    rangeInHours: 0,
    range: 105,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 127,
    weightClass: 6,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'new_flyer_xcelsior_charge_ng_35_ft_350_kwh_bev',
    category: 'On-Road',
    name: "New Flyer Xcelsior Charge NG 35' 350 kWh",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'New Flyer',
    year: 2021,
    model: 'Xcelsior Charge NG',
    duty: 'heavy',
    trim: "35' 350 kWh",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/new_flyer_xcelsior_charge_ng_35_ft_350_kwh_bev/new_flyer_xcelsior_charge_ng_35_ft_350_kwh_bev.png',
    rangeInMiles: 179,
    rangeInHours: 0,
    range: 179,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 350,
    weightClass: 7,
    msrp: 700000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'new_flyer_xcelsior_charge_ng_35_ft_440_kwh_bev',
    category: 'On-Road',
    name: "New Flyer Xcelsior Charge NG 35' 440 kWh",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'New Flyer',
    year: 2021,
    model: 'Xcelsior Charge NG',
    duty: 'heavy',
    trim: "35' 440 kWh",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/new_flyer_xcelsior_charge_ng_35_ft_440_kwh_bev/new_flyer_xcelsior_charge_ng_35_ft_440_kwh_bev.png',
    rangeInMiles: 220,
    rangeInHours: 0,
    range: 220,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 440,
    weightClass: 7,
    msrp: 740000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'new_flyer_xcelsior_charge_ng_40_ft_350_kwh_bev',
    category: 'On-Road',
    name: "New Flyer Xcelsior Charge NG 40' 350 kWh",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'New Flyer',
    year: 2021,
    model: 'Xcelsior Charge NG',
    duty: 'heavy',
    trim: "40' 350 kWh",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/new_flyer_xcelsior_charge_ng_40_ft_350_kwh_bev/new_flyer_xcelsior_charge_ng_40_ft_350_kwh_bev.png',
    rangeInMiles: 174,
    rangeInHours: 0,
    range: 174,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 350,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'new_flyer_xcelsior_charge_ng_40_ft_440_kwh_bev',
    category: 'On-Road',
    name: "New Flyer Xcelsior Charge NG 40' 440 kWh",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'New Flyer',
    year: 2021,
    model: 'Xcelsior Charge NG',
    duty: 'heavy',
    trim: "40' 440 kWh",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/new_flyer_xcelsior_charge_ng_40_ft_440_kwh_bev/new_flyer_xcelsior_charge_ng_40_ft_440_kwh_bev.png',
    rangeInMiles: 213,
    rangeInHours: 0,
    range: 213,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 440,
    weightClass: 8,
    msrp: 830000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'new_flyer_xcelsior_charge_ng_40_ft_525_kwh_bev',
    category: 'On-Road',
    name: "New Flyer Xcelsior Charge NG 40' 525 kWh",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'New Flyer',
    year: 2021,
    model: 'Xcelsior Charge NG',
    duty: 'heavy',
    trim: "40' 525 kWh",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/new_flyer_xcelsior_charge_ng_40_ft_525_kwh_bev/new_flyer_xcelsior_charge_ng_40_ft_525_kwh_bev.png',
    rangeInMiles: 251,
    rangeInHours: 0,
    range: 251,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 525,
    weightClass: 8,
    msrp: 870000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'new_flyer_xcelsior_charge_ng_60_ft_525_kwh_bev',
    category: 'On-Road',
    name: "New Flyer Xcelsior Charge NG 60' 525 kWh",
    type: 'Transit Bus',
    subtype: 'Articulated Bus',
    formFactor: 'transit_bus',
    make: 'New Flyer',
    year: 2021,
    model: 'Xcelsior Charge NG',
    duty: 'heavy',
    trim: "60' 525 kWh",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/new_flyer_xcelsior_charge_ng_60_ft_525_kwh_bev/new_flyer_xcelsior_charge_ng_60_ft_525_kwh_bev.png',
    rangeInMiles: 153,
    rangeInHours: 0,
    range: 153,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 525,
    weightClass: 8,
    msrp: 1150000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Nissan_LEAF__BEV_2022',
    category: 'On-Road',
    name: 'Nissan LEAF',
    type: 'Passenger',
    subtype: 'Hatchback',
    formFactor: 'Hatchback',
    make: 'Nissan',
    year: 2022,
    model: 'LEAF',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Nissan_LEAF__BEV_2022/116.png',
    rangeInMiles: 149,
    rangeInHours: 0,
    range: 149,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 40,
    weightClass: 1,
    msrp: 27400,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Nissan_LEAF_PLUS_BEV_2022',
    category: 'On-Road',
    name: 'Nissan LEAF PLUS',
    type: 'Passenger',
    subtype: 'Hatchback',
    formFactor: 'Hatchback',
    make: 'Nissan',
    year: 2022,
    model: 'LEAF',
    duty: 'light',
    trim: 'PLUS',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Nissan_LEAF_PLUS_BEV_2022/116.png',
    rangeInMiles: 226,
    rangeInHours: 0,
    range: 226,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 62,
    weightClass: 1,
    msrp: 32400,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'nova_bus_lfse_40_ft_bev',
    category: 'On-Road',
    name: "Nova Bus LFSe 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Nova Bus',
    year: 2021,
    model: 'LFSe',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/nova_bus_lfse_40_ft_bev/nova_bus_lfse_40_ft_bev.png',
    rangeInMiles: 211,
    rangeInHours: 0,
    range: 211,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 230,
    weightClass: 7,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'nova_bus_lfse_plus_40_ft_bev',
    category: 'On-Road',
    name: "Nova Bus LFSe+ 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Nova Bus',
    year: 2021,
    model: 'LFSe+',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/nova_bus_lfse_plus_40_ft_bev/nova_bus_lfse_plus_40_ft_bev.png',
    rangeInMiles: 292,
    rangeInHours: 0,
    range: 292,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 594,
    weightClass: 7,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'peterbilt_220ev_100_mile_range_bev',
    category: 'On-Road',
    name: 'Peterbilt 220EV 100 Mile Range',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Peterbilt',
    year: 2021,
    model: '220EV',
    duty: 'medium',
    trim: '100 Mile Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/peterbilt_220ev_100_mile_range_bev/peterbilt_220ev_100_mile_range_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 141,
    weightClass: 6,
    msrp: 220000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'peterbilt_220ev_200_mile_range_bev',
    category: 'On-Road',
    name: 'Peterbilt 220EV 200 Mile Range',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'Peterbilt',
    year: 2021,
    model: '220EV',
    duty: 'heavy',
    trim: '200 Mile Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/peterbilt_220ev_200_mile_range_bev/peterbilt_220ev_200_mile_range_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 282,
    weightClass: 7,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'peterbilt_520ev_308_kwh_bev',
    category: 'On-Road',
    name: 'Peterbilt 520EV 308 kWh',
    type: 'Refuse Truck',
    subtype: 'Heavy Duty',
    formFactor: 'refuse_truck',
    make: 'Peterbilt',
    year: 2021,
    model: '520EV',
    duty: 'heavy',
    trim: '308 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/peterbilt_520ev_308_kwh_bev/peterbilt_520ev_308_kwh_bev.png',
    rangeInMiles: 80,
    rangeInHours: 0,
    range: 80,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 308,
    weightClass: 8,
    msrp: 460000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'peterbilt_520ev_420_kwh_bev',
    category: 'On-Road',
    name: 'Peterbilt 520EV 420 kWh',
    type: 'Refuse Truck',
    subtype: 'Heavy Duty',
    formFactor: 'refuse_truck',
    make: 'Peterbilt',
    year: 2021,
    model: '520EV',
    duty: 'heavy',
    trim: '420 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/peterbilt_520ev_420_kwh_bev/peterbilt_520ev_420_kwh_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 420,
    weightClass: 8,
    msrp: 490000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'peterbilt_579ev_264_kwh_bev',
    category: 'On-Road',
    name: 'Peterbilt 579EV 264 kWh',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Peterbilt',
    year: 2021,
    model: '579EV',
    duty: 'heavy',
    trim: '264 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/peterbilt_579ev_264_kwh_bev/peterbilt_579ev_264_kwh_bev.png',
    rangeInMiles: 110,
    rangeInHours: 0,
    range: 110,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 264,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'peterbilt_579ev_440_kwh_bev',
    category: 'On-Road',
    name: 'Peterbilt 579EV 440 kWh',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Peterbilt',
    year: 2021,
    model: '579EV',
    duty: 'heavy',
    trim: '440 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/peterbilt_579ev_440_kwh_bev/peterbilt_579ev_440_kwh_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 440,
    weightClass: 8,
    msrp: 330000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_400_shuttle_bus_125_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 400 Shuttle Bus 125 kWh',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 400',
    duty: 'medium',
    trim: 'Shuttle Bus 125 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_400_shuttle_bus_125_kwh_bev/phoenix_motorcars_zeus_400_shuttle_bus_125_kwh_bev.png',
    rangeInMiles: 130,
    rangeInHours: 0,
    range: 130,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 125,
    weightClass: 4,
    msrp: 275000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_400_shuttle_bus_156_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 400 Shuttle Bus 156 kWh',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 400',
    duty: 'medium',
    trim: 'Shuttle Bus 156 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_400_shuttle_bus_156_kwh_bev/phoenix_motorcars_zeus_400_shuttle_bus_156_kwh_bev.png',
    rangeInMiles: 160,
    rangeInHours: 0,
    range: 160,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 156,
    weightClass: 4,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_400_shuttle_bus_63_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 400 Shuttle Bus 63 kWh',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 400',
    duty: 'medium',
    trim: 'Shuttle Bus 63 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_400_shuttle_bus_63_kwh_bev/phoenix_motorcars_zeus_400_shuttle_bus_63_kwh_bev.png',
    rangeInMiles: 70,
    rangeInHours: 0,
    range: 70,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 63,
    weightClass: 4,
    msrp: 225000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_400_shuttle_bus_94_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 400 Shuttle Bus 94 kWh',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    formFactor: 'shuttle_bus',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 400',
    duty: 'medium',
    trim: 'Shuttle Bus 94 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_400_shuttle_bus_94_kwh_bev/phoenix_motorcars_zeus_400_shuttle_bus_94_kwh_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 94,
    weightClass: 4,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.11,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_500_truck_125_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 500 Truck 125 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 500',
    duty: 'medium',
    trim: 'Truck 125 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_500_truck_125_kwh_bev/phoenix_motorcars_zeus_500_truck_125_kwh_bev.png',
    rangeInMiles: 130,
    rangeInHours: 0,
    range: 130,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 125,
    weightClass: 4,
    msrp: 230000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_500_truck_156_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 500 Truck 156 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 500',
    duty: 'medium',
    trim: 'Truck 156 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_500_truck_156_kwh_bev/phoenix_motorcars_zeus_500_truck_156_kwh_bev.png',
    rangeInMiles: 160,
    rangeInHours: 0,
    range: 160,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 156,
    weightClass: 4,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_500_truck_63_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 500 Truck 63 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 500',
    duty: 'medium',
    trim: 'Truck 63 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_500_truck_63_kwh_bev/phoenix_motorcars_zeus_500_truck_63_kwh_bev.png',
    rangeInMiles: 70,
    rangeInHours: 0,
    range: 70,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 63,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'phoenix_motorcars_zeus_500_truck_94_kwh_bev',
    category: 'On-Road',
    name: 'Phoenix Motorcars Zeus 500 Truck 94 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Phoenix Motorcars',
    year: 2021,
    model: 'Zeus 500',
    duty: 'medium',
    trim: 'Truck 94 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/phoenix_motorcars_zeus_500_truck_94_kwh_bev/phoenix_motorcars_zeus_500_truck_94_kwh_bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 94,
    weightClass: 4,
    msrp: 210000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Polestar_2_Long_Range_AWD_BEV_2022',
    category: 'On-Road',
    name: 'Polestar 2 Long Range Dual Motor',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Polestar',
    year: 2022,
    model: '2',
    duty: 'light',
    trim: 'Long Range Dual Motor',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Polestar_2_Long_Range_AWD_BEV_2022/Polestar_2_New_Angled_View.png',
    rangeInMiles: 249,
    rangeInHours: 0,
    range: 249,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 78,
    weightClass: 1,
    msrp: 49900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Polestar_2_Long_Range_BEV_2022',
    category: 'On-Road',
    name: 'Polestar 2 Long Range Single Motor',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Polestar',
    year: 2022,
    model: '2',
    duty: 'light',
    trim: 'Long Range Single Motor',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Polestar_2_Long_Range_BEV_2022/Polestar_2_New_Angled_View.png',
    rangeInMiles: 270,
    rangeInHours: 0,
    range: 270,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 78,
    weightClass: 1,
    msrp: 45900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Porsche_Taycan__BEV_2022',
    category: 'On-Road',
    name: 'Porsche Taycan',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Porsche',
    year: 2022,
    model: 'Taycan',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Porsche_Taycan__BEV_2022/116.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 79.2,
    weightClass: 2,
    msrp: 82700,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Porsche_Taycan_Battery_Plus_BEV_2022',
    category: 'On-Road',
    name: 'Porsche Taycan Performance Battery Plus',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Porsche',
    year: 2022,
    model: 'Taycan',
    duty: 'light',
    trim: 'Performance Battery Plus',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Porsche_Taycan_Battery_Plus_BEV_2022/116.png',
    rangeInMiles: 225,
    rangeInHours: 0,
    range: 225,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 93.4,
    weightClass: 2,
    msrp: 88480,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'proterra_zx5_35_ft_bev',
    category: 'On-Road',
    name: "Proterra ZX5 35'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Proterra',
    year: 2021,
    model: 'ZX5',
    duty: 'heavy',
    trim: "35'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/proterra_zx5_35_ft_bev/proterra_zx5_35_ft_bev.png',
    rangeInMiles: 124,
    rangeInHours: 0,
    range: 124,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 225,
    weightClass: 8,
    msrp: 700000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'proterra_zx5_40_ft_bev',
    category: 'On-Road',
    name: "Proterra ZX5 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Proterra',
    year: 2021,
    model: 'ZX5',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/proterra_zx5_40_ft_bev/proterra_zx5_40_ft_bev.png',
    rangeInMiles: 117,
    rangeInHours: 0,
    range: 117,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 225,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'proterra_zx5_max_40_ft_bev',
    category: 'On-Road',
    name: "Proterra ZX5 MAX 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Proterra',
    year: 2021,
    model: 'ZX5',
    duty: 'heavy',
    trim: "MAX 40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/proterra_zx5_max_40_ft_bev/proterra_zx5_max_40_ft_bev.png',
    rangeInMiles: 297,
    rangeInHours: 0,
    range: 297,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 675,
    weightClass: 8,
    msrp: 930000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'proterra_zx5_plus_35_ft_bev',
    category: 'On-Road',
    name: "Proterra ZX5+ 35'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Proterra',
    year: 2021,
    model: 'ZX5+',
    duty: 'heavy',
    trim: "35'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/proterra_zx5_plus_35_ft_bev/proterra_zx5_plus_35_ft_bev.png',
    rangeInMiles: 240,
    rangeInHours: 0,
    range: 240,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 450,
    weightClass: 8,
    msrp: 770000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'proterra_zx5_plus_40_ft_bev',
    category: 'On-Road',
    name: "Proterra ZX5+ 40'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Proterra',
    year: 2021,
    model: 'ZX5+',
    duty: 'heavy',
    trim: "40'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/proterra_zx5_plus_40_ft_bev/proterra_zx5_plus_40_ft_bev.png',
    rangeInMiles: 216,
    rangeInHours: 0,
    range: 216,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 450,
    weightClass: 8,
    msrp: 860000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'rivian_r1s_105_kwh_bev_2021',
    category: 'On-Road',
    name: 'Rivian R1S 105 kWh',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'SUV',
    make: 'Rivian',
    year: 2021,
    model: 'R1S',
    duty: 'light',
    trim: '105 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/rivian_r1s_105_kwh_bev_2021/rivian_r1s_105_kwh_bev_2021.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 105,
    weightClass: 2,
    msrp: 70000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'rivian_r1s_135_kwh_bev_2021',
    category: 'On-Road',
    name: 'Rivian R1S 135 kWh',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'SUV',
    make: 'Rivian',
    year: 2021,
    model: 'R1S',
    duty: 'light',
    trim: '135 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/rivian_r1s_135_kwh_bev_2021/rivian_r1s_135_kwh_bev_2021.png',
    rangeInMiles: 316,
    rangeInHours: 0,
    range: 316,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 135,
    weightClass: 2,
    msrp: 76250,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'rivian_r1s_180_kwh_bev_2021',
    category: 'On-Road',
    name: 'Rivian R1S 180 kWh',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'SUV',
    make: 'Rivian',
    year: 2021,
    model: 'R1S',
    duty: 'light',
    trim: '180 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/rivian_r1s_180_kwh_bev_2021/rivian_r1s_180_kwh_bev_2021.png',
    rangeInMiles: 400,
    rangeInHours: 0,
    range: 400,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 180,
    weightClass: 2,
    msrp: 82500,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'rivian_r1t_launch_edition_bev',
    category: 'On-Road',
    name: 'Rivian R1T Launch Edition',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'Rivian',
    year: 2022,
    model: 'R1T',
    duty: 'light',
    trim: 'Launch Edition',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/rivian_r1t_launch_edition_bev/rivian_r1t_launch_edition_bev.png',
    rangeInMiles: 314,
    rangeInHours: 0,
    range: 314,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 135,
    weightClass: 2,
    msrp: 75000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'rivian_r1t_180_kwh_bev',
    category: 'On-Road',
    name: 'Rivian R1T Max Pack Battery',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'Rivian',
    year: 2022,
    model: 'R1T',
    duty: 'light',
    trim: 'Max Pack Battery',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/rivian_r1t_180_kwh_bev/rivian_r1t_180_kwh_bev.png',
    rangeInMiles: 400,
    rangeInHours: 0,
    range: 400,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 180,
    weightClass: 2,
    msrp: 77500,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'rivian_r1t_step_van_bev',
    category: 'On-Road',
    name: 'Rivian R1T Step Van',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'Rivian',
    year: 2022,
    model: 'R1T',
    duty: 'medium',
    trim: 'Step Van',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/rivian_r1t_step_van_bev/rivian_r1t_step_van_bev.png',
    rangeInMiles: 400,
    rangeInHours: 0,
    range: 400,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 180,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'roush_ford_f-650__bev',
    category: 'On-Road',
    name: 'Roush Ford F-650',
    type: 'Cutaway Chassis',
    subtype: 'Heavy Duty',
    formFactor: 'cutaway_chassis',
    make: 'Roush',
    year: 2021,
    model: 'Ford F-650',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/roush_ford_f-650__bev/roush_ford_f-650__bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 7,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_ford_f-450__bev',
    category: 'On-Road',
    name: 'SEA Electric Ford F-450',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    formFactor: 'cutaway_chassis',
    make: 'SEA Electric',
    year: 2021,
    model: 'Ford F-450',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_ford_f-450__bev/sea_electric_ford_f-450__bev.png',
    rangeInMiles: 220,
    rangeInHours: 0,
    range: 220,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 4,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_ford_f-59__bev',
    category: 'On-Road',
    name: 'SEA Electric Ford F-59',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'SEA Electric',
    year: 2021,
    model: 'Ford F-59',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_ford_f-59__bev/sea_electric_ford_f-59__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 5,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_ford_f-650__bev',
    category: 'On-Road',
    name: 'SEA Electric Ford F-650',
    type: 'Cutaway Chassis',
    subtype: 'Heavy Duty',
    formFactor: 'cutaway_chassis',
    make: 'SEA Electric',
    year: 2021,
    model: 'Ford F-650',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_ford_f-650__bev/sea_electric_ford_f-650__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 7,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_ford_f-750__bev',
    category: 'On-Road',
    name: 'SEA Electric Ford F-750',
    type: 'Cutaway Chassis',
    subtype: 'Heavy Duty',
    formFactor: 'cutaway_chassis',
    make: 'SEA Electric',
    year: 2021,
    model: 'Ford F-750',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_ford_f-750__bev/sea_electric_ford_f-750__bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 7,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_ford_transit_ev__bev',
    category: 'On-Road',
    name: 'SEA Electric Ford Transit EV',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'SEA Electric',
    year: 2021,
    model: 'Ford Transit EV',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_ford_transit_ev__bev/sea_electric_ford_transit_ev__bev.png',
    rangeInMiles: 190,
    rangeInHours: 0,
    range: 190,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 88,
    weightClass: 3,
    msrp: 120000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_hino_195__bev',
    category: 'On-Road',
    name: 'SEA Electric Hino 195',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'SEA Electric',
    year: 2021,
    model: 'Hino 195',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_hino_195__bev/sea_electric_hino_195__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 5,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_isuzu_ftr__bev',
    category: 'On-Road',
    name: 'SEA Electric Isuzu FTR',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'SEA Electric',
    year: 2021,
    model: 'Isuzu FTR',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_isuzu_ftr__bev/sea_electric_isuzu_ftr__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 160,
    weightClass: 7,
    msrp: 220000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_isuzu_npr__bev',
    category: 'On-Road',
    name: 'SEA Electric Isuzu NPR',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'SEA Electric',
    year: 2021,
    model: 'Isuzu NPR',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_isuzu_npr__bev/sea_electric_isuzu_npr__bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 3,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_isuzu_nqr__bev',
    category: 'On-Road',
    name: 'SEA Electric Isuzu NQR',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'SEA Electric',
    year: 2021,
    model: 'Isuzu NQR',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_isuzu_nqr__bev/sea_electric_isuzu_nqr__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 136,
    weightClass: 5,
    msrp: 190000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_isuzu_nrr__bev',
    category: 'On-Road',
    name: 'SEA Electric Isuzu NRR',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'SEA Electric',
    year: 2021,
    model: 'Isuzu NRR',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_isuzu_nrr__bev/sea_electric_isuzu_nrr__bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 7,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_sea_e4b__bev',
    category: 'On-Road',
    name: 'SEA Electric SEA E4B',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    formFactor: 'shuttle_bus',
    make: 'SEA Electric',
    year: 2021,
    model: 'SEA E4B',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_sea_e4b__bev/sea_electric_sea_e4b__bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 88,
    weightClass: 3,
    msrp: 55000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_sea_ev4__bev',
    category: 'On-Road',
    name: 'SEA Electric SEA EV4',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    formFactor: 'cargo_van',
    make: 'SEA Electric',
    year: 2021,
    model: 'SEA EV4',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_sea_ev4__bev/sea_electric_sea_ev4__bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 88,
    weightClass: 3,
    msrp: 55000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_sea-drive_120a_bev',
    category: 'On-Road',
    name: 'SEA Electric SEA-Drive 120a',
    type: 'BEV Drive System',
    subtype: 'Medium Duty',
    formFactor: 'bev_drive_system',
    make: 'SEA Electric',
    year: 2021,
    model: 'SEA-Drive',
    duty: 'medium',
    trim: '120a',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_sea-drive_120a_bev/sea_electric_sea-drive_120a_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 5,
    msrp: 120000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_sea-drive_120b_bev',
    category: 'On-Road',
    name: 'SEA Electric SEA-Drive 120b',
    type: 'BEV Drive System',
    subtype: 'Heavy Duty',
    formFactor: 'bev_drive_system',
    make: 'SEA Electric',
    year: 2021,
    model: 'SEA-Drive',
    duty: 'heavy',
    trim: '120b',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_sea-drive_120b_bev/sea_electric_sea-drive_120b_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 7,
    msrp: 198000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_sea-drive_120c_bev',
    category: 'On-Road',
    name: 'SEA Electric SEA-Drive 120c',
    type: 'BEV Drive System',
    subtype: 'Heavy Duty',
    formFactor: 'bev_drive_system',
    make: 'SEA Electric',
    year: 2021,
    model: 'SEA-Drive',
    duty: 'heavy',
    trim: '120c',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_sea-drive_120c_bev/sea_electric_sea-drive_120c_bev.png',
    rangeInMiles: 170,
    rangeInHours: 0,
    range: 170,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 138,
    weightClass: 7,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_sea-drive_180a_bev',
    category: 'On-Road',
    name: 'SEA Electric SEA-Drive 180a',
    type: 'BEV Drive System',
    subtype: 'Heavy Duty',
    formFactor: 'bev_drive_system',
    make: 'SEA Electric',
    year: 2021,
    model: 'SEA-Drive',
    duty: 'heavy',
    trim: '180a',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_sea-drive_180a_bev/sea_electric_sea-drive_180a_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 220,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'sea_electric_sea-drive_180b_bev',
    category: 'On-Road',
    name: 'SEA Electric SEA-Drive 180b',
    type: 'BEV Drive System',
    subtype: 'Heavy Duty',
    formFactor: 'bev_drive_system',
    make: 'SEA Electric',
    year: 2021,
    model: 'SEA-Drive',
    duty: 'heavy',
    trim: '180b',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/sea_electric_sea-drive_180b_bev/sea_electric_sea-drive_180b_bev.png',
    rangeInMiles: 125,
    rangeInHours: 0,
    range: 125,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 220,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'solaris_urbino_12_electric_39_ft_bev',
    category: 'On-Road',
    name: "Solaris Urbino 12 Electric 39'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Solaris',
    year: 2021,
    model: 'Urbino 12 Electric',
    duty: 'heavy',
    trim: "39'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/solaris_urbino_12_electric_39_ft_bev/solaris_urbino_12_electric_39_ft_bev.png',
    rangeInMiles: 125,
    rangeInHours: 0,
    range: 125,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 395,
    weightClass: 8,
    msrp: 700000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.43,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'solaris_urbino_15_le_electric_49_ft_bev',
    category: 'On-Road',
    name: "Solaris Urbino 15 LE Electric 49'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Solaris',
    year: 2021,
    model: 'Urbino 15 LE Electric',
    duty: 'heavy',
    trim: "49'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/solaris_urbino_15_le_electric_49_ft_bev/solaris_urbino_15_le_electric_49_ft_bev.png',
    rangeInMiles: 125,
    rangeInHours: 0,
    range: 125,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 470,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'solaris_urbino_18_electric_59_ft_bev',
    category: 'On-Road',
    name: "Solaris Urbino 18 Electric 59'",
    type: 'Transit Bus',
    subtype: 'Articulated Bus',
    formFactor: 'transit_bus',
    make: 'Solaris',
    year: 2021,
    model: 'Urbino 18 Electric',
    duty: 'heavy',
    trim: "59'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/solaris_urbino_18_electric_59_ft_bev/solaris_urbino_18_electric_59_ft_bev.png',
    rangeInMiles: 124,
    rangeInHours: 0,
    range: 124,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 553,
    weightClass: 8,
    msrp: 1200000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'solaris_urbino_8.9_le_electric_29_ft_bev',
    category: 'On-Road',
    name: "Solaris Urbino 8.9 LE Electric 29'",
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    formFactor: 'transit_bus',
    make: 'Solaris',
    year: 2021,
    model: 'Urbino 8.9 LE Electric',
    duty: 'heavy',
    trim: "29'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/solaris_urbino_8.9_le_electric_29_ft_bev/solaris_urbino_8.9_le_electric_29_ft_bev.png',
    rangeInMiles: 93,
    rangeInHours: 0,
    range: 93,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 124,
    weightClass: 8,
    msrp: 210000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.28,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'tesla_cybertruck_dual_motor_awd_bev_2021',
    category: 'On-Road',
    name: 'Tesla Cybertruck Dual Motor AWD',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'Tesla',
    year: 2022,
    model: 'Cybertruck',
    duty: 'light',
    trim: 'Dual Motor AWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/tesla_cybertruck_dual_motor_awd_bev_2021/tesla_cybertruck_dual_motor_awd_bev_2021.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 120,
    weightClass: 2,
    msrp: 49900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'tesla_cybertruck_single_motor_rwd_bev_2021',
    category: 'On-Road',
    name: 'Tesla Cybertruck Single Motor RWD',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'Tesla',
    year: 2022,
    model: 'Cybertruck',
    duty: 'light',
    trim: 'Single Motor RWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/tesla_cybertruck_single_motor_rwd_bev_2021/tesla_cybertruck_single_motor_rwd_bev_2021.png',
    rangeInMiles: 250,
    rangeInHours: 0,
    range: 250,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 2,
    msrp: 39900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'tesla_cybertruck_tri_motor_awd_bev_2022',
    category: 'On-Road',
    name: 'Tesla Cybertruck Tri Motor AWD',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    formFactor: 'pickup_truck',
    make: 'Tesla',
    year: 2022,
    model: 'Cybertruck',
    duty: 'light',
    trim: 'Tri Motor AWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/tesla_cybertruck_tri_motor_awd_bev_2022/tesla_cybertruck_tri_motor_awd_bev_2022.png',
    rangeInMiles: 500,
    rangeInHours: 0,
    range: 500,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 200,
    weightClass: 2,
    msrp: 69900,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_3_Long_Range_AWD_BEV_2022',
    category: 'On-Road',
    name: 'Tesla Model 3 Long Range AWD',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Tesla',
    year: 2021,
    model: 'Model 3',
    duty: 'light',
    trim: 'Long Range AWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_3_Long_Range_AWD_BEV_2021/116.png',
    rangeInMiles: 353,
    rangeInHours: 0,
    range: 353,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 82,
    weightClass: 1,
    msrp: 49990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_3_Performance_AWD_BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model 3 Performance AWD',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Tesla',
    year: 2021,
    model: 'Model 3',
    duty: 'light',
    trim: 'Performance AWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_3_Performance_AWD_BEV_2021/116.png',
    rangeInMiles: 315,
    rangeInHours: 0,
    range: 315,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 82,
    weightClass: 1,
    msrp: 57990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_3_Standard_Range_Plus__BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model 3 Standard Range Plus',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Tesla',
    year: 2021,
    model: 'Model 3',
    duty: 'light',
    trim: 'Standard Range Plus',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_3_Standard_Range_Plus__BEV_2021/116.png',
    rangeInMiles: 263,
    rangeInHours: 0,
    range: 263,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 55,
    weightClass: 1,
    msrp: 43990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_S_Long_Range_Plus_BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model S Long Range',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Tesla',
    year: 2021,
    model: 'Model S',
    duty: 'light',
    trim: 'Long Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_S_Long_Range_Plus_BEV_2021/116.png',
    rangeInMiles: 405,
    rangeInHours: 0,
    range: 405,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 1,
    msrp: 94990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_S_Plaid_BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model S Plaid',
    type: 'Passenger',
    subtype: 'Sedan',
    formFactor: 'Sedan',
    make: 'Tesla',
    year: 2021,
    model: 'Model S',
    duty: 'light',
    trim: 'Plaid',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_S_Plaid_BEV_2021/116.png',
    rangeInMiles: 396,
    rangeInHours: 0,
    range: 396,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 1,
    msrp: 129990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.095,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_X_Long_Range_BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model X Long Range',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Tesla',
    year: 2021,
    model: 'Model X',
    duty: 'light',
    trim: 'Long Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_X_Long_Range_BEV_2021/116.png',
    rangeInMiles: 360,
    rangeInHours: 0,
    range: 360,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 2,
    msrp: 104990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_X_Plaid_BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model X Plaid',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Tesla',
    year: 2021,
    model: 'Model X',
    duty: 'light',
    trim: 'Plaid',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_X_Plaid_BEV_2021/116.png',
    rangeInMiles: 340,
    rangeInHours: 0,
    range: 340,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 100,
    weightClass: 2,
    msrp: 119990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_Y_Long_Range_AWD_BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model Y Long Range AWD',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Tesla',
    year: 2021,
    model: 'Model Y',
    duty: 'light',
    trim: 'Long Range AWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_Y_Long_Range_AWD_BEV_2021/116.png',
    rangeInMiles: 326,
    rangeInHours: 0,
    range: 326,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 75,
    weightClass: 1,
    msrp: 56990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Tesla_Model_Y_Performance_BEV_2021',
    category: 'On-Road',
    name: 'Tesla Model Y Performance AWD',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Tesla',
    year: 2021,
    model: 'Model Y',
    duty: 'light',
    trim: 'Performance AWD',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Tesla_Model_Y_Performance_BEV_2021/116.png',
    rangeInMiles: 303,
    rangeInHours: 0,
    range: 303,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 75,
    weightClass: 1,
    msrp: 61990,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'tesla_semi_300_mile_range_bev',
    category: 'On-Road',
    name: 'Tesla Semi 300 Mile Range',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Tesla',
    year: 2021,
    model: 'Semi',
    duty: 'heavy',
    trim: '300 Mile Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/tesla_semi_300_mile_range_bev/tesla_semi_300_mile_range_bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 1000,
    weightClass: 8,
    msrp: 150000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'tesla_semi_500_mile_range_bev',
    category: 'On-Road',
    name: 'Tesla Semi 500 Mile Range',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Tesla',
    year: 2021,
    model: 'Semi',
    duty: 'heavy',
    trim: '500 Mile Range',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/tesla_semi_500_mile_range_bev/tesla_semi_500_mile_range_bev.png',
    rangeInMiles: 500,
    rangeInHours: 0,
    range: 500,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 1000,
    weightClass: 8,
    msrp: 180000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'thomas_built_buses_saf-t-liner_c2_jouley__bev',
    category: 'On-Road',
    name: 'Thomas Built Buses Saf-T-Liner C2 Jouley',
    type: 'School Bus',
    subtype: 'Type C',
    formFactor: 'school_bus',
    make: 'Thomas Built Buses',
    year: 2021,
    model: 'Saf-T-Liner C2 Jouley',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/thomas_built_buses_saf-t-liner_c2_jouley__bev/thomas_built_buses_saf-t-liner_c2_jouley__bev.png',
    rangeInMiles: 135,
    rangeInHours: 0,
    range: 135,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 220,
    weightClass: 8,
    msrp: 350000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'van_hool_cx45e_45_ft_bev',
    category: 'On-Road',
    name: "Van Hool CX45E 45'",
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    formFactor: 'motor_coach',
    make: 'Van Hool',
    year: 2021,
    model: 'CX45E',
    duty: 'heavy',
    trim: "45'",
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/van_hool_cx45e_45_ft_bev/van_hool_cx45e_45_ft_bev.png',
    rangeInMiles: 310,
    rangeInHours: 0,
    range: 310,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 676,
    weightClass: 8,
    msrp: 790000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.45,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Volkswagen_ID.4__BEV_2021',
    category: 'On-Road',
    name: 'Volkswagen ID.4',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Volkswagen',
    year: 2021,
    model: 'ID.4',
    duty: 'light',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Volkswagen_ID.4__BEV_2021/116.png',
    rangeInMiles: 260,
    rangeInHours: 0,
    range: 260,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 82,
    weightClass: 1,
    msrp: 39995,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'Volvo_XC40_Recharge_Pure_Electric_BEV_2022',
    category: 'On-Road',
    name: 'Volvo XC40 Recharge Pure Electric',
    type: 'Passenger',
    subtype: 'SUV',
    formFactor: 'Crossover',
    make: 'Volvo',
    year: 2022,
    model: 'XC40 Recharge',
    duty: 'light',
    trim: 'Pure Electric',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/Volvo_XC40_Recharge_Pure_Electric_BEV_2022/116.png',
    rangeInMiles: 223,
    rangeInHours: 0,
    range: 223,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 78,
    weightClass: 1,
    msrp: 55300,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.1,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'volvo_fe_electric__bev',
    category: 'On-Road',
    name: 'Volvo Trucks FE Electric',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'Volvo Trucks',
    year: 2021,
    model: 'FE Electric',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/volvo_fe_electric__bev/volvo_fe_electric__bev.png',
    rangeInMiles: 124,
    rangeInHours: 0,
    range: 124,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 300,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'volvo_fl_electric__bev',
    category: 'On-Road',
    name: 'Volvo Trucks FL Electric',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'Volvo Trucks',
    year: 2021,
    model: 'FL Electric',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/volvo_fl_electric__bev/volvo_fl_electric__bev.png',
    rangeInMiles: 186,
    rangeInHours: 0,
    range: 186,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 300,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'volvo_vnr_electric_4x2_straight_bev',
    category: 'On-Road',
    name: 'Volvo Trucks VNR Electric 4x2 Straight',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    formFactor: 'box_truck',
    make: 'Volvo Trucks',
    year: 2021,
    model: 'VNR Electric',
    duty: 'heavy',
    trim: '4x2 Straight',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/volvo_vnr_electric_4x2_straight_bev/volvo_vnr_electric_4x2_straight_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 264,
    weightClass: 7,
    msrp: 250000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.23,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'volvo_vnr_electric_4x2_tractor_bev',
    category: 'On-Road',
    name: 'Volvo Trucks VNR Electric 4x2 Tractor',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Volvo Trucks',
    year: 2021,
    model: 'VNR Electric',
    duty: 'heavy',
    trim: '4x2 Tractor',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/volvo_vnr_electric_4x2_tractor_bev/volvo_vnr_electric_4x2_tractor_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 264,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'volvo_vnr_electric_6x2_tractor_bev',
    category: 'On-Road',
    name: 'Volvo Trucks VNR Electric 6x2 Tractor',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Volvo Trucks',
    year: 2021,
    model: 'VNR Electric',
    duty: 'heavy',
    trim: '6x2 Tractor',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/volvo_vnr_electric_6x2_tractor_bev/volvo_vnr_electric_6x2_tractor_bev.png',
    rangeInMiles: 150,
    rangeInHours: 0,
    range: 150,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 264,
    weightClass: 8,
    msrp: 300000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'workhorse_c1000__bev',
    category: 'On-Road',
    name: 'Workhorse C1000',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'Workhorse',
    year: 2021,
    model: 'C1000',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/workhorse_c1000__bev/workhorse_c1000__bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 70,
    weightClass: 3,
    msrp: 120000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'workhorse_c650__bev',
    category: 'On-Road',
    name: 'Workhorse C650',
    type: 'Step Van',
    subtype: 'Medium Duty',
    formFactor: 'step_van',
    make: 'Workhorse',
    year: 2021,
    model: 'C650',
    duty: 'medium',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/workhorse_c650__bev/workhorse_c650__bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 70,
    weightClass: 3,
    msrp: 120000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'xos_delivery_van_120_kwh_bev',
    category: 'On-Road',
    name: 'Xos Delivery Van 120 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Xos',
    year: 2021,
    model: 'Delivery Van',
    duty: 'medium',
    trim: '120 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/xos_delivery_van_120_kwh_bev/xos_delivery_van_120_kwh_bev.png',
    rangeInMiles: 200,
    rangeInHours: 0,
    range: 200,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 120,
    weightClass: 6,
    msrp: 88000,
    msrpIsEstimated: true,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'xos_delivery_van__bev',
    category: 'On-Road',
    name: 'Xos Delivery Van 60 kWh',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    formFactor: 'box_truck',
    make: 'Xos',
    year: 2021,
    model: 'Delivery Van',
    duty: 'medium',
    trim: '60 kWh',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/xos_delivery_van__bev/xos_delivery_van__bev.png',
    rangeInMiles: 100,
    rangeInHours: 0,
    range: 100,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 60,
    weightClass: 6,
    msrp: 68000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.16,
    maintenanceCostPerHour: 0,
  },
  {
    fromApi: true,
    id: 'xos_et-one__bev',
    category: 'On-Road',
    name: 'Xos ET-One',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    formFactor: 'trailer_truck',
    make: 'Xos',
    year: 2021,
    model: 'ET-One',
    duty: 'heavy',
    trim: '',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/still-set-1280/xos_et-one__bev/xos_et-one__bev.png',
    rangeInMiles: 300,
    rangeInHours: 0,
    range: 300,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 360,
    weightClass: 8,
    msrp: 250000,
    msrpIsEstimated: false,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0,
  },
];

export default sampleApiVehicles;
