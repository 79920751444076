import React from 'react';
import PanelIntro from '../PanelIntro/PanelIntro';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import { TotalCostProjectChart, PaybackPeriodChart } from './TcoCharts';
import times from 'lodash/times';
import sum from 'lodash/sum';
import min from 'lodash/min';
import Site from '../../calculations/Site';
import Totals from '../../calculations/Totals';
import { formatAsDollars } from '../../utils/formatters';
import Section from '../../components/Section/Section';
import IncentivesSection from './Components/IncentivesSection/IncentivesSection';

export default function TCO() {
  const { formatMessage } = useIntl();
  const { selectedSite, startYear, dieselPrice, gasolinePrice, isAllSites, sites } = useInputs();
  const firstReplacementYear = min(
    sites.flatMap((site) => site.vehicleSets).map(({ replacementYear }) => replacementYear),
  );
  const yearsOfOperation = 10 - (firstReplacementYear - startYear);

  const retiringFleetCosts = isAllSites
    ? Totals.totalCosts(
        sites,
        startYear,
        true,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      )
    : Site.totalCosts(
        selectedSite,
        startYear,
        true,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      );

  const electrifiedFleetCosts = isAllSites
    ? Totals.totalCosts(
        sites,
        startYear,
        false,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      )
    : Site.totalCosts(
        selectedSite,
        startYear,
        false,
        {
          dieselPrice,
          gasolinePrice,
        },
        true,
      );

  // difference between retiring fleet and electrified fleet, can be negative if EVs are more expensive
  const lifetimeSavings =
    sum(Object.values(retiringFleetCosts.byCategory)) -
    sum(Object.values(electrifiedFleetCosts.byCategory));

  // first year where electrified fleet costs less than retiring fleet (or undefined if it doesn't happen within 10 years)
  let emptyYears = 0;
  const paybackYear =
    times(10).find((year) => {
      if (electrifiedFleetCosts.byYear[year] === 0 && retiringFleetCosts.byYear[year] === 0) {
        emptyYears++;
        return false;
      }
      return electrifiedFleetCosts.byYear[year] <= retiringFleetCosts.byYear[year];
    }) - emptyYears;

  return (
    <Box>
      <PanelIntro title="panels.tco.title" summary="panels.tco.summary" />
      <Section
        title={formatMessage({ id: 'panels.tco.totalCost' })}
        description={
          lifetimeSavings > 0 &&
          formatMessage(
            { id: 'panels.tco.willSave' },
            {
              value: <b>{formatAsDollars(Math.floor(lifetimeSavings))}</b>,
              years: <b>10 {formatMessage({ id: 'years' })}</b>,
              totalYears: (
                <b>
                  {yearsOfOperation} {formatMessage({ id: 'years' })}
                </b>
              ),
            },
          )
        }
      >
        <TotalCostProjectChart
          retiringFleetCosts={retiringFleetCosts.byCategory}
          electrifiedFleetCosts={electrifiedFleetCosts.byCategory}
          vehicleSets={selectedSite.vehicleSets}
        />
        <p>
          {formatMessage(
            { id: 'panels.tco.chartDisclaimer' },
            {
              link: (
                <b>
                  <a href="http://afleet.es.anl.gov/afleet/">http://afleet.es.anl.gov/afleet/</a>
                </b>
              ),
            },
          )}
        </p>
      </Section>
      <Section
        title={formatMessage({ id: 'panels.tco.payback' })}
        description={
          [NaN, undefined, null].includes(paybackYear)
            ? undefined
            : formatMessage(
                { id: 'panels.tco.initialInvestment' },
                {
                  years: (
                    <b>
                      {paybackYear + 1}{' '}
                      {formatMessage({ id: paybackYear === 0 ? 'year' : 'years' })}
                    </b>
                  ),
                },
              )
        }
      >
        <PaybackPeriodChart
          retiringFleetCosts={retiringFleetCosts.byYear}
          electrifiedFleetCosts={electrifiedFleetCosts.byYear}
          startYear={startYear}
          firstReplacementYear={firstReplacementYear}
        />
      </Section>
      <Section
        title={'Incentives'}
        description={
          'You may be eligible incentives for some or all of your vehicle sets. Open the containers below to include incentives in your calculations.'
        }
      >
        <IncentivesSection selectedSite={selectedSite} />
      </Section>
    </Box>
  );
}
